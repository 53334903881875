import {
  AppLayout,
  Box,
  Container,
  ContentLayout,
  Flashbar,
  FlashbarProps,
  FormField,
  Multiselect,
  Select,
  SpaceBetween
} from '@amzn/awsui-components-react';
import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import * as queries from 'src/graphql/queries';
import { isDefinedAndNotEmptyObject } from 'src/utils/CommonHelpers';
import { eUserTablePreferenceKeys } from 'src/utils/DmmConstants';
import { logger } from 'src/utils/Logger';
import { usePersistedState } from '../commons/CustomHooks/use-local-storage';
import { DropdownModel, MultiSelectGroupDropdownModel } from '../context/AppContextModel';
import { useAppContext } from '../context/AppContextProvider';
import { getMultiSelectPlaceHolderValue } from '../generic-components/Utils';
import { AppBreadcrumb } from '../navigation/AppBreadcrumb';
import { AppSideNavigation } from '../navigation/AppSideNavigation';
import { CampaignsContext } from './CampaignContext';
import {
  CorpDimensionsList,
  CountryAndCompanyCodeMapping,
  ListProductLineDimensions,
  ProductLine,
  ProductLineCampaignsEntity
} from './CampaignModels';
import { CampaignTable } from './CampaignTable';
import { CampaignToolContent } from './CampaignToolContent';
import * as CampaignUtils from './CampaignUtils';

const EMPTY_DROPDOWN_OPTION = { label: 'Choose an option', value: '' };
const EMPTY_MULTI_SELECT_ALL_INITIAL_VALUE = [
  {
    label: 'Select All',
    value: 'Select All',
    options: []
  }
];

export const Campaigns: React.FC = () => {
  const appContext = useAppContext();
  const navigate = useNavigate();

  const { CampaignId } = useParams();
  const [campaignItems, setCampaignItems] = useState<ProductLineCampaignsEntity[]>([]);
  const [campaignsLoading, setCampaignsLoading] = useState(false);

  const [filteredCampaignItems, setFilteredCampaignItems] = useState<ProductLineCampaignsEntity[]>([]);

  const [countryCompanyMapping, setCountryAndCompanyMapping] = useState<CountryAndCompanyCodeMapping[]>([]);
  const [corpDimensionsList, setCorpDimensionsList] = useState<CorpDimensionsList>({} as CorpDimensionsList);
  const [listProductLineDimensions, setListProductLineDimensions] = useState<ListProductLineDimensions[]>([]);

  const [campaignBreadcrumbs, setCampaignBreadcrumbs] = useState<any[]>([]);
  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);
  const [toolsOpen, setToolsOpen] = useState(false);

  const [productLineOptions, setProductLineOptions] = useState<DropdownModel[]>([]);
  const [selectedProductLineOption, setSelectedProductLineOption] = usePersistedState(
    eUserTablePreferenceKeys.CAMPAIGN_PRODUCT_LINE_DROPDOWN_OPTION,
    EMPTY_DROPDOWN_OPTION
  );
  const [selectedProductLine, setSelectedProductLine] = useState<ProductLine>({} as ProductLine);

  const [campaignList, setCampaignList] = useState<MultiSelectGroupDropdownModel[]>(EMPTY_MULTI_SELECT_ALL_INITIAL_VALUE);
  const [countryList, setCountryList] = useState<MultiSelectGroupDropdownModel[]>(EMPTY_MULTI_SELECT_ALL_INITIAL_VALUE);

  const [selectedCampaign, setSelectedCampaign] = usePersistedState(eUserTablePreferenceKeys.CAMPAIGN_CAMPAIGN_DROPDOWN_OPTION, []);
  const [selectedCountry, setSelectedCountry] = usePersistedState(eUserTablePreferenceKeys.CAMPAIGN_COUNTRY_DROPDOWN_OPTION, []);

  useEffect(() => {
    setCampaignBreadcrumbs(CampaignUtils.getUserBreadcrumbs(CampaignId));
  }, [CampaignId]);

  const notificationHandler = (status: boolean, notificationMessage: string, notificationType: FlashbarProps.Type) => {
    displayFlashMessage(status, notificationMessage, notificationType);
  };

  const displayFlashMessage = (loading: boolean, content: string, flashbarType: FlashbarProps.Type) => {
    setFlashbarItems([
      {
        loading: loading,
        type: flashbarType,
        content: content,
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => setFlashbarItems([])
      }
    ]);
  };

  /*
    Fetches Product Lines for which the user has access
    By default, Dropdown will be selected with the first option
  */
  useEffect(() => {
    const userProductLines: DropdownModel[] = appContext.userDetails.userProductLines?.map((productLine) => {
      return {
        label: productLine.productName,
        value: `${productLine.productID}`
      } as DropdownModel;
    });
    setProductLineOptions(userProductLines);
    if (JSON.stringify(selectedProductLineOption) !== JSON.stringify(EMPTY_DROPDOWN_OPTION)) {
      setSelectedProductLineOption(selectedProductLineOption);
      assignProductLineFound(selectedProductLineOption);
    } else {
      setSelectedProductLineOption(userProductLines.length > 0 ? userProductLines[0] : EMPTY_DROPDOWN_OPTION);
      assignProductLineFound(userProductLines[0]);
    }
  }, [appContext.userDetails.userProductLines]);

  const onProductLineChange = (detail: any) => {
    setSelectedProductLineOption(detail.selectedOption);
    clearCountryAndCampaignSelection();
    assignProductLineFound(detail.selectedOption);
    CampaignId && navigate('/campaigns');
  };

  const assignProductLineFound = (selectedProductLineOption: DropdownModel) => {
    const productLineFound = appContext.userDetails.userProductLines?.find(
      (productLine) => productLine.productID === +selectedProductLineOption.value
    );
    productLineFound && setSelectedProductLine(productLineFound as ProductLine);
  };

  const campaignErrorText = () => {
    if (isDefinedAndNotEmptyObject(selectedProductLine) && campaignList?.[0].options.length === 0) {
      return 'No campaigns available for the selection';
    } else if (isDefinedAndNotEmptyObject(selectedProductLine) && selectedCampaign.length === 0) {
      return 'Select at least one campaign';
    } else {
      return '';
    }
  };

  const countryErrorText = () => {
    if (isDefinedAndNotEmptyObject(selectedProductLine) && countryList?.[0].options.length === 0) {
      return 'No countries available for the selection';
    } else if (isDefinedAndNotEmptyObject(selectedProductLine) && selectedCountry.length === 0) {
      return 'Select at least one country';
    } else {
      return '';
    }
  };

  useEffect(() => {
    const uniqueCampaigns = processUniqueOptions(campaignItems, 'campaignName', '');
    setCampaignList([
      {
        label: 'Select All',
        value: 'Select All',
        options: uniqueCampaigns
      }
    ]);

    const uniqueCountries = processUniqueOptions(campaignItems, 'country', '-');
    setCountryList([
      {
        label: 'Select All',
        value: 'Select All',
        options: uniqueCountries
      }
    ]);
  }, [campaignItems]);

  // Processes unique options from campaign items, replacing null or empty with a fallback
  const processUniqueOptions = (items: ProductLineCampaignsEntity[], key: 'campaignName' | 'country', emptyFallback: string): DropdownModel[] => {
    const uniqueItems = Array.from(new Set(items.map((item) => item[key] ?? emptyFallback)));
    return uniqueItems
      .map((item) => ({
        value: item,
        label: item || emptyFallback, // Display fallback if value is empty
        disabled: false
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  const clearCountryAndCampaignSelection = () => {
    setCountryList(EMPTY_MULTI_SELECT_ALL_INITIAL_VALUE);
    setSelectedCountry([]);
    setCampaignList(EMPTY_MULTI_SELECT_ALL_INITIAL_VALUE);
    setSelectedCampaign([]);
  };

  useEffect(() => {
    filterCampaignItems();
  }, [selectedCountry, selectedCampaign, campaignItems]);

  const filterCampaignItems = () => {
    const filteredData = campaignItems.filter((item) => {
      // Check if the country or channel values exist in the countryList and channelList
      return (
        selectedCampaign.filter((campaign: any) => campaign.label !== 'Select All').some((campaign: any) => campaign.value === item.campaignName) &&
        selectedCountry.filter((campaign: any) => campaign.label !== 'Select All').some((country: any) => country.value === item.country)
      );
    });
    setFilteredCampaignItems(filteredData);
  };

  const refreshCampaigns = () => {
    getCampaigns();
  };

  const getCampaigns = async () => {
    if (selectedProductLine.productID) {
      setCampaignsLoading(true);
      try {
        const response: any = await API.graphql({
          query: queries.listProductLineCampaigns,
          variables: {
            dataClassificationId: appContext.userDetails.dataClassification.dataClassificationId,
            productLineId: selectedProductLine.productID
          }
        });
        setCampaignItems(CampaignUtils.parseCampaignData(response.data.listProductLineCampaigns));
        setCampaignsLoading(false);
      } catch (error: any) {
        logger.error('Unable to load campaign data.', error);
        setCampaignsLoading(false);
      }
    }
  };

  return (
    <>
      <AppLayout
        headerSelector="#h"
        contentType="default"
        navigation={<AppSideNavigation />}
        breadcrumbs={<AppBreadcrumb items={campaignBreadcrumbs} />}
        notifications={<Flashbar items={flashbarItems} />}
        tools={<CampaignToolContent />}
        toolsOpen={toolsOpen}
        onToolsChange={({ detail }) => setToolsOpen(detail.open)}
        disableContentPaddings
        content={
          <>
            <CampaignsContext.Provider
              value={{
                campaignItems,
                setCampaignItems,
                campaignsLoading,
                setCampaignsLoading,
                filteredCampaignItems,
                setFilteredCampaignItems,
                corpDimensionsList,
                setCorpDimensionsList,
                countryCompanyMapping,
                setCountryAndCompanyMapping,
                notificationHandler,
                selectedProductLine,
                setSelectedProductLine,
                listProductLineDimensions,
                setListProductLineDimensions,
                toolsOpen,
                setToolsOpen,
                refreshCampaigns
              }}
            >
              <ContentLayout>
                <Box margin={{ top: 'l', bottom: 'xl', left: 'xxxl', right: 'xxxl' }} padding={{ left: 'xxxl', right: 'xxxl' }}>
                  <SpaceBetween size="m">
                    {/* Campaign - Header Section - To select "Product Line , Campaigns and Country" */}
                    <Container>
                      <SpaceBetween direction="horizontal" size="m">
                        {/* Product line dropdown  */}
                        <FormField className="form-field-select-width-15rem" label="Product Line">
                          <Select
                            expandToViewport={true}
                            filteringType="auto"
                            options={productLineOptions}
                            selectedOption={selectedProductLineOption}
                            onChange={({ detail }) => {
                              onProductLineChange(detail);
                            }}
                            empty="No options"
                            placeholder="Choose an option"
                          ></Select>
                        </FormField>

                        {/* List of campaigns from forecast template data */}
                        <FormField
                          className="multi-select-place-holder-form-field form-field-select-width-20rem"
                          label="Campaign"
                          errorText={campaignErrorText()}
                        >
                          <Multiselect
                            filteringType="auto"
                            expandToViewport
                            selectedAriaLabel="Selected"
                            options={campaignList}
                            selectedOptions={selectedCampaign}
                            onChange={({ detail }) => setSelectedCampaign(detail.selectedOptions as MultiSelectGroupDropdownModel[])}
                            hideTokens
                            placeholder={getMultiSelectPlaceHolderValue(selectedCampaign, 'Campaign')}
                          />
                        </FormField>

                        {/* List of countries from forecast template data */}
                        <FormField
                          className="multi-select-place-holder-form-field form-field-select-width-20rem"
                          label="Country"
                          errorText={countryErrorText()}
                        >
                          <Multiselect
                            filteringType="auto"
                            expandToViewport
                            selectedAriaLabel="Selected"
                            options={countryList}
                            selectedOptions={selectedCountry}
                            onChange={({ detail }) => setSelectedCountry(detail.selectedOptions as MultiSelectGroupDropdownModel[])}
                            placeholder={getMultiSelectPlaceHolderValue(selectedCountry, 'Country')}
                            hideTokens
                          />
                        </FormField>
                      </SpaceBetween>
                    </Container>

                    {/* Based on PL selection, display Campaigns  */}
                    {CampaignId && (
                      <Outlet /> // Outlet for selected Campaign
                    )}
                    {!CampaignId && <CampaignTable />}
                  </SpaceBetween>
                </Box>
              </ContentLayout>
            </CampaignsContext.Provider>
          </>
        }
      ></AppLayout>
    </>
  );
};
