import React, { useState, useEffect } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  AppLayout,
  Box,
  Button,
  Container,
  FlashbarProps,
  Flashbar,
  Link,
  Header,
  Select,
  SpaceBetween,
  Table,
  Pagination,
  PropertyFilter,
  ContentLayout
} from '@amzn/awsui-components-react/polaris';
import { API } from 'aws-amplify';

import { logger } from 'src/utils/Logger';
import * as queries from 'src/graphql/queries';

import { BaseBreadcrumbs, eUserTablePreferenceKeys } from 'src/utils/DmmConstants';
import { AppBreadcrumb } from '../../navigation/AppBreadcrumb';
import { AppSideNavigation } from '../../navigation/AppSideNavigation';
import { EmptyStateMessage } from '../../generic-components/EmptyState';
import { useAppContext } from '../../context/AppContextProvider';
import { getFilterCountText, PROPERTY_FILTERING_I18N_CONSTANTS } from '../../../utils/CommonHelpers';
import {
  COLUMN_DEFINITIONS,
  FILTERING_PROPERTIES,
  FORECAST_TEMPLATE_DEFAULT_PREFERENCES,
  ForecastStatusTablePreferences
} from './ForecastTemplateStatusConfig';
import { ReportingForecastStatusToolContent } from './ReportingForecastStatusToolContent';
import { useAsyncDynamoStorage } from '../../commons/CustomHooks/use-dynamo-storage';
import { useColumnWidths } from '../../commons/CustomHooks/use-column-widths';

const resourceBreadcrumbs = [
  ...BaseBreadcrumbs,
  {
    text: 'Reporting',
    href: '/reporting'
  },
  {
    text: 'Forecast Template Status',
    href: '#!'
  }
];

export const parsePlanningCycleData = (data: any[]) => {
  let result = data.map((row) => ({ label: row.scenario.scenarioName, value: `${row.scenarioTemplateId}` }));
  return result;
};

interface ForecastTemplateStatusReport {
  forecastStatus: string;
  productLine: string;
  updatedBy: string;
  updatedTime: string;
}

export const ForecastTemplateStatus = () => {
  const appContext = useAppContext();
  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);
  const [toolsOpen, setToolsOpen] = useState(false);
  const [planningCycles, setPlanningCycles] = useState<{ label: string; value: string }[]>([]);
  const [selectedPlanningCycle, setSelectedPlanningCycle] = useState<any>({ label: 'Choose an option', value: '' });

  const [loading, setLoading] = useState(true);

  const [columnDefinitions, saveWidths, columnDefinitionsLoading] = useColumnWidths(
    eUserTablePreferenceKeys.FORECAST_TEMPLATE_STATUS_TABLE_WIDTHS,
    COLUMN_DEFINITIONS
  );
  const [preferences, setPreferences] = useAsyncDynamoStorage(
    eUserTablePreferenceKeys.FORECAST_TEMPLATE_STATUS_TABLE_PREFERENCES,
    FORECAST_TEMPLATE_DEFAULT_PREFERENCES
  );

  const [forecastTemplateStatusReports, setForecastTemplateStatusReports] = useState<ForecastTemplateStatusReport[]>([]);

  const getPlanningCycles = async () => {
    setLoading(true);
    try {
      const response: any = await API.graphql({
        query: queries.listCurrentPlanningCycles,
        variables: {
          dataClassificationId: appContext.userDetails.dataClassification.dataClassificationId
        }
      });
      setLoading(false);
      const parsedPlanningCycles: any[] = parsePlanningCycleData(response.data.listCurrentPlanningCycles);
      setPlanningCycles(parsedPlanningCycles);
      setSelectedPlanningCycle(parsedPlanningCycles?.length != 0 ? parsedPlanningCycles[0] : { label: 'Choose an option', value: '' });
    } catch (err) {
      let errString = JSON.stringify(err);
      logger.error('Unable to load planning cycle data.', { error: errString });
      setLoading(false);
      setFlashbarItems([
        {
          type: 'error',
          content: 'Unable to load planning cycle data. Please try again or contact support.',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setFlashbarItems([])
        }
      ]);
    }
  };

  const getProductLineForecastStatus = async () => {
    setLoading(true);
    try {
      const response: any = await API.graphql({
        query: queries.listForecastTemplateStatusReport,
        variables: {
          dataClassificationId: appContext.userDetails.dataClassification.dataClassificationId,
          scenarioTemplateId: parseInt(selectedPlanningCycle.value)
        }
      });
      setLoading(false);
      setForecastTemplateStatusReports(response.data.listForecastTemplateStatusReport);
    } catch (error: any) {
      logger.error('Unable to load product line forecast status data.', error);
      setLoading(false);
      setFlashbarItems([
        {
          type: 'error',
          content: 'Unable to load product line status data. Please try again or contact support.',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setFlashbarItems([])
        }
      ]);
    }
  };

  const refreshForecastStatus = () => {
    setLoading(true);
    getProductLineForecastStatus();
  };

  useEffect(() => {
    getPlanningCycles();
  }, []);

  useEffect(() => {
    if (selectedPlanningCycle.value) {
      getProductLineForecastStatus();
    }
  }, [selectedPlanningCycle]);

  const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(forecastTemplateStatusReports, {
    propertyFiltering: {
      filteringProperties: FILTERING_PROPERTIES,
      empty: <EmptyStateMessage title="No product lines" subtitle="No product line templates to display." />,
      noMatch: (
        <EmptyStateMessage
          title="No matches"
          subtitle="No match found to display."
          action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
        />
      )
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {}
  });
  const { selectedItems } = collectionProps;

  return (
    <AppLayout
      contentType="default"
      headerSelector="#h"
      navigation={<AppSideNavigation />}
      breadcrumbs={<AppBreadcrumb items={resourceBreadcrumbs} />}
      notifications={<Flashbar items={flashbarItems} />}
      tools={<ReportingForecastStatusToolContent />}
      toolsOpen={toolsOpen}
      onToolsChange={(event) => setToolsOpen(event.detail.open)}
      disableContentPaddings
      content={
        <ContentLayout>
          <Box margin={{ top: 'l', bottom: 'xl', left: 'xxxl', right: 'xxxl' }} padding={{ left: 'xxxl', right: 'xxxl' }}>
            <SpaceBetween direction="vertical" size="m">
              <Container>
                <SpaceBetween direction="horizontal" size="m">
                  <div>
                    <Box variant="awsui-key-label">Planning Cycles</Box>
                    <div className="form-field-select-width-15rem">
                      <Select
                        selectedOption={selectedPlanningCycle}
                        onChange={({ detail }) => {
                          setSelectedPlanningCycle(detail.selectedOption);
                        }}
                        options={planningCycles}
                        empty="No options"
                        placeholder="Choose an option"
                      ></Select>
                    </div>
                  </div>
                </SpaceBetween>
              </Container>

              <Table
                variant="container"
                stickyHeader
                {...collectionProps}
                header={
                  <Header
                    counter={selectedItems?.length ? `(${selectedItems.length}/${items.length})` : `(${items.length})`}
                    actions={
                      <SpaceBetween direction="horizontal" size="m">
                        <Button iconName="refresh" variant="icon" onClick={refreshForecastStatus} />
                      </SpaceBetween>
                    }
                    info={
                      <Link variant="info" onFollow={() => setToolsOpen(true)}>
                        Info
                      </Link>
                    }
                  >
                    Forecast Template Status
                  </Header>
                }
                loading={loading}
                visibleColumns={preferences.visibleContent}
                wrapLines={preferences.wrapLines}
                stripedRows={preferences.stripedRows}
                resizableColumns={preferences.custom}
                columnDefinitions={columnDefinitions}
                onColumnWidthsChange={saveWidths as any}
                items={items}
                filter={
                  <PropertyFilter
                    i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
                    {...propertyFilterProps}
                    countText={getFilterCountText(filteredItemsCount)}
                    expandToViewport={true}
                  />
                }
                pagination={<Pagination {...paginationProps} />}
                preferences={
                  <ForecastStatusTablePreferences
                    preferences={preferences ? preferences : FORECAST_TEMPLATE_DEFAULT_PREFERENCES}
                    setPreferences={setPreferences}
                  />
                }
              />
            </SpaceBetween>
          </Box>
        </ContentLayout>
      }
    />
  );
};
