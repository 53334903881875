export const DocumentationLink = 'https://w.amazon.com/bin/view/Devices_Managed_Marketing';

export enum LandingPageSection {
  MainHeader = 'Device Managed Marketing Spend Planning',
  MainHeaderDescription = 'Device Managed Marketing (DMM) spend planning provides a single tool for planning actuals and forecast.',
  HowItWorks = 'How it works',
  HowItWorksDescription = `FP&A and HEI collects and consolidates Device Managed Marketing (DMM) plans for product line groups.
    The consolidated data is used to load marketing plans to Cognos for quarterly guidance and OP planning. 
    The data is used to inform high visibility reporting, d-team goals, monthly marketing forums and S-team write-ups.`,
  BenefitsAndFeatures = 'Benefits and features',
  BenefitsAndFeaturesSingleSource = `Single source`,
  BenefitsAndFeaturesSingleSourceDescription = `Provides a centralized platform for product line groups to plan device managed marketing spend. 
  Automatically retrieves and integrate actuals purchase order data.`,
  BenefitsAndFeaturesReportsAndAnalytics = `Reporting and analytics`,
  BenefitsAndFeaturesReportsAndAnalyticsDescription = `Produces a consolidated report of marketing plans for Cognos that accurately ties out to PAX. 
    Provides reports and interactive views for analyzing reporting data.`,
  MoreResources = 'More resources'
}
