import KatalLogger, { Level } from '@amzn/katal-logger';
import { UserAuthContext } from 'src/components/context/AppContextModel';

export let logger: KatalLogger;

export const configureTheLogger = (userAlias: string, userAuthData: UserAuthContext) => {
  const katalLoggerConfig = {
    url: 'https://beta.logger.msp.device.finance.amazon.dev/v1/log',
    logThreshold: Level.INFO,
    maxLogLineSize: 10000,
    logToConsole: true,
    context: {
      app: 'dmm-website',
      env: 'beta',
      userAlias: userAlias,
      userAuthData: userAuthData
    }
  };
  logger = new KatalLogger(katalLoggerConfig);
};
