import {
  Badge,
  Button,
  ColumnLayout,
  Container,
  Form,
  FormField,
  Header,
  Input,
  Select,
  SpaceBetween,
  StatusIndicator,
  Table,
  TableProps,
  Toggle
} from '@amzn/awsui-components-react';
import { isEmpty } from '@aws-amplify/core';
import { API } from 'aws-amplify';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as mutations from 'src/graphql/mutations';
import * as queries from 'src/graphql/queries';
import { logger } from 'src/utils/Logger';
import { getValidationErrorMessage } from 'src/utils/labels';
import { FpnaDimensionModel } from '../context/AppContextModel';
import { useAppContext } from '../context/AppContextProvider';
import { useAuth } from '../context/AuthContext';
import { useCampaigns } from './CampaignContext';
import { CampaignCorpDimensions, DropdownModel, DropdownModelWithDescription, ProductLineCampaignsEntity } from './CampaignModels';
import { campaignCorpDimensionColumnDefinition } from './CampaignTableConfig';
import {
  CampaignFormSchema,
  FormInitialValues,
  getProductLineUsers,
  getSelectedCampaignFormValues,
  parseCampaignFormValues,
  parseDimensionData,
  updateFormDimensionValues
} from './CampaignUtils';
import {
  CorpDimensionValidationStatus,
  validateCampaignDetailsUniqueness,
  validateCorpDimensions,
  validateFpnaDimensionConditionalRelationships,
  validateFpnaDimensionRequiredFields
} from './CampaignValidations';
import { EMPTY_DROPDOWN, EMPTY_DROPDOWN_DESC } from 'src/utils/CommonHelpers';

export const CampaignDetails = () => {
  const navigate = useNavigate();
  const { CampaignId: paramsCampaignId } = useParams();
  const user = useAuth();
  const appContext = useAppContext();
  const campaignsContext = useCampaigns();
  const selectedProductLine = campaignsContext?.selectedProductLine;
  const selectedProductLineIsEcho = selectedProductLine.productID === 1;
  const isCampaignDimensionMappingEditable = !campaignsContext?.selectedProductLine.isFpnaManagedPl;

  const [corpDimensionRowData, setCorpDimensionRowData] = useState<CampaignCorpDimensions[]>([]);
  const [selectedCorpDimensionRowData, setSelectedCorpDimensionRowData] = useState<CampaignCorpDimensions[]>([]);
  const [corpDimensionColumnDefinition, setCorpDimensionColumnDefinition] = useState<TableProps.ColumnDefinition<CampaignCorpDimensions>[]>([]);
  const [corpDimensionError, setCorpDimensionError] = useState<CorpDimensionValidationStatus>('valid');
  const [campaignError, setCampaignError] = useState<string>('valid');

  const formRef = useRef<FormikProps<any>>(null);
  const [campaignDetailForm, setCampaignDetailForm] = useState(FormInitialValues);
  const [remainingAllCampaignsInFormStructure, setRemainingAllCampaignsInFormStructure] = useState<any[]>([]);

  const [cloneFromExistingCampaign, setCloneFromExistingCampaign] = useState<any>();

  const [fpnaDimensionsFormList, setFpnaDimensionsFormList] = useState<FpnaDimensionModel[]>([]);
  const [productLineUserOptions, setProductLineUserOptions] = useState<DropdownModelWithDescription[]>([]);

  const [listBusinessCategory, setListBusinessCategory] = useState<DropdownModel[]>([]);
  const [listRevenueEstimateCategory, setListRevenueEstimateCategory] = useState<DropdownModel[]>([]);
  const [listCountry, setListCountry] = useState<DropdownModel[]>([]);

  const [isCreateForm, setIsCreateForm] = useState(true);

  useEffect(() => {
    getBusinessCategoryList();
    getRevenueEstimateList();
    getCountryList();
  }, []);

  useEffect(() => {
    if (!isEmpty(selectedProductLine)) {
      campaignsContext.refreshCampaigns();
    }
  }, [selectedProductLine]);

  useEffect(() => {
    const listFpnaDimensions = appContext.appMetadata.listFpnaDimensions;
    const users = appContext.users;
    if (listFpnaDimensions && users) {
      let fpnaDimensions: FpnaDimensionModel[] = parseDimensionData(listFpnaDimensions);
      setFpnaDimensionsFormList(fpnaDimensions);
      setCampaignDetailForm(updateFormDimensionValues(fpnaDimensions));
      setProductLineUserOptions(EMPTY_DROPDOWN_DESC.concat(getProductLineUsers(appContext.users, selectedProductLine.productID)));
    }
  }, [appContext]);

  useEffect(() => {
    if (paramsCampaignId) {
      const selectedCampaignDetails = campaignsContext.campaignItems.find((campaign) => campaign.campaignId.toString() === paramsCampaignId);

      // If selected Campaign exists, update the form with selected Campaign values
      if (selectedCampaignDetails) {
        const remainingCampaignDetails = campaignsContext.campaignItems.filter((campaign) => campaign.campaignId.toString() !== paramsCampaignId);
        let parsedCampaignItems: any[] = [];
        remainingCampaignDetails &&
          remainingCampaignDetails.forEach((campaignItem) => {
            parsedCampaignItems.push(getSelectedCampaignFormValues(campaignItem));
          });
        setRemainingAllCampaignsInFormStructure(parsedCampaignItems);

        formRef.current?.resetForm();
        let selectedCampaignInFormStructure = getSelectedCampaignFormValues(selectedCampaignDetails);
        setCampaignDetailForm(selectedCampaignInFormStructure);
        configureCorpDimensionTable('editCampaign', selectedCampaignDetails?.campaignsDimMapping, selectedCampaignDetails?.isActive);
        setIsCreateForm(false);
      } else {
        let parsedCampaignItems: any[] = [];
        campaignsContext.campaignItems.forEach((campaignItem) => {
          parsedCampaignItems.push(getSelectedCampaignFormValues(campaignItem));
        });
        setRemainingAllCampaignsInFormStructure(parsedCampaignItems);

        formRef.current?.resetForm();
        setCampaignDetailForm(FormInitialValues);
        configureCorpDimensionTable('newCampaign', [], true);
        setIsCreateForm(true);
      }
    }
  }, [paramsCampaignId, campaignsContext.campaignItems, campaignsContext.corpDimensionsList]);

  const getBusinessCategoryList = async () => {
    try {
      const businessCategoryDimensionValues = campaignsContext?.listProductLineDimensions?.find(
        (listProductLineDimension) => listProductLineDimension.dimensionName === 'Business Category'
      )?.dimensionValues;
      const dimensionValuesAsDropdowns = businessCategoryDimensionValues?.map((businessCategoryDimensionValue) => {
        return {
          label: businessCategoryDimensionValue.dimensionValue,
          value: businessCategoryDimensionValue.dimensionValue
        };
      });
      setListBusinessCategory(EMPTY_DROPDOWN.concat(dimensionValuesAsDropdowns ? dimensionValuesAsDropdowns : []));
    } catch (error: any) {
      setListBusinessCategory([]);
      logger.error(`Unable to load businessCategory Dimension values. `, error);
    }
  };

  const getRevenueEstimateList = async () => {
    try {
      const revenueEstimateDimensionValues = campaignsContext?.listProductLineDimensions?.find(
        (listProductLineDimension) => listProductLineDimension.dimensionName === 'Revenue Estimate Category'
      )?.dimensionValues;
      const dimensionValuesAsDropdowns = revenueEstimateDimensionValues?.map((revenueEstimateDimensionValue) => {
        return {
          label: revenueEstimateDimensionValue.dimensionValue,
          value: revenueEstimateDimensionValue.dimensionValue
        };
      });
      setListRevenueEstimateCategory(EMPTY_DROPDOWN.concat(dimensionValuesAsDropdowns ? dimensionValuesAsDropdowns : []));
    } catch (error: any) {
      setListRevenueEstimateCategory([]);
      logger.error(`Unable to load revenueEstimate Dimension values. `, error);
    }
  };

  const getCountryList = async () => {
    try {
      const countryDimensionValues = campaignsContext?.listProductLineDimensions?.find(
        (listProductLineDimension) => listProductLineDimension.dimensionName === 'Country'
      )?.dimensionValues;
      const dimensionValuesAsDropdowns = countryDimensionValues?.map((countryDimensionValue) => {
        return {
          label: countryDimensionValue.dimensionValue,
          value: countryDimensionValue.dimensionValue
        };
      });
      setListCountry(dimensionValuesAsDropdowns ? dimensionValuesAsDropdowns : []);
    } catch (error: any) {
      setListCountry([]);
      logger.error(`Unable to load Country Dimension values. `, error);
    }
  };

  const getFpnaCorpDimensions = async () => {
    try {
      const listFpnaCorpDimensionsResponse: any = await API.graphql({
        query: queries.listFpnaCorpDimensions,
        variables: {
          productLineId: selectedProductLine?.productID
        }
      });
      const response = listFpnaCorpDimensionsResponse.data.listFpnaCorpDimensions;
      return response;
    } catch (error: any) {
      logger.error(`Unable to load listFpnaCorpDimensions. `, error);
      return [];
    }
  };

  const isActiveChanged = (isActive: boolean) => {
    setCorpDimensionColumnDefinition(
      campaignCorpDimensionColumnDefinition(isCampaignDimensionMappingEditable, isActive, campaignsContext.corpDimensionsList)
    );
  };

  // Configures Campaign Dimensions in Ag-Grid table
  const configureCorpDimensionTable = async (
    eventType: 'editCampaign' | 'newCampaign' | 'newCloneCampaign',
    campaignsDimMapping: CampaignCorpDimensions[],
    isCampaignActive: boolean,
    selectedCampaignDetails?: ProductLineCampaignsEntity
  ) => {
    setSelectedCorpDimensionRowData([]); // clearing already selected corp dimensions
    setCorpDimensionRowData([]); // clearing the data
    setCorpDimensionColumnDefinition(
      campaignCorpDimensionColumnDefinition(isCampaignDimensionMappingEditable, isCampaignActive, campaignsContext.corpDimensionsList)
    ); // updating Corp DImension Table definitions.

    switch (eventType) {
      case 'editCampaign':
        logger.info('Edit campaign. adding from existing');
        const _campaignCorpDimension = campaignsDimMapping as CampaignCorpDimensions[];
        setCorpDimensionRowData(_campaignCorpDimension);
        break;

      case 'newCampaign':
        if (!isCampaignDimensionMappingEditable) {
          logger.info('New campaign with isFPNA Managed. adding from FPNA CORP');
          const _campaignCorpDimension = await getFpnaCorpDimensions();
          setCorpDimensionRowData(
            _campaignCorpDimension.map((corpDimension: any) => {
              return { ...corpDimension, companyCode: '' };
            })
          );
        } else {
          logger.info('New campaign with NonFPNA Managed. Adding empty corp');
          const _campaignCorpDimension: CampaignCorpDimensions[] = [
            {
              productLineCampaignCorpId: null,
              accountNumber: '',
              channelCode: '',
              companyCode: '',
              costCenter: '',
              locationCode: '',
              productCode: '',
              projectCode: '',
              isActive: true,
              updatedBy: user.Alias,
              createdBy: user.Alias
            }
          ];
          setCorpDimensionRowData(_campaignCorpDimension);
        }
        break;

      case 'newCloneCampaign':
        if (!isCampaignDimensionMappingEditable) {
          logger.info('New clone campaign with isFPNA Managed. adding from FPNA CORP');
          const _campaignCorpDimension = await getFpnaCorpDimensions();
          setCorpDimensionRowData(
            _campaignCorpDimension.map((corpDimension: any) => {
              return { ...corpDimension, companyCode: getCompanyCodeFromCountry(selectedCampaignDetails?.country) };
            })
          );
        } else {
          logger.info('New  clone campaign  with non FPNA Managed. Adding the Corp dimension from cloned Campaign.');
          setCorpDimensionRowData(
            campaignsDimMapping.map((campaignDim) => {
              return { ...campaignDim, productLineCampaignCorpId: null };
            })
          );
        }
        break;

      default:
        setCorpDimensionRowData([]);
        break;
    }
  };

  // Handling for Submission
  const handleSubmit = async (formValues: any, formikHelpers: FormikHelpers<any>) => {
    const campaignUniquenessValidation = validateCampaignDetailsUniqueness(formValues, remainingAllCampaignsInFormStructure, [
      'campaignId',
      'isActive'
    ]);
    if (campaignUniquenessValidation) {
      setCampaignError('valid');
    } else {
      setCampaignError('Same combination of Campaign already exists. Please check again.');
    }

    const corpDimensionValidation = validateCorpDimensions(corpDimensionRowData);
    if (corpDimensionValidation === 'valid') {
      setCorpDimensionError('valid');
    } else {
      setCorpDimensionError(corpDimensionValidation);
    }

    if (campaignUniquenessValidation && corpDimensionValidation === 'valid') {
      formRef.current?.setSubmitting(true);
      const form = parseCampaignFormValues(formValues, selectedProductLine, corpDimensionRowData, user, appContext);

      const mutationQuery = isCreateForm ? mutations.createProductLineCampaigns : mutations.updateProductLineCampaigns;
      try {
        const response = await API.graphql({
          query: mutationQuery,
          variables: { input: form }
        });
        formRef.current?.setSubmitting(false);
        campaignsContext.notificationHandler(false, `${form[0]?.campaignName} ${isCreateForm ? 'created' : 'updated'}  successfully.`, 'success');
        formRef.current?.resetForm();
        logger.info(`Successfully updated Campaign Details for ${form[0]?.campaignName} - Product ID - ${form[0]?.productLine.productID}`);
        navigate('/campaigns');
      } catch (error: any) {
        logger.error(`Error while submitting Campaign Details for ${form[0]?.campaignName} - Product ID - ${form[0]?.productLine.productID}`, error);
        campaignsContext.notificationHandler(
          false,
          `Error while submitting ${form[0]?.campaignName} details. Please try again or contact support.`,
          'error'
        );
        formRef.current?.setSubmitting(false);
      }
    }
  };

  // Form Validation
  const onValidateForm = (formValues: any) => {
    const fpnaDimensions = appContext.appMetadata.listFpnaDimensions;
    const requiredErrors = validateFpnaDimensionRequiredFields(formValues, fpnaDimensions, selectedProductLineIsEcho);
    const conditionalErrors = validateFpnaDimensionConditionalRelationships(
      formValues,
      fpnaDimensions,
      listBusinessCategory,
      listRevenueEstimateCategory
    );
    const resultErrors = { ...requiredErrors, ...conditionalErrors };
    return resultErrors;
  };

  const handleInlineEdit = (currentItem: any, columnDefinition: any, value: any) => {
    let fullCollection = corpDimensionRowData;
    const newItem =
      columnDefinition.id === 'isActive'
        ? { ...currentItem, [columnDefinition.id]: value === 'Active' }
        : { ...currentItem, [columnDefinition.id]: value };
    setCorpDimensionRowData(fullCollection.map((item) => (item === currentItem ? newItem : item)));
  };

  const removeSelectedCorpDimensions = () => {
    let finalRow = corpDimensionRowData.filter((obj) => !selectedCorpDimensionRowData.includes(obj));
    setCorpDimensionRowData(finalRow);
    setSelectedCorpDimensionRowData([]);
  };

  const addNewRowOfCorpDimension = () => {
    const newRow: CampaignCorpDimensions[] = [
      {
        productLineCampaignCorpId: null,
        accountNumber: '',
        channelCode: '',
        companyCode: getCompanyCodeFromCountry(formRef.current?.values?.country?.label),
        costCenter: '',
        locationCode: '',
        productCode: '',
        projectCode: '',
        isActive: true,
        updatedBy: user.Alias
      }
    ];
    const finalCorpDimensions = newRow.concat(corpDimensionRowData);
    setCorpDimensionRowData(finalCorpDimensions);
  };

  const cloneFromExistingCampaignDetails = (cloneFromCampaignName: string | undefined) => {
    if (cloneFromCampaignName) {
      const selectedCampaignDetails = campaignsContext.campaignItems.find((campaign) => campaign.campaignName === cloneFromCampaignName);
      if (selectedCampaignDetails) {
        let result = getSelectedCampaignFormValues(selectedCampaignDetails);
        formRef.current?.resetForm();
        setCampaignDetailForm({ ...result, campaignId: null });

        const clonedCorpDimension = selectedCampaignDetails?.campaignsDimMapping?.map((corpDimension: CampaignCorpDimensions) => {
          return {
            ...corpDimension,
            updatedBy: user.Alias
          };
        });
        configureCorpDimensionTable('newCloneCampaign', clonedCorpDimension, selectedCampaignDetails?.isActive, selectedCampaignDetails);
        setIsCreateForm(true);
      }
    }
  };

  const setFpnaDimensionFieldValue = (dimension: FpnaDimensionModel, event: any) => {
    formRef?.current?.setFieldValue(`${dimension.key}`, event.detail.selectedOption);
  };

  const newCampaign = () => {
    navigate('/campaigns/new');
  };

  const countryChanged = (countrySelected: any) => {
    formRef?.current?.setFieldValue('country', countrySelected);
    const countryCompanyCodeMapping = campaignsContext?.countryCompanyMapping?.find(
      (countryMapping) => countryMapping.countryCode === countrySelected.label
    );
    if (countryCompanyCodeMapping) {
      const updatedCampaignDimensionRowData = corpDimensionRowData.map((row) => {
        row.companyCode = countryCompanyCodeMapping.companyCode;
        row.updatedBy = user.Alias;
        return row;
      });
      setCorpDimensionRowData(updatedCampaignDimensionRowData);
    }
  };

  const getCompanyCodeFromCountry = (countrySelected: any) => {
    const countryCompanyCodeMapping = campaignsContext?.countryCompanyMapping?.find(
      (countryMapping) => countryMapping.countryCode === countrySelected
    );
    return countryCompanyCodeMapping ? countryCompanyCodeMapping.companyCode : '';
  };

  return (
    <>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={campaignDetailForm}
        validate={onValidateForm}
        validationSchema={CampaignFormSchema}
        onSubmit={handleSubmit}
      >
        {({ values, touched, errors, setFieldValue, handleSubmit, isSubmitting }) => {
          return (
            <>
              <SpaceBetween size="l">
                <form onSubmit={handleSubmit}>
                  <Form>
                    <Container
                      header={
                        <Header
                          variant="h3"
                          actions={
                            <SpaceBetween size="m" direction="horizontal">
                              <Button variant="normal" disabled={isEmpty(selectedProductLine)} onClick={newCampaign} formAction="none">
                                New Campaign
                              </Button>
                              <Button loading={isSubmitting} variant="primary" disabled={isSubmitting} formAction={'submit'}>
                                Submit
                              </Button>
                            </SpaceBetween>
                          }
                        >
                          <SpaceBetween size="m" direction="horizontal">
                            <Button formAction="none" iconName="arrow-left" onClick={() => navigate('/campaigns/')}></Button>
                            <div>
                              <>
                                {`Campaign Details`}{' '}
                                {campaignError !== 'valid' ? <StatusIndicator type="error">{campaignError}</StatusIndicator> : <></>}
                              </>
                            </div>
                          </SpaceBetween>
                        </Header>
                      }
                    >
                      <SpaceBetween size="s" direction="vertical">
                        {paramsCampaignId === 'new' && (
                          <FormField
                            className="form-field-select-width-20rem"
                            label={
                              <span>
                                Clone from existing Campaign<i> - optional</i>
                              </span>
                            }
                            description="Select a Campaign to populate all fields"
                          >
                            <Select
                              filteringType="auto"
                              selectedOption={cloneFromExistingCampaign}
                              onChange={({ detail }) => {
                                setCloneFromExistingCampaign(detail.selectedOption);
                                cloneFromExistingCampaignDetails(detail.selectedOption.label);
                              }}
                              options={campaignsContext?.campaignItems
                                ?.sort((a, b) => (a.campaignName < b.campaignName ? -1 : 1))
                                ?.map((campaign) => {
                                  return { label: campaign.campaignName, value: campaign.campaignName };
                                })}
                              selectedAriaLabel="Selected"
                            />
                          </FormField>
                        )}

                        <Toggle
                          checked={values.isActive}
                          onChange={({ detail }) => {
                            setFieldValue('isActive', detail.checked);
                            isActiveChanged(detail.checked);
                          }}
                        >
                          <Badge color={values.isActive ? 'green' : 'red'}>{values.isActive ? 'Active' : 'Inactive'}</Badge>
                        </Toggle>

                        <ColumnLayout columns={2}>
                          <FormField label="Campaign name" errorText={getValidationErrorMessage(touched.campaignName, errors.campaignName)}>
                            <Input
                              disabled={!values.isActive}
                              value={values.campaignName}
                              onChange={(event) => setFieldValue('campaignName', event.detail.value)}
                            />
                          </FormField>

                          <FormField
                            label={
                              <span>
                                Business owner<i> - optional</i>
                              </span>
                            }
                          >
                            <Select
                              placeholder="Choose an option"
                              filteringType="auto"
                              selectedAriaLabel="Selected"
                              disabled={!values.isActive}
                              selectedOption={values.businessOwner ? values.businessOwner : null}
                              onChange={(event) => setFieldValue('businessOwner', event.detail.selectedOption)}
                              options={productLineUserOptions}
                            />
                          </FormField>

                          <FormField
                            label={'Country'}
                            // @ts-ignore
                            errorText={touched.country?.label && errors.country?.label}
                          >
                            <Select
                              placeholder="Choose an option"
                              filteringType="auto"
                              selectedAriaLabel="Selected"
                              disabled={!values.isActive}
                              selectedOption={values.country ? values.country : null}
                              onChange={(event) => countryChanged(event.detail.selectedOption)}
                              options={listCountry}
                            />
                          </FormField>

                          <FormField
                            label={
                              <span>
                                Efficiency Goal<i> - optional</i>
                              </span>
                            }
                          >
                            <Input
                              disabled={!values.isActive}
                              value={values.efficiencyGoal}
                              onChange={(event) => setFieldValue('efficiencyGoal', event.detail.value)}
                            />
                          </FormField>

                          <FormField
                            label={<span>{`Revenue Estimate Category ${selectedProductLineIsEcho ? '' : ' - optional'}`}</span>}
                            // @ts-ignore
                            errorText={touched.revenueEstimate?.label && errors.revenueEstimate?.label}
                          >
                            <Select
                              placeholder="Choose an option"
                              filteringType="auto"
                              selectedAriaLabel="Selected"
                              disabled={!values.isActive}
                              selectedOption={values.revenueEstimate ? values.revenueEstimate : null}
                              onChange={(event) => setFieldValue('revenueEstimate', event.detail.selectedOption)}
                              options={listRevenueEstimateCategory}
                            />
                          </FormField>

                          <FormField
                            label={<span>{`Business Category ${selectedProductLineIsEcho ? '' : ' - optional'}`}</span>}
                            // @ts-ignore
                            errorText={touched?.businessCategory?.label && errors?.businessCategory?.label}
                          >
                            <Select
                              placeholder="Choose an option"
                              filteringType="auto"
                              selectedAriaLabel="Selected"
                              disabled={!values.isActive}
                              selectedOption={values.businessCategory ? values.businessCategory : null}
                              onChange={(event) => setFieldValue('businessCategory', event.detail.selectedOption)}
                              options={listBusinessCategory}
                            />
                          </FormField>

                          {fpnaDimensionsFormList.map((dimension: FpnaDimensionModel) => (
                            <FormField
                              label={`${dimension.name}`}
                              key={dimension.key}
                              // using as dynamically generated dimension form fields doesn't work with static formik model
                              errorText={
                                // @ts-ignore
                                touched[dimension.key]?.label && errors[dimension.key]?.label ? errors[dimension.key]?.label : errors[dimension.key]
                              }
                            >
                              <Select
                                disabled={!values.isActive}
                                selectedOption={values[dimension.key]}
                                onChange={(event) => setFpnaDimensionFieldValue(dimension, event)}
                                options={dimension.dropdownValues}
                                placeholder="Choose an option"
                                filteringType="auto"
                                selectedAriaLabel="Selected"
                              />
                            </FormField>
                          ))}
                        </ColumnLayout>
                      </SpaceBetween>
                    </Container>
                  </Form>
                </form>

                {/* Campaign Dimensions  (Corp Dimensions)  Table*/}
                <Table
                  variant="container"
                  header={
                    <Header
                      actions={
                        <SpaceBetween size="m" direction="horizontal">
                          {isCampaignDimensionMappingEditable && selectedCorpDimensionRowData?.length > 0 && (
                            <Button onClick={removeSelectedCorpDimensions}>Remove Selected</Button>
                          )}
                          {isCampaignDimensionMappingEditable && <Button onClick={addNewRowOfCorpDimension}>New Dimension</Button>}
                        </SpaceBetween>
                      }
                    >
                      <>
                        {`Campaign Dimensions`}{' '}
                        {corpDimensionError !== 'valid' ? <StatusIndicator type="error">{corpDimensionError}</StatusIndicator> : <></>}
                      </>
                    </Header>
                  }
                  columnDefinitions={corpDimensionColumnDefinition}
                  items={corpDimensionRowData}
                  submitEdit={handleInlineEdit}
                  selectionType="multi"
                  selectedItems={selectedCorpDimensionRowData}
                  onSelectionChange={({ detail }) => setSelectedCorpDimensionRowData(detail.selectedItems)}
                  isItemDisabled={(corpDimensionRow) => corpDimensionRow.productLineCampaignCorpId !== null}
                />
              </SpaceBetween>
            </>
          );
        }}
      </Formik>
    </>
  );
};
