import { CollectionPreferencesProps, PropertyFilterProps, RadioGroupProps } from '@amzn/awsui-components-react';

export const appLayoutLabels = {
  navigation: 'Side navigation',
  navigationToggle: 'Open side navigation',
  navigationClose: 'Close side navigation',
  notifications: 'Notifications',
  tools: 'Help panel',
  toolsToggle: 'Open help panel',
  toolsClose: 'Close help panel'
};

export const PAGINATION_LABELS = {
  nextPageLabel: 'Next page',
  previousPageLabel: 'Previous page',
  pageLabel: (pageNumber: any) => `Page ${pageNumber} of all pages`
};

export function getMatchesCountText(count: number) {
  return count === 1 ? `1 match` : `${count} matches`;
}

export const CUSTOM_PREFERENCE_OPTIONS: RadioGroupProps.RadioButtonDefinition[] = [{ value: 'table', label: 'Table' }];

export const distributionSelectionLabels = {
  itemSelectionLabel: (data: any, row: any) => `select ${row.id}`,
  allItemsSelectionLabel: () => 'select all',
  selectionGroupLabel: 'Distribution selection'
};

export const originsSelectionLabels = {
  itemSelectionLabel: (data: any, row: any): string => `select ${row.name}`,
  allItemsSelectionLabel: () => 'select all',
  selectionGroupLabel: 'Origins selection'
};

export const behaviorsSelectionLabels = {
  itemSelectionLabel: (data: any, row: any) => `select path ${row.pathPattern} from origin ${row.origin}`,
  allItemsSelectionLabel: () => 'select all',
  selectionGroupLabel: 'Behaviors selection'
};

export const logsSelectionLabels = {
  itemSelectionLabel: (data: any, row: any) => `select ${row.name}`,
  allItemsSelectionLabel: () => 'select all',
  selectionGroupLabel: 'Logs selection'
};

const generateColumnLabel =
  ({ id, header }: { id: any; header: any }) =>
  (sortState: any) => {
    const columnIsSorted = sortState.sortingColumn === id;
    const ascending = !sortState.sortingDescending;
    return `${typeof header === 'function' ? header() : header}, ${
      columnIsSorted ? `sorted ${ascending ? 'ascending' : 'descending'}` : 'not sorted'
    }.`;
  };

export const addColumnSortLabels = (columns: any, sortColumns: any) =>
  columns.map((col: any) => ({
    label: sortColumns.find((sortCol: any) => sortCol.id === col.id) ? generateColumnLabel(col) : undefined,
    ...col
  }));

export const getValidationErrorMessage = (touched: any, errors: any) => {
  return touched && errors ? errors : '';
};

export const BLANK_SEARCH = { tokens: [], operation: 'and' } as PropertyFilterProps.Query;
