import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { LoadingStatus } from '../context/AppContextModel';
import { getAllItemsFromDynamoDb } from 'src/utils/AWSDynamoDBServices';
import { eMSPDynamoDBTables } from 'src/utils/DmmConstants';
import { logger } from 'src/utils/Logger';
import { LoadingSpinner } from '../generic-components/Spinner';
import { Alert, Header, SpaceBetween } from '@amzn/awsui-components-react';
import TutorialThumbnail from './TutorialThumbnail';

interface VideoTutorialFromBroadcast {
  uuid: string;
  title: string;
  description?: string;
  location: string;
  link: string;
}

const BroadcastVideoTutorials: React.FC = () => {
  const authContext = useAuth();
  const [tutorialsLoadingStatus, setTutorialsLoadingStatus] = useState<LoadingStatus>(LoadingStatus.NotInitiated);
  const [videoTutorialsList, setVideoTutorialsList] = useState<VideoTutorialFromBroadcast[]>([]);

  useEffect(() => {
    if (authContext.userAuthDataLoadingStatus === LoadingStatus.Completed) {
      getVideoTutorialsListFromDynamo();
    }
  }, [authContext]);

  const getVideoTutorialsListFromDynamo = async () => {
    try {
      setTutorialsLoadingStatus(LoadingStatus.Loading);
      const tutorialsList: VideoTutorialFromBroadcast[] = (await getAllItemsFromDynamoDb(
        eMSPDynamoDBTables.MSP_VIDEO_TUTORIALS
      )) as VideoTutorialFromBroadcast[];
      let _videoTutorialsList: VideoTutorialFromBroadcast[] = [];
      for (const mspVideo of tutorialsList) {
        if (mspVideo.location === 'broadcast') {
          _videoTutorialsList.push(mspVideo);
        }
      }
      setVideoTutorialsList(_videoTutorialsList);
      setTutorialsLoadingStatus(LoadingStatus.Completed);
    } catch (error: any) {
      setTutorialsLoadingStatus(LoadingStatus.Failed);
      logger.error('Unable to fetch presigned url ', error);
    }
  };

  return (
    <>
      {(tutorialsLoadingStatus === LoadingStatus.NotInitiated || tutorialsLoadingStatus === LoadingStatus.Loading) && <LoadingSpinner />}
      {tutorialsLoadingStatus === LoadingStatus.Failed && (
        <Alert type="error" dismissAriaLabel="Close alert" header="unknown issues">
          {'Unable to load videos. Please try again later.'}
        </Alert>
      )}
      {tutorialsLoadingStatus === LoadingStatus.Completed && (
        <>
          {videoTutorialsList.map((videoTutorial, index) => {
            return (
              <SpaceBetween size="m" direction="horizontal" key={index}>
                <TutorialThumbnail key={index} link={videoTutorial.link} displayTitle={videoTutorial.title} category={'Demo'} />
              </SpaceBetween>
            );
          })}
        </>
      )}
    </>
  );
};

export default BroadcastVideoTutorials;
