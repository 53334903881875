import { AppLayoutProps, FlashbarProps, NonCancelableCustomEvent } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useNumberPref, useObjectPref } from 'src/utils/UserPrefs';
import { PurchaseOrderForm } from './PurchaseOrderForm';
import { BaseBreadcrumbs } from 'src/utils/DmmConstants';

export function usePOSplitPanelProps() {
  const [splitPanelPrefs, setSplitPanelPrefs] = useObjectPref<AppLayoutProps.SplitPanelPreferences>(location.pathname, 'splitPanelPrefs', {
    position: 'side'
  });
  const [splitPanelSize, setSplitPanelSize] = useNumberPref(location.pathname, 'splitPanelSize');
  const [splitPanelOpen, setSplitPanelOpen] = useState<boolean>(false);

  const onSplitPanelPreferencesChange = (event: NonCancelableCustomEvent<AppLayoutProps.SplitPanelPreferences>) => {
    setSplitPanelPrefs(event.detail);
  };

  const onSplitPanelResize = (event: NonCancelableCustomEvent<AppLayoutProps.SplitPanelResizeDetail>) => {
    setSplitPanelSize(event.detail.size);
  };

  const onSplitPanelToggle = (event: NonCancelableCustomEvent<AppLayoutProps.ChangeDetail>) => {
    setSplitPanelOpen(event.detail.open);
  };

  const splitPanelOpenByEdit = (splitPanelOpen: boolean) => {
    setSplitPanelOpen(splitPanelOpen);
  };

  useEffect(() => {
    let updatedSplitPanelPrefs: AppLayoutProps.SplitPanelPreferences = { position: 'side' };
    setSplitPanelPrefs(updatedSplitPanelPrefs);
  }, []);

  return {
    splitPanelPrefs,
    onSplitPanelPreferencesChange,
    splitPanelSize,
    onSplitPanelResize,
    splitPanelOpen,
    splitPanelOpenByEdit,
    onSplitPanelToggle
  };
}

export const getPurchaseOrderPanelContent = (
  productLineId: number,
  actualsMonth: string,
  displayFlashMessage: (content: string, flashBarType: FlashbarProps.Type) => void,
  updateTheTable: () => void,
  selectedPurchaseOrderItems: any[]
) => {
  return (
    <PurchaseOrderForm
      productLineId={productLineId}
      actualsMonth={actualsMonth}
      displayFlashMessage={displayFlashMessage}
      updateTableHandler={updateTheTable}
      selectedPurchaseOrderItems={selectedPurchaseOrderItems}
    />
  );
};

export const getUserBreadcrumbs = () => {
  return [
    ...BaseBreadcrumbs,
    {
      text: 'Purchase Orders',
      href: '/purchase-orders'
    }
  ];
};

/*
 * input (string) -> YYYYMM
 * output (string) -> YYYY-MM
 */
export const getDisplayActualMonth = (month: string) => {
  const displayMonth = `${month.substring(0, 4)}-${month.substring(4)}`;
  return displayMonth;
};

export const mapActualMonthsDropdown = (data: string[]) => {
  const result = data.map((value) => {
    return {
      label: getDisplayActualMonth(value),
      value: value
    };
  });
  return result;
};

// map po details data
// otherwise property filter doesn't work with nested data
export const mapCampaignPurchaseOrderDetails = (data: any[]) => {
  const result = data.map((value) => {
    return {
      campaignMapId: value.campaignMapId,
      campaignId: value.campaignId,
      campaignName: value.campaignName,
      poNumber: value.poNumber,
      costCenter: value.poDetails.costCenter,
      accountNumber: value.poDetails.accountNumber,
      channelCode: value.poDetails.channelCode,
      companyCode: value.poDetails.companyCode,
      lineDescription: value.poDetails.lineDescription,
      journalName: value.poDetails.journalName,
      ledgerName: value.poDetails.ledgerName,
      locationCode: value.poDetails.locationCode,
      poRequester: value.poDetails.poRequestor,
      vendorNumber: value.poDetails.vendorNumber,
      poAmountUsd: value.poDetails.poAmountUsd,
      q1EfficiencyResults: value.efficiencyResults.Q1,
      q2EfficiencyResults: value.efficiencyResults.Q2,
      q3EfficiencyResults: value.efficiencyResults.Q3,
      q4EfficiencyResults: value.efficiencyResults.Q4,
      updatedBy: value.updatedBy,
      updatedTime: value.updatedTime
    };
  });
  return result;
};

/*
 * check if campaign purchase order details have any untagged POs
 * if so then mark incomplete
 */
export const getPurchaseOrderStatus = (data: any[]) => {
  if (data.length == 0) {
    return '';
  }
  let status = data.find((element) => element.campaignId == null);
  return status ? 'incomplete' : 'in-progress';
};

/*
 * checks if there is a balance to display
 * returns "" if balance is null
 */
export const parseTotalBalance = (balance: string | null) => {
  if (balance) {
    return balance;
  }
  return '';
};

/*
Checks the total number of untagged POs
 */
export const checkTotalNumberOfUnTaggedPOs = (data: any[]): { hasCampaigns: boolean; emptyCampaignsCount: number; summaryMessage: string } => {
  if (data.length == 0) {
    return { hasCampaigns: false, emptyCampaignsCount: 0, summaryMessage: '-' };
  }

  let _emptyCampaignsCount = data.filter((element) => element.campaignId == null)?.length || 0;
  return {
    hasCampaigns: true,
    emptyCampaignsCount: _emptyCampaignsCount,
    summaryMessage: `${_emptyCampaignsCount} ${_emptyCampaignsCount === 1 ? ' PO need to be tagged' : ' POs need to be tagged'}`
  };
};
