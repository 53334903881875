import { FlashbarProps } from '@amzn/awsui-components-react';
import { createContext, useContext } from 'react';
import { DropdownModel, MultiSelectGroupDropdownModel, PlanningCycleDataModel, ProductLine } from '../context/AppContextModel';
import { ForecastTemplateStatus } from './ForecastModel';

export type ForecastTemplateContextType = {
  selectedProductLine?: ProductLine;
  setSelectedProductLine: (product: ProductLine) => void;
  selectedPlanningCycle: PlanningCycleDataModel;
  setSelectedPlanningCycle: (planningCycle: PlanningCycleDataModel) => void;

  selectedCampaignDropdown: DropdownModel[];
  setSelectedCampaignDropdown: (channel: DropdownModel[]) => void;

  selectedCountryDropdown: DropdownModel[];
  setSelectedCountryDropdown: (country: DropdownModel[]) => void;

  selectedRevenueEstimateDropdown: DropdownModel[];
  setSelectedRevenueEstimateDropdown: (country: DropdownModel[]) => void;

  displayFlashMessage: (content: string, flashBarType: FlashbarProps.Type, isDismissible?: boolean) => void;
  forecastTemplateStatus: ForecastTemplateStatus;
  setForecastTemplateStatus: (forecastTemplateStatus: ForecastTemplateStatus) => void;
  fullForecastData: any[];
  setFullForecastData: (fullForecastData: any[]) => void;
};

export const ForecastTemplateInitialData = createContext<ForecastTemplateContextType>({
  selectedProductLine: {} as ProductLine,
  setSelectedProductLine: (product) => {},
  selectedPlanningCycle: {} as PlanningCycleDataModel,
  setSelectedPlanningCycle: () => {},

  selectedCampaignDropdown: [],
  setSelectedCampaignDropdown: (channel: DropdownModel[]) => {},
  selectedCountryDropdown: [],
  setSelectedCountryDropdown: (country: DropdownModel[]) => {},
  selectedRevenueEstimateDropdown: [],
  setSelectedRevenueEstimateDropdown: (revenueEstimateDropdown: DropdownModel[]) => {},

  displayFlashMessage: () => {},
  forecastTemplateStatus: { forecastStatus: 'pending' } as ForecastTemplateStatus,
  setForecastTemplateStatus: () => {},
  fullForecastData: [],
  setFullForecastData: (fullForecastData: any[]) => {}
});
export const useForecastTemplate = () => useContext(ForecastTemplateInitialData);
