import { Box, Container, FormField, Grid, Multiselect, Select, SpaceBetween, StatusIndicator, Toggle } from '@amzn/awsui-components-react';
import { isEmpty } from '@aws-amplify/core';
import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import * as mutations from 'src/graphql/mutations';
import * as queries from 'src/graphql/queries';
import { handleNull, isDefinedAndNotEmptyObject } from 'src/utils/CommonHelpers';
import { getCurrentUserLocalTimeReadableFormat, getReadableFormatOfMonthAndYear, isAfterCurrentTime } from 'src/utils/DateTimeUtils';
import { eUserTypes } from 'src/utils/DmmConstants';
import { logger } from 'src/utils/Logger';
import { DropdownModel, MultiSelectGroupDropdownModel, PlanningCycleDataModel, ProductLine } from '../context/AppContextModel';
import { useAppContext } from '../context/AppContextProvider';
import { useAuth } from '../context/AuthContext';
import { getMultiSelectPlaceHolderValue } from '../generic-components/Utils';
import { ForecastTemplateStatus } from './ForecastModel';
import { useForecastTemplate } from './ForecastTemplateContext';
import * as ForecastUtils from './ForecastUtils';

export interface ForecastDetailsSelectionProps {
  onToolOpen(isOpen: boolean): void;
}

const EMPTY_MULTI_SELECT_ALL_INITIAL_VALUE = [
  {
    label: 'Select All',
    value: 'Select All',
    options: []
  }
];

export const ForecastDetailsSelection: React.FC<ForecastDetailsSelectionProps> = (props) => {
  const userAuthDetails = useAuth();
  const appContext = useAppContext();

  const planningCycleList = appContext.appMetadata.listCurrentPlanningCycles;
  const dataClassificationId = appContext.userDetails?.dataClassification?.dataClassificationId;

  const {
    selectedProductLine,
    setSelectedProductLine,
    selectedPlanningCycle,
    setSelectedPlanningCycle,

    selectedCountryDropdown,
    setSelectedCountryDropdown,
    selectedCampaignDropdown,
    setSelectedCampaignDropdown,
    selectedRevenueEstimateDropdown,
    setSelectedRevenueEstimateDropdown,

    displayFlashMessage,
    forecastTemplateStatus,
    setForecastTemplateStatus,
    fullForecastData
  } = useForecastTemplate();

  const [campaignList, setCampaignList] = useState<MultiSelectGroupDropdownModel[]>(EMPTY_MULTI_SELECT_ALL_INITIAL_VALUE);
  const [countryList, setCountryList] = useState<MultiSelectGroupDropdownModel[]>(EMPTY_MULTI_SELECT_ALL_INITIAL_VALUE);
  const [revenueEstimateCategoryList, setRevenueEstimateCategoryList] =
    useState<MultiSelectGroupDropdownModel[]>(EMPTY_MULTI_SELECT_ALL_INITIAL_VALUE);

  const [forecastTemplateToggleStatus, setForecastTemplateToggleStatus] = useState<boolean>(false);
  const [forecastTemplateToggleInProgress, setForecastTemplateToggleInProgress] = useState<boolean>(false);

  useEffect(() => {
    const uniqueCampaigns = Array.from(new Set(fullForecastData.map((item) => item.campaignName)))
      .map((campaign) => ({ value: campaign, label: campaign, disabled: false }))
      .sort((a, b) => a.label.localeCompare(b.label));
    setCampaignList([
      {
        label: 'Select All',
        value: 'Select All',
        options: uniqueCampaigns
      }
    ]);

    const uniqueCountries = Array.from(new Set(fullForecastData.map((item) => item.country)))
      .map((country) => ({ value: country, label: country, disabled: false }))
      .sort((a, b) => a.label.localeCompare(b.label));
    setCountryList([
      {
        label: 'Select All',
        value: 'Select All',
        options: uniqueCountries
      }
    ]);
    if (uniqueCountries.length === 1) {
      setSelectedCountryDropdown(uniqueCountries);
    }

    const revenueEstimateList = Array.from(new Set(fullForecastData.map((item) => item.revenueEstimate)))
      .map((revenueEstimate) => ({ value: revenueEstimate, label: revenueEstimate || '-', disabled: false }))
      .sort((a, b) => a.label.localeCompare(b.label));
    setRevenueEstimateCategoryList([
      {
        label: 'Select All',
        value: 'Select All',
        options: revenueEstimateList
      }
    ]);

    if (revenueEstimateList.length === 1) {
      setSelectedRevenueEstimateDropdown(revenueEstimateList);
    }
  }, [fullForecastData]);

  const clearCountryAndCampaignSelection = () => {
    setCountryList(EMPTY_MULTI_SELECT_ALL_INITIAL_VALUE);
    setSelectedCountryDropdown([]);
    setCampaignList(EMPTY_MULTI_SELECT_ALL_INITIAL_VALUE);
    setSelectedCampaignDropdown([]);
    setRevenueEstimateCategoryList(EMPTY_MULTI_SELECT_ALL_INITIAL_VALUE);
    setSelectedRevenueEstimateDropdown([]);
  };

  // Fetches the list of Campaigns based on Product selection
  const onProductChange = (detail: any) => {
    clearCountryAndCampaignSelection();
    const filteredProduct = appContext.userDetails.userProductLines?.find((productLine) => productLine.productID === +detail.selectedOption.value);
    setSelectedProductLine(filteredProduct ? filteredProduct : ({} as ProductLine));
  };

  const onScenarioChange = (detail: any) => {
    // clearCountryAndCampaignSelection();
    const filteredPlanningCycle = planningCycleList.find((planningCycle) => planningCycle.scenario.scenarioId === +detail.selectedOption.value);
    setSelectedPlanningCycle(filteredPlanningCycle ? filteredPlanningCycle : ({} as PlanningCycleDataModel));
  };

  useEffect(() => {
    if (
      isDefinedAndNotEmptyObject(selectedPlanningCycle) &&
      isDefinedAndNotEmptyObject(selectedProductLine) &&
      selectedProductLine?.productID != undefined
    ) {
      getForecastTemplateStatus(dataClassificationId, selectedProductLine?.productID, selectedPlanningCycle?.scenarioTemplateId);
      const filteredPlanningCycle = planningCycleList.find(
        (planningCycle) => planningCycle.scenario.scenarioId === +selectedPlanningCycle?.scenario?.scenarioId
      );
      setSelectedPlanningCycle(filteredPlanningCycle ? filteredPlanningCycle : ({} as PlanningCycleDataModel));
    }
  }, []);

  useEffect(() => {
    if (dataClassificationId != undefined && selectedProductLine?.productID != undefined && selectedPlanningCycle?.scenarioTemplateId != undefined) {
      getForecastTemplateStatus(dataClassificationId, selectedProductLine?.productID, selectedPlanningCycle?.scenarioTemplateId);
      setForecastTemplateToggleStatus(true);
    } else {
      setForecastTemplateStatus({ ...forecastTemplateStatus, forecastStatus: 'pending' });
      setForecastTemplateToggleStatus(false);
    }
  }, [appContext, selectedProductLine, selectedPlanningCycle]);

  const getForecastTemplateStatus = async (dataClassificationId: number, productLineId: number, scenarioTemplateId: number) => {
    setForecastTemplateToggleInProgress(true);
    try {
      const statusResponse: any = await API.graphql({
        query: queries.getForecastTemplateStatus,
        variables: {
          dataClassificationId: dataClassificationId,
          productLineId: productLineId,
          scenarioTemplateId: scenarioTemplateId
        }
      });
      const responseDetails: ForecastTemplateStatus = {
        ...statusResponse.data.getForecastTemplateStatus,
        isPoMapped: handleNull(statusResponse.data.getForecastTemplateStatus.isPoMapped)
      };
      setForecastTemplateStatus({
        ...responseDetails,
        forecastStatus: responseDetails.isActive === null ? 'in-progress' : 'success',
        isActive: responseDetails.isActive !== null ? responseDetails.isActive : false
      });
      setForecastTemplateToggleInProgress(false);
      logger.info(`Fetched Planning Cycle status for ${selectedPlanningCycle.scenario.scenarioName} `);
    } catch (error: any) {
      setForecastTemplateToggleInProgress(false);
      logger.error('Unable to fetch Forecast Template Status ', error);
    }
  };

  const toggleApprovalStatus = (detail: any) => {
    if (!isEmpty(forecastTemplateStatus) && forecastTemplateStatus?.isActive === null) {
      createForecastTemplateStatus(detail.checked);
    } else {
      updateForecastTemplateStatus(detail.checked);
    }
  };

  const isApprovalChecked = (): boolean => {
    // isActive can be null
    return forecastTemplateStatus?.isActive ? true : false;
  };

  const approvalLabel = (): string => {
    return forecastTemplateStatus?.isActive ? 'Approved' : 'Unapproved';
  };

  const disableCheckForApproval = () => {
    return (
      !forecastTemplateToggleStatus ||
      forecastTemplateToggleInProgress ||
      !isAfterCurrentTime(ForecastUtils.getFinalLockDate(selectedPlanningCycle, userAuthDetails.Alias))
    );
  };

  const createForecastTemplateStatus = async (approveMode: boolean) => {
    setForecastTemplateToggleInProgress(true);
    const createRequest = {
      createdBy: userAuthDetails.Alias,
      updatedBy: userAuthDetails.Alias,
      dataClassificationId: appContext.userDetails.dataClassification.dataClassificationId,
      productLineId: selectedProductLine?.productID,
      scenarioTemplateId: selectedPlanningCycle.scenarioTemplateId,
      isActive: approveMode
    };
    try {
      const createForecastTemplateResponse: any = await API.graphql({
        query: mutations.createForecastTemplateStatus,
        variables: { input: createRequest }
      });
      if (createForecastTemplateResponse?.data?.createForecastTemplateStatus?.numberOfRecordsUpdated == 1) {
        logger.info(`Updated Planning Cycle Approval status for ${selectedPlanningCycle.scenario.scenarioName} `);
        setForecastTemplateStatus({ ...forecastTemplateStatus, forecastStatus: 'success', isActive: approveMode });
        displayFlashMessage(`Successfully updated Approval status of Forecast Template `, 'success');
        setForecastTemplateToggleInProgress(false);
      } else {
        logger.error(`Unable to update Planning Cycle status for ${selectedPlanningCycle.scenario.scenarioName} `);
        displayFlashMessage(`Unable to update Planning Cycle status for Forecast Template `, 'error');
        setForecastTemplateToggleInProgress(false);
      }
    } catch (error) {
      logger.error(`Unable to update Approval status of Forecast Template`);
      setForecastTemplateToggleInProgress(false);
    }
  };

  const updateForecastTemplateStatus = async (approveMode: boolean) => {
    setForecastTemplateToggleInProgress(true);
    const updateRequest = {
      createdBy: userAuthDetails.Alias,
      updatedBy: userAuthDetails.Alias,
      dataClassificationId: appContext.userDetails.dataClassification.dataClassificationId,
      productLineId: selectedProductLine?.productID,
      scenarioTemplateId: selectedPlanningCycle.scenarioTemplateId,
      isActive: approveMode
    };
    try {
      const updateForecastTemplateResponse: any = await API.graphql({
        query: mutations.updateForecastTemplateStatus,
        variables: { input: updateRequest }
      });
      if (updateForecastTemplateResponse?.data?.updateForecastTemplateStatus?.numberOfRecordsUpdated == 1) {
        setForecastTemplateStatus({ ...forecastTemplateStatus, forecastStatus: 'success', isActive: approveMode });
        logger.info(`Updated Planning Cycle Approval status for ${selectedPlanningCycle.scenario.scenarioName} `);
        displayFlashMessage(`Successfully updated Planning Cycle status of Forecast Template`, 'success');
        setForecastTemplateToggleInProgress(false);
      } else {
        logger.error(`Unable to change status of Planning Cycle status for ${selectedPlanningCycle.scenario.scenarioName} `);
        displayFlashMessage(`Unable to change status of Planning Cycle status for ${selectedPlanningCycle.scenario.scenarioName} `, 'error');
        setForecastTemplateToggleInProgress(false);
      }
    } catch (error) {
      logger.error(`Unable to update Approval status of Forecast Template`);
      setForecastTemplateToggleInProgress(false);
    }
  };

  const getErrorText = (
    selectedPlanningCycle: PlanningCycleDataModel,
    selectedProductLine: ProductLine | undefined,
    itemList: MultiSelectGroupDropdownModel[],
    selectedItems: MultiSelectGroupDropdownModel[] | DropdownModel[],
    itemType: 'campaign' | 'country' | 'Revenue Estimate'
  ) => {
    if (
      isDefinedAndNotEmptyObject(selectedPlanningCycle) &&
      isDefinedAndNotEmptyObject(selectedProductLine) &&
      itemList?.[0]?.options?.length === 0
    ) {
      return `No data`;
    } else if (isDefinedAndNotEmptyObject(selectedPlanningCycle) && isDefinedAndNotEmptyObject(selectedProductLine) && selectedItems.length === 0) {
      return `Select at least one option`;
    } else {
      return '';
    }
  };

  const campaignError = getErrorText(selectedPlanningCycle, selectedProductLine, campaignList, selectedCampaignDropdown, 'campaign');

  const countryError = getErrorText(selectedPlanningCycle, selectedProductLine, countryList, selectedCountryDropdown, 'country');

  const revenueEstimateError = getErrorText(
    selectedPlanningCycle,
    selectedProductLine,
    revenueEstimateCategoryList,
    selectedRevenueEstimateDropdown,
    'Revenue Estimate'
  );

  return (
    <>
      <Grid gridDefinition={ForecastUtils.defaultForecastDetailsContainerGridDefinition}>
        <Container className="forecast-details-selection-container">
          <Grid gridDefinition={ForecastUtils.defaultForecastDetailsSelectionGridDefinition}>
            {/* List of Product Lines based on user access  */}
            <FormField
              label={
                <Box fontSize="body-s" fontWeight="bold">
                  Product Line
                </Box>
              }
            >
              <Select
                expandToViewport
                selectedAriaLabel="Selected"
                filteringType="auto"
                selectedOption={{ label: selectedProductLine?.productName, value: selectedProductLine?.productID?.toString() }}
                onChange={({ detail }) => onProductChange(detail)}
                options={appContext.userDetails.userProductLines?.map((productLine) => {
                  return {
                    label: productLine.productName,
                    value: productLine.productID.toString()
                  };
                })}
                placeholder={'Select Product Line'}
              />
            </FormField>

            {/* List of scenarios from current planning cycle  */}
            <FormField
              label={
                <Box fontSize="body-s" fontWeight="bold">
                  Planning Cycle
                </Box>
              }
            >
              <Select
                expandToViewport
                selectedAriaLabel="Selected"
                selectedOption={{
                  label: selectedPlanningCycle?.scenario?.scenarioName,
                  value: selectedPlanningCycle?.scenario?.scenarioId?.toString()
                }}
                onChange={({ detail }) => onScenarioChange(detail)}
                options={
                  planningCycleList
                    ? planningCycleList.map((currentPlanningCycle) => {
                        return {
                          label: currentPlanningCycle.scenario.scenarioName,
                          value: currentPlanningCycle.scenario.scenarioId.toString()
                        };
                      })
                    : []
                }
                placeholder={'Select Planning Cycle'}
              />
            </FormField>

            {/* List of campaigns from forecast template data */}
            <FormField
              label={
                <Box fontSize="body-s" fontWeight="bold">
                  Campaign
                </Box>
              }
              errorText={campaignError}
            >
              <Multiselect
                filteringType="auto"
                expandToViewport
                selectedAriaLabel="Selected"
                options={campaignList}
                selectedOptions={selectedCampaignDropdown}
                onChange={({ detail }) => setSelectedCampaignDropdown(detail.selectedOptions as MultiSelectGroupDropdownModel[])}
                hideTokens
                placeholder={getMultiSelectPlaceHolderValue(selectedCampaignDropdown, 'Campaign')}
              />
            </FormField>

            {/* List of countries from forecast template data */}
            <FormField
              label={
                <Box fontSize="body-s" fontWeight="bold">
                  Country
                </Box>
              }
              errorText={countryError}
            >
              <Multiselect
                filteringType="auto"
                expandToViewport
                selectedAriaLabel="Selected"
                options={countryList}
                selectedOptions={selectedCountryDropdown}
                onChange={({ detail }) => setSelectedCountryDropdown(detail.selectedOptions as MultiSelectGroupDropdownModel[])}
                placeholder={getMultiSelectPlaceHolderValue(selectedCountryDropdown, 'Country')}
                hideTokens
              />
            </FormField>

            {/* List of Revenue Estimate Category from forecast template data */}
            <FormField
              label={
                <Box fontSize="body-s" fontWeight="bold">
                  Revenue Estimate Category
                </Box>
              }
              errorText={revenueEstimateError}
            >
              <Box fontSize="body-s">
                <Multiselect
                  className="multi-select-place-holder"
                  filteringType="auto"
                  expandToViewport
                  selectedAriaLabel="Selected"
                  options={revenueEstimateCategoryList}
                  selectedOptions={selectedRevenueEstimateDropdown}
                  onChange={({ detail }) => {
                    setSelectedRevenueEstimateDropdown(detail.selectedOptions as MultiSelectGroupDropdownModel[]);
                  }}
                  placeholder={getMultiSelectPlaceHolderValue(selectedRevenueEstimateDropdown, 'Revenue Estimate Category')}
                  hideTokens
                />
              </Box>
            </FormField>
          </Grid>
        </Container>
        <Container className="forecast-details-selection-container">
          <SpaceBetween size="m" direction="horizontal">
            {/* Forecast status  */}
            <FormField
              data-testid="forecast-status"
              label={
                <Box fontSize="body-s" fontWeight="bold">
                  Forecast Status
                </Box>
              }
              // className="form-field-select-width-8rem"
            >
              <Box variant="awsui-key-label" fontSize="body-s">
                {appContext.userDetails?.userType?.userTypeDescription === eUserTypes.PRODUCT_LINE_USER && (
                  <>
                    {forecastTemplateStatus?.forecastStatus === 'pending' && <>{'-'}</>}
                    {forecastTemplateStatus?.forecastStatus != 'pending' && (
                      <StatusIndicator
                        type={forecastTemplateStatus?.isActive ? 'success' : 'in-progress'}
                        colorOverride={forecastTemplateStatus?.isActive ? 'green' : 'grey'}
                      >
                        {forecastTemplateStatus?.isActive ? 'Approved' : 'Unapproved'}
                      </StatusIndicator>
                    )}
                  </>
                )}

                {appContext.userDetails?.userType?.userTypeDescription !== eUserTypes.PRODUCT_LINE_USER && (
                  <>
                    <Toggle
                      disabled={disableCheckForApproval()}
                      onChange={({ detail }) => toggleApprovalStatus(detail)}
                      checked={isApprovalChecked()} // isActive can be null.
                    >
                      {approvalLabel()}
                    </Toggle>
                  </>
                )}
              </Box>
            </FormField>

            {/* tool lock date based on scenario  */}
            <FormField
              data-testid="Tool-Lock-Date"
              // className="form-field-select-width-10rem"
              label={
                <Box fontSize="body-s" fontWeight="bold">
                  Forecast Lock Date
                </Box>
              }
            >
              {isDefinedAndNotEmptyObject(selectedPlanningCycle) && (
                <Box
                  variant="awsui-key-label"
                  fontSize="body-s"
                  color={
                    isAfterCurrentTime(ForecastUtils.getFinalLockDate(selectedPlanningCycle, userAuthDetails.Alias))
                      ? 'text-status-info'
                      : 'text-status-error'
                  }
                >
                  {isDefinedAndNotEmptyObject(selectedPlanningCycle)
                    ? getCurrentUserLocalTimeReadableFormat(ForecastUtils.getFinalLockDate(selectedPlanningCycle, userAuthDetails.Alias))
                    : '-'}
                </Box>
              )}
              {!isDefinedAndNotEmptyObject(selectedPlanningCycle) && (
                <Box variant="awsui-key-label" fontSize="body-s" color={'text-status-info'}>
                  {'-'}
                </Box>
              )}
            </FormField>

            <FormField
              data-testid="Forecast-Start-Month"
              label={
                <Box fontSize="body-s" fontWeight="bold">
                  Forecast Months
                </Box>
              }
              // className="form-field-select-width-12rem"
            >
              <Box variant="awsui-key-label" fontSize="body-s">
                {`${
                  isDefinedAndNotEmptyObject(selectedPlanningCycle) ? getReadableFormatOfMonthAndYear(selectedPlanningCycle?.forecastStartMonth) : '-'
                } - ${
                  isDefinedAndNotEmptyObject(selectedPlanningCycle) ? getReadableFormatOfMonthAndYear(selectedPlanningCycle?.forecastEndMonth) : '-'
                }`}
              </Box>
            </FormField>
          </SpaceBetween>
        </Container>
      </Grid>
    </>
  );
};
