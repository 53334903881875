import { Badge, Box, Button, Modal, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react';
import { KatFileUploadStatus } from '@amzn/katal-components/es/file-upload';
import { KatFileUpload } from '@amzn/katal-react';
import React, { useEffect, useState } from 'react';
import { VALIDATION_FAILED, VALIDATION_INITIATED, VALIDATION_NOT_INITIATED, ValidationEntity } from 'src/utils/CommonHelpers';
import { getHeaderRowFromExcel, readExcelFile } from 'src/utils/FileServices';
import { logger } from 'src/utils/Logger';
import { useAppContext } from '../context/AppContextProvider';
import { useAuth } from '../context/AuthContext';
import { LoadingSpinner } from '../generic-components/Spinner';
import * as ForecastUtils from './ForecastUtils';
import * as ForecastValidations from './ForecastValidations';
import { FixedColumnInformation } from './ForecastModel';

interface FileState {
  fileUploadState: FileUploadState;
}

interface FileUploadState {
  status: KatFileUploadStatus;
  error?: string;
}

export interface FileValidation extends Record<string, any> {
  HeadersMatching: ValidationEntity;
  RequiredFiledMissing: ValidationEntity;
  MatchProductLineCampaignAndCorpIds: ValidationEntity;
}

const INITIAL_VALIDATION_STATUS: FileValidation = {
  HeadersMatching: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Header validation' },
  RequiredFiledMissing: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Required Field validation' },
  MatchProductLineCampaignAndCorpIds: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Campaign and Corp Id validation' },
  DuplicateProductLineCampaignAndCorpIds: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Duplicate Campaign and Corp Id validation' }
};

interface ForecastImportModalProps {
  showModal: boolean;
  onCancel: () => void;
  onConfirm: (uploadFileData: any[]) => void;
  fixedColumnInfo: FixedColumnInformation;
  forecastTableRowData: any[];
}
export const ForecastImportModal = ({ showModal, onCancel, onConfirm, fixedColumnInfo, forecastTableRowData }: ForecastImportModalProps) => {
  const user = useAuth();
  const appContext = useAppContext();

  const [file, setFile] = useState<File | null>(null);
  const [fileState, setFileState] = useState<FileState>({ fileUploadState: { status: KatFileUploadStatus.READY, error: '' } });
  const [fileValidation, setFileValidation] = useState<FileValidation>(INITIAL_VALIDATION_STATUS);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [submissionError, setSubmissionError] = useState('');
  const [fileHeaders, setFileHeaders] = useState<string[]>([]);
  const [uploadFileData, setUploadFileData] = useState<any[]>([]);

  useEffect(() => {
    setUploadingFile(false);
    setFileValidation(INITIAL_VALIDATION_STATUS);
    setFile(null);
    setFileState({ fileUploadState: { status: KatFileUploadStatus.READY, error: '' } });
  }, [showModal]);

  const handleOnFileUpload = async (event: KatFileUpload.FilesAttachedEvent) => {
    logger.info(` upload - handleOnFileUpload`);
    setFile(event.detail.files[0]);
    const fileInfo = await Promise.all([getHeaderRowFromExcel(event.detail.files[0]), readExcelFile(event.detail.files[0])]);
    ValidateData(fileInfo[0], fileInfo[1]);
  };

  const handleOnFileReplacement = async (event: KatFileUpload.FilesReplacedEvent) => {
    logger.info(` upload - handleOnFileReplacement`);
    setFile(event.detail.newFiles[0]);
    const fileInfo = await Promise.all([getHeaderRowFromExcel(event.detail.newFiles[0]), readExcelFile(event.detail.newFiles[0])]);
    ValidateData(fileInfo[0], fileInfo[1]);
  };

  const handleOnFileRemove = (event: KatFileUpload.FilesRemovedEvent) => {
    setFile(null);
    setUploadFileData([]);
  };

  const ValidateData = async (uploadFileHeader: string[], uploadFileData: any[]) => {
    if (uploadFileHeader.length === 0 || uploadFileData.length === 0) {
      setFileValidation({
        HeadersMatching: { ...VALIDATION_FAILED, validationMessage: 'No data available in file' },
        RequiredFiledMissing: { ...VALIDATION_FAILED, validationMessage: 'No data available in file' },
        MatchProductLineCampaignAndCorpIds: { ...VALIDATION_FAILED, validationMessage: 'No data available in file' },
        DuplicateProductLineCampaignAndCorpIds: { ...VALIDATION_FAILED, validationMessage: 'No data available in file' }
      });
    } else {
      const parsedForecastUploadFile = ForecastUtils.parseExcelToForecastObject(uploadFileData, fixedColumnInfo);
      setUploadFileData(parsedForecastUploadFile);
      setFileValidation({
        HeadersMatching: { ...VALIDATION_INITIATED, validationMessage: 'Validating Headers' },
        RequiredFiledMissing: { ...VALIDATION_INITIATED, validationMessage: 'Checking required fields' },
        MatchProductLineCampaignAndCorpIds: { ...VALIDATION_INITIATED, validationMessage: 'Validating campaign and corp id records' },
        DuplicateProductLineCampaignAndCorpIds: {
          ...VALIDATION_INITIATED,
          validationMessage: 'Validating if campaign and corp id records have duplicates'
        }
      });

      // Updating the UI about the validation status
      setFileValidation({
        // Basic checks like required fields, headers and duplicates
        HeadersMatching: await ForecastValidations.validateHeaderData(uploadFileHeader, fixedColumnInfo),
        RequiredFiledMissing: await ForecastValidations.validateRequiredFields(uploadFileHeader, parsedForecastUploadFile),
        MatchProductLineCampaignAndCorpIds: await ForecastValidations.ValidateProductLineCampaignAndCorpId(
          uploadFileHeader,
          parsedForecastUploadFile,
          forecastTableRowData
        ),
        DuplicateProductLineCampaignAndCorpIds: await ForecastValidations.ValidateDuplicateProductLineCampaignAndCorpId(
          uploadFileHeader,
          parsedForecastUploadFile,
          forecastTableRowData
        )
      });
    }
  };

  const isValidFile = () => {
    return (
      fileValidation.HeadersMatching.validationStatus === 'success' &&
      fileValidation.RequiredFiledMissing.validationStatus === 'success' &&
      fileValidation.MatchProductLineCampaignAndCorpIds.validationStatus === 'success' &&
      fileValidation.DuplicateProductLineCampaignAndCorpIds.validationStatus === 'success'
    );
  };

  const handleExcelFileUploadSubmit = async () => {
    setUploadingFile(true);
    onConfirm(uploadFileData);
    setUploadingFile(false);
  };

  return (
    <Modal
      onDismiss={() => {
        setUploadingFile(true);
        onCancel();
      }}
      visible={showModal}
      closeAriaLabel="Close modal"
      header={'Forecast Import'}
      footer={
        <>
          {submissionError !== '' ? <StatusIndicator type="error">{submissionError}</StatusIndicator> : <></>}
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="link"
                onClick={() => {
                  setUploadingFile(true);
                  onCancel();
                }}
              >
                Cancel
              </Button>
              <Button variant="primary" disabled={!isValidFile() || uploadingFile} onClick={handleExcelFileUploadSubmit}>
                Load data
              </Button>
            </SpaceBetween>
          </Box>
        </>
      }
    >
      <SpaceBetween size="m" direction="vertical">
        {!uploadingFile && (
          <KatFileUpload
            accept=".xlsx"
            file-view="list"
            variant="small"
            required
            disabled={uploadingFile}
            multiple={false}
            state={fileState}
            onFilesAttached={(e) => handleOnFileUpload(e)}
            onFilesReplaced={(e) => handleOnFileReplacement(e)}
            onFilesRemoved={(e) => handleOnFileRemove(e)}
          >
            <div slot="hint">{'Accepted file format: .xlsx'}</div>
            <div slot="constraints-message">{'Accepted file format: .xlsx'}</div>
            <div slot="error">{fileState.fileUploadState.error}</div>
          </KatFileUpload>
        )}
        {uploadingFile && <LoadingSpinner />}
        <div>
          <SpaceBetween size="m" direction="vertical">
            {Object.keys(fileValidation).map((key, index) => {
              return (
                <StatusIndicator key={index} type={fileValidation[key].validationStatus} colorOverride={fileValidation[key].colorOverride}>
                  {`${fileValidation[key].validationMessage}`}
                </StatusIndicator>
              );
            })}
          </SpaceBetween>
        </div>
      </SpaceBetween>
    </Modal>
  );
};
