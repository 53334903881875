import React from 'react';
import { HelpPanel, Link } from '@amzn/awsui-components-react';
import { eSIMLinks } from 'src/utils/DmmConstants';

export const PlanningCycleInfoPanel = () => {
  return (
    <HelpPanel
      header={<h2>Planning cycle scheduler</h2>}
      footer={
        <>
          <h3>Contact Us</h3>
          <ul>
            <li>
              <Link external href={eSIMLinks.ISSUE_REPORT}>
                Contact Tool Support
              </Link>
            </li>
          </ul>
        </>
      }
    >
      <h3>Description</h3>
      <p>Configures the planning cycle and deadlines for forecast templates that product lines use to plan spend.</p>

      <h3>How to use</h3>
      <div>
        <ol>
          <li>Select Create to add a new planning cycle</li>
          <li>From scenario’s action dropdown select Edit to modify the planning cycle</li>
          <li>
            From scenario’s action dropdown select Close to <b>permanently</b> close a scenario once the forecast is complete.
          </li>
        </ol>
      </div>
    </HelpPanel>
  );
};
