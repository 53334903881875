import React, { useState } from 'react';
import {
  Box,
  AppLayout,
  Tabs,
  SpaceBetween,
  Flashbar,
  FlashbarProps,
  ContentLayout,
  Header,
  Button,
  Link
} from '@amzn/awsui-components-react/polaris';

import { AppSideNavigation } from '../navigation/AppSideNavigation';
import { CurrentPlanningCycleList } from './CurrentPlanningCycleList';
import { PlanningCycleHistoricalTable } from './PlanningCycleTable';
import { BaseBreadcrumbs } from 'src/utils/DmmConstants';
import { PlanningCycleInfoPanel } from './PlanningCycleInfoPanel';
import { AppBreadcrumb } from '../navigation/AppBreadcrumb';
import { useNavigate } from 'react-router-dom';

const resourceScenarioBreadcrumbs = [
  ...BaseBreadcrumbs,
  {
    text: 'Planning cycle',
    href: '#!'
  }
];

export const PlanningCycle = () => {
  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);
  const [toolsOpen, setToolsOpen] = useState(false);
  const navigate = useNavigate();

  const displayFlashMessage = (loading: boolean, content: string, flashbarType: FlashbarProps.Type) => {
    setFlashbarItems([
      {
        loading: loading,
        type: flashbarType,
        content: content,
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => setFlashbarItems([])
      }
    ]);
  };

  // callback handler for notification messages
  const notificationHandler = (status: boolean, notificationMessage: string, notificationType: FlashbarProps.Type) => {
    displayFlashMessage(status, notificationMessage, notificationType);
  };

  const handleToolOpen = (isOpen: boolean) => {
    setToolsOpen(isOpen);
  };

  const handleCreateButton = () => {
    navigate({ pathname: '/planning-cycle/create' });
  };

  return (
    <AppLayout
      headerSelector="#h"
      notifications={<Flashbar items={flashbarItems} />}
      navigation={<AppSideNavigation />}
      breadcrumbs={<AppBreadcrumb items={resourceScenarioBreadcrumbs} />}
      tools={<PlanningCycleInfoPanel />}
      toolsOpen={toolsOpen}
      onToolsChange={({ detail }) => setToolsOpen(detail.open)}
      content={
        <ContentLayout
          disableOverlap
          header={
            <Header
              variant="h2"
              actions={
                <Button variant="primary" onClick={handleCreateButton} iconName="add-plus">
                  {'New planning cycle'}
                </Button>
              }
              info={
                <Link variant="info" onFollow={() => handleToolOpen(true)}>
                  Info
                </Link>
              }
            >
              {'Planning cycle'}
            </Header>
          }
        >
          <Tabs
            tabs={[
              {
                label: 'Current Planning Cycle',
                id: 'current',
                content: <CurrentPlanningCycleList messageHandler={notificationHandler} onToolOpen={handleToolOpen} />
              },
              {
                label: 'Historical',
                id: 'Historical Scenario',
                content: <PlanningCycleHistoricalTable messageHandler={notificationHandler} onToolOpen={handleToolOpen} />
              }
            ]}
          />
        </ContentLayout>
      }
    />
  );
};
