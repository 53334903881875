import {
  Badge,
  Box,
  Button,
  FlashbarProps,
  Form,
  FormField,
  Input,
  Multiselect,
  Select,
  SpaceBetween,
  Spinner,
  Toggle
} from '@amzn/awsui-components-react';
import { RestAPI } from '@aws-amplify/api-rest';
import { API } from 'aws-amplify';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from 'src/components/context/AuthContext';
import * as mutations from 'src/graphql/mutations';
import { eUserTypes } from 'src/utils/DmmConstants';
import { getCurrentUserLocalTime } from 'src/utils/DateTimeUtils';
import { getValidationErrorMessage } from 'src/utils/labels';
import { logger } from 'src/utils/Logger';
import * as Yup from 'yup';
import { User } from '../context/AppContextModel';
import { useAppContext } from '../context/AppContextProvider';
import { getMultiSelectPlaceHolderValue } from '../generic-components/Utils';
import { PAPIUserInfo, PAPIUserValidation, UserForm } from './models/Users';
import { sendSNSNotification } from 'src/utils/AWSServices';

interface UserFormProps {
  selectedUsers: User[];
  updateTheTable: (updatedUserRow: User, eventType: 'Create' | 'Edit') => void;
  displayFlashMessage: (content: string, flashBarType: FlashbarProps.Type) => void;
}

const FormInitialValues: UserForm = {
  userAlias: '',
  isValidUserAlias: false,
  userName: '',
  updatedTime: '',
  updatedBy: '',
  createdBy: '',
  createdTime: '',
  productLine: [],
  userAccess: { label: '', value: '' },
  isActive: false,
  userType: { label: '', value: '' },
  dataClassification: { label: '', value: '' }
};

const REQUIRED_FIELD = 'Required field';
const yupRequiredDropdownField = () => {
  return Yup.object().shape({
    label: Yup.string().required(REQUIRED_FIELD)
  });
};

const UserFormSchema = Yup.object().shape({
  userAlias: Yup.string()
    .required(REQUIRED_FIELD)
    .min(1, 'Minimum 1 character required')
    .max(20, 'Maximum 20 characters allowed')
    .matches(/^[a-z]+$/, 'Allows only a-z'),
  userName: Yup.string().required(REQUIRED_FIELD),
  productLine: Yup.array().min(1, 'Select at least 1 Product Line'),
  userAccess: yupRequiredDropdownField(),
  isActive: Yup.bool().required(REQUIRED_FIELD),
  userType: yupRequiredDropdownField(),
  dataClassification: yupRequiredDropdownField()
});

export const AccessAuthorizationForm = ({ selectedUsers, updateTheTable, displayFlashMessage }: UserFormProps) => {
  const userDetails = useAuth();
  const { users, setUsers, appMetadata } = useAppContext();
  const userFormRef = useRef<FormikProps<UserForm>>(null);
  const [eventType, setEventType] = useState<'Create' | 'Edit'>(selectedUsers.length === 0 ? 'Create' : 'Edit');
  const [userFormInitialValues, setUserFormInitialValues] = useState<UserForm>(FormInitialValues);
  const [validatingUserWithPAPI, setValidatingUserWithPAPI] = useState<PAPIUserValidation>({
    validating: false,
    isValid: false,
    validationMessage: 'Not Initiated',
    PAPIUserDetails: {} as PAPIUserInfo
  });

  useEffect(() => {
    userFormRef.current?.resetForm();
    setEventType(selectedUsers.length === 0 ? 'Create' : 'Edit');
    if (selectedUsers.length != 0) {
      let formattedToFormModel = getFormModel(selectedUsers[0]);
      formattedToFormModel = { ...formattedToFormModel, isValidUserAlias: true };
      setValidatingUserWithPAPI({
        ...validatingUserWithPAPI,
        validating: false,
        isValid: true,
        validationMessage: 'Valid Alias'
      });
      setUserFormInitialValues(formattedToFormModel);
    } else {
      setUserFormInitialValues(FormInitialValues);
    }
  }, [selectedUsers]);

  const getFormModel = (selectedUser: User): UserForm => {
    return {
      userAlias: selectedUser.userAlias,
      isValidUserAlias: eventType === 'Edit',
      userName: selectedUser.userName,
      updatedTime: selectedUser.updatedTime,
      updatedBy: selectedUser.updatedBy,
      createdBy: selectedUser.createdBy,
      createdTime: selectedUser.createdTime,
      productLine: selectedUser?.productLine?.map((product) => {
        return { label: product.productName, value: product.productID.toString(), description: product.isFpnaManagedPl ? 'FPNA Managed' : '' };
      }),
      userAccess: {
        label: selectedUser?.userAccess.userAccessDescription,
        value: selectedUser?.userAccess.isReadOnly.toString()
      },
      isActive: selectedUser?.isActive,
      userType: {
        label: selectedUser?.userType.userTypeDescription,
        value: selectedUser?.userType.userTypeId.toString()
      },
      dataClassification: {
        label: selectedUser?.dataClassification.dataClassificationDesc,
        value: selectedUser?.dataClassification.dataClassificationId.toString()
      }
    };
  };

  const prepareSnsMessage = (userAlias: string) => {
    return `New user '${userAlias}' added into DMM Tool. \nTo add user into the DMM Quicksight Report LDAP Group, please use the below link. \nhttps://permissions.amazon.com/a/team/dmm-quicksight-report-access?requester=${userAlias}&reason=New%20User\n`;
  };

  const handleSubmit = async (formValues: UserForm, formikHelpers: FormikHelpers<UserForm>) => {
    try {
      const form = formatToUserModel(formValues);
      const updateUserResponse = await API.graphql({
        query: mutations.updateUser,
        variables: { input: form }
      });
      updateTheTable(form, eventType);
      formikHelpers.setSubmitting(false);
      logger.info(`${eventType} operation for ${formValues.userAlias} is success`);
      if (eventType === 'Create') {
        sendSNSNotification('New User', prepareSnsMessage(formValues.userAlias));
      }
    } catch (error: any) {
      logger.error(`Unable to ${eventType} ${formValues.userAlias}`, error);
      displayFlashMessage(`Unable to ${eventType} ${formValues.userAlias}`, 'error');
      formikHelpers.setSubmitting(false);
    }
  };

  const formatToUserModel = (userFormValues: UserForm) => {
    let userModel: User = {
      userAlias: userFormValues.userAlias,
      userName: userFormValues.userName.replace("'", "''"),
      dataClassification: {
        dataClassificationId: +userFormValues.dataClassification.value,
        dataClassificationDesc: userFormValues.dataClassification.label
      },
      userType: {
        userTypeId: +userFormValues.userType.value,
        userTypeDescription: userFormValues.userType.label
      },
      userAccess: {
        isReadOnly: userFormValues.userAccess.value === 'true',
        userAccessDescription: userFormValues.userAccess.label
      },
      isActive: userFormValues.isActive,
      productLine: userFormValues.productLine.map((product) => {
        return { productID: +product.value, productName: product.label, isFpnaManagedPl: product.description === 'FPNA Managed' };
      }),
      createdBy: eventType === 'Create' ? userDetails.Alias : userFormValues.createdBy,
      createdTime: eventType === 'Create' ? getCurrentUserLocalTime() : userFormValues.createdTime,
      updatedBy: userDetails.Alias,
      updatedTime: getCurrentUserLocalTime()
    };
    return userModel;
  };

  const validateUserAlias = async (userInput: string) => {
    if (!isExistingUser(userInput)) {
      userFormRef.current?.validateField('userAlias');
      let userAliasErrors = userFormRef.current?.errors.userAlias;
      if (userFormRef.current?.values.userAlias !== '' && userAliasErrors === undefined) {
        userFormRef.current?.setFieldValue('userName', '');
        setValidatingUserWithPAPI({
          ...validatingUserWithPAPI,
          validating: true,
          validationMessage: 'Validating Alias'
        });
        await RestAPI.get('PAPI', '/employee', getPapiParams(userInput))
          .then((response) => {
            let employeeBasicInfo: PAPIUserInfo = response.data.basicInfo;
            setValidatingUserWithPAPI({
              ...validatingUserWithPAPI,
              validating: false,
              isValid: employeeBasicInfo.jobStatus === 'A', // The employee's job status. "A" for active, "I" for inactive.
              PAPIUserDetails: response.data.basicInfo,
              validationMessage: employeeBasicInfo.jobStatus === 'A' ? 'Valid Alias' : 'User is not active'
            });
            userFormRef.current?.setFieldValue('isValidUserAlias', employeeBasicInfo.jobStatus === 'A');
            userFormRef.current?.setFieldValue('userName', employeeBasicInfo.firstName + ' ' + employeeBasicInfo.lastName);
            logger.info(`Alias validated using PAPI for ${userInput}`);
          })
          .catch((error: any) => {
            logger.error(`Alias validation failed using PAPI for ${userInput}`, error);
            setValidatingUserWithPAPI({
              ...validatingUserWithPAPI,
              validating: false,
              isValid: false,
              validationMessage: 'Invalid Alias'
            });
            userFormRef.current?.setFieldValue('isValidUserAlias', false);
            userFormRef.current?.setFieldValue('userName', '');
          });
      }
    }
  };

  const isExistingUser = (userInput: string) => {
    const userAliasFound = users.find((user) => user.userAlias === userInput);
    if (userAliasFound) {
      logger.info(`Entered existing user alias ${userInput} for Create`);
      setEventType('Edit');
      let formattedToFormModel = getFormModel(userAliasFound);
      formattedToFormModel = { ...formattedToFormModel, isValidUserAlias: true };
      setUserFormInitialValues(formattedToFormModel);
      setValidatingUserWithPAPI({
        ...validatingUserWithPAPI,
        validating: false,
        isValid: true,
        validationMessage: 'Valid Alias'
      });
    } else {
      setUserFormInitialValues(FormInitialValues);
    }
    return userAliasFound !== undefined;
  };

  const getPapiParams = (userALias: string) => {
    return {
      response: true,
      queryStringParameters: {
        login: userALias,
        expandOptions: ''
      }
    };
  };

  const allProductsDropdown = appMetadata.listProducts?.map((product) => {
    return {
      label: product.productName,
      value: product.productID?.toString()
    };
  });

  const onUserTypeChanged = (detail: any) => {
    userFormRef.current?.setFieldValue('userType', detail.selectedOption);
    switch (detail.selectedOption.label) {
      case eUserTypes.FPNA_USER:
        userFormRef.current?.setFieldValue('productLine', allProductsDropdown);
        break;
      default:
        userFormRef.current?.setFieldValue('productLine', []);
        break;
    }
  };

  return (
    <>
      <SpaceBetween size="s" direction="vertical">
        <Formik<UserForm>
          innerRef={userFormRef}
          enableReinitialize
          initialValues={userFormInitialValues}
          validationSchema={UserFormSchema}
          onSubmit={handleSubmit}
        >
          {({ values, touched, errors, setFieldValue, handleSubmit, isSubmitting, dirty }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Form>
                  <SpaceBetween size="xl" direction="vertical">
                    <SpaceBetween size="m" direction="vertical">
                      <FormField label="Alias" errorText={touched.userAlias && errors.userAlias}>
                        <Input
                          disabled={eventType === 'Edit'}
                          value={values.userAlias}
                          onChange={(event) => setFieldValue('userAlias', event.detail.value.toLowerCase())}
                          onBlur={() => validateUserAlias(values.userAlias)}
                        />
                      </FormField>

                      <FormField errorText={touched.isValidUserAlias && errors.isValidUserAlias}>
                        <SpaceBetween size="s" direction="horizontal">
                          {validatingUserWithPAPI.validating && (
                            <Box margin="xxxs" padding="xxxs" textAlign="left">
                              <Spinner size="normal" />
                            </Box>
                          )}
                          <Badge color={values.isValidUserAlias ? 'green' : 'red'}>{validatingUserWithPAPI.validationMessage}</Badge>
                        </SpaceBetween>
                      </FormField>

                      <FormField label="Name" errorText={touched.userName && errors.userName} constraintText="Auto fills by providing valid Alias">
                        <Input disabled value={values.userName} onChange={(event) => setFieldValue('userName', event.detail.value)} />
                      </FormField>

                      <FormField label="Classification" errorText={touched.dataClassification?.label && errors.dataClassification?.label}>
                        <Select
                          selectedOption={values.dataClassification}
                          onChange={({ detail }) => setFieldValue('dataClassification', detail.selectedOption)}
                          options={appMetadata?.listDataClassification?.map((classification) => {
                            return {
                              value: classification.dataClassificationId.toString(),
                              label: classification.dataClassificationDesc
                            };
                          })}
                          selectedAriaLabel="Selected"
                        />
                      </FormField>

                      <FormField label="Type" errorText={touched.userType?.label && errors.userType?.label}>
                        <Select
                          selectedOption={values.userType}
                          onChange={({ detail }) => onUserTypeChanged(detail)}
                          options={appMetadata?.listUserTypes?.map((type) => {
                            return { value: type.userTypeId.toString(), label: type.userTypeDescription };
                          })}
                          selectedAriaLabel="Selected"
                        />
                      </FormField>

                      <FormField label="Access" errorText={touched.userAccess?.label && errors.userAccess?.label}>
                        <Select
                          selectedOption={values.userAccess}
                          onChange={({ detail }) => setFieldValue('userAccess', detail.selectedOption)}
                          options={appMetadata?.listUserAccess?.map((access) => {
                            return { value: access.isReadOnly?.toString(), label: access.userAccessDescription };
                          })}
                          selectedAriaLabel="Selected"
                        />
                      </FormField>

                      <FormField label="Status" errorText={touched.isActive && errors.isActive}>
                        <Toggle checked={values.isActive} onChange={({ detail }) => setFieldValue('isActive', detail.checked)}>
                          {values.isActive ? 'Active' : 'Inactive'}
                        </Toggle>
                      </FormField>

                      <FormField label="Product Lines" errorText={getValidationErrorMessage(touched.productLine, errors.productLine)}>
                        <Multiselect
                          hideTokens
                          placeholder={getMultiSelectPlaceHolderValue(values.productLine, 'Product Line')}
                          filteringType="auto"
                          selectedOptions={values.productLine}
                          onChange={({ detail }) => setFieldValue('productLine', detail.selectedOptions)}
                          deselectAriaLabel={(e) => `Remove ${e.label}`}
                          options={allProductsDropdown}
                          selectedAriaLabel="Selected"
                        />
                      </FormField>
                    </SpaceBetween>

                    <SpaceBetween size="m" direction="vertical">
                      <Button variant="primary" disabled={isSubmitting || !dirty} formAction={'submit'}>
                        {eventType === 'Create' ? 'Create' : 'Update'}
                      </Button>
                    </SpaceBetween>
                  </SpaceBetween>
                </Form>
              </form>
            );
          }}
        </Formik>
      </SpaceBetween>
    </>
  );
};
