import React from 'react';
import { Checkbox, CollectionPreferences, CollectionPreferencesProps, Select, StatusIndicator, TableProps } from '@amzn/awsui-components-react';
import { getCurrentUserLocalTimeReadableFormat } from 'src/utils/DateTimeUtils';
import { Link } from 'react-router-dom';
import { PAGE_SIZE_OPTIONS } from 'src/utils/CommonHelpers';
import { CampaignCorpDimensions, CorpDimensionsList } from './CampaignModels';
import { DateForm, formatTimestamp } from '../generic-components/DateTimeForm';

export const DEFAULT_PAGE_SIZE = 20;

export const CAMPAIGN_COLUMN_DEFINITIONS_PREFIX: TableProps.ColumnDefinition<any>[] = [
  {
    id: 'campaignId',
    sortingField: 'campaignId',
    header: 'Id',
    cell: (item: any) => item.campaignId
  },
  {
    id: 'campaignName',
    sortingField: 'campaignName',
    header: 'Campaign name',
    cell: (item: any) => <Link to={'/campaigns/' + item.campaignId}>{item.campaignName}</Link>,
    width: 170
  },
  {
    id: 'status',
    sortingField: 'status',
    header: 'Status',
    cell: (item: any) => (
      <StatusIndicator colorOverride={item.isActive ? 'green' : 'red'} type={item.isActive ? 'success' : 'stopped'}>
        {item.status}
      </StatusIndicator>
    ),
    width: 110
  }
];

export const CAMPAIGN_COLUMN_DEFINITIONS_SUFFIX: TableProps.ColumnDefinition<any>[] = [
  {
    id: 'country',
    sortingField: 'country',
    cell: (item: any) => item.country,
    header: 'Country',
    width: 160
  },
  {
    id: 'businessOwner',
    sortingField: 'businessOwner',
    cell: (item: any) => item.businessOwner,
    header: 'Business Owner',
    width: 160
  },
  {
    id: 'efficiencyGoal',
    sortingField: 'efficiencyGoal',
    cell: (item: any) => item.efficiencyGoal,
    header: 'Efficiency Goal',
    width: 130
  },
  {
    id: 'revenueEstimate',
    sortingField: 'revenueEstimate',
    cell: (item: any) => item.revenueEstimate,
    header: 'Revenue Estimate Category',
    width: 100
  },
  {
    id: 'businessCategory',
    sortingField: 'businessCategory',
    cell: (item: any) => item.businessCategory,
    header: 'Business Category',
    width: 100
  },
  {
    id: 'createdBy',
    sortingField: 'createdBy',
    cell: (item: any) => item.createdBy,
    header: 'Created By',
    width: 160
  },
  {
    id: 'createdTime',
    sortingField: 'createdTime',
    cell: (item: any) => getCurrentUserLocalTimeReadableFormat(item.createdTime),
    header: 'Created Time',
    width: 160
  },
  {
    id: 'updatedBy',
    sortingField: 'updatedBy',
    cell: (item: any) => item.updatedBy,
    header: 'Updated By',
    width: 160
  },
  {
    id: 'updatedTime',
    sortingField: 'updatedTime',
    cell: (item: any) => item.updatedTime,
    header: 'Updated Time',
    width: 160
  }
];

export const VISIBLE_CONTENT = [
  'campaignName',
  'status',
  'businessOwner',
  'efficiencyGoal',
  'revenueEstimate',
  'businessCategory',
  'country',
  'updatedTime'
];

export const VISIBLE_CONTENT_OPTIONS_PREFIX = [
  { id: 'campaignId', label: 'Id' },
  { id: 'campaignName', label: 'Campaign Name' },
  { id: 'status', label: 'Status' }
];

export const VISIBLE_CONTENT_OPTIONS_SUFFIX = [
  { id: 'country', label: 'Country' },
  { id: 'businessOwner', label: 'Business Owner' },
  { id: 'efficiencyGoal', label: 'Efficiency Goal' },
  { id: 'revenueEstimate', label: 'Revenue Estimate' },
  { id: 'businessCategory', label: 'Business Category' },
  { id: 'createdBy', label: 'Created By' },
  { id: 'createdTime', label: 'Created Time' },
  { id: 'updatedBy', label: 'Updated By' },
  { id: 'updatedTime', label: 'Updated Time' }
];

export const CAMPAIGN_INITIAL_FILTERING_PROPERTIES: any[] = [
  {
    propertyLabel: 'Campaign name',
    key: 'campaignName',
    groupValuesLabel: 'campaign values',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'Status',
    key: 'status',
    groupValuesLabel: 'Status values',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'Country',
    key: 'country',
    groupValuesLabel: 'Country values',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'Business owner',
    key: 'businessOwner',
    groupValuesLabel: 'Business owner values',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'Updated by',
    key: 'updatedBy',
    groupValuesLabel: 'Updated by values',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'Updated Time',
    key: 'updatedTime',
    groupValuesLabel: 'Updated Time values',
    operators: ['<', '<=', '>', '>=']
    // Work in Progress
    // .map((operator) => ({
    //   operator,
    //   form: DateForm,
    //   format: formatTimestamp,
    //   match: "datetime",
    // })),
  }
];

export const getColumnDefinitions = (fpnaDimensions: any[]) => {
  const customColumnDefinitions = fpnaDimensions.map((fpnaDimension) => {
    return {
      id: fpnaDimension.key,
      sortingField: fpnaDimension.key,
      cell: (item: any) => item[fpnaDimension.key],
      header: fpnaDimension.name,
      minWidth: 170
    };
  });
  return [...CAMPAIGN_COLUMN_DEFINITIONS_PREFIX, ...customColumnDefinitions, ...CAMPAIGN_COLUMN_DEFINITIONS_SUFFIX];
};

export const getVisibleContent = (fpnaDimensions: any[]) => {
  const fpnaDimensionContent = fpnaDimensions.map((dimension) => {
    return dimension.key;
  });
  let result = [...VISIBLE_CONTENT, ...fpnaDimensionContent];
  return result;
};

export const getVisibleContentOptions = (fpnaDimensions: any[]) => {
  const visibleContent = fpnaDimensions.map((dimension) => {
    return {
      id: dimension.key,
      label: dimension.name
    };
  });
  let resultOptions = [...VISIBLE_CONTENT_OPTIONS_PREFIX, ...visibleContent, ...VISIBLE_CONTENT_OPTIONS_SUFFIX];
  return [
    {
      label: 'Properties',
      options: resultOptions
    }
  ] as CollectionPreferencesProps.VisibleContentOptionsGroup[];
};

export const getFilteringProperties = (fpnaDimensions: any[]) => {
  const filteringProperties = fpnaDimensions.map((dimension) => {
    return {
      propertyLabel: dimension.name,
      key: dimension.key,
      groupValuesLabel: `${dimension.name} values`,
      operators: [':', '!:', '=', '!=']
    };
  });
  let result = [...CAMPAIGN_INITIAL_FILTERING_PROPERTIES, ...filteringProperties];
  return result;
};

export const CAMPAIGN_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences = {
  pageSize: 20,
  visibleContent: VISIBLE_CONTENT,
  wrapLines: false,
  stripedRows: false,
  custom: false
};

export const CampaignTablePreferences = ({
  preferences,
  setPreferences,
  visibleContentOptions,
  disabled = false,
  pageSizeOptions = PAGE_SIZE_OPTIONS
}: any) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: 'Page size',
      options: pageSizeOptions
    }}
    wrapLinesPreference={{
      label: 'Wrap lines',
      description: 'Check to see all the text and wrap the lines'
    }}
    stripedRowsPreference={{
      label: 'Striped rows',
      description: 'Check to add alternating shaded rows'
    }}
    visibleContentPreference={{
      title: 'Select visible columns',
      options: visibleContentOptions
    }}
    customPreference={(checked, setChecked) => (
      <Checkbox
        onChange={({ detail }) => {
          setChecked(detail.checked);
        }}
        checked={checked}
      >
        Resizable Columns
      </Checkbox>
    )}
  />
);

// Campaign Dimensions Config (Corp DImensions)
export const campaignCorpDimensionColumnDefinition = (isEditable: boolean, isCampaignActive: boolean, corpDimensions: CorpDimensionsList) => {
  return isEditable && isCampaignActive
    ? editableCorpDimensions(corpDimensions)
    : ([
        {
          id: 'isActive',
          sortingField: 'isActive',
          header: 'Status',
          cell: (item: any) => (
            <StatusIndicator colorOverride={item.isActive ? 'green' : 'red'} type={item.isActive ? 'success' : 'stopped'}>
              {item.isActive ? 'Active' : 'Inactive'}
            </StatusIndicator>
          )
        },
        {
          id: 'accountNumber',
          sortingField: 'accountNumber',
          header: 'Account',
          cell: (item: any) => {
            return item.accountNumber;
          }
        },
        {
          id: 'channelCode',
          sortingField: 'channelCode',
          header: 'Channel Code',
          cell: (item: any) => item.channelCode
        },
        {
          id: 'companyCode',
          sortingField: 'companyCode',
          header: 'Company Code',
          cell: (item: any) => item.companyCode
        },
        {
          id: 'costCenter',
          sortingField: 'costCenter',
          header: 'Cost Center',
          cell: (item: any) => item.costCenter
        },
        {
          id: 'locationCode',
          sortingField: 'locationCode',
          header: 'Location Code',
          cell: (item: any) => item.locationCode
        },
        {
          id: 'productCode',
          sortingField: 'productCode',
          header: 'Product Code',
          cell: (item: any) => item.productCode
        },
        {
          id: 'projectCode',
          sortingField: 'projectCode',
          header: 'Project Code',
          cell: (item: any) => item.projectCode
        }
      ] as TableProps.ColumnDefinition<CampaignCorpDimensions>[]);
};

const editableCorpDimensions = (corpDimensions: CorpDimensionsList) => {
  const statusOptions = [
    { label: 'Active', value: 'Active' },
    { label: 'Inactive', value: 'Inactive' }
  ];
  const accountNumberOptions = corpDimensions?.accountNumber?.map((accountValue) => {
    return { label: accountValue.id, value: accountValue.id, description: accountValue.name };
  });
  const channelCodeOptions = corpDimensions?.channelCode?.map((channelCodeValue) => {
    return { label: channelCodeValue.id, value: channelCodeValue.id, description: channelCodeValue.name };
  });
  // const companyCodeOptions = corpDimensions?.companyCode?.map(companyCodeValue => { return { label: companyCodeValue.id, value: companyCodeValue.id, description: companyCodeValue.name } });
  const costCenterOptions = corpDimensions?.costCenter?.map((costCenterValue) => {
    return { label: costCenterValue.id, value: costCenterValue.id, description: costCenterValue.name };
  });
  const locationCodeOptions = corpDimensions?.locationCode?.map((locationCodeValue) => {
    return { label: locationCodeValue.id, value: locationCodeValue.id, description: locationCodeValue.name };
  });
  const productCodeOptions = corpDimensions?.productCode?.map((productCodeValue) => {
    return { label: productCodeValue.id, value: productCodeValue.id, description: productCodeValue.name };
  });
  const projectCodeOptions = corpDimensions?.projectCode?.map((projectCodeValue) => {
    return { label: projectCodeValue.id, value: projectCodeValue.id, description: projectCodeValue.name };
  });
  return [
    {
      id: 'isActive',
      sortingField: 'isActive',
      header: 'Status',
      minWidth: 170,
      cell: (item: any) => (
        <StatusIndicator colorOverride={item.isActive ? 'green' : 'red'} type={item.isActive ? 'success' : 'stopped'}>
          {item.isActive ? 'Active' : 'Inactive'}
        </StatusIndicator>
      ),
      editConfig: {
        ariaLabel: 'Status',
        errorIconAriaLabel: 'Validation Error',
        validation: (item, value) => {
          if (value === '') {
            return 'required';
          }
        },
        editIconAriaLabel: 'editable',
        editingCell: (item, { currentValue, setValue }) => {
          const value = currentValue ?? item.isActive;
          return (
            <Select
              expandToViewport
              autoFocus={true}
              filteringType="auto"
              selectedOption={statusOptions?.find((option) => option.value === value) ?? null}
              onChange={(event) => {
                setValue(event.detail.selectedOption.value ?? item.isActive);
              }}
              options={statusOptions}
            />
          );
        }
      }
    },
    {
      id: 'accountNumber',
      sortingField: 'accountNumber',
      header: 'Account',
      cell: (item: any) => {
        return item.accountNumber;
      },
      editConfig: {
        ariaLabel: 'Account',
        errorIconAriaLabel: 'Validation Error',
        validation: (item, value) => {
          if (value === '') {
            return 'required';
          }
        },
        editIconAriaLabel: 'editable',
        editingCell: (item, { currentValue, setValue }) => {
          const value = currentValue ?? item.accountNumber;
          return (
            <Select
              expandToViewport
              autoFocus={true}
              filteringType="auto"
              selectedOption={accountNumberOptions?.find((option) => option.value === value) ?? null}
              onChange={(event) => {
                setValue(event.detail.selectedOption.value ?? item.accountNumber);
              }}
              options={accountNumberOptions}
            />
          );
        }
      }
    },
    {
      id: 'channelCode',
      sortingField: 'channelCode',
      header: 'Channel Code',
      cell: (item: any) => item.channelCode,
      editConfig: {
        ariaLabel: 'Channel Code',
        errorIconAriaLabel: 'Validation Error',
        validation: (item, value) => {
          if (value === '') {
            return 'required';
          }
        },
        editIconAriaLabel: 'editable',
        editingCell: (item, { currentValue, setValue }) => {
          const value = currentValue ?? item.channelCode;
          return (
            <Select
              expandToViewport
              autoFocus={true}
              filteringType="auto"
              selectedOption={channelCodeOptions?.find((option) => option.value === value) ?? null}
              onChange={(event) => {
                setValue(event.detail.selectedOption.value ?? item.channelCode);
              }}
              options={channelCodeOptions}
            />
          );
        }
      }
    },
    {
      id: 'companyCode',
      sortingField: 'companyCode',
      header: 'Company Code',
      cell: (item: any) => item.companyCode
    },
    {
      id: 'costCenter',
      sortingField: 'costCenter',
      header: 'Cost Center',
      cell: (item: any) => item.costCenter,
      editConfig: {
        ariaLabel: 'Cost Center',
        errorIconAriaLabel: 'Validation Error',
        validation: (item, value) => {
          if (value === '') {
            return 'required';
          }
        },
        editIconAriaLabel: 'editable',
        editingCell: (item, { currentValue, setValue }) => {
          const value = currentValue ?? item.costCenter;
          return (
            <Select
              expandToViewport
              autoFocus={true}
              filteringType="auto"
              selectedOption={costCenterOptions?.find((option) => option.value === value) ?? null}
              onChange={(event) => {
                setValue(event.detail.selectedOption.value ?? item.costCenter);
              }}
              options={costCenterOptions}
            />
          );
        }
      }
    },
    {
      id: 'locationCode',
      sortingField: 'locationCode',
      header: 'Location Code',
      cell: (item: any) => item.locationCode,
      editConfig: {
        ariaLabel: 'Location Code',
        errorIconAriaLabel: 'Validation Error',
        validation: (item, value) => {
          if (value === '') {
            return 'required';
          }
        },
        editIconAriaLabel: 'editable',
        editingCell: (item, { currentValue, setValue }) => {
          const value = currentValue ?? item.locationCode;
          return (
            <Select
              expandToViewport
              autoFocus={true}
              filteringType="auto"
              selectedOption={locationCodeOptions?.find((option) => option.value === value) ?? null}
              onChange={(event) => {
                setValue(event.detail.selectedOption.value ?? item.locationCode);
              }}
              options={locationCodeOptions}
            />
          );
        }
      }
    },
    {
      id: 'productCode',
      sortingField: 'productCode',
      header: 'Product Code',
      cell: (item: any) => item.productCode,
      editConfig: {
        ariaLabel: 'Product Code',
        errorIconAriaLabel: 'Validation Error',
        validation: (item, value) => {
          if (value === '') {
            return 'required';
          }
        },
        editIconAriaLabel: 'editable',
        editingCell: (item, { currentValue, setValue }) => {
          const value = currentValue ?? item.productCode;
          return (
            <Select
              expandToViewport
              autoFocus={true}
              filteringType="auto"
              selectedOption={productCodeOptions?.find((option) => option.value === value) ?? null}
              onChange={(event) => {
                setValue(event.detail.selectedOption.value ?? item.productCode);
              }}
              options={productCodeOptions}
            />
          );
        }
      }
    },
    {
      id: 'projectCode',
      sortingField: 'projectCode',
      header: 'Project Code',
      cell: (item: any) => item.projectCode,
      editConfig: {
        ariaLabel: 'Project Code',
        errorIconAriaLabel: 'Validation Error',
        validation: (item, value) => {
          if (value === '') {
            return 'required';
          }
        },
        editIconAriaLabel: 'editable',
        editingCell: (item, { currentValue, setValue }) => {
          const value = currentValue ?? item.projectCode;
          return (
            <Select
              expandToViewport
              autoFocus={true}
              filteringType="auto"
              selectedOption={projectCodeOptions?.find((option) => option.value === value) ?? null}
              onChange={(event) => {
                setValue(event.detail.selectedOption.value ?? item.projectCode);
              }}
              options={projectCodeOptions}
            />
          );
        }
      }
    }
  ] as TableProps.ColumnDefinition<CampaignCorpDimensions>[];
};

export const CORP_DIMENSION_VISIBLE_CONTENT = [
  'accountNumber',
  'channelCode',
  'companyCode',
  'costCenter',
  'locationCode',
  'productCode',
  'projectCode'
];
