import { PropertyFilterProps, StatusIndicatorProps } from '@amzn/awsui-components-react';

/*
 * Displays dash -
 * if value is null
 */
export const displayNull = (value: any) => {
  if (value == null) {
    return '-';
  }
  return value;
};

export const getFilterCountText = (count: any) => `${count} ${count === 1 ? 'match' : 'matches'}`;

/*
 * polaris constants for creating search parameters
 */
export const PROPERTY_FILTERING_I18N_CONSTANTS: PropertyFilterProps.I18nStrings = {
  filteringAriaLabel: 'your choice',
  dismissAriaLabel: 'Dismiss',

  filteringPlaceholder: 'Search',
  groupValuesText: 'Values',
  groupPropertiesText: 'Properties',
  operatorsText: 'Operators',

  operationAndText: 'and',
  operationOrText: 'or',

  operatorLessText: 'Less than',
  operatorLessOrEqualText: 'Less than or equal',
  operatorGreaterText: 'Greater than',
  operatorGreaterOrEqualText: 'Greater than or equal',
  operatorContainsText: 'Contains',
  operatorDoesNotContainText: 'Does not contain',
  operatorEqualsText: 'Equals',
  operatorDoesNotEqualText: 'Does not equal',

  editTokenHeader: 'Edit filter',
  propertyText: 'Property',
  operatorText: 'Operator',
  valueText: 'Value',
  cancelActionText: 'Cancel',
  applyActionText: 'Apply',
  allPropertiesLabel: 'All properties',

  tokenLimitShowMore: 'Show more',
  tokenLimitShowFewer: 'Show fewer',
  clearFiltersText: 'Clear filters',
  removeTokenButtonAriaLabel: () => 'Remove token',
  enteredTextLabel: (text: string) => `Use: "${text}"`
};

export const handleNull = (value: boolean | null | undefined): boolean => {
  return value ? value : false;
};

/*
* takes a number and converts 
it to a string formatted as usd currency
*/
export const convertNumberToUSD = (value: number) => {
  const usdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });
  return usdFormatter.format(value);
};

/*
* takes a number and converts 
  it to a string formatted as usd currency with 2 fraction digits
*/
export const convertNumberToUSDWithFractionDigits = (value: number) => {
  const usdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  });
  return usdFormatter.format(value);
};

/* table page default size */
export const DEFAULT_PAGE_SIZE = 10;

/* table page default large size */
export const DEFAULT_LARGE_PAGE_SIZE = 50;

/* table page size options */
export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10 items' },
  { value: 20, label: '20 items' },
  { value: 30, label: '30 items' },
  { value: 50, label: '50 items' },
  { value: 100, label: '100 items' },
  { value: 200, label: '200 items' },
  { value: 300, label: '300 items' },
  { value: 400, label: '400 items' },
  { value: 500, label: '500 items' },
  { value: 1000, label: '1000 items' }
];

export const isDefinedAndNotEmptyObject = (obj: any): boolean => {
  return typeof obj !== 'undefined' && Object.keys(obj).length !== 0;
};

// File Validations
export const VALIDATION_NOT_INITIATED: ValidationEntity = {
  validationStatus: 'pending',
  colorOverride: 'grey',
  validationMessage: 'Not initiated'
};

export const VALIDATION_INITIATED: ValidationEntity = {
  validationStatus: 'loading',
  colorOverride: 'blue',
  validationMessage: 'Validating'
};

export const VALIDATION_SUCCESS: ValidationEntity = {
  validationStatus: 'success',
  colorOverride: 'green',
  validationMessage: 'Valid'
};

export const VALIDATION_FAILED: ValidationEntity = {
  validationStatus: 'pending',
  colorOverride: 'red',
  validationMessage: 'Invalid'
};

export interface ValidationEntity {
  colorOverride: StatusIndicatorProps.Color;
  validationStatus: StatusIndicatorProps.Type;
  validationMessage: string;
}

export const isValidNumber = (str: string): boolean => {
  // Match any string that starts with optional minus sign,
  // followed by one or more digits, optionally followed by decimal point and more digits
  const regex = /^-?\d+(\.\d+)?$/;
  return regex.test(str);
};

export const checkIfAllFieldsArePresent = (arrayOfObjects: any[], fieldsToExclude: string[]) => {
  return arrayOfObjects.every((object: any) => {
    return Object.keys(object).every((field: any) => {
      if (fieldsToExclude.includes(field)) {
        return true;
      }
      return object[field] !== undefined && object[field] !== null && object[field] !== '';
    });
  });
};

export const checkIfArrayOfObjectsAreUnique = (arrayOfObjects: any[], fieldsToExclude: string[]): boolean => {
  const set = new Set();
  for (const arrayOfObject of arrayOfObjects) {
    const filteredObj = Object.fromEntries(Object.entries(arrayOfObject).filter(([key]) => !fieldsToExclude.includes(key)));
    if (set.has(JSON.stringify(filteredObj))) {
      return false;
    }
    set.add(JSON.stringify(filteredObj));
  }
  return true;
};

// Exclude values from the array by the given array
export const excludeValuesFromArray = (array: any[], valuesToExclude: any[]) => {
  return array.filter((value) => !valuesToExclude.includes(value));
};

export const parseNumber = (str: string): number | null => {
  const num = parseInt(str);
  return isNaN(num) || num?.toString() !== str ? null : num;
};

export const DECIMAL_REGEX = /^[+-]?(\d*\.)?\d+$/;

export const EMPTY_DROPDOWN = [{ label: '-', value: '' }];
export const EMPTY_DROPDOWN_DESC = [{ label: '-', value: '', description: '' }];

export const camelToNormal = (camelCaseString: string): string => {
  // Replace all capital letters with a space and the lowercase letter
  return (
    camelCaseString
      .replace(/([A-Z])/g, ' $1')
      // Capitalize the first letter
      .replace(/^./, (str) => str.toUpperCase())
  );
};
