import { KatSpinner } from '@amzn/katal-react';
import React from 'react';
import ReactDOM from 'react-dom/client';

import AppInitWrapper from './components/AppInitWrapper';
import './i18n';

import '@amzn/katal-components/styles.css';
import { App } from './components/App';
import { AuthProvider } from './components/context/AuthContext';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <AppInitWrapper>
      <React.Suspense fallback={<KatSpinner size="large" />}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </React.Suspense>
    </AppInitWrapper>
  </React.StrictMode>
);
