import { DateRangePickerProps } from '@amzn/awsui-components-react';

// This is used for Loading Status
export enum LoadingStatus {
  NotInitiated = 'Not Initiated',
  Loading = 'Loading',
  Completed = 'Completed',
  Failed = 'Failed'
}

// Strictly limiting the UserAuthenticationDetails to Cognito Auth Details
export interface UserAuthContext {
  Alias: string;
  DisplayName: string;
  Email: string;
  UserLdaps: string[];
  error: string;
  userAuthDataLoadingStatus: LoadingStatus;
}

// App Application Level Context
export interface AppContext {
  users: User[];
  setUsers: (users: User[]) => void;
  userDetails: UserDetails;
  setUserDetails: (userDetails: UserDetails) => void;
  appMetadata: AppMetadata;
  setAppMetadata: (appMetadata: AppMetadata) => void;
  contextLoadingError: string | null;
  contextLoadingStatus: LoadingStatus;
}

// Logged in user related information
export interface UserDetails {
  isActive: boolean; // If user is in-active, user will be redirecting to Landing page only
  isReadOnly: boolean; // TODO - isReadOnly, Yet to implement this
  userType: UserType | undefined; // In case of not an existing user, backend returns nothing
  dataClassification: DataClassification; // In case of not an existing user, backend returns nothing
  userProductLines: ProductLine[] | [];
}

// App Metadata Information
// Gather's information by combining dimensional data into a single custom query
// Channel, Country, Product...
// custom-query - GetMetadataQuery
export interface AppMetadata {
  listChannel: Channel[];
  listCountry: Country[];
  listProducts: ProductLine[];
  listScenarios: Scenario[];
  listUserAccess: UserAccess[];
  listUserTypes: UserType[];
  listDataClassification: DataClassification[];

  listCurrentPlanningCycles: PlanningCycleDataModel[]; // depends on data classification id
  listFpnaDimensions: ListFpnaDimensionsEntity[]; // depends on data classification id
}

export interface User {
  userAlias: string;
  userName: string;
  userType: UserType;
  userAccess: UserAccess;
  isActive: boolean;
  updatedBy: string;
  updatedTime: string;

  createdBy: string;
  createdTime: string;

  productLine: ProductLine[];
  dataClassification: DataClassification;
}

export interface UserType {
  userTypeDescription: string;
  userTypeId: number;
}

export interface UserAccess {
  isReadOnly: boolean;
  userAccessDescription: string;
}

export interface DataClassification {
  dataClassificationDesc: string;
  dataClassificationId: number;
}

export interface EfficiencyMeasurement {
  efficiencyMeasurementId: number;
  efficiencyMeasurementName: string;
}

export interface DesiredBusinessImpact {
  desiredBusinessImpactId: number;
  desiredBusinessImpactName: string;
}

export interface SpendCategory {
  spendCategoryId: number;
  spendCategoryName: string;
}

export interface ProductLine {
  productID: number;
  productName: string;
  isFpnaManagedPl: boolean | null;
}

export interface Scenario {
  scenarioId: number;
  scenarioName: string;
  updatedBy: string | null;
  updatedTime: string | null;
  createdBy: string | null;
  createdTime: string | null;
}

export interface Country {
  countryCode: string;
  countryId: number;
  countryName: string;
  currencyCode: string;
}

export interface Channel {
  channelId: number;
  channelName: string;
}

export interface ListFpnaDimensionsEntity {
  dataClassification: DataClassification;
  dimensionId: number;
  dimensionKey: string;
  dimensionName: string;
  dimensionValues: ListFpnaDimensionValues[] | null;
  isRequired: boolean;
  isActive: boolean;
  updatedBy: string;
  updatedTime: string;
  createdBy: string;
  createdTime: string;
}

export interface ListFpnaDimensionValues {
  value: string;
  valueDesc: string | null;
  conditionalRelationship: ListFpnaDimensionConditionalRelationship[] | [] | null;
}

export interface ListFpnaDimensionConditionalRelationship {
  dimensionId: number | null;
  dimensionKey: string | null;
  validValues: string[] | null;
  invalidValues: string[] | null;
}

export interface FpnaDimensionModel {
  key: string;
  name: string;
  dropdownValues: DropdownModel[];
  isRequired: boolean;
}

export interface BaselineScenarioInput {
  baselineScenarioName?: string | null;
  baselineScenarioTemplateId?: number | null;
}

export interface PlanningCycleDataModel {
  scenarioTemplateId: number;
  scenario: {
    scenarioId: number;
    scenarioName: string;
    createdBy: string | null;
    createdTime: string | null;
    updatedBy: string | null;
    updatedTime: string | null;
  };
  dataClassification: {
    dataClassificationId: number;
    dataClassificationDesc: string;
  };
  actualsStartMonth: string;
  actualsEndMonth: string;
  forecastStartMonth: string;
  forecastEndMonth: string;
  openToolDate: string;
  lockToolDate: string;
  isExtended: boolean;
  extendedToUsers: string[];
  extendedLockDateTime: string | null;
  baselineScenario: null | BaselineScenarioInput;
  forecastDataAvailable: boolean;
  isActive: boolean;
  createdBy: string;
  updatedBy: string | null;
  updatedTime: string;
  createdTime: string;
}

export interface DropdownModel {
  label: string;
  value: string;
}

export interface MultiSelectGroupDropdownModel {
  value: string;
  label: string;
  options: DropdownModel[];
}
