import {
  AppLayoutProps,
  Box,
  Checkbox,
  CollectionPreferences,
  CollectionPreferencesProps,
  FlashbarProps,
  NonCancelableCustomEvent,
  StatusIndicator,
  TableProps
} from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { getCurrentUserLocalTimeReadableFormat } from 'src/utils/DateTimeUtils';
import { PAGE_SIZE_OPTIONS } from 'src/utils/CommonHelpers';
import { CorpProducts, ProductLineDetail } from './ProductLineModel';
import { useNumberPref, useObjectPref } from 'src/utils/UserPrefs';
import { ProductLineDetailForm } from './ProductLineForm';

export const PRODUCT_LINE_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<ProductLineDetail>[] = [
  {
    id: 'productId',
    header: 'Product Id',
    cell: (item) => item.productId,
    sortingField: 'productId'
  },
  {
    id: 'productCode',
    header: 'Product Code',
    cell: (item) => item.productCode,
    sortingField: 'productCode'
  },
  {
    id: 'productName',
    header: 'Product Line',
    cell: (item) => item.productName,
    sortingField: 'productName'
  },
  {
    id: 'isActive',
    header: 'Status',
    cell: (item) => (
      <StatusIndicator colorOverride={item.isActive ? 'green' : 'red'} type={item.isActive ? 'success' : 'stopped'}>
        {item.isActive ? 'Active' : 'Inactive'}
      </StatusIndicator>
    ),
    sortingField: 'isActive'
  },
  {
    id: 'isFpnaManagedPl',
    header: 'isFpnaManagedPl',
    cell: (item) => (item.isFpnaManagedPl ? 'Yes' : 'No'),
    sortingField: 'isFpnaManagedPl'
  },
  {
    id: 'createdBy',
    header: 'Created By',
    cell: (item) => item.createdBy,
    sortingField: 'createdBy'
  },
  {
    id: 'createdTime',
    header: 'Created Time',
    cell: (item) => getCurrentUserLocalTimeReadableFormat(item.createdTime),
    sortingField: 'createdTime'
  },
  {
    id: 'updatedBy',
    header: 'Updated By',
    cell: (item) => item.updatedBy,
    sortingField: 'updatedBy'
  },
  {
    id: 'updatedTime',
    header: 'Updated Time',
    cell: (item) => getCurrentUserLocalTimeReadableFormat(item.updatedTime),
    sortingField: 'updatedTime'
  }
];

export const PRODUCT_LINE_FILTERING_PROPERTIES = [
  {
    key: 'productCode',
    propertyLabel: 'Product Code',
    groupValuesLabel: 'Product Codes',
    operators: [':', '!:', '=', '!=']
  },
  {
    key: 'productName',
    propertyLabel: 'Product Name',
    groupValuesLabel: 'Product Names',
    operators: [':', '!:', '=', '!=']
  },
  {
    key: 'isActive',
    propertyLabel: 'isActive',
    groupValuesLabel: 'isActive',
    operators: [':', '!:', '=', '!=']
  }
];

export const DEFAULT_PRODUCT_LINE_VISIBLE_CONTENT = [
  'productCode',
  'productName',
  'isActive',
  'createdBy',
  'createdTime',
  'updatedBy',
  'updatedTime'
];

export const DEFAULT_PRODUCT_LINE_VISIBLE_CONTENT_OPTIONS: CollectionPreferencesProps.VisibleContentOptionsGroup[] = [
  {
    label: 'Product Line properties',
    options: [
      { id: 'productCode', label: 'Product Code' },
      { id: 'productName', label: 'Product Name' },
      { id: 'isActive', label: 'isActive' },
      { id: 'createdBy', label: 'Created By' },
      { id: 'createdTime', label: 'Created Time' },
      { id: 'updatedBy', label: 'Updated By' },
      { id: 'updatedTime', label: 'Updated Time' }
    ]
  }
];

export const DEFAULT_PRODUCT_LINE_PREFERENCES: CollectionPreferencesProps.Preferences = {
  pageSize: 20,
  visibleContent: DEFAULT_PRODUCT_LINE_VISIBLE_CONTENT,
  wrapLines: false,
  stripedRows: false,
  custom: false
};

export const ProductLinePreferences = ({
  preferences,
  setPreferences,
  disabled,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  visibleContentOptions = DEFAULT_PRODUCT_LINE_VISIBLE_CONTENT_OPTIONS
}: any) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: 'Page size',
      options: pageSizeOptions
    }}
    wrapLinesPreference={{
      label: 'Wrap lines',
      description: 'Check to see all the text and wrap the lines'
    }}
    stripedRowsPreference={{
      label: 'Striped rows',
      description: 'Check to add alternating shaded rows'
    }}
    visibleContentPreference={{
      title: 'Select visible columns',
      options: visibleContentOptions
    }}
    customPreference={(checked, setChecked) => (
      <Checkbox
        onChange={({ detail }) => {
          setChecked(detail.checked);
        }}
        checked={checked}
      >
        Resizable Columns
      </Checkbox>
    )}
  />
);

export function useProductLineSplitPanelProps() {
  const [splitPanelPrefs, setSplitPanelPrefs] = useObjectPref<AppLayoutProps.SplitPanelPreferences>(location.pathname, 'splitPanelPrefs', {
    position: 'side'
  });
  const [splitPanelSize, setSplitPanelSize] = useNumberPref(location.pathname, 'splitPanelSize');
  const [splitPanelOpen, setSplitPanelOpen] = useState<boolean>(false);

  const onSplitPanelPreferencesChange = (event: NonCancelableCustomEvent<AppLayoutProps.SplitPanelPreferences>) => {
    setSplitPanelPrefs(event.detail);
  };

  const onSplitPanelResize = (event: NonCancelableCustomEvent<AppLayoutProps.SplitPanelResizeDetail>) => {
    setSplitPanelSize(event.detail.size);
  };

  const onSplitPanelToggle = (event: NonCancelableCustomEvent<AppLayoutProps.ChangeDetail>) => {
    setSplitPanelOpen(event.detail.open);
  };

  const splitPanelOpenByEdit = (splitPanelOpen: boolean) => {
    setSplitPanelOpen(splitPanelOpen);
  };

  useEffect(() => {
    let updatedSplitPanelPrefs: AppLayoutProps.SplitPanelPreferences = {
      position: 'side'
    };
    setSplitPanelPrefs(updatedSplitPanelPrefs);
  }, []);

  return {
    splitPanelPrefs,
    onSplitPanelPreferencesChange,
    splitPanelSize,
    onSplitPanelResize,
    splitPanelOpen,
    splitPanelOpenByEdit,
    onSplitPanelToggle
  };
}

export const getProductLinePanelContent = (
  productLineMappingsList: ProductLineDetail[],
  selectedProductLine: ProductLineDetail[],
  corpProducts: CorpProducts[],
  updateTheTable: (requestStatus: string, message: string) => void,
  displayFlashMessage: (content: string, flashBarType: FlashbarProps.Type) => void
) => {
  if (selectedProductLine.length > 1) {
    return <Box variant="h4">Select only 1 Product Line</Box>;
  } else {
    return (
      <ProductLineDetailForm
        productLineMappingsList={productLineMappingsList}
        selectedProductLineDetail={selectedProductLine}
        corpProducts={corpProducts}
        updateTheTable={updateTheTable}
        displayFlashMessage={displayFlashMessage}
      ></ProductLineDetailForm>
    );
  }
};
