import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  AppLayout,
  Box,
  Button,
  Flashbar,
  FlashbarProps,
  Header,
  Link,
  Pagination,
  SpaceBetween,
  SplitPanel,
  Table,
  TableProps
} from '@amzn/awsui-components-react';
import { API } from 'aws-amplify';
import * as mutations from 'src/graphql/mutations';
import * as queries from 'src/graphql/queries';
import PropertyFilter, { PropertyFilterProps } from '@amzn/awsui-components-react/polaris/property-filter';
import React, { useEffect, useState } from 'react';
import { AppBreadcrumb } from 'src/components/navigation/AppBreadcrumb';
import { AppSideNavigation } from 'src/components/navigation/AppSideNavigation';
import { BaseBreadcrumbs, eUserTablePreferenceKeys } from 'src/utils/DmmConstants';
import { ProductLineToolBar } from './ProductLineToolBar';
import { CorpProducts, ProductLineDetail, ProductLineState } from './ProductLineModel';
import { useAsyncDynamoStorage } from 'src/components/commons/CustomHooks/use-dynamo-storage';
import {
  DEFAULT_PRODUCT_LINE_PREFERENCES,
  PRODUCT_LINE_COLUMN_DEFINITIONS,
  PRODUCT_LINE_FILTERING_PROPERTIES,
  ProductLinePreferences,
  getProductLinePanelContent,
  useProductLineSplitPanelProps
} from './ProductLineConfig';
import { useColumnWidths } from 'src/components/commons/CustomHooks/use-column-widths';
import { TableEmptyState, TableNoMatchState } from 'src/components/commons/CommonComponents';
import { PROPERTY_FILTERING_I18N_CONSTANTS } from 'src/utils/CommonHelpers';
import { PAGINATION_LABELS, getMatchesCountText } from 'src/utils/labels';
import { logger } from 'src/utils/Logger';
import { useAuth } from 'src/components/context/AuthContext';
import { SPLIT_PANEL_I18NSTRINGS } from 'src/components/generic-components/SplitPanelConstants';

const getUserBreadcrumbs = () => {
  return [
    ...BaseBreadcrumbs,
    {
      text: 'Product Line',
      href: '/product-line'
    }
  ];
};

export const ProductLine = () => {
  const userDetails = useAuth();
  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);
  const [toolsOpen, setToolsOpen] = useState(false);

  const [productLineState, setProductLineState] = useState<ProductLineState>({
    productLinesLoading: false,
    productLinesFetched: false,
    productLinesChanged: false,
    productLinesSubmitInProgress: false
  });

  const [corpProducts, setCorpProducts] = useState<CorpProducts[]>([]);
  const [productLineMappingsList, setProductLineMappingsList] = useState<ProductLineDetail[]>([]);
  const [selectedProductLineMappingsList, setSelectedProductLineMappingsList] = useState<ProductLineDetail[]>([]);

  const [filteringProperties, setFilteringProperties] = useState<any>(PRODUCT_LINE_FILTERING_PROPERTIES);

  const BLANK_SEARCH = {
    tokens: [],
    operation: 'and'
  } as PropertyFilterProps.Query;
  const [query, setQuery] = useAsyncDynamoStorage(eUserTablePreferenceKeys.PRODUCT_LINE_TABLE_FILTERING_PROPERTIES, BLANK_SEARCH);

  const [columnDefinitions, saveWidths, columnDefinitionsLoading] = useColumnWidths(
    eUserTablePreferenceKeys.PRODUCT_LINE_TABLE_COLUMN_WIDTHS,
    PRODUCT_LINE_COLUMN_DEFINITIONS
  );
  const [preferences, setPreferences] = useAsyncDynamoStorage(
    eUserTablePreferenceKeys.PRODUCT_LINE_TABLE_PREFERENCES,
    DEFAULT_PRODUCT_LINE_PREFERENCES
  );

  const {
    splitPanelPrefs,
    splitPanelSize,
    splitPanelOpen,
    onSplitPanelPreferencesChange,
    onSplitPanelResize,
    onSplitPanelToggle,
    splitPanelOpenByEdit
  } = useProductLineSplitPanelProps();

  useEffect(() => {
    actions.setPropertyFiltering(query);
  }, [query]);

  useEffect(() => {
    getProductLinesMappingList();
    getCorpProducts();
  }, []);

  const displayFlashMessage = (content: string, flashBarType: FlashbarProps.Type) => {
    setFlashbarItems([
      {
        type: flashBarType,
        content: content,
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => setFlashbarItems([])
      }
    ]);
  };

  const updateTheTable = (requestStatus: string, message: string) => {
    if (requestStatus === 'success') {
      refreshProductLineMappingsList();
      displayFlashMessage(message, 'success');
      splitPanelOpenByEdit(false);
    } else {
      displayFlashMessage(message, 'error');
    }
  };

  const panelContent = getProductLinePanelContent(
    productLineMappingsList,
    selectedProductLineMappingsList,
    corpProducts,
    updateTheTable,
    displayFlashMessage
  );

  const getCorpProducts = async () => {
    try {
      const response: any = await API.graphql({
        query: queries.listCorpProducts,
        variables: {}
      });
      const activeCorpProducts = response.data.listCorpProducts;
      setCorpProducts(activeCorpProducts);
    } catch (error: any) {
      logger.error(`Unable to fetch listCorpProducts `, error);
      displayFlashMessage(`Unable to fetch Corp Products list. Some of the features might not work as expected. Please reach out to admin.`, 'error');
    }
  };

  const getProductLinesMappingList = async () => {
    setProductLineState({ ...productLineState, productLinesLoading: true, productLinesFetched: false });
    try {
      const response: any = await API.graphql({
        query: queries.listProductsDetail,
        variables: {}
      });
      setProductLineMappingsList(response.data.listProductsDetail ? response.data.listProductsDetail : []);
      setProductLineState({ ...productLineState, productLinesLoading: false, productLinesFetched: true });
    } catch (error: any) {
      setProductLineState({ ...productLineState, productLinesLoading: false, productLinesFetched: true });
      logger.error(`Unable to fetch listProductsDetail `, error);
    }
  };

  const refreshProductLineMappingsList = () => {
    getProductLinesMappingList();
    getCorpProducts();
  };

  // When user selects a row using Radio button
  const tableSelectionChanged = (detail: any) => {
    setSelectedProductLineMappingsList(detail.selectedItems);
  };

  // When user selects a row by clicking anywhere in the row
  const onRowClickChanged = (detail: any) => {
    setSelectedProductLineMappingsList([detail.item]);
  };

  const { items, actions, filteredItemsCount, collectionProps, paginationProps, propertyFilterProps } = useCollection(productLineMappingsList || [], {
    propertyFiltering: {
      filteringProperties,
      empty: <TableEmptyState resourceName="Product Line" />,
      noMatch: (
        <TableNoMatchState
          onClearFilter={() => {
            actions.setPropertyFiltering({ tokens: [], operation: 'and' });
          }}
        />
      )
    },
    pagination: {
      pageSize: preferences.pageSize
    },
    sorting: {
      defaultState: {
        sortingColumn: { sortingField: 'updatedTime' },
        isDescending: true
      }
    },
    selection: {}
  });

  return (
    <>
      <AppLayout
        headerSelector="#h"
        navigation={<AppSideNavigation />}
        notifications={<Flashbar items={flashbarItems} />}
        breadcrumbs={<AppBreadcrumb items={getUserBreadcrumbs()} />}
        tools={<ProductLineToolBar />}
        toolsOpen={toolsOpen}
        onToolsChange={({ detail }) => setToolsOpen(detail.open)}
        splitPanelOpen={splitPanelOpen}
        splitPanelPreferences={splitPanelPrefs}
        splitPanelSize={320}
        onSplitPanelPreferencesChange={onSplitPanelPreferencesChange}
        onSplitPanelResize={onSplitPanelResize}
        onSplitPanelToggle={onSplitPanelToggle}
        splitPanel={
          <SplitPanel
            className="dmm-split-panel"
            hidePreferencesButton
            header={
              selectedProductLineMappingsList.length > 1
                ? 'Multiple records selected'
                : selectedProductLineMappingsList.length === 0
                  ? 'Create'
                  : 'Edit' + ' Product Line'
            }
            i18nStrings={SPLIT_PANEL_I18NSTRINGS}
          >
            {panelContent}
          </SplitPanel>
        }
        disableContentPaddings
        content={
          <Box margin={{ top: 'l', bottom: 'xl', left: 'xxxl', right: 'xxxl' }} padding={{ left: 'xxxl', right: 'xxxl' }}>
            <Table
              variant="container"
              stickyHeader
              header={
                <Header
                  counter={`(${productLineMappingsList!.length})`}
                  actions={
                    <SpaceBetween size="m" direction="horizontal">
                      <Button iconName="refresh" onClick={refreshProductLineMappingsList}></Button>
                      <Button
                        onClick={() => {
                          setSelectedProductLineMappingsList([]);
                          splitPanelOpenByEdit(true);
                        }}
                        disabled={!productLineState.productLinesFetched}
                      >
                        Add
                      </Button>
                      <Button
                        variant="primary"
                        disabled={selectedProductLineMappingsList!.length === 0}
                        onClick={() => {
                          splitPanelOpenByEdit(true);
                        }}
                      >
                        Edit
                      </Button>
                    </SpaceBetween>
                  }
                  info={
                    <Link variant="info" onFollow={() => setToolsOpen(true)}>
                      Info
                    </Link>
                  }
                >
                  {'Product Lines'}
                </Header>
              }
              {...collectionProps}
              visibleColumns={preferences.visibleContent}
              wrapLines={preferences.wrapLines}
              stripedRows={preferences.stripedRows}
              resizableColumns={preferences.custom}
              loading={productLineState.productLinesLoading}
              loadingText="Loading Product Lines"
              columnDefinitions={columnDefinitions as TableProps.ColumnDefinition<any>[]}
              items={items}
              selectionType={'single'}
              onColumnWidthsChange={saveWidths as any}
              onSelectionChange={({ detail }) => tableSelectionChanged(detail)}
              selectedItems={selectedProductLineMappingsList}
              filter={
                <PropertyFilter
                  i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
                  countText={getMatchesCountText(filteredItemsCount!)}
                  expandToViewport={true}
                  {...propertyFilterProps}
                  query={query}
                  onChange={(event) => {
                    setQuery(event.detail.tokens?.length === 0 ? BLANK_SEARCH : event.detail);
                  }}
                />
              }
              pagination={<Pagination {...paginationProps} ariaLabels={PAGINATION_LABELS} />}
              preferences={
                <ProductLinePreferences preferences={preferences ? preferences : DEFAULT_PRODUCT_LINE_PREFERENCES} setPreferences={setPreferences} />
              }
            />
          </Box>
        }
      />
    </>
  );
};
