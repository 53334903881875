import React from 'react';
import { StatusIndicator, TableProps } from '@amzn/awsui-components-react';
import { displayNull } from 'src/utils/CommonHelpers';
import { getCurrentUserLocalTimeReadableFormat } from 'src/utils/DateTimeUtils';
import { HistoricalPlanningCycle } from './PlanningCycleModels';

export const ScenarioColumnDefinitions: TableProps.ColumnDefinition<HistoricalPlanningCycle>[] = [
  {
    id: 'name',
    header: 'Scenario name',
    cell: (item) => item.name
  },
  {
    id: 'actuals_start_month',
    header: 'Actuals start',
    cell: (item) => displayNull(item.actuals_start_month)
  },
  {
    id: 'actuals_end_month',
    header: 'Actuals end',
    cell: (item) => displayNull(item.actuals_end_month)
  },
  {
    id: 'forecast_start_month',
    header: 'Forecast start',
    cell: (item) => item.forecast_start_month
  },
  {
    id: 'forecast_end_month',
    header: 'Forecast end',
    cell: (item) => item.forecast_end_month
  },
  {
    id: 'is_active',
    header: 'Active',
    cell: (item) => (
      <StatusIndicator type={item.is_active === true ? 'success' : 'error'}>{item.is_active === true ? 'open' : 'closed'}</StatusIndicator>
    )
  },
  {
    id: 'open_tool_date',
    header: 'Open tool date',
    cell: (item) => getCurrentUserLocalTimeReadableFormat(item.open_tool_date)
  },
  {
    id: 'lock_tool_date',
    header: 'Lock tool date',
    cell: (item) => getCurrentUserLocalTimeReadableFormat(item.lock_tool_date)
  },
  {
    id: 'updated_by',
    header: 'Updated by',
    cell: (item) => item.updated_by
  }
];
