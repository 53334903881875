import {
  AppLayout,
  Box,
  Button,
  CollectionPreferencesProps,
  Header,
  Popover,
  SpaceBetween,
  SplitPanel,
  StatusIndicator
} from '@amzn/awsui-components-react';
import React from 'react';
import { SPLIT_PANEL_I18NSTRINGS } from './SplitPanelConstants';

export const CopyToClipboard = (textTobeCopied: string) => {
  return (
    <span className="custom-wrapping">
      <Box margin={{ right: 'xxs' }} display="inline-block">
        <Popover
          size="small"
          position="top"
          triggerType="custom"
          dismissButton={false}
          content={<StatusIndicator type="success">copied</StatusIndicator>}
        >
          <Button
            variant="inline-icon"
            iconName="copy"
            onClick={() => {
              navigator.clipboard.writeText(textTobeCopied);
            }}
          />
        </Popover>
      </Box>
      <code>
        <b>{textTobeCopied}</b>
      </code>
    </span>
  );
};

export const getMultiSelectPlaceHolderValue = (entity: any, entityName: string): string => {
  let placeHolderValue = 'Select ' + entityName;
  if (entity?.length == 0) {
    return placeHolderValue;
  }

  if (entity?.length == 1) {
    placeHolderValue = entity[0].label;
  }

  if (entity?.length > 1) {
    placeHolderValue = entity[0].label + ' + ' + (entity?.length - 1) + (entity?.length - 1 === 1 ? ' Other' : ' Others');
  }
  return `${placeHolderValue} `;
};
