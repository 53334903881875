import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AccessAndAuthorization } from '../access-authorization/AccessAndAuthorization';
import { Campaigns } from '../campaigns/Campaigns';
import { ForecastTemplate } from '../forecast-template/ForecastTemplate';
import { PageNotFound } from '../generic-components/PageNotFound';
import { ForecastTemplateStatus } from 'src/components/reporting/forecast-template-status-report/ForecastTemplateStatus';
import LandingPage from '../landing-page/LandingPage';
import { AppTopNavigation } from './AppTopNavigation';
import { PrivateRoutes } from './PrivateRoutes';
import { ConsolidatedReport } from '../reporting/quicksight-reports/ReportingTabs';
import { CampaignDetails } from '../campaigns/CampaignDetails';
import { PurchaseOrders } from '../purchase-orders/PurchaseOrders';
import { ProductLine } from '../mappings/product-line/ProductLine';
import { ApplicationBanner } from './ApplicationBanner';
import { PlanningCycleManageForm } from '../planning-cycle/PlanningCycleManageForm';
import { PlanningCycle } from '../planning-cycle/PlanningCycle';

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <AppTopNavigation />
      <ApplicationBanner />
      <Routes>
        {/* LandingPage is accessible to all types of users */}
        <Route path="/" element={<LandingPage />} />

        {/* Private routes are available based on user type, and user status (active or in-active) */}
        <Route element={<PrivateRoutes />}>
          <Route path="/access" element={<AccessAndAuthorization />} />
          <Route path="/planning-cycle" element={<PlanningCycle />} />
          <Route path="/planning-cycle/create" element={<PlanningCycleManageForm type="create" />} />
          <Route path="/planning-cycle/edit/:scenarioTemplateId" element={<PlanningCycleManageForm type="edit" />} />
          <Route path="/campaigns" element={<Campaigns />}>
            <Route path="/campaigns/:CampaignId" element={<CampaignDetails />} />
          </Route>
          <Route path="/purchase-orders" element={<PurchaseOrders />} />
          <Route path="/product-line" element={<ProductLine />} />
          <Route path="/forecast-template" element={<ForecastTemplate />} />
          <Route path="/forecast-template-status" element={<ForecastTemplateStatus />} />
          <Route path="/reporting" element={<ConsolidatedReport />} />
        </Route>

        {/* Redirect all 404's to PageNotFound */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
