import { useEffect, useState } from 'react';
import { UserAuthContext } from 'src/components/context/AppContextModel';
import { useAuth } from 'src/components/context/AuthContext';
import { getItemFromDynamoDB, putItemInDynamoDB } from 'src/utils/AWSDynamoDBServices';
import { getCurrentUTCTimeZone } from 'src/utils/DateTimeUtils';
import { logger } from 'src/utils/Logger';
import { eMSPDynamoDBTables, eUserTablePreferenceKeys } from 'src/utils/DmmConstants';

const PARTITION_KEY = 'msp-user-preference-key';
type loadingStatus = 'not-initiated' | 'loading' | 'loaded' | 'loading-failed';

// custom hook to store data into dynamodb
export function useAsyncDynamoStorage<T>(key: eUserTablePreferenceKeys, defaultValue?: T) {
  const userDetails = useAuth();
  const [widths, setWidths] = useState<any>(defaultValue);
  const [loading, setLoading] = useState<loadingStatus>('loading');

  useEffect(() => {
    async function fetchPreferencesFromDynamo() {
      try {
        setLoading('loading');
        const response = await getItemFromDynamoDB(eMSPDynamoDBTables.DMM_USER_PREFS, getKeyValue(key, userDetails));
        setLoading('loaded');
        if (response) setWidths(JSON.parse(JSON.stringify(response.user_preference)));
      } catch (error: any) {
        logger.error('Unable to fetch column definitions from dynamo db', error);
        setLoading('loading-failed');
      }
    }

    if (key) {
      fetchPreferencesFromDynamo();
    }
  }, [key]);

  function saveWidths(newValue: T) {
    setWidths(newValue);
    save(key, newValue, userDetails);
  }

  return [widths, saveWidths, loading];
}

export const save = (key: string, value: any, userDetails: UserAuthContext) => {
  const putObject = {
    [PARTITION_KEY]: getKeyValue(key, userDetails),
    user_preference: JSON.parse(JSON.stringify(value)),
    lastUpdatedAt: getCurrentUTCTimeZone()
  };
  putItemInDynamoDB(eMSPDynamoDBTables.DMM_USER_PREFS, putObject)
    .then((response) => {})
    .catch((error) => {
      logger.error('Unable to save column definitions to dynamo db', error);
    });
};

export const getKeyValue = (key: string, userDetails: UserAuthContext) => {
  return key + '-' + userDetails.Alias;
};
