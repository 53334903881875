import { AppLayoutProps, Box, FlashbarProps, NonCancelableCustomEvent, SplitPanel } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useNumberPref, useObjectPref } from 'src/utils/UserPrefs';
import { SPLIT_PANEL_I18NSTRINGS } from '../generic-components/SplitPanelConstants';
import { User } from '../context/AppContextModel';
import { AccessAuthorizationForm } from './AccessAndAuthorizationForm';

export function useSplitPanelProps() {
  const [splitPanelPrefs, setSplitPanelPrefs] = useObjectPref<AppLayoutProps.SplitPanelPreferences>(location.pathname, 'splitPanelPrefs', {
    position: 'side'
  });
  const [splitPanelSize, setSplitPanelSize] = useNumberPref(location.pathname, 'splitPanelSize');
  const [splitPanelOpen, setSplitPanelOpen] = useState<boolean>(false);

  const onSplitPanelPreferencesChange = (event: NonCancelableCustomEvent<AppLayoutProps.SplitPanelPreferences>) => {
    setSplitPanelPrefs(event.detail);
  };

  const onSplitPanelResize = (event: NonCancelableCustomEvent<AppLayoutProps.SplitPanelResizeDetail>) => {
    setSplitPanelSize(event.detail.size);
  };

  const onSplitPanelToggle = (event: NonCancelableCustomEvent<AppLayoutProps.ChangeDetail>) => {
    setSplitPanelOpen(event.detail.open);
  };

  const splitPanelOpenByEdit = (splitPanelOpen: boolean) => {
    setSplitPanelOpen(splitPanelOpen);
  };

  useEffect(() => {
    let updatedSplitPanelPrefs: AppLayoutProps.SplitPanelPreferences = { position: 'side' };
    setSplitPanelPrefs(updatedSplitPanelPrefs);
  }, []);

  return {
    splitPanelPrefs,
    onSplitPanelPreferencesChange,
    splitPanelSize,
    onSplitPanelResize,
    splitPanelOpen,
    splitPanelOpenByEdit,
    onSplitPanelToggle
  };
}

export const getPanelContent = (
  selectedUsers: User[],
  updateTheTable: (updatedUserRow: User, eventType: 'Create' | 'Edit') => void,
  displayFlashMessage: (content: string, flashBarType: FlashbarProps.Type) => void
) => {
  if (selectedUsers.length > 1) {
    return <Box variant="h4">Select only 1 User</Box>;
  } else {
    return <AccessAuthorizationForm selectedUsers={selectedUsers} updateTheTable={updateTheTable} displayFlashMessage={displayFlashMessage} />;
  }
};
