import { ColDef, ProcessDataFromClipboardParams, ValueParserParams } from 'ag-grid-community';
import { applyCellClassRules, cellValueParser, forecastCurrencyFormatter, trimEmptyString } from 'src/utils/AgGridUtils';
import { camelToNormal, parseNumber } from 'src/utils/CommonHelpers';
import { generateMonthList, getQuarterFromMonth, getYearFromMonthYear } from 'src/utils/DateTimeUtils';
import { PlanningCycleDataModel } from '../context/AppContextModel';
import { FixedColumnInformation } from './ForecastModel';
import { accountNumberToolTip, campaignNameToolTip, costCenterToolTip } from './ForecastTemplateTable';
import { cloneDeep } from 'lodash';

// API to get the column definition for the forecast table
export const parseColumnDefinition = (fixedColumnInfo: FixedColumnInformation, isPastLockData: boolean) => {
  let orderedColumnDefinition = [
    {
      headerName: 'Campaign Details',
      marryChildren: true,
      children: getCampaignHeadersWithPinned(fixedColumnInfo.fpnaDimensionKeys, 'Campaign Details') // forecastTableHeaders?.find((forecastHeader: any) => forecastHeader.headerName === "Campaign Details")?.children
    },
    {
      headerName: 'Corp Dimensions',
      marryChildren: true,
      children: getCorpDimensionHeadersWithPinned('Corp Dimensions') // forecastTableHeaders?.find((forecastHeader: any) => forecastHeader.headerName === "Corp Dimensions")?.children
    },
    {
      headerName: 'Actual',
      marryChildren: true,
      children: getActualsHeadersWithoutGrouping(fixedColumnInfo.actualMonths, 'Actual', false) //forecastTableHeaders?.find((forecastHeader: any) => forecastHeader.headerName === "Actual")?.children
    },
    {
      headerName: 'Forecast',
      marryChildren: true,
      children: getForecastHeadersWithoutGrouping(fixedColumnInfo.forecastMonths, 'Forecast', isPastLockData) // forecastTableHeaders?.find((forecastHeader: any) => forecastHeader.headerName === "Forecast")?.children
    }
  ];

  return orderedColumnDefinition;
};

export const getCampaignHeadersWithPinned = (fpnaDimensionKeys: string[], headerFor: 'Campaign Details') => {
  const campaignColumnDefinition: ColDef[] = [
    {
      field: 'campaignName',
      columnGroupShow: 'closed',
      editable: false,
      headerName: 'Campaign Name',
      pinned: 'left',
      lockPinned: true,
      width: 200,
      tooltipField: 'campaignName',
      tooltipComponent: campaignNameToolTip
      // autoHeight: true,
      // cellStyle: { whiteSpace: "normal"}
    },
    {
      field: 'campaignName',
      columnGroupShow: 'open',
      editable: false,
      headerName: 'Campaign Name',
      pinned: 'left',
      lockPinned: true,
      suppressFiltersToolPanel: true,
      width: 200,
      tooltipField: 'campaignName',
      tooltipComponent: campaignNameToolTip
    },
    {
      field: 'country',
      columnGroupShow: 'closed',
      editable: false,
      headerName: 'Country',
      pinned: 'left',
      lockPinned: true
    },
    {
      field: 'country',
      columnGroupShow: 'open',
      editable: false,
      headerName: 'Country',
      pinned: 'left',
      lockPinned: true
    },
    {
      field: 'businessOwner',
      columnGroupShow: 'open',
      editable: false,
      headerName: 'Business Owner'
    },
    {
      field: 'efficiencyGoal',
      columnGroupShow: 'open',
      editable: false,
      headerName: 'Efficiency Goal'
    },
    {
      field: 'revenueEstimate',
      columnGroupShow: 'open',
      editable: false,
      headerName: 'Revenue Estimate Category'
    },
    {
      field: 'businessCategory',
      columnGroupShow: 'open',
      editable: false,
      headerName: 'Business Category'
    }
  ];

  const fpnaDimension = fpnaDimensionKeys?.map((fpnaDimensionKey) => {
    return {
      field: fpnaDimensionKey,
      columnGroupShow: 'open',
      editable: false,
      headerName: camelToNormal(fpnaDimensionKey)
    } as ColDef;
  });
  return campaignColumnDefinition.concat(fpnaDimension);
};

export const getCorpDimensionHeadersWithPinned = (headerFor: 'Corp Dimensions') => {
  return [
    {
      field: 'accountNumber',
      editable: false,
      headerName: 'Account',
      columnGroupShow: 'closed',
      pinned: 'left',
      lockPinned: true,
      tooltipComponent: accountNumberToolTip,
      tooltipValueGetter: (params: any) => ({ value: params.value })
    },
    {
      field: 'accountNumber',
      editable: false,
      headerName: 'Account',
      columnGroupShow: 'open',
      pinned: 'left',
      lockPinned: true,
      suppressFiltersToolPanel: true,
      tooltipComponent: accountNumberToolTip,
      tooltipValueGetter: (params: any) => ({ value: params.value })
    },
    {
      field: 'costCenter',
      editable: false,
      headerName: 'Cost Center',
      columnGroupShow: 'open',
      tooltipComponent: costCenterToolTip,
      tooltipValueGetter: (params: any) => ({ value: params.value })
    },
    {
      field: 'channelCode',
      editable: false,
      headerName: 'Channel Code',
      columnGroupShow: 'open'
    },
    {
      field: 'companyCode',
      editable: false,
      headerName: 'Company Code',
      columnGroupShow: 'open'
    },
    {
      field: 'locationCode',
      editable: false,
      headerName: 'Location Code',
      columnGroupShow: 'open'
    },
    {
      field: 'productCode',
      editable: false,
      headerName: 'Product Code',
      columnGroupShow: 'open'
    },
    {
      field: 'projectCode',
      editable: false,
      headerName: 'Project Code',
      columnGroupShow: 'open'
    }
  ];
};

// Actuals & Forecast Header for the forecast table.
// Involves Yearly, Quarterly & Monthly grouping.
export const getActualForecastHeadersWithGrouping = (monthHeaders: string[], headerFor: 'Actual' | 'Forecast', isEditable: boolean): ColDef[] => {
  const QuarterlyYearlyTotalColumnBgColor = '#ebeae8';
  const ActualColumnBgColor = '#bab9b6';

  const agGridHeaderData: ColDef[] = [];

  const yearlyData = new Set<string>();
  monthHeaders.map((monthHeader) => {
    yearlyData.add(getYearFromMonthYear(monthHeader));
  });

  yearlyData.forEach((year: string) => {
    const monthsOfThisYear = monthHeaders.filter((monthHeader) => monthHeader.endsWith(year.toString()));
    const quarters: Set<string> = new Set();
    monthsOfThisYear?.forEach((month) => {
      quarters.add(getQuarterFromMonth(month));
    });
    const quartersOfThisYear: string[] = Array.from(quarters);

    const quarterWithMonthlyHeaderInfo: ColDef[] = [];
    quartersOfThisYear.map((quarter) => {
      const monthsOfThisQuarter = monthsOfThisYear.filter((month) => quarter === getQuarterFromMonth(month));

      // Month columns
      const quarterlyMonthsHeader: ColDef[] = monthsOfThisQuarter.map((monthInQuarter) => {
        return {
          editable: isEditable,
          field: monthInQuarter,
          headerName: monthInQuarter.replace(/(\w{3})(\d{4})/, '$1-$2'),
          suppressMovable: true,
          lockPinned: true,
          cellStyle: headerFor === 'Actual' ? { backgroundColor: ActualColumnBgColor } : {},
          cellClassRules: applyCellClassRules,
          cellRenderer: 'agAnimateShowChangeCellRenderer',
          valueParser: (params: ValueParserParams) => cellValueParser(params.newValue),
          valueFormatter: forecastCurrencyFormatter
        } as ColDef;
      });

      // Quarterly columns
      quarterWithMonthlyHeaderInfo.push({
        headerName: quarter,
        marryChildren: true,
        children: quarterlyMonthsHeader.concat({
          // Quarterly Total- Columns
          editable: false, // Since these are total columns, it is always false
          field: year.toString() + quarter + 'Total',
          headerName: `${quarter}-${year}`,
          suppressMovable: true,
          lockPinned: true,
          cellStyle: headerFor === 'Actual' ? { backgroundColor: ActualColumnBgColor } : { backgroundColor: QuarterlyYearlyTotalColumnBgColor },
          cellRenderer: 'agAnimateShowChangeCellRenderer',
          valueParser: (params: ValueParserParams) => cellValueParser(params.newValue),
          valueFormatter: forecastCurrencyFormatter,
          valueGetter: (params) => {
            let sum = 0;
            let containsNumberOrZero = false;

            quarterlyMonthsHeader.forEach((quarterMonth: any) => {
              // for debugging
              // console.debug(`Quarterly - ${quarterMonth.field} value is ${params.data[quarterMonth.field]} which is a type of ${typeof params.data[quarterMonth.field]}`);
              if (params.data[quarterMonth.field] === null) {
                // do nothing
              } else if (params.data[quarterMonth.field] === '0') {
                containsNumberOrZero = true;
              } else {
                if (params.data[quarterMonth.field] != null && !isNaN(params.data[quarterMonth.field])) {
                  containsNumberOrZero = true;
                  sum += +params.data[quarterMonth.field];
                }
              }
            });
            return containsNumberOrZero ? sum : null;
          }
        } as ColDef)
      } as ColDef);
    });

    // Yearly columns
    const yearly = {
      headerName: year.toString(),
      marryChildren: true,
      columnGroupShow: 'open',
      children: quarterWithMonthlyHeaderInfo.concat({
        // Yearly Total - Columns
        editable: false, // Since these are total columns, it is always false
        headerName: `${year}`,
        field: year + 'YearlyTotal',
        suppressMovable: true,
        lockPinned: true,
        marryChildren: true,
        cellStyle: headerFor === 'Actual' ? { backgroundColor: ActualColumnBgColor } : { backgroundColor: QuarterlyYearlyTotalColumnBgColor },
        cellRenderer: 'agAnimateShowChangeCellRenderer',
        valueParser: (params: ValueParserParams) => cellValueParser(params.newValue),
        valueFormatter: forecastCurrencyFormatter,
        valueGetter: (params) => {
          let sum = 0;
          let containsNumberOrZero = false;

          quarterWithMonthlyHeaderInfo.forEach((quarterMonth: any) => {
            quarterMonth.children?.forEach((monthly: any) => {
              if (!monthly?.field?.toLowerCase()?.endsWith('total')) {
                // for debugging
                // console.debug(`Yearly  - ${monthly.field} value is ${params.data[monthly.field]} which is a type of ${typeof params.data[monthly.field]}`);
                if (params.data[monthly.field] === null) {
                  // do nothing
                } else if (params.data[monthly.field] === '0') {
                  containsNumberOrZero = true;
                } else {
                  if (params.data[monthly.field] != null && !isNaN(params.data[monthly.field])) {
                    containsNumberOrZero = true;
                    sum += +params.data[monthly.field];
                  }
                }
              }
            });
          });
          return containsNumberOrZero ? sum : null;
        }
      } as ColDef)
    } as ColDef;
    agGridHeaderData.push(yearly);
  });
  return agGridHeaderData;
};

export const getForecastHeadersWithoutGrouping = (monthHeaders: string[], headerFor: 'Forecast', isEditable: boolean): ColDef[] => {
  const agGridHeaderData: ColDef[] = getActualForecastHeadersWithGrouping(monthHeaders, headerFor, isEditable);
  const finalOutput: any[] = [];
  agGridHeaderData.forEach((year: any) => {
    year.children?.forEach((quarter: any) => {
      if (quarter.children?.length > 0) {
        quarter.children?.forEach((monthlyData: any) => {
          finalOutput.push(monthlyData);
        });
      } else {
        finalOutput.push(quarter);
      }
    });
  });
  return finalOutput;
};

export const getActualsHeadersWithoutGrouping = (monthHeaders: string[], headerFor: 'Actual', isEditable: boolean): ColDef[] => {
  const agGridHeaderData: ColDef[] = getActualForecastHeadersWithGrouping(monthHeaders, headerFor, isEditable);
  const finalOutput: any[] = [];
  agGridHeaderData.forEach((year: any) => {
    year.children?.forEach((quarter: any) => {
      if (quarter.children?.length > 0) {
        quarter.children?.forEach((monthlyData: any) => {
          finalOutput.push({ ...monthlyData, columnGroupShow: 'open' });
        });
      } else {
        finalOutput.push({ ...quarter, columnGroupShow: 'open' });
        finalOutput.push({ ...quarter, columnGroupShow: 'closed' });
      }
    });
  });
  return finalOutput;
};

// API to Forecast Table row data
// API will pass "productLineCampaignCorpId", "productLineCampaignId" as numbers
//     and Actual and Forecast Month values as number || null
export const parseRowData = (listActualsForecastDetailsResponse: any) => {
  const parsedRowData: any[] = listActualsForecastDetailsResponse?.map((data: any) => {
    return {
      ...data,
      amountValue: JSON.parse(data.amountValue),
      fpnaDimension: JSON.parse(data.fpnaDimension)
    };
  });
  const flatten = parsedRowData?.map((eachRow: any) => {
    // Parsing amountValue
    let amountValueObject: any = {};
    eachRow.amountValue.map((amount: any) => {
      Object.keys(amount).forEach((key) => {
        amountValueObject = { ...amountValueObject, [key]: amount[key] };
      });
    });
    delete eachRow.amountValue;

    // Parsing fpnaDimensions
    let fpnaDimensionObject: any = {};
    eachRow.fpnaDimension.map((fpnaDimension: any) => {
      Object.keys(fpnaDimension).forEach((key) => {
        fpnaDimensionObject = { ...fpnaDimensionObject, [key]: fpnaDimension[key] };
      });
    });
    delete eachRow.fpnaDimension;

    return {
      isEdited: false,
      ...eachRow,
      ...amountValueObject,
      ...fpnaDimensionObject
    };
  });
  return flatten?.sort((a, b) => (a.campaignName > b.campaignName ? 1 : -1));
};

// Exporting Forecast Table row data to Excel
export const parseForecastDataToExcelObject = (rowData: any[], agGridColumnList: string[], fixedColumnInfo: FixedColumnInformation) => {
  const fieldsToHaveAndInThisOrder = fixedColumnInfo.campaignColumns.concat(
    fixedColumnInfo.fpnaDimensionKeys,
    fixedColumnInfo.corpDimensions,
    fixedColumnInfo.actualMonths,
    fixedColumnInfo.forecastMonths
  );
  const filteredFields: any[] = [];
  rowData.forEach((row) => {
    const result = fieldsToHaveAndInThisOrder.reduce((acc: any, key: string) => {
      if (row.hasOwnProperty(key)) {
        acc[key] = row[key];
      }
      return acc;
    }, {});
    filteredFields.push(result);
  });

  let parsedRowData: any = [];
  filteredFields.forEach((eachRow: Record<string, any>) => {
    parsedRowData.push(reorderObjectKeys(eachRow, fieldsToHaveAndInThisOrder));
  });
  return parsedRowData;
};

// Importing Forecast Table row data from Excel
export const parseExcelToForecastObject = (excelData: any[], fixedColumnInfo: FixedColumnInformation) => {
  // Removing all other fields from the uploaded file except "productLineCampaignCorpId", "productLineCampaignId" and Forecast Months
  const fieldsToHaveAndInThisOrder = ['productLineCampaignCorpId', 'productLineCampaignId'].concat(fixedColumnInfo.forecastMonths);

  const filteredFields: any[] = [];
  excelData.forEach((row) => {
    const result = fieldsToHaveAndInThisOrder.reduce((acc: any, key: string) => {
      if (row.hasOwnProperty(key)) {
        if (key === 'productLineCampaignCorpId' || key === 'productLineCampaignId') {
          acc[key] = parseNumber(row[key]);
        } else {
          acc[key] = trimEmptyString(row[key]);
        }
      }
      return acc;
    }, {});
    filteredFields.push(result);
  });

  let parsedRowData: any = [];
  filteredFields.forEach((eachRow: Record<string, any>) => {
    parsedRowData.push(reorderObjectKeys(eachRow, fieldsToHaveAndInThisOrder));
  });
  return parsedRowData;
};

const reorderObjectKeys = (obj: Record<string, any>, keys: string[]): Record<string, any> => {
  const entries = Object.entries(obj);
  const sortedEntries = entries.sort(([keyA], [keyB]) => {
    const indexA = keys.indexOf(keyA);
    const indexB = keys.indexOf(keyB);
    return indexA - indexB;
  });
  const orderedObj = sortedEntries.reduce((result: any, [key, value]) => {
    result[key] = value;
    return result;
  }, {});
  return orderedObj;
};

export const parseAgGridRowDataToMutationObject = (fixedColumnInfo: FixedColumnInformation, rowData: any[]) => {
  const columnsToRemove = fixedColumnInfo.campaignColumns.concat(
    fixedColumnInfo.fpnaDimensionKeys,
    fixedColumnInfo.corpDimensions,
    fixedColumnInfo.actualMonths,
    'accountName',
    'costCenterName'
  );

  const finalForecastMeasures: any[] = [];
  rowData.forEach((row) => {
    const result = Object.keys(row)
      .filter(
        (key) =>
          (!columnsToRemove.includes(key) && !key.toLowerCase().includes('total')) ||
          key === 'productLineCampaignCorpId' ||
          key === 'productLineCampaignId'
      )
      .reduce((acc: any, key) => {
        acc[key] = row[key];
        return acc;
      }, {});
    finalForecastMeasures.push(result);
  });
  return finalForecastMeasures;
};

export const getFinalLockDate = (selectedPlanningCycle: PlanningCycleDataModel, userAlias: string) => {
  const userHasExtendedPermissions = selectedPlanningCycle.extendedToUsers.includes(userAlias);
  const lockDate =
    selectedPlanningCycle.isExtended && userHasExtendedPermissions ? selectedPlanningCycle.extendedLockDateTime : selectedPlanningCycle.lockToolDate;
  return lockDate ? lockDate : selectedPlanningCycle.lockToolDate;
};

export const getMonthsFromPlanningCycle = (selectedPlanningCycle: PlanningCycleDataModel) => {
  const actualMonthsList = generateMonthList(selectedPlanningCycle.actualsStartMonth, selectedPlanningCycle.actualsEndMonth);
  const forecastMonthsList = generateMonthList(selectedPlanningCycle.forecastStartMonth, selectedPlanningCycle.forecastEndMonth);
  return {
    actualMonths: actualMonthsList,
    forecastMonths: forecastMonthsList
  };
};

export const splitArrayIntoChunks = (maxAllowedCharacters: number, arrayOfObjects: any[]) => {
  const chunks = [];
  let currentChunk = [];
  let currentChunkSize = 0;

  for (const obj of arrayOfObjects) {
    const jsonString = JSON.stringify(obj);
    const objSize = jsonString.length;

    if (objSize > maxAllowedCharacters) {
      throw new Error('An individual object in the array exceeds the maximum character limit.');
    }

    if (currentChunkSize + objSize > maxAllowedCharacters) {
      chunks.push(currentChunk);
      currentChunk = [];
      currentChunkSize = 0;
    }

    currentChunk.push(obj);
    currentChunkSize += objSize;
  }

  if (currentChunk.length > 0) {
    chunks.push(currentChunk);
  }

  return chunks;
};

export const defaultForecastDetailsSelectionGridDefinition = [
  { colspan: 2, xxs: 12 },
  { colspan: 2, xxs: 12 },
  { colspan: 3, xxs: 12 },
  { colspan: 2, xxs: 12 },
  { colspan: 3, xxs: 12 }
];

export const defaultForecastDetailsContainerGridDefinition = [
  { colspan: { default: 7, xl: 7, l: 7, m: 7, s: 12, xs: 12, xxs: 12 } },
  { colspan: { default: 5, xl: 5, l: 5, m: 5, s: 12, xs: 12, xxs: 12 } }
];

/**
 * Cleanses the data array from clipboard to resolve the issue of an extra empty cell being added.
 * Sometimes this issue can be found in windows machines only.
 * This function creates a deep copy of the input data and removes any extraneous last row
 * if it consists of a single, empty cell, which is a common issue when pasting data into Ag Grid.
 *
 * @param params Parameters that include the data array from clipboard.
 * @returns The cleansed data array, ready for processing by Ag Grid.
 */
export function processDataFromClipboard(params: ProcessDataFromClipboardParams): string[][] {
  const data = cloneDeep(params.data); // Ensures no direct mutation of the input data
  const lastRow = data[data.length - 1]; // Inspect the last row

  // Check for and remove an extraneous last row that consists of a single, empty cell
  if (lastRow.length === 1 && lastRow[0] === '') {
    data.pop();
  }

  return data;
}
