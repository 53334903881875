import React from 'react';
import { Checkbox, CollectionPreferences, CollectionPreferencesProps, StatusIndicator, TableProps } from '@amzn/awsui-components-react';

import { getCurrentUserLocalTimeReadableFormat } from 'src/utils/DateTimeUtils';
import { PAGE_SIZE_OPTIONS, displayNull } from 'src/utils/CommonHelpers';
import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks';
import { addColumnSortLabels } from 'src/utils/labels';

const SORTABLE_COLUMNS: any = [];

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<any>[] = addColumnSortLabels(
  [
    {
      id: 'productLine',
      sortingField: 'productLine',
      header: 'Product Line',
      cell: (item: any) => item.productLine,
      width: 330
    },
    {
      id: 'forecastStatus',
      sortingField: 'forecastStatus',
      header: 'Status',
      cell: (item: any) => <StatusIndicator type={item.forecastStatus == 'Approved' ? 'success' : 'error'}>{item.forecastStatus}</StatusIndicator>,
      width: 200
    },
    {
      id: 'updatedBy',
      sortingField: 'updatedBy',
      cell: (item: any) => displayNull(item.updatedBy),
      header: 'Updated By',
      width: 200
    },
    {
      id: 'updatedTime',
      sortingField: 'updatedTime',
      cell: (item: any) => getCurrentUserLocalTimeReadableFormat(item.updatedTime),
      header: 'Updated Time',
      width: 200
    }
  ],
  SORTABLE_COLUMNS
);

export const VISIBLE_CONTENT = ['productLine', 'forecastStatus', 'updatedBy', 'updatedTime'];

export const VISIBLE_CONTENT_OPTIONS: CollectionPreferencesProps.VisibleContentOptionsGroup[] = [
  {
    label: 'Properties',
    options: [
      { id: 'productLine', label: 'Product Line' },
      { id: 'forecastStatus', label: 'Status' },
      { id: 'updatedBy', label: 'Updated By' },
      { id: 'updatedTime', label: 'Updated Time' }
    ]
  }
];

export const FILTERING_PROPERTIES: PropertyFilterProperty[] = [
  {
    propertyLabel: 'Product line',
    key: 'productLine',
    groupValuesLabel: 'product line values',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'Status',
    key: 'forecastStatus',
    groupValuesLabel: 'Status values',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'Updated by',
    key: 'updatedBy',
    groupValuesLabel: 'Updated by values',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'Updated time',
    key: 'updatedTime',
    groupValuesLabel: 'Updated time values',
    operators: [':', '!:', '=', '!=']
  }
];

export const FORECAST_TEMPLATE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences = {
  pageSize: 20,
  visibleContent: VISIBLE_CONTENT,
  wrapLines: false,
  stripedRows: false,
  custom: false
};

export const ForecastStatusTablePreferences = ({
  preferences,
  setPreferences,
  disabled,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  visibleContentOptions = VISIBLE_CONTENT_OPTIONS
}: any) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: 'Page size',
      options: pageSizeOptions
    }}
    wrapLinesPreference={{
      label: 'Wrap lines',
      description: 'Check to see all the text and wrap the lines'
    }}
    stripedRowsPreference={{
      label: 'Striped rows',
      description: 'Check to add alternating shaded rows'
    }}
    visibleContentPreference={{
      title: 'Select visible columns',
      options: visibleContentOptions
    }}
    customPreference={(checked, setChecked) => (
      <Checkbox
        onChange={({ detail }) => {
          setChecked(detail.checked);
        }}
        checked={checked}
      >
        Resizable Columns
      </Checkbox>
    )}
  />
);
