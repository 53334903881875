import React, { useState, useEffect } from 'react';
import { QuicksightReports } from './ReportingTabs';
import { API } from 'aws-amplify';
import * as queries from 'src/graphql/queries';
import { logger } from 'src/utils/Logger';
import { Flashbar, FlashbarProps, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react';
import { LoadingSpinner } from 'src/components/generic-components/Spinner';
import { useAuth } from 'src/components/context/AuthContext';

interface QuickSightDashboardReportProps {
  quickSightReportInfo: QuicksightReports;
}
export const QuickSightDashboardReport = ({ quickSightReportInfo }: QuickSightDashboardReportProps) => {
  const userDetails = useAuth();
  const [generatedUrl, setGeneratedUrl] = useState<string>('');
  const [quicksightDashboardLoading, setQuicksightDashboardLoading] = useState<boolean>(true);
  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);

  useEffect(() => {
    generatedDashboardURLs(quickSightReportInfo);
  }, [quickSightReportInfo]);

  const generatedDashboardURLs = async (report: QuicksightReports) => {
    setQuicksightDashboardLoading(true);

    try {
      const response: any = await API.graphql({
        query: queries.getQuicksightEmbeddedUrl,
        variables: {
          dashboardId: report.dashboardId,
          userAlias: userDetails.Alias
        }
      });
      setGeneratedUrl(response.data.getQuicksightEmbeddedUrl.embdedUrl);
      setQuicksightDashboardLoading(false);
    } catch (error: any) {
      setQuicksightDashboardLoading(false);
      logger.error('Unable to get Quick Sight Embedded URLs ', error);
      setGeneratedUrl('');
      displayFlashMessage('Unable to load Quick Sight dashboard. Please try again later.', 'error', true);
    }
  };

  const displayFlashMessage = (content: string, flashBarType: FlashbarProps.Type = 'info', isDismissible: boolean = true) => {
    setFlashbarItems(
      content
        ? [
            {
              type: flashBarType,
              content: content,
              dismissible: isDismissible,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashbarItems([])
            }
          ]
        : []
    );
  };

  return (
    <>
      <SpaceBetween size="m" direction="vertical">
        <Flashbar items={flashbarItems} />
        {quicksightDashboardLoading && <LoadingSpinner></LoadingSpinner>}
        {!quicksightDashboardLoading &&
          (generatedUrl === '' ? (
            <StatusIndicator type="error">{'Unable to load QuickSight dashboard. Please try again later.'}</StatusIndicator>
          ) : (
            <iframe
              sandbox="allow-same-origin allow-scripts allow-popups allow-modals allow-forms allow-downloads allow-top-navigation allow-top-navigation-by-user-activation"
              className="quicksight-dashboard-frame"
              src={generatedUrl}
            ></iframe>
          ))}
      </SpaceBetween>
    </>
  );
};
