export const APP_NAME = 'DMM';

export const BaseBreadcrumbs = [
  {
    text: APP_NAME,
    href: '/'
  }
];

// LDAP Group's
export const LDAP_AD3_TEAM = 'ad3-team';
export const LDAP_DMM_DEV_TEAM = 'das-dmm-dev';
export const LDAP_DMM_ADMINS = 'dmm-admins';

// User Types
export enum eUserTypes {
  FPNA_USER = 'FPNA User',
  PRODUCT_LINE_LEAD = 'Product Line Lead',
  PRODUCT_LINE_USER = 'Product Line User'
}

// SIM links based on use case
export enum eSIMLinks {
  REQUEST_ACCESS = 'https://t.corp.amazon.com/create/templates/7c51478b-1fa5-47c2-b6b6-52cc9acc4fa6',
  FEATURE_REQUEST = 'https://t.corp.amazon.com/create/templates/2cda8d8a-9e44-4d8c-b748-36b0ba4d89ac',
  ISSUE_REPORT = 'https://t.corp.amazon.com/create/templates/63a8c902-66f6-4aee-8e10-b23eeb4358f7'
}

export enum eMSPDynamoDBTables {
  MSP_VIDEO_TUTORIALS = 'msp-video-tutorials',
  DMM_USER_PREFS = 'msp-portal-user-preferences'
}

// Shouldn't rename the existing constants as they are being used in dynamodb table
export enum eUserTablePreferenceKeys {
  // Access Authorizations
  ACCESS_AUTHORIZATION_TABLE_WIDTHS = 'AccessAuthorization-Table-Widths',
  ACCESS_AUTHORIZATION_TABLE_PREFERENCES = 'AccessAuthorization-Table-Preferences',
  ACCESS_AUTHORIZATION_TABLE_FILTERING_PROPERTIES = 'AccessAuthorization-Table-Filtering-Properties',

  // Purchase Orders (PO's)
  PURCHASE_ORDER_TABLE_COLUMN_WIDTHS = 'PurchaseOrder-Table-Column-Widths',
  PURCHASE_ORDER_TABLE_PREFERENCES = 'PurchaseOrder-Table-Preferences',
  PURCHASE_ORDER_TABLE_FILTERING_PROPERTIES = 'PurchaseOrder-Table-Filtering-Properties',
  PURCHASE_ORDERS_PRODUCT_LINE_OPTION = 'PurchaseOrder-Product-Line-Dropdown-Option',
  PURCHASE_ORDERS_ACTUAL_MONTHS_OPTION = 'PurchaseOrder-Actual-Months-Dropdown-Option',

  // Campaigns
  CAMPAIGN_QUERY = 'Campaign-Query',
  CAMPAIGN_TABLE_VISIBLE_CONTENT_OPTIONS = 'Campaign-Table-Visible-Options',
  CAMPAIGN_TABLE_FILTERING_PROPERTIES = 'Campaign-Table-Filtering-Properties',
  CAMPAIGN_TABLE_DEFAULT_PREFERENCES = 'Campaign-Table-Default-Preferences',
  CAMPAIGN_PRODUCT_LINE_DROPDOWN_OPTION = 'Campaign-Product-Line-Dropdown-Option',
  CAMPAIGN_CAMPAIGN_DROPDOWN_OPTION = 'Campaign-Campaign-Dropdown-Option-v1',
  CAMPAIGN_COUNTRY_DROPDOWN_OPTION = 'Campaign-Country-Dropdown-Option-v1',

  // Mappings - Product Line
  PRODUCT_LINE_TABLE_COLUMN_WIDTHS = 'Product-Line-Table-Column-Widths',
  PRODUCT_LINE_TABLE_PREFERENCES = 'Product-Line-Table-Preferences',
  PRODUCT_LINE_TABLE_FILTERING_PROPERTIES = 'Product-Line-Table-Filtering-Properties',

  // Forecast Template
  FORECAST_TEMPLATE_STATUS_TABLE_WIDTHS = 'ForecastTemplateStatus-Table-Widths',
  FORECAST_TEMPLATE_STATUS_TABLE_PREFERENCES = 'ForecastTemplateStatus-Table-Preferences',

  FORECAST_TEMPLATE_SELECTED_PRODUCT_LINE = 'ForecastTemplateStatus-Selected-Product-Line',
  FORECAST_TEMPLATE_PRODUCT_LINE_OPTION = 'ForecastTemplateStatus-Product-Line-Dropdown-Option',

  FORECAST_TEMPLATE_SELECTED_PLANNING_CYCLE = 'ForecastTemplateStatus-Selected-Planning-Cycle',
  FORECAST_TEMPLATE_PLANNING_CYCLE_OPTION = 'ForecastTemplateStatus-Planning-Cycle-Dropdown-Option',

  FORECAST_TEMPLATE_CAMPAIGN_OPTION = 'ForecastTemplateStatus-Campaign-Dropdown-Option',
  FORECAST_TEMPLATE_COUNTRY_OPTION = 'ForecastTemplateStatus-Country-Dropdown-Option',
  FORECAST_TEMPLATE_REVENUE_ESTIMATE_OPTION = 'ForecastTemplateStatus-RevenueEstimate-Dropdown-Option'
}
