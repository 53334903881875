import { Checkbox, CollectionPreferences, CollectionPreferencesProps, StatusIndicator, TableProps } from '@amzn/awsui-components-react';
import React from 'react';
import { getCurrentUserLocalTimeReadableFormat } from 'src/utils/DateTimeUtils';
import { PAGE_SIZE_OPTIONS } from 'src/utils/CommonHelpers';
import { User } from '../context/AppContextModel';

export const DMM_USER_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<User>[] = [
  {
    id: 'userAlias',
    header: 'Alias',
    cell: (item) => item.userAlias,
    sortingField: 'userAlias'
  },
  {
    id: 'userName',
    header: 'Name',
    cell: (item) => item.userName,
    sortingField: 'userName'
  },
  {
    id: 'userTypeDescription',
    header: 'User Type',
    cell: (item) => item.userType.userTypeDescription,
    sortingComparator: (item, item2) => (item.userType.userTypeDescription > item2.userType.userTypeDescription ? -1 : 1)
  },
  {
    id: 'userAccessDescription',
    header: 'Access Level',
    cell: (item) => item.userAccess.userAccessDescription,
    sortingComparator: (item, item2) => (item.userAccess.userAccessDescription > item2.userAccess.userAccessDescription ? -1 : 1)
  },
  {
    id: 'isActive',
    header: 'Status',
    cell: (item) => (
      <StatusIndicator colorOverride={item.isActive ? 'green' : 'red'} type={item.isActive ? 'success' : 'stopped'}>
        {item.isActive ? 'Active' : 'Inactive'}
      </StatusIndicator>
    ),
    sortingField: 'isActive'
  },
  {
    id: 'updatedBy',
    header: 'Updated By',
    cell: (item) => item.updatedBy,
    sortingField: 'updatedBy'
  },
  {
    id: 'updatedTime',
    header: 'Updated At',
    cell: (item) => getCurrentUserLocalTimeReadableFormat(item.updatedTime),
    sortingField: 'updatedTime',
    sortingComparator: (item, item2) => (item.updatedTime > item2.updatedTime ? -1 : 1)
  }
];

export const DMM_USER_FILTERING_PROPERTIES = [
  {
    key: 'userAlias',
    propertyLabel: 'Alias',
    groupValuesLabel: 'Alias values',
    operators: [':', '!:', '=', '!=']
  },
  {
    key: 'userName',
    propertyLabel: 'Name',
    groupValuesLabel: 'Names',
    operators: [':', '!:', '=', '!=']
  },
  {
    key: 'isActive',
    propertyLabel: 'Status',
    groupValuesLabel: 'Status',
    operators: [':', '!:', '=', '!=']
  }
];

export const DEFAULT_VISIBLE_CONTENT = [
  'userAlias',
  'userName',
  'userTypeDescription',
  'userAccessDescription',
  'isActive',
  'updatedBy',
  'updatedTime'
];

export const DEFAULT_VISIBLE_CONTENT_OPTIONS: CollectionPreferencesProps.VisibleContentOptionsGroup[] = [
  {
    label: 'Access & Authorization Table properties',
    options: [
      { id: 'userAlias', label: 'Alias' },
      { id: 'userName', label: 'Name' },
      { id: 'userTypeDescription', label: 'User Type' },
      { id: 'userAccessDescription', label: 'Access Level' },
      { id: 'isActive', label: 'Status' },
      { id: 'updatedBy', label: 'Updated By' },
      { id: 'updatedTime', label: 'Updated At' }
    ]
  }
];

export const DMM_USER_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences = {
  pageSize: 20,
  visibleContent: DEFAULT_VISIBLE_CONTENT,
  wrapLines: false,
  stripedRows: false,
  custom: false
};

export const Preferences = ({
  preferences,
  setPreferences,
  disabled,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  visibleContentOptions = DEFAULT_VISIBLE_CONTENT_OPTIONS
}: any) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: 'Page size',
      options: pageSizeOptions
    }}
    wrapLinesPreference={{
      label: 'Wrap lines',
      description: 'Check to see all the text and wrap the lines'
    }}
    stripedRowsPreference={{
      label: 'Striped rows',
      description: 'Check to add alternating shaded rows'
    }}
    visibleContentPreference={{
      title: 'Select visible columns',
      options: visibleContentOptions
    }}
    customPreference={(checked, setChecked) => (
      <Checkbox
        onChange={({ detail }) => {
          setChecked(detail.checked);
        }}
        checked={checked}
      >
        Resizable Columns
      </Checkbox>
    )}
  />
);
