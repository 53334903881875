import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  AppLayout,
  Box,
  Button,
  Flashbar,
  FlashbarProps,
  Header,
  Link,
  Pagination,
  SpaceBetween,
  SplitPanel,
  Table,
  TableProps
} from '@amzn/awsui-components-react';
import PropertyFilter, { PropertyFilterProps } from '@amzn/awsui-components-react/polaris/property-filter';
import React, { useEffect, useState } from 'react';
import { PROPERTY_FILTERING_I18N_CONSTANTS } from 'src/utils/CommonHelpers';
import { BaseBreadcrumbs, eUserTablePreferenceKeys } from 'src/utils/DmmConstants';
import { getMatchesCountText, PAGINATION_LABELS } from 'src/utils/labels';
import { TableEmptyState, TableNoMatchState } from '../commons/CommonComponents';
import { useColumnWidths } from '../commons/CustomHooks/use-column-widths';
import { useAsyncDynamoStorage } from '../commons/CustomHooks/use-dynamo-storage';
import { LoadingStatus, User } from '../context/AppContextModel';
import { getAllUsers, useAppContext } from '../context/AppContextProvider';
import { LoadingSpinner } from '../generic-components/Spinner';
import { AppBreadcrumb } from '../navigation/AppBreadcrumb';
import { AppSideNavigation } from '../navigation/AppSideNavigation';
import { AccessAndAuthorizationToolBar } from './AccessAndAuthorizationInfo';
import { getPanelContent, useSplitPanelProps } from './AccessAndAuthorizationSplitPanel';
import { DMM_USER_COLUMN_DEFINITIONS, DMM_USER_DEFAULT_PREFERENCES, DMM_USER_FILTERING_PROPERTIES, Preferences } from './AccessAuthTableDefinitions';
import { UserState } from './models/Users';
import { SPLIT_PANEL_I18NSTRINGS } from '../generic-components/SplitPanelConstants';

const getUserBreadcrumbs = () => {
  return [
    ...BaseBreadcrumbs,
    {
      text: 'Access & Authorization',
      href: '/access'
    }
  ];
};

export const AccessAndAuthorization = () => {
  const { users, setUsers, contextLoadingStatus } = useAppContext();
  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);
  const [toolsOpen, setToolsOpen] = useState(false);
  const [userState, setUserState] = useState<UserState>({
    userDataChanged: false,
    userDataFetched: false,
    userDataLoading: true,
    userDataSubmitInProgress: false
  });

  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [filteringProperties, setFilteringProperties] = useState<any>(DMM_USER_FILTERING_PROPERTIES);

  const BLANK_SEARCH = { tokens: [], operation: 'and' } as PropertyFilterProps.Query;
  const [query, setQuery] = useAsyncDynamoStorage(eUserTablePreferenceKeys.ACCESS_AUTHORIZATION_TABLE_FILTERING_PROPERTIES, BLANK_SEARCH);

  const [columnDefinitions, saveWidths, columnDefinitionsLoading] = useColumnWidths(
    eUserTablePreferenceKeys.ACCESS_AUTHORIZATION_TABLE_WIDTHS,
    DMM_USER_COLUMN_DEFINITIONS
  );
  const [preferences, setPreferences] = useAsyncDynamoStorage(
    eUserTablePreferenceKeys.ACCESS_AUTHORIZATION_TABLE_PREFERENCES,
    DMM_USER_DEFAULT_PREFERENCES
  );

  useEffect(() => {
    actions.setPropertyFiltering(query);
  }, [query]);

  const {
    splitPanelPrefs,
    splitPanelSize,
    splitPanelOpen,
    onSplitPanelPreferencesChange,
    onSplitPanelResize,
    onSplitPanelToggle,
    splitPanelOpenByEdit
  } = useSplitPanelProps();

  const displayFlashMessage = (content: string, flashBarType: FlashbarProps.Type) => {
    setFlashbarItems([
      {
        type: flashBarType,
        content: content,
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => setFlashbarItems([])
      }
    ]);
  };

  const updateTheTable = (updatedUserRow: User, eventType: 'Create' | 'Edit') => {
    if (eventType === 'Create') {
      setUsers([updatedUserRow].concat(users));
    } else {
      setUsers(
        users.map((user) => {
          if (user.userAlias === updatedUserRow.userAlias) {
            return { ...updatedUserRow };
          } else {
            return { ...user };
          }
        })
      );
    }
    splitPanelOpenByEdit(false);
    refreshUsers();
    displayFlashMessage(`User "${updatedUserRow.userAlias}" ${eventType === 'Create' ? 'created' : 'updated'} successfully.`, 'success');
  };

  const panelContent = getPanelContent(selectedUsers, updateTheTable, displayFlashMessage);

  useEffect(() => {
    setUserState({ ...userState, userDataLoading: contextLoadingStatus === LoadingStatus.Loading });
  }, [contextLoadingStatus]);

  const refreshUsers = () => {
    setUserState({ ...userState, userDataLoading: true });
    getAllUsers()
      .then((allUsers) => {
        setUsers(allUsers);
        setUserState({ ...userState, userDataLoading: false });
      })
      .catch((err) => {
        setUserState({ ...userState, userDataLoading: false });
      });
  };

  // When user selects a row using Radio button
  const tableSelectionChanged = (detail: any) => {
    setSelectedUsers(detail.selectedItems);
  };

  // When user selects a row by clicking anywhere in the row
  const onRowClickChanged = (detail: any) => {
    setSelectedUsers([detail.item]);
  };

  const { items, actions, filteredItemsCount, collectionProps, paginationProps, propertyFilterProps } = useCollection(users || [], {
    propertyFiltering: {
      filteringProperties,
      empty: <TableEmptyState resourceName="Users" />,
      noMatch: (
        <TableNoMatchState
          onClearFilter={() => {
            actions.setPropertyFiltering({ tokens: [], operation: 'and' });
          }}
        />
      )
    },
    pagination: {
      pageSize: preferences.pageSize
    },
    sorting: {
      defaultState: {
        sortingColumn: { sortingField: 'updatedTime' },
        isDescending: true
      }
    },
    selection: {}
  });

  return (
    <>
      <AppLayout
        headerSelector="#h"
        contentType="default"
        navigation={<AppSideNavigation />}
        notifications={<Flashbar items={flashbarItems} />}
        breadcrumbs={<AppBreadcrumb items={getUserBreadcrumbs()} />}
        tools={<AccessAndAuthorizationToolBar />}
        toolsOpen={toolsOpen}
        onToolsChange={({ detail }) => setToolsOpen(detail.open)}
        splitPanelOpen={splitPanelOpen}
        splitPanelPreferences={splitPanelPrefs}
        splitPanelSize={320}
        onSplitPanelPreferencesChange={onSplitPanelPreferencesChange}
        onSplitPanelResize={onSplitPanelResize}
        onSplitPanelToggle={onSplitPanelToggle}
        splitPanel={
          <SplitPanel
            className="dmm-split-panel"
            hidePreferencesButton
            header={selectedUsers.length > 1 ? 'Multiple users selected' : selectedUsers.length === 0 ? 'Create' : 'Edit' + ' User'}
            i18nStrings={SPLIT_PANEL_I18NSTRINGS}
          >
            {panelContent}
          </SplitPanel>
        }
        disableContentPaddings
        content={
          <Box margin={{ top: 'l', bottom: 'xl', left: 'xxxl', right: 'xxxl' }} padding={{ left: 'xxxl', right: 'xxxl' }}>
            {columnDefinitionsLoading === 'loading' && <LoadingSpinner />}
            {columnDefinitionsLoading !== 'loading' && (
              <Table
                variant="container"
                stickyHeader={true}
                header={
                  <Header
                    counter={users && (selectedUsers!.length ? `(${selectedUsers!.length}/${users.length})` : `(${users.length})`)}
                    actions={
                      <SpaceBetween size="m" direction="horizontal">
                        <Button iconName="refresh" onClick={refreshUsers}></Button>
                        <Button
                          onClick={() => {
                            setSelectedUsers([]);
                            splitPanelOpenByEdit(true);
                          }}
                        >
                          Add
                        </Button>
                        <Button
                          variant="primary"
                          disabled={selectedUsers!.length === 0}
                          onClick={() => {
                            splitPanelOpenByEdit(true);
                          }}
                        >
                          Edit
                        </Button>
                      </SpaceBetween>
                    }
                    info={
                      <Link variant="info" onFollow={() => setToolsOpen(true)}>
                        Info
                      </Link>
                    }
                  >
                    {'Access & Authorization'}
                  </Header>
                }
                {...collectionProps}
                visibleColumns={preferences.visibleContent}
                wrapLines={preferences.wrapLines}
                stripedRows={preferences.stripedRows}
                resizableColumns={preferences.custom}
                loading={userState?.userDataLoading}
                loadingText="Loading user data"
                columnDefinitions={columnDefinitions as TableProps.ColumnDefinition<User>[]}
                items={items}
                selectionType={'single'}
                onColumnWidthsChange={saveWidths as any}
                onSelectionChange={({ detail }) => tableSelectionChanged(detail)}
                onRowClick={({ detail }) => onRowClickChanged(detail)}
                selectedItems={selectedUsers}
                filter={
                  <PropertyFilter
                    i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
                    countText={getMatchesCountText(filteredItemsCount!)}
                    expandToViewport={true}
                    {...propertyFilterProps}
                    query={query}
                    onChange={(event) => {
                      setQuery(event.detail.tokens?.length === 0 ? BLANK_SEARCH : event.detail);
                    }}
                  />
                }
                pagination={<Pagination {...paginationProps} ariaLabels={PAGINATION_LABELS} />}
                preferences={<Preferences preferences={preferences ? preferences : DMM_USER_DEFAULT_PREFERENCES} setPreferences={setPreferences} />}
              />
            )}
          </Box>
        }
      />
    </>
  );
};
