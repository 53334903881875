import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SideNavigation, SideNavigationProps } from '@amzn/awsui-components-react';
import { LDAP_DMM_ADMINS, eUserTypes } from '../../utils/DmmConstants';
import { useAppContext } from '../context/AppContextProvider';
import { useAuth } from '../context/AuthContext';
export interface Navigation {
  section: string;
  type: string;
  text: string;
  href: string;
  accessibleTo: string[];
}

export const NavigationItems: Navigation[] = [
  { section: 'Admin', type: 'link', text: 'Access & Authorization', href: '/access', accessibleTo: [eUserTypes.FPNA_USER] },
  { section: 'Admin', type: 'link', text: 'Planning Cycle', href: '/planning-cycle', accessibleTo: [eUserTypes.FPNA_USER] },
  { section: 'Admin', type: 'link', text: 'Campaigns', href: '/campaigns', accessibleTo: [eUserTypes.FPNA_USER, eUserTypes.PRODUCT_LINE_LEAD] },
  {
    section: 'Admin',
    type: 'link',
    text: 'Purchase Orders',
    href: '/purchase-orders',
    accessibleTo: [eUserTypes.FPNA_USER, eUserTypes.PRODUCT_LINE_LEAD]
  },
  { section: 'Mappings', type: 'link', text: 'Product Line', href: '/product-line', accessibleTo: [LDAP_DMM_ADMINS] },
  {
    section: 'Planning',
    type: 'link',
    text: 'Forecast Template',
    href: '/forecast-template',
    accessibleTo: [eUserTypes.FPNA_USER, eUserTypes.PRODUCT_LINE_LEAD, eUserTypes.PRODUCT_LINE_USER]
  },
  {
    section: 'Reporting & Analytics',
    type: 'link',
    text: 'Reporting',
    href: '/reporting',
    accessibleTo: [eUserTypes.FPNA_USER, eUserTypes.PRODUCT_LINE_LEAD, eUserTypes.PRODUCT_LINE_USER]
  },
  {
    section: 'Reporting & Analytics',
    type: 'link',
    text: 'Forecast Template Status',
    href: '/forecast-template-status',
    accessibleTo: [eUserTypes.FPNA_USER, eUserTypes.PRODUCT_LINE_LEAD, eUserTypes.PRODUCT_LINE_USER]
  }
];

const hasCommonElements = (array1: string[], array2: string[]): boolean => {
  return array1?.filter((element) => array2?.includes(element))?.length > 0;
};

export const AppSideNavigation = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { userDetails } = useAppContext();
  const userAuthenticationDetails = useAuth();
  const [sideNavigation, setSideNavigation] = useState<SideNavigationProps.Item[]>([]);
  const [activeRef, setActiveRef] = useState<string>('');

  const sideNavigationItems = () => {
    const userType: string = userDetails.userType?.userTypeDescription ? userDetails.userType?.userTypeDescription : '';
    const userLdaps: string[] = userAuthenticationDetails.UserLdaps;

    const accessBasedNavigationItems = NavigationItems.filter(
      (navigation) => navigation.accessibleTo.includes(userType) || hasCommonElements(navigation.accessibleTo, userLdaps)
    );
    const uniqueSections = accessBasedNavigationItems
      .filter((thing, i, arr) => arr.findIndex((t) => t.section === thing.section) === i)
      .map((navigation) => navigation.section);

    let navigationItems: SideNavigationProps.Item[] = uniqueSections.map((sectionName) => {
      return {
        type: 'section',
        text: sectionName,
        items: accessBasedNavigationItems
          .filter((navigationItem) => navigationItem.section === sectionName)
          .map((_navigation) => {
            return {
              type: _navigation.type,
              text: _navigation.text,
              href: _navigation.href
            } as SideNavigationProps.Item;
          })
      };
    });
    setSideNavigation(navigationItems);
  };

  useEffect(() => {
    sideNavigationItems();
  }, [userDetails]);

  useEffect(() => {
    setActiveRef('/' + pathname.split('/')[1]);
  }, [pathname]);

  return (
    <SideNavigation
      items={sideNavigation}
      activeHref={activeRef}
      onFollow={(event) => {
        if (!event.detail.external) {
          event.preventDefault();
          navigate(event.detail.href);
        }
      }}
    />
  );
};
