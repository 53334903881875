import {
  Badge,
  Box,
  Button,
  Checkbox,
  FlashbarProps,
  Form,
  FormField,
  Input,
  Multiselect,
  Select,
  SpaceBetween,
  Spinner,
  Toggle
} from '@amzn/awsui-components-react';
import { RestAPI } from '@aws-amplify/api-rest';
import { API } from 'aws-amplify';
import * as mutations from 'src/graphql/mutations';
import * as queries from 'src/graphql/queries';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { CorpProducts, CreateProductLine, ProductLineDetail, ProductLineDetailFormTye, UpdateProductLine } from './ProductLineModel';
import * as Yup from 'yup';
import { useAuth } from 'src/components/context/AuthContext';
import { logger } from 'src/utils/Logger';
import { getCurrentUTCTimeZoneInISO } from 'src/utils/DateTimeUtils';

interface ProductLineDetailFormProps {
  productLineMappingsList: ProductLineDetail[];
  selectedProductLineDetail: ProductLineDetail[];
  corpProducts: CorpProducts[];
  updateTheTable: (requestStatus: string, message: string) => void;
  displayFlashMessage: (content: string, flashBarType: FlashbarProps.Type) => void;
}

const FormInitialValues: ProductLineDetailFormTye = {
  productCode: { label: '', value: '' },
  productName: '',
  isActive: true,
  isFpnaManaged: false
};

const REQUIRED_FIELD = 'Required field';
const yupRequiredDropdownField = () => {
  return Yup.object().shape({
    label: Yup.string().required(REQUIRED_FIELD)
  });
};

const ProductLineDetailFormSchema = Yup.object().shape({
  productId: Yup.number().nullable(),
  productCode: yupRequiredDropdownField(),
  productName: Yup.string()
    .required(REQUIRED_FIELD)
    // .min(1, "At least 1 character is required")
    .max(200, 'max 200 characters are allowed')
    .matches(/^(?=.*[a-zA-Z]).+$/, 'At least 1 characters is required')
});

export const ProductLineDetailForm = ({
  productLineMappingsList,
  selectedProductLineDetail,
  corpProducts,
  updateTheTable,
  displayFlashMessage
}: ProductLineDetailFormProps) => {
  const userDetails = useAuth();

  const [productLineFormInitialValues, setProductLineFormInitialValues] = useState<ProductLineDetailFormTye>(FormInitialValues);
  const productLineFormRef = useRef<FormikProps<ProductLineDetailFormTye>>(null);

  const [eventType, setEventType] = useState<'Create' | 'Edit'>(selectedProductLineDetail.length === 0 ? 'Create' : 'Edit');

  useEffect(() => {
    productLineFormRef.current?.resetForm();
    setEventType(selectedProductLineDetail.length === 0 ? 'Create' : 'Edit');
    if (selectedProductLineDetail.length != 0) {
      let formattedToFormModel: ProductLineDetailFormTye = {
        ...selectedProductLineDetail[0],
        productCode: {
          label: selectedProductLineDetail[0].productCode,
          value: selectedProductLineDetail[0].productName
        },
        isFpnaManaged: selectedProductLineDetail[0].isFpnaManagedPl
      };
      setProductLineFormInitialValues(formattedToFormModel);
    } else {
      setProductLineFormInitialValues(FormInitialValues);
    }
  }, [selectedProductLineDetail]);

  const handleSubmit = async (formValues: ProductLineDetailFormTye, formikHelpers: FormikHelpers<ProductLineDetailFormTye>) => {
    try {
      if (eventType === 'Edit') {
        const requestBody: UpdateProductLine = parseEditProductLine(formValues);
        const response: any = await API.graphql({
          query: mutations.updateProduct,
          variables: { input: requestBody }
        });
      } else {
        const requestBody: CreateProductLine = parseCreateProductLine(formValues);
        const response: any = await API.graphql({
          query: mutations.createProduct,
          variables: { input: requestBody }
        });
      }
      productLineFormRef?.current?.setSubmitting(false);
      updateTheTable('success', `Successfully ${eventType === 'Create' ? 'created' : 'updated'} '${formValues.productName}'`);
      logger.info(`Successfully ${eventType === 'Create' ? 'created' : 'updated'} '${formValues.productName}'`);
    } catch (error: any) {
      logger.error(`Unable to ${eventType === 'Create' ? 'create' : 'update'} ${formValues.productName}`, error);
      productLineFormRef?.current?.setSubmitting(false);
      updateTheTable('failed', `Unable to ${eventType === 'Create' ? 'create' : 'update'} '${formValues.productName}'`);
    }
  };

  const parseEditProductLine = (formValues: ProductLineDetailFormTye): UpdateProductLine => {
    return {
      productCode: formValues.productCode.label,
      productName: formValues.productName.trim(),
      updatedBy: userDetails.Alias,
      updatedTime: getCurrentUTCTimeZoneInISO(),
      isActive: formValues.isActive,
      isFpnaManaged: formValues.isFpnaManaged
    };
  };

  const parseCreateProductLine = (formValues: ProductLineDetailFormTye): CreateProductLine => {
    return {
      productCode: formValues.productCode.label,
      productName: formValues.productName.trim(),
      createdBy: userDetails.Alias,
      createdTime: getCurrentUTCTimeZoneInISO(),
      isFpnaManaged: formValues.isFpnaManaged
    };
  };

  const formikCustomValidate = (formValues: ProductLineDetailFormTye | undefined) => {
    if (!formValues) {
      return;
    }
    let errors: any = {};
    const productCodeExists = productLineMappingsList?.find((productCodeDim) => productCodeDim.productCode === formValues.productCode.label);
    if (eventType === 'Create' && productCodeExists) {
      errors = {
        ...errors,
        productCode: {
          label: 'Product Code already exists'
        }
      };
    }

    return { ...errors };
  };

  return (
    <>
      <SpaceBetween size="s" direction="vertical">
        <Formik<ProductLineDetailFormTye>
          innerRef={productLineFormRef}
          enableReinitialize
          initialValues={productLineFormInitialValues}
          validationSchema={ProductLineDetailFormSchema}
          validate={formikCustomValidate}
          onSubmit={handleSubmit}
        >
          {({ values, touched, errors, setFieldValue, handleSubmit, isSubmitting, dirty }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Form>
                  <SpaceBetween size="xl" direction="vertical">
                    <SpaceBetween size="m" direction="vertical">
                      <FormField label="Product Code" errorText={touched.productCode?.label && errors.productCode?.label}>
                        <Select
                          selectedAriaLabel="Selected"
                          filteringType="auto"
                          disabled={eventType === 'Edit'}
                          selectedOption={values.productCode}
                          onChange={({ detail }) => {
                            setFieldValue('productName', detail.selectedOption.value);
                            setFieldValue('productCode', detail.selectedOption);
                          }}
                          options={corpProducts?.map((corpProduct) => {
                            return {
                              value: corpProduct.productName,
                              label: corpProduct.productCode
                            };
                          })}
                        />
                      </FormField>

                      <FormField label="Product Line" errorText={touched.productName && errors.productName}>
                        <Input value={values.productName} onChange={(event) => setFieldValue('productName', event.detail.value)} />
                      </FormField>

                      <FormField>
                        <Checkbox onChange={({ detail }) => setFieldValue('isFpnaManaged', detail.checked)} checked={values.isFpnaManaged}>
                          FPNA Managed
                        </Checkbox>
                      </FormField>

                      <FormField>
                        <Toggle
                          checked={values.isActive}
                          disabled={eventType === 'Create'}
                          onChange={({ detail }) => setFieldValue('isActive', detail.checked)}
                        >
                          {values.isActive ? 'Active' : 'Inactive'}
                        </Toggle>
                      </FormField>
                    </SpaceBetween>

                    <SpaceBetween size="m" direction="vertical">
                      <Button variant="primary" disabled={isSubmitting || !dirty} formAction={'submit'} loading={isSubmitting}>
                        {eventType === 'Create' ? 'Create' : 'Update'}
                      </Button>
                    </SpaceBetween>
                  </SpaceBetween>
                </Form>
              </form>
            );
          }}
        </Formik>
      </SpaceBetween>
    </>
  );
};
