import {
  AppLayout,
  Button,
  ColumnLayout,
  ContentLayout,
  Flashbar,
  FlashbarProps,
  Link,
  SpaceBetween,
  StatusIndicator
} from '@amzn/awsui-components-react';
import Box from '@amzn/awsui-components-react/polaris/box';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import React, { useEffect, useState } from 'react';
import { useAppContext } from '../context/AppContextProvider';
import { useAuth } from '../context/AuthContext';
import { AppSideNavigation } from '../navigation/AppSideNavigation';
import { DocumentationLink, LandingPageSection } from './landing-page-constants';
import { VideoTutorials } from './VideoTutorials';
import BroadcastVideoTutorials from './BroadcastVideoTutorials';
import { eSIMLinks } from 'src/utils/DmmConstants';
import { ValueWithLabel } from '../generic-components/KeyValuePair';

const LandingPage = () => {
  const [navigationOpen, setNavigationOpen] = useState(false);
  const userAuthenticationDetails = useAuth();
  const appContext = useAppContext();
  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);

  useEffect(() => {
    if (!appContext?.userDetails.isActive) {
      let errorMessage = userAuthenticationDetails?.error
        ? userAuthenticationDetails?.error
        : "You are currently inactive. Use the button 'Request Access' to raise a ticket.";
      setFlashbarItems([
        {
          type: 'error',
          content: errorMessage,
          dismissible: false
        }
      ]);
    }
  }, [userAuthenticationDetails]);

  const requestAccess = () => {
    return (
      !appContext?.userDetails.isActive && (
        <Button href={eSIMLinks.REQUEST_ACCESS} target="_blank" iconAlign="right" iconName="external" variant="primary">
          Request Access
        </Button>
      )
    );
  };

  return (
    <>
      <Flashbar items={flashbarItems} />
      <AppLayout
        headerSelector="#h"
        navigation={<AppSideNavigation />}
        navigationOpen={navigationOpen}
        onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
        toolsHide
        content={
          <Box margin={{ top: 'xl' }} className="landing-page-content">
            <ContentLayout
              header={
                <Header variant="h1" description={LandingPageSection.MainHeaderDescription} actions={requestAccess()}>
                  {LandingPageSection.MainHeader}
                </Header>
              }
            >
              <Container header={<Header variant="h2">{LandingPageSection.HowItWorks}</Header>}>
                <Box variant="p" color="text-body-secondary">
                  {LandingPageSection.HowItWorksDescription}
                </Box>
              </Container>

              {/* Benefits and features */}
              <Box margin={{ top: 'l' }}>
                <SpaceBetween size="l" direction="vertical">
                  <Header>{LandingPageSection.BenefitsAndFeatures}</Header>
                  <SpaceBetween direction="horizontal" size="l" className="benefits-and-features-container">
                    <Container header={<Header>{LandingPageSection.BenefitsAndFeaturesSingleSource}</Header>}>
                      <Box variant="p" color="text-body-secondary">
                        {LandingPageSection.BenefitsAndFeaturesSingleSourceDescription}
                      </Box>
                    </Container>
                    <Container header={<Header>{LandingPageSection.BenefitsAndFeaturesReportsAndAnalytics}</Header>}>
                      <Box variant="p" color="text-body-secondary">
                        {LandingPageSection.BenefitsAndFeaturesReportsAndAnalyticsDescription}
                      </Box>
                    </Container>
                  </SpaceBetween>
                </SpaceBetween>
              </Box>

              {/* More Resources */}
              <Box margin={{ top: 'l' }}>
                <Container header={<Header headingTagOverride="h3">{LandingPageSection.MoreResources}</Header>}>
                  <SpaceBetween size="l">
                    <ValueWithLabel label="">
                      <Link href={DocumentationLink} external={true} variant="primary">
                        Documentation
                      </Link>
                    </ValueWithLabel>
                    <ValueWithLabel label="">
                      <Link href={eSIMLinks.ISSUE_REPORT} external={true} variant="primary">
                        Contact Tool Support
                      </Link>
                    </ValueWithLabel>
                  </SpaceBetween>
                </Container>
              </Box>

              {/* Tutorials  */}
              <Box margin={{ top: 'l' }}>
                <Container header={<Header>{'Tutorials'}</Header>}>
                  <BroadcastVideoTutorials />
                </Container>
              </Box>
            </ContentLayout>
          </Box>
        }
      />
    </>
  );
};

export default LandingPage;
