import {
  DECIMAL_REGEX,
  VALIDATION_FAILED,
  VALIDATION_SUCCESS,
  ValidationEntity,
  checkIfAllFieldsArePresent,
  excludeValuesFromArray
} from 'src/utils/CommonHelpers';
import { logger } from 'src/utils/Logger';
import { FixedColumnInformation } from './ForecastModel';

// Excel file Import Validations

export const validateHeaderData = (fileHeader: string[], fixedColumnInfo: FixedColumnInformation): Promise<ValidationEntity> => {
  const fieldsToHaveAndInThisOrder = fixedColumnInfo.campaignColumns.concat(
    fixedColumnInfo.fpnaDimensionKeys,
    fixedColumnInfo.corpDimensions,
    fixedColumnInfo.actualMonths,
    fixedColumnInfo.forecastMonths
  );

  logger.info('Forecast excel import fileHeader ', { info: fileHeader });
  logger.info('Forecast excel import fileHeaderToBe ', { info: fieldsToHaveAndInThisOrder });

  return new Promise((resolve, reject) => {
    try {
      if (
        fileHeader.length === fieldsToHaveAndInThisOrder.length &&
        fileHeader.every((element, index) => element === fieldsToHaveAndInThisOrder[index])
      ) {
        return resolve({
          ...VALIDATION_SUCCESS,
          validationMessage: 'Valid Headers'
        });
      } else {
        return resolve({
          ...VALIDATION_FAILED,
          validationMessage: 'Invalid Headers'
        });
      }
    } catch (error) {
      return reject({
        ...VALIDATION_FAILED,
        validationMessage: 'Unable to validate Headers'
      });
    }
  });
};

export const validateRequiredFields = (uploadFileHeader: string[], uploadFileData: any[]): Promise<ValidationEntity> => {
  return new Promise((resolve, reject) => {
    const optionalFields = excludeValuesFromArray(uploadFileHeader, ['productLineCampaignCorpId', 'productLineCampaignId']);
    const allValuesPresent = checkIfAllFieldsArePresent(uploadFileData, optionalFields);
    return resolve(
      allValuesPresent
        ? {
            ...VALIDATION_SUCCESS,
            validationMessage: 'Has all required fields'
          }
        : {
            ...VALIDATION_FAILED,
            validationMessage: 'Please make sure all required fields (productLineCampaignCorpId, productLineCampaignId) are present'
          }
    );
  });
};

export const ValidateProductLineCampaignAndCorpId = (
  uploadFileHeader: string[],
  uploadFileData: any[],
  forecastTableRowData: any[]
): Promise<ValidationEntity> => {
  return new Promise((resolve, reject) => {
    try {
      const masterArray = forecastTableRowData?.map((obj) => {
        return {
          productLineCampaignCorpId: obj.productLineCampaignCorpId,
          productLineCampaignId: obj.productLineCampaignId
        };
      });
      const targetArray = uploadFileData?.map((obj) => {
        return {
          productLineCampaignCorpId: obj.productLineCampaignCorpId,
          productLineCampaignId: obj.productLineCampaignId
        };
      });

      // Removing this validation which mandates User has to upload all Campaigns.
      // JSON.stringify(masterArray) === JSON.stringify(targetArray) ?
      //     resolve({
      //         ...VALIDATION_SUCCESS,
      //         validationMessage: "Has valid productLineCampaignCorpId and productLineCampaignId values",
      //     }) : resolve({
      //         ...VALIDATION_FAILED,
      //         validationMessage: "Please make sure the fields productLineCampaignCorpId and productLineCampaignId belongs to the selected Campaign and they aren't changed.",
      //     })

      // Validation
      // Validate if uploading rows is exist in active campaign. User can upload only few Campaigns instead all.
      const allObjectsExistInMaster = targetArray.every((targetObj) => {
        return masterArray.find((masterObj) => {
          return (
            targetObj.productLineCampaignId === masterObj.productLineCampaignId &&
            targetObj.productLineCampaignCorpId === masterObj.productLineCampaignCorpId
          );
        });
      });

      return allObjectsExistInMaster
        ? resolve({
            ...VALIDATION_SUCCESS,
            validationMessage: 'Has valid productLineCampaignCorpId and productLineCampaignId values'
          })
        : resolve({
            ...VALIDATION_FAILED,
            validationMessage:
              "Please ensure that the data corresponds to the selected filters and that the fields 'productLineCampaignCorpId' and 'productLineCampaignId' remain unchanged."
          });
    } catch (error: any) {
      logger.error('Unable to validate the uploaded data. ', error);
      resolve({
        ...VALIDATION_FAILED,
        validationMessage: 'Has invalid data'
      });
    }
  });
};

export const ValidateDuplicateProductLineCampaignAndCorpId = (
  uploadFileHeader: string[],
  uploadFileData: any[],
  forecastTableRowData: any[]
): Promise<ValidationEntity> => {
  return new Promise((resolve, reject) => {
    try {
      const targetArray = uploadFileData?.map((obj) => {
        return {
          productLineCampaignCorpId: obj.productLineCampaignCorpId,
          productLineCampaignId: obj.productLineCampaignId
        };
      });

      // check if an array of objects have duplicates in it.
      return !hasDuplicates(targetArray)
        ? resolve({
            ...VALIDATION_SUCCESS,
            validationMessage: 'No duplicates'
          })
        : resolve({
            ...VALIDATION_FAILED,
            validationMessage: 'Found duplicate records for the fields productLineCampaignCorpId and productLineCampaignId'
          });
    } catch (error: any) {
      logger.error('Unable to validate the duplicate data. ', error);
      resolve({
        ...VALIDATION_FAILED,
        validationMessage: 'Unable to validate the duplicate data.'
      });
    }
  });
};

const hasDuplicates = (arr: any[]) => {
  const valuesSoFar = new Set();
  for (const obj of arr) {
    const stringified = JSON.stringify(obj);
    if (valuesSoFar.has(stringified)) {
      return true;
    }
    valuesSoFar.add(stringified);
  }
  return false;
};

// Validating the data before Submit to backend.
// Validating is all the forecast month values are either null or valid decimal.
export const ValidateData = (fixedColumnInfo: FixedColumnInformation, rowData: any[]) => {
  const fieldsToHaveAndInThisOrder = fixedColumnInfo.forecastMonths;
  const filteredFields: any[] = [];
  rowData.forEach((row) => {
    const result = fieldsToHaveAndInThisOrder.reduce((acc: any, key: string) => {
      if (row.hasOwnProperty(key)) {
        acc[key] = row[key];
      }
      return acc;
    }, {});
    filteredFields.push(result);
  });
  // check if all field values are either null or valid decimal
  const allFieldsValid = filteredFields.every((obj) => Object.values(obj).every((val: any) => val === null || DECIMAL_REGEX.test(val)));
  return allFieldsValid;
};
