import { Alert, AppLayout, Box, Container, ContentLayout, Flashbar, FlashbarProps, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect, useRef, useState } from 'react';
import { BaseBreadcrumbs, eUserTablePreferenceKeys } from 'src/utils/DmmConstants';
import { LoadingStatus, MultiSelectGroupDropdownModel, PlanningCycleDataModel, ProductLine } from '../context/AppContextModel';
import { useAuth } from '../context/AuthContext';
import { AppBreadcrumb } from '../navigation/AppBreadcrumb';
import { AppSideNavigation } from '../navigation/AppSideNavigation';
import { ForecastDetailsSelection } from './ForecastDetailsSelection';
import { ForecastTemplateInitialData } from './ForecastTemplateContext';
import { ForecastTemplateTable, ForecastTemplateTableMethods } from './ForecastTemplateTable';
import { ForecastTemplateToolBar } from './ForecastTemplateToolBar';
import { ForecastTemplateStatus } from './ForecastModel';
import * as ForecastUtils from './ForecastUtils';
import { getRemainingTimeDetails, isAfterCurrentTime } from 'src/utils/DateTimeUtils';
import { isDefinedAndNotEmptyObject } from 'src/utils/CommonHelpers';
import { logger } from 'src/utils/Logger';
import { usePersistedState } from '../commons/CustomHooks/use-local-storage';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from '../generic-components/ErrorFallback';

const getUserBreadcrumbs = () => {
  return [
    ...BaseBreadcrumbs,
    {
      text: 'Forecast Template',
      href: '/forecast-template'
    }
  ];
};

export const ForecastTemplate = () => {
  const userDetails = useAuth();
  const [toolsOpen, setToolsOpen] = useState(false);
  const [navigationOpen, setNavigationOpen] = useState(true);

  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);
  const [forecastNotification, setForecastNotification] = useState<string>('');

  const [selectedProductLine, setSelectedProductLine] = usePersistedState(
    eUserTablePreferenceKeys.FORECAST_TEMPLATE_SELECTED_PRODUCT_LINE,
    {} as ProductLine
  );
  const [selectedPlanningCycle, setSelectedPlanningCycle] = usePersistedState(
    eUserTablePreferenceKeys.FORECAST_TEMPLATE_SELECTED_PLANNING_CYCLE,
    {} as PlanningCycleDataModel
  );

  const [selectedCampaignDropdown, setSelectedCampaignDropdown] = usePersistedState(
    eUserTablePreferenceKeys.FORECAST_TEMPLATE_CAMPAIGN_OPTION,
    [] as MultiSelectGroupDropdownModel[]
  );
  const [selectedCountryDropdown, setSelectedCountryDropdown] = usePersistedState(
    eUserTablePreferenceKeys.FORECAST_TEMPLATE_COUNTRY_OPTION,
    [] as MultiSelectGroupDropdownModel[]
  );
  const [selectedRevenueEstimateDropdown, setSelectedRevenueEstimateDropdown] = usePersistedState(
    eUserTablePreferenceKeys.FORECAST_TEMPLATE_REVENUE_ESTIMATE_OPTION,
    [] as MultiSelectGroupDropdownModel[]
  );

  const [fullForecastData, setFullForecastData] = useState<any[]>([]);
  const [forecastTemplateStatus, setForecastTemplateStatus] = useState<ForecastTemplateStatus>({
    forecastStatus: 'pending'
  } as ForecastTemplateStatus);

  const forecastTemplateTableRef = useRef<ForecastTemplateTableMethods>(null);

  useEffect(() => {
    if (isDefinedAndNotEmptyObject(selectedPlanningCycle) && isDefinedAndNotEmptyObject(forecastTemplateStatus)) {
      if (!forecastTemplateStatus.isActive) {
        const intervalId = setInterval(() => {
          const finalLockDate = ForecastUtils.getFinalLockDate(selectedPlanningCycle, userDetails.Alias);
          const notificationMessage = isAfterCurrentTime(finalLockDate)
            ? `Reminder: You have ${getRemainingTimeDetails(finalLockDate)} to submit the forecast.`
            : '';
          setForecastNotification(notificationMessage);
        }, 1000);
        return () => clearInterval(intervalId);
      } else {
        const notificationMessage = `The forecast planning cycle has been approved, and as such, any further changes are not permitted at this time.`;
        setForecastNotification(notificationMessage);
      }
    }
  }, [selectedPlanningCycle, forecastTemplateStatus]);

  useEffect(() => {
    if (
      isDefinedAndNotEmptyObject(selectedProductLine) &&
      isDefinedAndNotEmptyObject(selectedPlanningCycle) &&
      isDefinedAndNotEmptyObject(forecastTemplateStatus)
    ) {
      forecastTemplateTableRef?.current?.refreshForecast();
    }
  }, [forecastTemplateStatus]);

  useEffect(() => {
    if (isDefinedAndNotEmptyObject(selectedPlanningCycle) && isDefinedAndNotEmptyObject(selectedProductLine)) {
      logger.info(
        `User selected product line - ${selectedProductLine?.productID} 
        and planning cycle with scenario Template Id - 
        ${selectedPlanningCycle?.scenarioTemplateId} `
      );
      forecastTemplateTableRef?.current?.refreshForecast();
    }
  }, [selectedPlanningCycle, selectedProductLine]);

  useEffect(() => {
    forecastTemplateTableRef?.current?.loadPartialData([]);
  }, [selectedCountryDropdown, selectedCampaignDropdown, selectedRevenueEstimateDropdown]);

  const displayFlashMessage = (content: string, flashBarType: FlashbarProps.Type = 'info', isDismissible: boolean = true) => {
    setFlashbarItems(
      content
        ? [
            {
              type: flashBarType,
              content: content,
              dismissible: isDismissible,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashbarItems([])
            }
          ]
        : []
    );
  };

  const handleToolOpen = (isOpen: boolean) => {
    setToolsOpen(isOpen);
  };

  return (
    <>
      {userDetails.userAuthDataLoadingStatus === LoadingStatus.Completed && (
        <>
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              window.location.reload();
            }}
          >
            <AppLayout
              contentType="default"
              headerSelector="#h"
              notifications={
                <>
                  {forecastNotification != '' && (
                    <Alert statusIconAriaLabel="Info" type="info">
                      {forecastNotification}
                    </Alert>
                  )}
                  <Flashbar items={flashbarItems} />
                </>
              }
              navigation={<AppSideNavigation />}
              onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
              breadcrumbs={<AppBreadcrumb items={getUserBreadcrumbs()} />}
              tools={<ForecastTemplateToolBar />}
              onToolsChange={({ detail }) => setToolsOpen(detail.open)}
              toolsOpen={toolsOpen}
              disableContentPaddings
              content={
                <ForecastTemplateInitialData.Provider
                  value={{
                    selectedProductLine,
                    setSelectedProductLine,
                    selectedPlanningCycle,
                    setSelectedPlanningCycle,
                    selectedCountryDropdown,
                    setSelectedCountryDropdown,
                    selectedCampaignDropdown,
                    setSelectedCampaignDropdown,
                    selectedRevenueEstimateDropdown,
                    setSelectedRevenueEstimateDropdown,
                    displayFlashMessage,
                    forecastTemplateStatus,
                    setForecastTemplateStatus,
                    fullForecastData,
                    setFullForecastData
                  }}
                >
                  <ContentLayout>
                    <Box
                      margin={{ bottom: 'xl', left: 'xxxl', right: 'xxxl' }}
                      padding={navigationOpen ? { right: 'xxl' } : { left: 'xxxl', right: 'xxl' }}
                    >
                      <SpaceBetween size="m" direction="vertical">
                        <ForecastDetailsSelection onToolOpen={handleToolOpen} />
                        <Box padding={{ bottom: 'xs' }}>
                          <ForecastTemplateTable ref={forecastTemplateTableRef} />
                        </Box>
                      </SpaceBetween>
                    </Box>
                  </ContentLayout>
                </ForecastTemplateInitialData.Provider>
              }
            />
          </ErrorBoundary>
        </>
      )}
    </>
  );
};
