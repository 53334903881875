import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks';
import { Checkbox, CollectionPreferences, CollectionPreferencesProps, TableProps } from '@amzn/awsui-components-react';
import React from 'react';
import { getCurrentUserLocalTimeReadableFormat } from 'src/utils/DateTimeUtils';
import { PAGE_SIZE_OPTIONS, convertNumberToUSD } from 'src/utils/CommonHelpers';
import { addColumnSortLabels } from '../../utils/labels';

const SORTABLE_COLUMNS: any = [];

export const PURCHASE_ORDER_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<any>[] = addColumnSortLabels(
  [
    {
      id: 'campaignId',
      sortingField: 'campaignId',
      header: 'Campaign Id',
      cell: (item: any) => item.campaignId,
      maxWidth: 50
    },
    {
      id: 'campaignMapId',
      sortingField: 'campaignMapId',
      header: 'Campaign Map Id',
      cell: (item: any) => item.campaignMapId,
      maxWidth: 50
    },
    {
      id: 'campaignName',
      sortingField: 'campaignName',
      header: 'Campaign name',
      cell: (item: any) => item.campaignName || '-',
      minWidth: 150
    },
    {
      id: 'poNumber',
      sortingField: 'poNumber',
      header: 'PO #',
      cell: (item: any) => item.poNumber,
      minWidth: 100
    },
    {
      id: 'poAmountUsd',
      sortingField: 'poAmountUsd',
      header: 'Amount (USD)',
      cell: (item: any) => (item.poAmountUsd ? convertNumberToUSD(item.poAmountUsd) : '-'),
      minWidth: 100
    },
    {
      id: 'accountNumber',
      sortingField: 'accountNumber',
      header: 'Account',
      cell: (item: any) => item.accountNumber,
      minWidth: 20
    },
    {
      id: 'costCenter',
      sortingField: 'costCenter',
      header: 'Cost Center',
      cell: (item: any) => item.costCenter,
      minWidth: 20
    },
    {
      id: 'channelCode',
      sortingField: 'channelCode',
      header: 'Channel Code',
      cell: (item: any) => item.channelCode,
      minWidth: 20
    },
    {
      id: 'companyCode',
      sortingField: 'companyCode',
      header: 'Company Code',
      cell: (item: any) => item.companyCode,
      minWidth: 20
    },
    {
      id: 'lineDescription',
      sortingField: 'lineDescription',
      header: 'Line Description',
      cell: (item: any) => item.lineDescription
    },
    {
      id: 'locationCode',
      sortingField: 'locationCode',
      header: 'Location Code',
      cell: (item: any) => item.locationCode
    },
    {
      id: 'ledgerName',
      sortingField: 'ledgerName',
      header: 'Ledger Name',
      cell: (item: any) => item.ledgerName
    },
    {
      id: 'journalName',
      sortingField: 'journalName',
      header: 'Journal Name',
      cell: (item: any) => item.journalName
    },
    {
      id: 'productCode',
      sortingField: 'productCode',
      header: 'Product Code',
      cell: (item: any) => item.productCode
    },
    {
      id: 'poRequester',
      sortingField: 'poRequester',
      header: 'PO Requester',
      cell: (item: any) => item.poRequester
    },
    {
      id: 'vendorNumber',
      sortingField: 'vendorNumber',
      header: 'Vendor #',
      cell: (item: any) => item.vendorNumber
    },
    {
      id: 'q1EfficiencyResults',
      sortingField: 'q1EfficiencyResults',
      header: 'Q1 Efficiency Results',
      cell: (item: any) => item.q1EfficiencyResults
    },
    {
      id: 'q2EfficiencyResults',
      sortingField: 'q2EfficiencyResults',
      header: 'Q2 Efficiency Results',
      cell: (item: any) => item.q2EfficiencyResults
    },
    {
      id: 'q3EfficiencyResults',
      sortingField: 'q3EfficiencyResults',
      header: 'Q3 Efficiency Results',
      cell: (item: any) => item.q3EfficiencyResults
    },
    {
      id: 'q4EfficiencyResults',
      sortingField: 'q4EfficiencyResults',
      header: 'Q4 Efficiency Results',
      cell: (item: any) => item.q4EfficiencyResults
    },
    {
      id: 'updatedBy',
      sortingField: 'updatedBy',
      cell: (item: any) => item.updatedBy,
      header: 'Updated By',
      minWidth: 100
    },
    {
      id: 'updatedTime',
      sortingField: 'updatedTime',
      cell: (item: any) => getCurrentUserLocalTimeReadableFormat(item.updatedTime),
      header: 'Updated Time',
      minWidth: 100
    }
  ],
  SORTABLE_COLUMNS
);

export const VISIBLE_CONTENT = [
  'campaignName',
  'poNumber',
  'poAmountUsd',
  'lineDescription',
  'accountNumber',
  'channelCode',
  'costCenter',
  'companyCode'
];

export const VISIBLE_CONTENT_OPTIONS: CollectionPreferencesProps.VisibleContentOptionsGroup[] = [
  {
    label: 'Properties',
    options: [
      { id: 'campaignMapId', label: 'Campaign Map Id' },
      { id: 'campaignId', label: 'Id' },
      { id: 'campaignName', label: 'Campaign Name' },
      { id: 'poNumber', label: 'PO Number' },
      { id: 'poAmountUsd', label: 'Amount (USD)' },
      { id: 'costCenter', label: 'Cost Center' },
      { id: 'accountNumber', label: 'Account' },
      { id: 'channelCode', label: 'Channel Code' },
      { id: 'companyCode', label: 'Company Code' },
      { id: 'lineDescription', label: 'Line Description' },
      { id: 'journalName', label: 'Journal Name' },
      { id: 'ledgerName', label: 'Ledger Name' },
      { id: 'locationCode', label: 'Location Code' },
      { id: 'poRequester', label: 'PO Requester' },
      { id: 'vendorNumber', label: 'Vendor Number' },
      { id: 'q1EfficiencyResults', label: 'Q1 Efficiency Results' },
      { id: 'q2EfficiencyResults', label: 'Q2 Efficiency Results' },
      { id: 'q3EfficiencyResults', label: 'Q3 Efficiency Results' },
      { id: 'q4EfficiencyResults', label: 'Q4 Efficiency Results' },
      { id: 'updatedBy', label: 'Updated By' },
      { id: 'updatedTime', label: 'Updated Time' }
    ]
  }
];

export const PURCHASE_ORDERS_FILTERING_PROPERTIES: PropertyFilterProperty[] = [
  {
    propertyLabel: 'Campaign name',
    key: 'campaignName',
    groupValuesLabel: 'campaign values',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'PO Number',
    key: 'poNumber',
    groupValuesLabel: 'po number values',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'Account',
    key: 'accountNumber',
    groupValuesLabel: 'Account values',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'Cost Center',
    key: 'costCenter',
    groupValuesLabel: 'cost center values',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'Channel Code',
    key: 'channelCode',
    groupValuesLabel: 'channel code values',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'Company Code',
    key: 'companyCode',
    groupValuesLabel: 'company code values',
    operators: [':', '!:', '=', '!=']
  },
  {
    propertyLabel: 'Updated by',
    key: 'updatedBy',
    groupValuesLabel: 'Updated by values',
    operators: [':', '!:', '=', '!=']
  }
];

export const PURCHASE_ORDER_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences = {
  pageSize: 20,
  visibleContent: VISIBLE_CONTENT,
  wrapLines: false,
  stripedRows: false,
  custom: false
};

export const PurchaseOrderTablePreferences = ({
  preferences,
  setPreferences,
  disabled,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  visibleContentOptions = VISIBLE_CONTENT_OPTIONS
}: any) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: 'Page size',
      options: pageSizeOptions
    }}
    wrapLinesPreference={{
      label: 'Wrap lines',
      description: 'Check to see all the text and wrap the lines'
    }}
    stripedRowsPreference={{
      label: 'Striped rows',
      description: 'Check to add alternating shaded rows'
    }}
    visibleContentPreference={{
      title: 'Select visible columns',
      options: visibleContentOptions
    }}
    customPreference={(checked, setChecked) => (
      <Checkbox
        onChange={({ detail }) => {
          setChecked(detail.checked);
        }}
        checked={checked}
      >
        Resizable Columns
      </Checkbox>
    )}
  />
);
