import React from 'react';
import { HelpPanel, Link } from '@amzn/awsui-components-react';
import { eSIMLinks } from 'src/utils/DmmConstants';

export const CampaignToolContent = () => {
  return (
    <HelpPanel
      header={<h2>Campaigns</h2>}
      footer={
        <>
          <h3>Contact Us</h3>
          <ul>
            <li>
              <Link external href={eSIMLinks.ISSUE_REPORT}>
                Contact Tool Support
              </Link>
            </li>
          </ul>
        </>
      }
    >
      <h3>Description</h3>
      <p>
        This controls each product line’s campaigns (marketing programs) and metrics to drive planning and actualized marketing spend. This is how
        marketing spend categories (metrics) are collected.
      </p>

      <h3>How to use</h3>
      <div>
        <h4>View Campaigns</h4>
        <ol>
          <li>Select the desired Product Line.</li>
          <li>Once the Product Line is selected, the Campaign and Country dropdowns will be automatically populated.</li>
          <li>Choose the specific campaigns you wish to view in the table.</li>
          <li>Select the country you want to view in the table.</li>
          <li>The table displays records that represent the combination of the selected Campaign and Country.</li>
        </ol>

        <h4>Add/Edit campaign</h4>
        <ol>
          <li>Create a campaign name to cover the marketing program being tracked.</li>
          <li>Fill out the campaign’s metrics.</li>
          <li>If you want to restrict the forecasting of the campaign's spend to specific individuals, assign a business owner.</li>
          <li>Click "Submit" to save the changes.</li>
        </ol>

        <h4>Activate/Deactivate campaign</h4>
        <ul>
          <li>Toggle the status to Activate/Deactivate the campaign.</li>
        </ul>
      </div>
    </HelpPanel>
  );
};
