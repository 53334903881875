import React from 'react';
import { HelpPanel, Link } from '@amzn/awsui-components-react';
import { eSIMLinks } from 'src/utils/DmmConstants';
import { DocumentationLink } from 'src/components/landing-page/landing-page-constants';

export const PurchaseOrderToolContent = () => {
  return (
    <HelpPanel
      header={<h2>Purchase Orders</h2>}
      footer={
        <>
          <h3>Contact Us</h3>
          <ul>
            <li>
              <Link external href={eSIMLinks.ISSUE_REPORT}>
                Contact Tool Support
              </Link>
            </li>
          </ul>
        </>
      }
    >
      <h3>Description</h3>
      <p>
        This controls each product line’s purchase orders (PO). Those POs need to be associated to campaigns (marketing programs). This is to bridge
        reporting variations and provide meaningful insights to drive efficient spend.
      </p>

      <h3>How to use</h3>
      <div>
        <ol>
          <li>Select your product line.</li>
          <li>Select the actual's period.</li>
          <li>Select one or more POs to tag to a campaign.</li>
          <li>
            Fill out the{' '}
            <Link external href={DocumentationLink}>
              Efficiency Results
            </Link>
            .
          </li>
          <li>Save changes</li>
        </ol>
        <ul>
          <li>Once all POs in that period have been tagged the status will flip to complete.</li>
          <li>Future invoices/costs for a PO will automatically be tagged to the associated campaign.</li>
        </ul>
      </div>
    </HelpPanel>
  );
};
