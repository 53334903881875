import { TopNavigation, TopNavigationProps } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { APP_NAME, LDAP_DMM_DEV_TEAM } from 'src/utils/DmmConstants';
import { useAppContext } from '../context/AppContextProvider';
import { useAuth } from '../context/AuthContext';
import { DebuggerComponent } from '../debugger-component/DebuggerComponent';

export const AppTopNavigation = () => {
  const navigate = useNavigate();
  const userAuthenticationDetails = useAuth();
  const appContext = useAppContext();
  const [showModal, setShowModal] = useState(false);

  const appTitleClicked = (e: any) => {
    e.preventDefault();
    navigate({ pathname: '/' });
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const basicTopNavBarUtilities: TopNavigationProps.Utility[] = [
    {
      type: 'menu-dropdown',
      text: userAuthenticationDetails.DisplayName,
      description: userAuthenticationDetails.Email,
      iconName: 'user-profile',
      items: [
        {
          id: 'userTypeDescription',
          text: appContext.userDetails.userType?.userTypeDescription ? appContext.userDetails.userType?.userTypeDescription : ''
        }
      ]
    }
  ];

  const adminUserTopNavBar: TopNavigationProps.Utility[] = [
    {
      type: 'button',
      text: 'testing mode',
      iconName: 'bug',
      onClick: () => {
        setShowModal(true);
      }
    }
  ];

  return (
    <>
      <DebuggerComponent showModal={showModal} closeModal={() => closeModal()}></DebuggerComponent>
      <div id="h" data-test-id="top-navigation">
        <TopNavigation
          identity={{
            href: '#',
            title: APP_NAME,
            onFollow: appTitleClicked
          }}
          utilities={
            userAuthenticationDetails.UserLdaps.includes(LDAP_DMM_DEV_TEAM)
              ? adminUserTopNavBar.concat(basicTopNavBarUtilities)
              : basicTopNavBarUtilities
          }
          i18nStrings={{
            searchIconAriaLabel: 'Search',
            searchDismissIconAriaLabel: 'Close search',
            overflowMenuTriggerText: 'More',
            overflowMenuTitleText: 'All',
            overflowMenuBackIconAriaLabel: 'Back',
            overflowMenuDismissIconAriaLabel: 'Close menu'
          }}
        />
      </div>
    </>
  );
};
