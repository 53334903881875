import { DynamoDBClientConfig, DynamoDBClient } from '@aws-sdk/client-dynamodb';
import * as DynamoDB from '@aws-sdk/client-dynamodb';
import { marshall, unmarshall } from '@aws-sdk/util-dynamodb'; // SDK V3, but worked the same in V2
import { getCredentialsFromAWSService } from './AuthServices';

export async function getDynamoClientConfig() {
  const REGION: any = 'us-west-2';
  const dynamoDBClientConfig: DynamoDBClientConfig = {
    region: REGION,
    credentials: await getCredentialsFromAWSService()
  };
  return dynamoDBClientConfig;
}

export async function getAllItemsFromDynamoDb(tableName: string) {
  const getAllItemsInput: DynamoDB.ScanCommandInput = {
    TableName: tableName
  };
  const command = new DynamoDB.ScanCommand(getAllItemsInput);
  const dynamoDBClient = new DynamoDBClient(await getDynamoClientConfig());
  const response: DynamoDB.ScanCommandOutput = await dynamoDBClient.send(command);
  const unmarshalled = response.Items?.map((i) => unmarshall(i));
  return unmarshalled;
}

export async function putItemInDynamoDB(tableName: string, tableItem: any) {
  const putItemInput: DynamoDB.PutItemCommandInput = {
    TableName: tableName,
    Item: marshall(tableItem)
  };
  const command = new DynamoDB.PutItemCommand(putItemInput);
  const dynamoDBClient = new DynamoDBClient(await getDynamoClientConfig());
  const response: DynamoDB.PutItemCommandOutput = await dynamoDBClient.send(command);
  return response;
}

export async function getItemFromDynamoDB(tableName: string, key: any) {
  const getItemInput: DynamoDB.GetItemCommandInput = {
    TableName: tableName,
    Key: marshall({
      'msp-user-preference-key': key
    })
  };
  const command = new DynamoDB.GetItemCommand(getItemInput);
  const dynamoDBClient = new DynamoDBClient(await getDynamoClientConfig());
  const response: DynamoDB.GetItemCommandOutput = await dynamoDBClient.send(command);
  return response.Item ? unmarshall(response.Item) : null;
}

/*

export function getItemFromDynamoDB(tableName: string, key: any) {
    const getItemInput: DynamoDB.GetItemCommandInput = {
        TableName: tableName,
        Key: key
    };
    const command = new DynamoDB.GetItemCommand(getItemInput);
    const getConfig = getDynamoClientConfig();
    return getConfig.then(async (config) => {
        const dynamoDBClient = new DynamoDBClient(config);
        return dynamoDBClient.send(command).then((response) => {
            return response.Item ? unmarshall(response.Item) : null;
        })
    })
}


*/
