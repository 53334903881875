import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Input, Select, SpaceBetween, Form, FormField } from '@amzn/awsui-components-react';
import { Formik, FormikProps, FormikHelpers } from 'formik';
import { API } from 'aws-amplify';
import { logger } from 'src/utils/Logger';
import * as mutations from 'src/graphql/mutations';
import * as queries from 'src/graphql/queries';
import { DropdownModel } from '../campaigns/CampaignModels';
import { UserAuthContext } from '../context/AppContextModel';
import { useAuth } from '../context/AuthContext';
import { getValidationErrorMessage } from 'src/utils/labels';
import { useAppContext } from '../context/AppContextProvider';

export const mapCampaigns = (data: any[]) => {
  const result = data.map((value) => {
    return {
      label: value.campaignName,
      value: `${value.campaignId}`
    };
  });
  return result;
};

const FormInitialValues: any = {
  campaign: { label: '', value: '' },
  q1: null,
  q2: null,
  q3: null,
  q4: null
};

export const getSelectedFormValues = (data: any) => {
  const campaignId = data.campaignId ? `${data.campaignId}` : '';
  const campaignName = data.campaignName ? data.campaignName : '';
  let result = {
    campaign: { label: campaignName, value: campaignId },
    q1: data.q1EfficiencyResults,
    q2: data.q2EfficiencyResults,
    q3: data.q3EfficiencyResults,
    q4: data.q4EfficiencyResults
  };
  return result;
};

/*
 * takes purchase order form data values and list of POs
 * parses for modifying multiple POs
 */
export const parseFormValues = (formValues: any, selectedPurchaseOrderItems: any[], user: UserAuthContext, actualsMonth: string) => {
  let result: any[] = selectedPurchaseOrderItems.map((item) => ({
    campaignMapId: item.campaignMapId,
    campaignId: parseInt(formValues.campaign.value),
    efficiencyResults: {
      Q1: parseFloat(formValues.q1),
      Q2: parseFloat(formValues.q2),
      Q3: parseFloat(formValues.q3),
      Q4: parseFloat(formValues.q4)
    },
    monthId: actualsMonth,
    updatedBy: user.Alias,
    isActive: true
  }));
  return result;
};

/*
 * validates business logic for campaign purchase order mapping
 * q1, q2, q3, q4 are optional
 */
export const onValidateForm = (values: any) => {
  let errors: any = {};
  if (!values.campaign.label) errors['campaign'] = 'Required field';
  if (values.q1 && isNaN(values.q1)) errors['q1'] = 'Result should be numerical';
  if (values.q2 && isNaN(values.q2)) errors['q2'] = 'Result should be numerical';
  if (values.q3 && isNaN(values.q3)) errors['q3'] = 'Result should be numerical';
  if (values.q4 && isNaN(values.q4)) errors['q4'] = 'Result should be numerical';
  return errors;
};

export interface FuncProps {
  productLineId: number;
  actualsMonth: string;
  displayFlashMessage(notificationMessage: string, notificationType: string): void;
  updateTableHandler(): void;
  selectedPurchaseOrderItems: any[];
}

export const PurchaseOrderForm = (props: FuncProps) => {
  const formRef = useRef<FormikProps<any>>(null);
  const user = useAuth();
  const appContext = useAppContext();
  const [formValues, setFormValues] = useState(FormInitialValues);
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);
  const [productLineCampaignOptions, setProductLineCampaignOptions] = useState<DropdownModel[]>([]);
  const [productLineId, setProductLineId] = useState(0);
  const [purchaseOrders, setPurchaseOrders] = useState<any[]>([]);

  useEffect(() => {
    formRef.current?.resetForm();
    setFormValues(FormInitialValues);
    if (props.selectedPurchaseOrderItems.length == 1) {
      // pre-populate form since only editing 1
      let result = getSelectedFormValues(props.selectedPurchaseOrderItems[0]);
      setFormValues(result);
    }
    setPurchaseOrders(props.selectedPurchaseOrderItems);
    setProductLineId(props.productLineId);
  }, [props]);

  useEffect(() => {
    // initialize campaign data
    if (productLineId > 0 && props.actualsMonth) {
      getCampaigns();
    }
  }, [productLineId, props.actualsMonth]);

  const getCampaigns = async () => {
    try {
      const response: any = await API.graphql({
        query: queries.listProductLineCampaigns,
        variables: {
          dataClassificationId: appContext.userDetails.dataClassification.dataClassificationId,
          productLineId: productLineId
        }
      });
      setProductLineCampaignOptions(mapCampaigns(response.data.listProductLineCampaigns));
    } catch (err) {
      let errString = JSON.stringify(err);
      logger.error('Unable to load campaign data.', { error: errString });
      props.displayFlashMessage('There was a problem. Please retry. If this continues please contact support.', 'error');
    }
  };

  const handleSubmit = async (formValues: any, formikHelpers: FormikHelpers<any>) => {
    setIsSubmitButtonLoading(true);
    formikHelpers.setSubmitting(false);
    const form = parseFormValues(formValues, props.selectedPurchaseOrderItems, user, props.actualsMonth);
    try {
      const response = await API.graphql({
        query: mutations.updateCampaignsPurchaseOrderMapping,
        variables: { input: form }
      });
      formikHelpers.setSubmitting(false);
      setIsSubmitButtonLoading(false);
      props.displayFlashMessage("Successfully Tagged the PO's", 'success');
      props.updateTableHandler();
      formikHelpers.resetForm();
    } catch (error: any) {
      logger.error(`error for ${formValues.userAlias}`, error);
      props.displayFlashMessage(`Error submitting. Please try again or contact support.`, 'error');
      formikHelpers.setSubmitting(false);
    }
    setIsSubmitButtonLoading(false);
  };

  return (
    <>
      {props.selectedPurchaseOrderItems?.length === 0 && <Box variant="h4">{'Select one or more purchase orders to tag'}</Box>}
      {props.selectedPurchaseOrderItems?.length > 0 && (
        <SpaceBetween size="xs">
          <Formik enableReinitialize={true} initialValues={formValues} validate={onValidateForm} onSubmit={handleSubmit}>
            {({ values, touched, errors, setFieldValue, handleSubmit, isSubmitting }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Form>
                    <SpaceBetween size="l" direction="vertical">
                      <FormField label={'Select campaign'} errorText={getValidationErrorMessage(touched.campaign, errors.campaign)}>
                        <Select
                          selectedOption={values.campaign}
                          onChange={(event) => setFieldValue('campaign', event.detail.selectedOption)}
                          options={productLineCampaignOptions}
                          placeholder="Choose an option"
                          filteringType="auto"
                          selectedAriaLabel="Selected"
                        />
                      </FormField>
                      <div>
                        <Box variant="awsui-key-label">Efficiency Results</Box>
                        <SpaceBetween size="l" direction="vertical">
                          <FormField label="Q1 (USD)" errorText={getValidationErrorMessage(touched.q1, errors.q1)}>
                            <Input
                              value={values.q1}
                              onChange={(event) => setFieldValue('q1', event.detail.value)}
                              placeholder="Enter amount in USD"
                            />
                          </FormField>
                          <FormField label="Q2 (USD)" errorText={getValidationErrorMessage(touched.q2, errors.q2)}>
                            <Input
                              value={values.q2}
                              onChange={(event) => setFieldValue('q2', event.detail.value)}
                              placeholder="Enter amount in USD"
                            />
                          </FormField>
                          <FormField label="Q3 (USD)" errorText={getValidationErrorMessage(touched.q3, errors.q3)}>
                            <Input
                              value={values.q3}
                              onChange={(event) => setFieldValue('q3', event.detail.value)}
                              placeholder="Enter amount in USD"
                            />
                          </FormField>
                          <FormField label="Q4 (USD)" errorText={getValidationErrorMessage(touched.q4, errors.q4)}>
                            <Input
                              value={values.q4}
                              onChange={(event) => setFieldValue('q4', event.detail.value)}
                              placeholder="Enter amount in USD"
                            />
                          </FormField>
                        </SpaceBetween>
                      </div>
                      <Button loading={isSubmitButtonLoading} variant="primary" disabled={isSubmitting} formAction={'submit'}>
                        Submit
                      </Button>
                    </SpaceBetween>
                  </Form>
                </form>
              );
            }}
          </Formik>
        </SpaceBetween>
      )}
    </>
  );
};
