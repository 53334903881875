import React from 'react';
import { HelpPanel, Link } from '@amzn/awsui-components-react';
import { eSIMLinks } from 'src/utils/DmmConstants';

export const ReportingForecastStatusToolContent = () => {
  return (
    <HelpPanel
      header={<h2>More Info</h2>}
      footer={
        <div>
          <div>
            <h3>Contact Us</h3>
            <ul>
              <li>
                <Link external href={eSIMLinks.ISSUE_REPORT}>
                  Contact Tool Support
                </Link>
              </li>
            </ul>
          </div>
        </div>
      }
    >
      <h3>Description</h3>
      <p>
        Product line status report provides the template status for each planning cycle. At a glance, you can see which product lines have been
        completed and approved by the product line lead and which are still unfinished.
      </p>

      <h3>How to use</h3>
      <div>
        <ol>
          <li>Select the planning cycle you wish to view</li>
        </ol>
      </div>
    </HelpPanel>
  );
};
