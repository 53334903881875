import * as mutations from 'src/graphql/mutations';
import { convertPacificTimeToUTC, splitDateTime } from 'src/utils/DateTimeUtils';
import { BaseBreadcrumbs } from 'src/utils/DmmConstants';
import * as yup from 'yup';
import { DropdownModel, PlanningCycleDataModel } from '../context/AppContextModel';
import { ScenarioResourceFormModel } from './PlanningCycleModels';

export const getResourceScenarioBreadcrumbs = (type: string) => {
  return [
    ...BaseBreadcrumbs,
    {
      text: 'Planning cycle',
      href: '/planning-cycle'
    },
    {
      text: `${type}`,
      href: '#!'
    }
  ];
};

/*
 * Actuals must be prev
 */
export const validateActualsDateRange = (date: Date) => {
  const currentMonthDate = new Date();
  return date < currentMonthDate;
};

/*
 * Forecast must be future
 */
export const validateForecastDateRange = (date: Date) => {
  const currentMonthDate = new Date();
  return date > currentMonthDate;
};

/*
 * gets scenarioTemplateId from path param
 * if undefined pass 0
 */
export const getScenarioTemplateId = (scenarioTemplateId: string | undefined) => {
  let result = typeof scenarioTemplateId !== 'undefined' ? parseInt(scenarioTemplateId) : 0;
  return result;
};

export const InitialFormValues = {
  scenarioTemplateId: null,
  scenarioName: {} as DropdownModel,
  actualsDateRange: null,
  forecastDateRange: null,
  toolWindowDateRange: null,
  isExtended: false,
  datePickerValue: '',
  timePickerValue: '',
  extendedLockDateTime: '',
  extendedToUsers: [],
  isActive: true
} as ScenarioResourceFormModel;

const REQUIRED_FIELD = 'Required field';
const yupRequiredDropdownField = () => {
  return yup.object().shape({
    label: yup.string().required(REQUIRED_FIELD)
  });
};
export const createScenarioValidationSchema = yup.object().shape({
  scenarioName: yupRequiredDropdownField(),
  forecastDateRange: yup.object().nullable().required('Forecast is required'),
  toolWindowDateRange: yup
    .object()
    .shape({
      startDate: yup.string().required('Tool window is required')
    })
    .nullable()
    .required('Tool window is required')
});

/*
 * checks if data was successfully mutated in db
 * by checking number of records updated
 */
export const getNotificationResponse = (response: any, queryName: string) => {
  if (response.data[queryName].numberOfRecordsUpdated >= 1) {
    return [
      {
        type: 'success',
        loading: false,
        content: 'Succeeded'
      }
    ];
  }
  return [
    {
      type: 'error',
      loading: false,
      content: 'There was nothing to do.'
    }
  ];
};

export const getFormDetails = (type: string) => {
  if (type === 'edit') {
    return {
      header: 'Edit planning cycle scenario',
      description: 'When you edit a planning cycle scenario, this will impact the current spend plan templates',
      formButtonName: 'Save Changes',
      formSubmitQuery: mutations.updatePlanningCycle,
      formQueryName: 'updatePlanningCycle'
    };
  }
  return {
    header: 'Create planning cycle scenario',
    description: 'When you create a new planning cycle scenario, you specify what scenario and when users load spend plans',
    formButtonName: 'Submit',
    formSubmitQuery: mutations.createPlanningCycle,
    formQueryName: 'createPlanningCycle'
  };
};

// API to FORM
export const parseCurrentPlanningCycleToFormModel = (getCurrentPlanningCycleResponse: PlanningCycleDataModel) => {
  const userDropdown = getCurrentPlanningCycleResponse?.extendedToUsers?.map((user) => {
    return { label: user, value: user };
  });
  let result = {
    scenarioTemplateId: getCurrentPlanningCycleResponse?.scenarioTemplateId,
    scenarioName: getCurrentPlanningCycleResponse?.scenario.scenarioId
      ? {
          value: `${getCurrentPlanningCycleResponse?.scenario.scenarioId}`,
          label: getCurrentPlanningCycleResponse?.scenario.scenarioName
        }
      : { label: '', value: '' },
    forecastDataAvailable: getCurrentPlanningCycleResponse.forecastDataAvailable || false,
    baselineScenario: getCurrentPlanningCycleResponse.baselineScenario
      ? {
          value: `${
            getCurrentPlanningCycleResponse?.baselineScenario.baselineScenarioTemplateId
              ? getCurrentPlanningCycleResponse?.baselineScenario.baselineScenarioTemplateId
              : ''
          }`,
          label: getCurrentPlanningCycleResponse?.baselineScenario.baselineScenarioName
            ? getCurrentPlanningCycleResponse?.baselineScenario.baselineScenarioName
            : ''
        }
      : { label: '', value: '' },
    actualsDateRange: getCurrentPlanningCycleResponse?.actualsStartMonth
      ? {
          type: 'absolute',
          startDate: getCurrentPlanningCycleResponse?.actualsStartMonth,
          endDate: getCurrentPlanningCycleResponse?.actualsEndMonth
        }
      : null,
    forecastDateRange: getCurrentPlanningCycleResponse?.forecastStartMonth
      ? {
          type: 'absolute',
          startDate: getCurrentPlanningCycleResponse?.forecastStartMonth,
          endDate: getCurrentPlanningCycleResponse?.forecastEndMonth
        }
      : null,
    toolWindowDateRange: getCurrentPlanningCycleResponse?.openToolDate
      ? {
          type: 'absolute',
          startDate: getCurrentPlanningCycleResponse?.openToolDate,
          endDate: getCurrentPlanningCycleResponse?.lockToolDate
        }
      : null,
    isActive: getCurrentPlanningCycleResponse?.isActive,
    isExtended: getCurrentPlanningCycleResponse?.isExtended ? getCurrentPlanningCycleResponse?.isExtended : false,
    datePickerValue:
      getCurrentPlanningCycleResponse?.extendedLockDateTime !== null ? splitDateTime(getCurrentPlanningCycleResponse?.extendedLockDateTime).date : '',
    timePickerValue:
      getCurrentPlanningCycleResponse?.extendedLockDateTime !== null ? splitDateTime(getCurrentPlanningCycleResponse?.extendedLockDateTime).time : '',
    extendedLockDateTime: getCurrentPlanningCycleResponse?.extendedLockDateTime,
    extendedToUsers: userDropdown ? userDropdown : []
  } as ScenarioResourceFormModel;
  return result;
};

// FORM to create/update Mutation
export const parsePlanningCycleFormValues = (
  pathParams: any,
  planningCycleFormValues: ScenarioResourceFormModel,
  userAlias: string,
  dataClassificationId: number
) => {
  if (planningCycleFormValues?.toolWindowDateRange?.startDate && planningCycleFormValues.toolWindowDateRange.endDate) {
    return {
      scenarioId: planningCycleFormValues?.scenarioName?.value ? +planningCycleFormValues?.scenarioName?.value : null,
      scenarioTemplateId: pathParams.scenarioTemplateId ? +pathParams.scenarioTemplateId : null,
      baselineScenario: {
        baselineScenarioName: planningCycleFormValues.baselineScenario?.label || null,
        baselineScenarioTemplateId: +planningCycleFormValues.baselineScenario?.value || null
      },
      dataClassificationId: dataClassificationId,
      actualsStartMonth: planningCycleFormValues.actualsDateRange?.startDate,
      actualsEndMonth: planningCycleFormValues.actualsDateRange?.endDate,
      forecastStartMonth: planningCycleFormValues.forecastDateRange?.startDate,
      forecastEndMonth: planningCycleFormValues.forecastDateRange?.endDate,
      openToolDate: convertPacificTimeToUTC(planningCycleFormValues?.toolWindowDateRange?.startDate),
      lockToolDate: convertPacificTimeToUTC(planningCycleFormValues.toolWindowDateRange.endDate),
      isExtended: planningCycleFormValues?.isExtended,
      extendedToUsers: planningCycleFormValues?.isExtended ? planningCycleFormValues?.extendedToUsers?.map((user) => user.label) : [],
      extendedLockDateTime: planningCycleFormValues?.extendedLockDateTime
        ? convertPacificTimeToUTC(planningCycleFormValues?.extendedLockDateTime)
        : null,
      createdBy: userAlias,
      isActive: planningCycleFormValues?.isActive,
      updatedBy: userAlias
    };
  } else {
    return {};
  }
};
