import { useCollection } from '@amzn/awsui-collection-hooks';
import { Box, Header, Link, Pagination, Table, TableProps, TextFilter } from '@amzn/awsui-components-react';
import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import * as queries from 'src/graphql/queries';
import { logger } from 'src/utils/Logger';
import { useAppContext } from '../context/AppContextProvider';
import { ScenarioColumnDefinitions } from './PlanningCycleTableConfig';
import { FuncProps, HistoricalPlanningCycle, PlanningCycleFormModel } from './PlanningCycleModels';
import { dateTimeComparator, dateTimeComparatorForTable } from 'src/utils/DateTimeUtils';

export const parsePlanningCycleData = (data: PlanningCycleFormModel[]) => {
  let result: HistoricalPlanningCycle[] = data.map((row) => ({
    id: row.scenario.scenarioId,
    name: row.scenario.scenarioName,
    actuals_start_month: row.actualsStartMonth,
    actuals_end_month: row.actualsEndMonth,
    is_active: row.isActive,
    forecast_start_month: row.forecastStartMonth,
    forecast_end_month: row.forecastEndMonth,
    open_tool_date: row.openToolDate,
    lock_tool_date: row.lockToolDate,
    created_by: row.createdBy,
    updated_by: row.updatedBy
  }));

  return result;
};

export const PlanningCycleHistoricalTable: React.FC<FuncProps> = (props) => {
  const appContext = useAppContext();
  const [loading, setLoading] = useState(true);
  const [planningCycles, setPlanningCycles] = useState<HistoricalPlanningCycle[]>([]);
  const [columnDefinitions, setColumnDefinitions] = useState<TableProps.ColumnDefinition<HistoricalPlanningCycle>[]>(ScenarioColumnDefinitions);

  useEffect(() => {
    getHistoricalPlanningCycleData();
  }, []);

  // gets historical planning cycles
  const getHistoricalPlanningCycleData = async () => {
    try {
      const response: any = await API.graphql({
        query: queries.listHistoricalPlanningCycles,
        variables: {
          dataClassificationId: appContext.userDetails.dataClassification.dataClassificationId
        }
      });
      setLoading(false);
      setPlanningCycles(parsePlanningCycleData(response.data.listHistoricalPlanningCycles));
    } catch (err) {
      let errString = JSON.stringify(err);
      logger.info(`ERROR - ${errString}`);
      setLoading(false);
      props.messageHandler(false, 'There was a problem. Please retry. If this continues please contact support.', 'error');
    }
  };

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(planningCycles, {
    filtering: {},
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: 'open_tool_date',
          sortingComparator: (a: HistoricalPlanningCycle, b: HistoricalPlanningCycle) => {
            return dateTimeComparatorForTable(a.open_tool_date, b.open_tool_date);
          }
        },
        isDescending: true
      }
    }
  });

  return (
    <Table
      {...collectionProps}
      header={
        <Header
          counter={filteredItemsCount != planningCycles.length ? `(${filteredItemsCount}/${planningCycles.length})` : `(${planningCycles.length})`}
          info={
            <Link variant="info" onFollow={() => props.onToolOpen(true)}>
              Info
            </Link>
          }
        >
          Historical Scenarios
        </Header>
      }
      className="scenarios-table"
      loading={loading}
      loadingText="Loading"
      items={items}
      columnDefinitions={columnDefinitions}
      empty={
        <Box textAlign="center" color="inherit">
          <b>No resources</b>
          <Box padding={{ bottom: 's' }} variant="p" color="inherit">
            No resources to display.
          </Box>
        </Box>
      }
      pagination={<Pagination {...paginationProps} />}
      filter={<TextFilter {...filterProps} />}
    />
  );
};
