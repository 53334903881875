/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCurrentPlanningCycle = /* GraphQL */ `
  query GetCurrentPlanningCycle($dataClassificationId: Int!, $scenarioTemplateId: Int!) {
    getCurrentPlanningCycle(dataClassificationId: $dataClassificationId, scenarioTemplateId: $scenarioTemplateId) {
      actualsEndMonth
      actualsStartMonth
      baselineScenario {
        baselineScenarioName
        baselineScenarioTemplateId
      }
      createdBy
      createdTime
      dataClassification {
        dataClassificationDesc
        dataClassificationId
      }
      extendedLockDateTime
      extendedToUsers
      forecastDataAvailable
      forecastEndMonth
      forecastStartMonth
      isActive
      isExtended
      lockToolDate
      openToolDate
      scenario {
        createdBy
        createdTime
        scenarioId
        scenarioName
        updatedBy
        updatedTime
      }
      scenarioTemplateId
      updatedBy
      updatedTime
    }
  }
`;
export const getForecastTemplateStatus = /* GraphQL */ `
  query GetForecastTemplateStatus($dataClassificationId: Int!, $productLineId: Int!, $scenarioTemplateId: Int!) {
    getForecastTemplateStatus(dataClassificationId: $dataClassificationId, productLineId: $productLineId, scenarioTemplateId: $scenarioTemplateId) {
      createdBy
      dataClassificationId
      isActive
      productLineId
      scenarioTemplateId
      updatedBy
    }
  }
`;
export const getQuicksightEmbeddedUrl = /* GraphQL */ `
  query GetQuicksightEmbeddedUrl($dashboardId: String!, $userAlias: String!) {
    getQuicksightEmbeddedUrl(dashboardId: $dashboardId, userAlias: $userAlias) {
      embdedUrl
    }
  }
`;
export const getUserAuthorization = /* GraphQL */ `
  query GetUserAuthorization($userAlias: String!) {
    getUserAuthorization(userAlias: $userAlias) {
      dataClassification {
        dataClassificationDesc
        dataClassificationId
      }
      isActive
      isReadOnly
      userAlias
      userName
      userType {
        userTypeDescription
        userTypeId
      }
    }
  }
`;
export const listActualBalances = /* GraphQL */ `
  query ListActualBalances($dataClassificationId: Int!, $monthId: String!, $productLineId: Int!) {
    listActualBalances(dataClassificationId: $dataClassificationId, monthId: $monthId, productLineId: $productLineId) {
      glActuals
      pnlActuals
    }
  }
`;
export const listActualsForecastDetails = /* GraphQL */ `
  query ListActualsForecastDetails($dataClassificationId: Int!, $productLineId: Int!, $scenarioTemplateId: Int!) {
    listActualsForecastDetails(dataClassificationId: $dataClassificationId, productLineId: $productLineId, scenarioTemplateId: $scenarioTemplateId) {
      accountNumber
      amountValue
      businessCategory
      businessOwner
      campaignName
      channelCode
      companyCode
      costCenter
      country
      efficiencyGoal
      fpnaDimension
      locationCode
      productCode
      productLineCampaignCorpId
      productLineCampaignId
      projectCode
      revenueEstimate
    }
  }
`;
export const listActualsTemplates = /* GraphQL */ `
  query ListActualsTemplates($dataClassificationId: Int!, $productLineCampaignId: Int!, $productLineId: Int!, $scenarioId: Int!) {
    listActualsTemplates(
      dataClassificationId: $dataClassificationId
      productLineCampaignId: $productLineCampaignId
      productLineId: $productLineId
      scenarioId: $scenarioId
    ) {
      actualsMeasuresUsd
      months {
        columndefinition {
          columnOrderId
          editable
          field
          headerName
          quarter
        }
      }
      productLineCampaignId
      year
    }
  }
`;
export const listBaselineScenarios = /* GraphQL */ `
  query ListBaselineScenarios {
    listBaselineScenarios {
      baselineScenarioName
      baselineScenarioTemplateId
    }
  }
`;
export const listCampaignsPurchaseOrderMapping = /* GraphQL */ `
  query ListCampaignsPurchaseOrderMapping($dataClassificationId: Int!, $monthId: String!, $productLineId: Int!) {
    listCampaignsPurchaseOrderMapping(dataClassificationId: $dataClassificationId, monthId: $monthId, productLineId: $productLineId) {
      campaignId
      campaignMapId
      campaignName
      createdBy
      createdTime
      efficiencyResults {
        Q1
        Q2
        Q3
        Q4
      }
      isActive
      monthId
      poDetails {
        accountNumber
        channelCode
        companyCode
        costCenter
        journalName
        ledgerName
        lineDescription
        locationCode
        poAmountUsd
        poRequestor
        productCode
        vendorNumber
      }
      poNumber
      productLine {
        isFpnaManagedPl
        productID
        productName
      }
      updatedBy
      updatedTime
    }
  }
`;
export const listChannel = /* GraphQL */ `
  query ListChannel {
    listChannel {
      channelId
      channelName
    }
  }
`;
export const listCorpDimensions = /* GraphQL */ `
  query ListCorpDimensions($productLineId: Int!) {
    listCorpDimensions(productLineId: $productLineId) {
      accountNumber {
        id
        name
      }
      channelCode {
        id
        name
      }
      companyCode {
        id
        name
      }
      costCenter {
        id
        name
      }
      locationCode {
        id
        name
      }
      productCode {
        id
        name
      }
      projectCode {
        id
        name
      }
    }
  }
`;
export const listCorpDimensionsAccount = /* GraphQL */ `
  query ListCorpDimensionsAccount($productLineId: Int!) {
    listCorpDimensionsAccount(productLineId: $productLineId) {
      accountNumber {
        id
        name
      }
    }
  }
`;
export const listCorpDimensionsChannel = /* GraphQL */ `
  query ListCorpDimensionsChannel($productLineId: Int!) {
    listCorpDimensionsChannel(productLineId: $productLineId) {
      channelCode {
        id
        name
      }
    }
  }
`;
export const listCorpDimensionsCompany = /* GraphQL */ `
  query ListCorpDimensionsCompany($productLineId: Int!) {
    listCorpDimensionsCompany(productLineId: $productLineId) {
      companyCode {
        id
        name
      }
    }
  }
`;
export const listCorpDimensionsCostCenter = /* GraphQL */ `
  query ListCorpDimensionsCostCenter($productLineId: Int!) {
    listCorpDimensionsCostCenter(productLineId: $productLineId) {
      costCenter {
        id
        name
      }
    }
  }
`;
export const listCorpDimensionsLocation = /* GraphQL */ `
  query ListCorpDimensionsLocation($productLineId: Int!) {
    listCorpDimensionsLocation(productLineId: $productLineId) {
      locationCode {
        id
        name
      }
    }
  }
`;
export const listCorpDimensionsProduct = /* GraphQL */ `
  query ListCorpDimensionsProduct($productLineId: Int!) {
    listCorpDimensionsProduct(productLineId: $productLineId) {
      productCode {
        id
        name
      }
    }
  }
`;
export const listCorpDimensionsProject = /* GraphQL */ `
  query ListCorpDimensionsProject($productLineId: Int!) {
    listCorpDimensionsProject(productLineId: $productLineId) {
      projectCode {
        id
        name
      }
    }
  }
`;
export const listCorpProducts = /* GraphQL */ `
  query ListCorpProducts {
    listCorpProducts {
      isActive
      isFpnaManagedPl
      productCode
      productName
    }
  }
`;
export const listCountry = /* GraphQL */ `
  query ListCountry {
    listCountry {
      countryCode
      countryId
      countryName
      currencyCode
    }
  }
`;
export const listCountryCompanyMapping = /* GraphQL */ `
  query ListCountryCompanyMapping {
    listCountryCompanyMapping {
      companyCode
      countryCode
      countryCompanyId
      isActive
    }
  }
`;
export const listCurrentPlanningCycles = /* GraphQL */ `
  query ListCurrentPlanningCycles($dataClassificationId: Int!) {
    listCurrentPlanningCycles(dataClassificationId: $dataClassificationId) {
      actualsEndMonth
      actualsStartMonth
      baselineScenario {
        baselineScenarioName
        baselineScenarioTemplateId
      }
      createdBy
      createdTime
      dataClassification {
        dataClassificationDesc
        dataClassificationId
      }
      extendedLockDateTime
      extendedToUsers
      forecastDataAvailable
      forecastEndMonth
      forecastStartMonth
      isActive
      isExtended
      lockToolDate
      openToolDate
      scenario {
        createdBy
        createdTime
        scenarioId
        scenarioName
        updatedBy
        updatedTime
      }
      scenarioTemplateId
      updatedBy
      updatedTime
    }
  }
`;
export const listDataClassification = /* GraphQL */ `
  query ListDataClassification {
    listDataClassification {
      dataClassificationDesc
      dataClassificationId
    }
  }
`;
export const listForecastEfficiencyGoals = /* GraphQL */ `
  query ListForecastEfficiencyGoals($productLineId: Int!) {
    listForecastEfficiencyGoals(productLineId: $productLineId) {
      createdBy
      efficiencyGoal
      efficiencyMeasure
      isActive
      productLineCampaignId
      updatedBy
    }
  }
`;
export const listForecastTemplateStatusReport = /* GraphQL */ `
  query ListForecastTemplateStatusReport($dataClassificationId: Int!, $scenarioTemplateId: Int!) {
    listForecastTemplateStatusReport(dataClassificationId: $dataClassificationId, scenarioTemplateId: $scenarioTemplateId) {
      forecastStatus
      productLine
      updatedBy
      updatedTime
    }
  }
`;
export const listForecastTemplates = /* GraphQL */ `
  query ListForecastTemplates($dataClassificationId: Int!, $productLineCampaignId: Int!, $productLineId: Int!, $scenarioId: Int!) {
    listForecastTemplates(
      dataClassificationId: $dataClassificationId
      productLineCampaignId: $productLineCampaignId
      productLineId: $productLineId
      scenarioId: $scenarioId
    ) {
      createdBy
      dataClassificationId
      forecastMeasuresUsd
      isActive
      months {
        columndefinition {
          columnOrderId
          editable
          field
          headerName
          quarter
        }
      }
      productLineCampaignId
      scenarioTemplateId
      updatedBy
      year
    }
  }
`;
export const listFpnaCorpDimensions = /* GraphQL */ `
  query ListFpnaCorpDimensions($productLineId: Int!) {
    listFpnaCorpDimensions(productLineId: $productLineId) {
      accountNumber
      channelCode
      companyCode
      costCenter
      createdBy
      createdTime
      isActive
      locationCode
      productCode
      productLineCampaignCorpId
      projectCode
      updatedBy
      updatedTime
    }
  }
`;
export const listFpnaDimensions = /* GraphQL */ `
  query ListFpnaDimensions($dataClassificationId: Int!) {
    listFpnaDimensions(dataClassificationId: $dataClassificationId) {
      createdBy
      createdTime
      dataClassification {
        dataClassificationDesc
        dataClassificationId
      }
      dimensionId
      dimensionKey
      dimensionName
      dimensionValues {
        conditionalRelationship {
          dimensionId
          dimensionKey
          invalidValues
          validValues
        }
        value
        valueDesc
      }
      isActive
      isRequired
      scenario
      updatedBy
      updatedTime
    }
  }
`;
export const listGlActualMonths = /* GraphQL */ `
  query ListGlActualMonths {
    listGlActualMonths {
      glActualMonths
    }
  }
`;
export const listGlPurchaseOrders = /* GraphQL */ `
  query ListGlPurchaseOrders($dataClassificationId: Int!, $monthId: String!, $productLineId: Int!) {
    listGlPurchaseOrders(dataClassificationId: $dataClassificationId, monthId: $monthId, productLineId: $productLineId) {
      costAccount {
        accountNumber
        costCenter
      }
      poNumber
    }
  }
`;
export const listHistoricalPlanningCycles = /* GraphQL */ `
  query ListHistoricalPlanningCycles($dataClassificationId: Int!) {
    listHistoricalPlanningCycles(dataClassificationId: $dataClassificationId) {
      actualsEndMonth
      actualsStartMonth
      baselineScenario {
        baselineScenarioName
        baselineScenarioTemplateId
      }
      createdBy
      createdTime
      dataClassification {
        dataClassificationDesc
        dataClassificationId
      }
      extendedLockDateTime
      extendedToUsers
      forecastDataAvailable
      forecastEndMonth
      forecastStartMonth
      isActive
      isExtended
      lockToolDate
      openToolDate
      scenario {
        createdBy
        createdTime
        scenarioId
        scenarioName
        updatedBy
        updatedTime
      }
      scenarioTemplateId
      updatedBy
      updatedTime
    }
  }
`;
export const listProductLineCampaigns = /* GraphQL */ `
  query ListProductLineCampaigns($dataClassificationId: Int!, $productLineId: Int!) {
    listProductLineCampaigns(dataClassificationId: $dataClassificationId, productLineId: $productLineId) {
      businessCategory
      businessOwner
      campaignId
      campaignName
      campaignsDimMapping {
        accountNumber
        channelCode
        companyCode
        costCenter
        createdBy
        createdTime
        isActive
        locationCode
        productCode
        productLineCampaignCorpId
        projectCode
        updatedBy
        updatedTime
      }
      country
      createdBy
      createdTime
      dataClassification {
        dataClassificationDesc
        dataClassificationId
      }
      efficiencyGoal
      fpnaDimensions {
        dimensionId
        dimensionKey
        dimensionName
        dimensionValue
      }
      isActive
      isPoMapped
      productLine {
        isFpnaManagedPl
        productID
        productName
      }
      revenueEstimate
      updatedBy
      updatedTime
    }
  }
`;
export const listProductLineDimensions = /* GraphQL */ `
  query ListProductLineDimensions($dataClassificationId: Int!) {
    listProductLineDimensions(dataClassificationId: $dataClassificationId) {
      createdBy
      createdTime
      dataClassification {
        dataClassificationDesc
        dataClassificationId
      }
      dimensionId
      dimensionName
      dimensionValues {
        dimensionValue
        dimensionValueId
      }
      isActive
      updatedBy
      updatedTime
    }
  }
`;
export const listProductUsers = /* GraphQL */ `
  query ListProductUsers($dataClassificationId: Int!, $productLineId: Int!) {
    listProductUsers(dataClassificationId: $dataClassificationId, productLineId: $productLineId) {
      productID
      productName
      users {
        userAlias
        userName
      }
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts {
    listProducts {
      isFpnaManagedPl
      productID
      productName
    }
  }
`;
export const listProductsDetail = /* GraphQL */ `
  query ListProductsDetail {
    listProductsDetail {
      createdBy
      createdTime
      isActive
      isFpnaManagedPl
      productCode
      productId
      productName
      updatedBy
      updatedTime
    }
  }
`;
export const listQuicksightReports = /* GraphQL */ `
  query ListQuicksightReports {
    listQuicksightReports {
      dashboardId
      tabId
      tabName
    }
  }
`;
export const listScenarios = /* GraphQL */ `
  query ListScenarios {
    listScenarios {
      createdBy
      createdTime
      scenarioId
      scenarioName
      updatedBy
      updatedTime
    }
  }
`;
export const listUserAccess = /* GraphQL */ `
  query ListUserAccess {
    listUserAccess {
      isReadOnly
      userAccessDescription
    }
  }
`;
export const listUserTypes = /* GraphQL */ `
  query ListUserTypes {
    listUserTypes {
      userTypeDescription
      userTypeId
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers {
    listUsers {
      createdBy
      createdTime
      dataClassification {
        dataClassificationDesc
        dataClassificationId
      }
      isActive
      productLine {
        isFpnaManagedPl
        productID
        productName
      }
      updatedBy
      updatedTime
      userAccess {
        isReadOnly
        userAccessDescription
      }
      userAlias
      userName
      userType {
        userTypeDescription
        userTypeId
      }
    }
  }
`;
