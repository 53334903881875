import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Button,
  CollectionPreferencesProps,
  Header,
  Link,
  Pagination,
  PropertyFilter,
  SpaceBetween,
  Table,
  TableProps
} from '@amzn/awsui-components-react';
import { isEmpty } from '@aws-amplify/core';
import { API } from 'aws-amplify';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as queries from 'src/graphql/queries';
import { PROPERTY_FILTERING_I18N_CONSTANTS } from 'src/utils/CommonHelpers';
import { logger } from 'src/utils/Logger';
import * as custom_queries from 'src/utils/custom-queries';
import { BLANK_SEARCH, PAGINATION_LABELS, getMatchesCountText } from 'src/utils/labels';
import { addToColumnDefinitions, mapWithColumnDefinitionIds } from '../commons/CustomHooks/use-column-widths';
import { usePersistedState } from '../commons/CustomHooks/use-local-storage';
import { useAppContext } from '../context/AppContextProvider';
import { useAuth } from '../context/AuthContext';
import { EmptyStateMessage } from '../generic-components/EmptyState';
import { CampaignsContext } from './CampaignContext';
import { CorpDimensionsList } from './CampaignModels';
import {
  CAMPAIGN_COLUMN_DEFINITIONS_PREFIX,
  CAMPAIGN_INITIAL_FILTERING_PROPERTIES,
  CAMPAIGN_TABLE_DEFAULT_PREFERENCES,
  CampaignTablePreferences,
  VISIBLE_CONTENT_OPTIONS_PREFIX,
  getColumnDefinitions,
  getFilteringProperties,
  getVisibleContent,
  getVisibleContentOptions
} from './CampaignTableConfig';
import * as CampaignUtils from './CampaignUtils';
import { eUserTablePreferenceKeys } from 'src/utils/DmmConstants';

export const CampaignTable = () => {
  const userDetails = useAuth();
  const appContext = useAppContext();
  const navigate = useNavigate();
  const {
    campaignItems,
    setCampaignItems,
    campaignsLoading,
    setCampaignsLoading,
    filteredCampaignItems,
    corpDimensionsList,
    setCorpDimensionsList,
    countryCompanyMapping,
    setCountryAndCompanyMapping,
    selectedProductLine,
    listProductLineDimensions,
    setListProductLineDimensions,
    toolsOpen,
    setToolsOpen,
    notificationHandler,
    refreshCampaigns
  } = useContext(CampaignsContext);

  const defaultVisibleContentOptions: CollectionPreferencesProps.VisibleContentOptionsGroup[] = [
    {
      label: 'Properties',
      options: VISIBLE_CONTENT_OPTIONS_PREFIX
    }
  ];

  const [fpnaDimensions, setFpnaDimensions] = useState<any[]>([]);

  // Table Preferences and Visible Column Options
  const [preferences, setPreferences] = usePersistedState(
    eUserTablePreferenceKeys.CAMPAIGN_TABLE_DEFAULT_PREFERENCES,
    CAMPAIGN_TABLE_DEFAULT_PREFERENCES
  );
  const [visibleContentOptions, setVisibleContentOptions] = usePersistedState(
    eUserTablePreferenceKeys.CAMPAIGN_TABLE_VISIBLE_CONTENT_OPTIONS,
    defaultVisibleContentOptions
  );

  // Table column definitions and column widths
  const [columnDefinitions, setColumnDefinitions] = useState(CAMPAIGN_COLUMN_DEFINITIONS_PREFIX); // usePersistedState("CAMPAIGN_TABLE_COLUMN_DEFINITIONS", CAMPAIGN_COLUMN_DEFINITIONS_PREFIX);
  const saveColumnWidthChanges = (event: any) => {
    const colDefinitionsWithIds = mapWithColumnDefinitionIds(columnDefinitions, 'width', event.detail.widths);
    const updatedColumnDefinitionsWithIds = addToColumnDefinitions(columnDefinitions, 'width', colDefinitionsWithIds);
    setColumnDefinitions(updatedColumnDefinitionsWithIds);
  };

  // Table Filtering Properties and use entered filter query
  const [filteringProperties, setFilteringProperties] = usePersistedState(
    eUserTablePreferenceKeys.CAMPAIGN_TABLE_FILTERING_PROPERTIES,
    CAMPAIGN_INITIAL_FILTERING_PROPERTIES
  );
  const [query, setQuery] = usePersistedState(eUserTablePreferenceKeys.CAMPAIGN_QUERY, BLANK_SEARCH);
  useEffect(() => {
    actions.setPropertyFiltering(query);
  }, [query]);

  useEffect(() => {
    const listFpnaDimensions = appContext.appMetadata.listFpnaDimensions;
    if (listFpnaDimensions && fpnaDimensions.length == 0) {
      let dimensions: { key: string; name: string }[] = CampaignUtils.parseFpnaDimensionData(listFpnaDimensions);
      setFpnaDimensions(dimensions);
    }
  }, [appContext]);

  // Based on dynamic Fpna Dimensions, dynamically generate the column definitions
  useEffect(() => {
    // Setting up Column Definitions based on FPNA Dimensions
    setColumnDefinitions(getColumnDefinitions(fpnaDimensions));

    // Setting up visible columns based on FPNA Dimensions
    setPreferences({ ...preferences, visibleContent: getVisibleContent(fpnaDimensions) });

    // dynamically update table based on FPNA Dimensions
    setVisibleContentOptions(getVisibleContentOptions(fpnaDimensions));

    // Setting up filtering properties based on FPNA Dimensions
    setFilteringProperties(getFilteringProperties(fpnaDimensions));
  }, [fpnaDimensions]);

  useEffect(() => {
    if (!isEmpty(selectedProductLine)) {
      fetchCountryAndCompanyMapping();
      refreshCampaigns();
      getCorpDimensionsList();
      getProductLineDimensionsList();
    }
  }, [selectedProductLine]);

  const fetchCountryAndCompanyMapping = async () => {
    try {
      const response: any = await API.graphql({
        query: queries.listCountryCompanyMapping
      });
      const countryAndCompanyMappingResponse = response.data.listCountryCompanyMapping;
      setCountryAndCompanyMapping(countryAndCompanyMappingResponse);
    } catch (error: any) {
      logger.error('Unable to fetch Country and Company mapping.', error);
      notificationHandler(false, `Unable to fetch Country and Company mapping. Please try again or reach out to Admin.`, 'error');
    }
  };

  const getProductLineDimensionsList = async () => {
    try {
      const listProductLineDimensionsResponse: any = await API.graphql({
        query: queries.listProductLineDimensions,
        variables: {
          dataClassificationId: appContext.userDetails.dataClassification.dataClassificationId
        }
      });
      const listProductLineDimensions: any = listProductLineDimensionsResponse.data.listProductLineDimensions;
      setListProductLineDimensions(listProductLineDimensions ? listProductLineDimensions : []);
    } catch (error: any) {
      logger.error('Unable to load ListProductLineDimensions.', error);
      setCampaignsLoading(false);
    }
  };

  const getCorpDimensionsList = async () => {
    try {
      const response: any = await API.graphql({
        query: custom_queries.getCampaignCorpDimensions(selectedProductLine.productID)
      });

      const _CorpDimensionsList: CorpDimensionsList = {
        accountNumber: response.data.listCorpDimensionsAccount.accountNumber,
        channelCode: response.data.listCorpDimensionsChannel.channelCode,
        companyCode: response.data.listCorpDimensionsCompany.companyCode,
        costCenter: response.data.listCorpDimensionsCostCenter.costCenter,
        locationCode: response.data.listCorpDimensionsLocation.locationCode,
        productCode: response.data.listCorpDimensionsProduct.productCode,
        projectCode: response.data.listCorpDimensionsProject.projectCode
      };
      setCorpDimensionsList(_CorpDimensionsList);
      if (!response.data.listCorpDimensionsAccount?.accountNumber) {
        notificationHandler(
          false,
          'Campaign Dimensions are empty. Some of the features might not work as expected. Please reach out to admin.',
          'error'
        );
      }
    } catch (error: any) {
      setCorpDimensionsList({} as CorpDimensionsList);
      logger.error(`Unable to load corp dimensions for product ${selectedProductLine?.productName}`, error);
      notificationHandler(
        false,
        'Unable to fetch Campaign Dimensions. Some of the features might not work as expected. Please reach out to admin.',
        'error'
      );
    }
  };

  const newCampaign = () => {
    navigate('/campaigns/new');
  };

  const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(filteredCampaignItems, {
    propertyFiltering: {
      filteringProperties: filteringProperties,
      empty: <EmptyStateMessage title="No campaigns" subtitle="No campaigns to display." />,
      noMatch: (
        <EmptyStateMessage
          title="No matches"
          subtitle="No match found to display."
          action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
        />
      )
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {
      defaultState: {
        sortingColumn: { sortingField: 'updatedTime' },
        isDescending: true
      }
    },
    selection: {}
  });

  return (
    <>
      <Table
        header={
          <Header
            counter={`(${filteredCampaignItems.length})`}
            actions={
              <SpaceBetween size="m" direction="horizontal">
                <Button variant="normal" disabled={isEmpty(selectedProductLine)} onClick={newCampaign}>
                  New Campaign
                </Button>
                <Button iconName="refresh" disabled={isEmpty(selectedProductLine)} onClick={refreshCampaigns} />
              </SpaceBetween>
            }
            info={
              <Link variant="info" onFollow={() => setToolsOpen(true)}>
                Info
              </Link>
            }
          >
            {'Campaigns'}
          </Header>
        }
        {...collectionProps}
        visibleColumns={preferences.visibleContent}
        wrapLines={preferences.wrapLines}
        stripedRows={preferences.stripedRows}
        resizableColumns={preferences.custom}
        loading={campaignsLoading}
        loadingText="Loading purchase order data"
        columnDefinitions={columnDefinitions as TableProps.ColumnDefinition<any>[]}
        items={items}
        variant="container"
        stickyHeader
        onColumnWidthsChange={saveColumnWidthChanges as any}
        filter={
          <PropertyFilter
            i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
            countText={getMatchesCountText(filteredItemsCount!)}
            expandToViewport={true}
            {...propertyFilterProps}
            query={query}
            onChange={(event) => {
              setQuery(event.detail.tokens?.length === 0 ? BLANK_SEARCH : event.detail);
            }}
          />
        }
        pagination={<Pagination {...paginationProps} ariaLabels={PAGINATION_LABELS} />}
        preferences={
          <CampaignTablePreferences preferences={preferences} setPreferences={setPreferences} visibleContentOptions={visibleContentOptions} />
        }
      />
    </>
  );
};
