import React from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { eUserTypes } from 'src/utils/DmmConstants';
import { LoadingStatus } from '../context/AppContextModel';
import { useAppContext } from '../context/AppContextProvider';
import { useAuth } from '../context/AuthContext';
import { Navigation, NavigationItems } from './AppSideNavigation';

/*
  PrivateRoutes checks multiple conditions like active, in-active and user type..etc
  For now, we are only checking if user is active or not. 
*/
export const PrivateRoutes = () => {
  const userAuthDetails = useAuth();
  const appContext = useAppContext();
  const location = useLocation();

  const userType = appContext.userDetails.userType?.userTypeDescription;
  const isFPNAUser = userType === eUserTypes.FPNA_USER;

  // If user is from FPNA & userAuthDataLoadingStatus is Completed, then user can access entire portal.
  if (userAuthDetails.userAuthDataLoadingStatus === LoadingStatus.Completed && isFPNAUser && appContext.userDetails.isActive) {
    return <Outlet />;
  }

  const listOfAccessibleComponents: Navigation[] = NavigationItems.filter((navigation) => navigation.accessibleTo.includes(userType ? userType : ''));
  const canAccess =
    userAuthDetails.userAuthDataLoadingStatus === LoadingStatus.Completed &&
    appContext.userDetails.isActive &&
    listOfAccessibleComponents.find((navigation) => location.pathname.includes(navigation.href));
  return canAccess ? <Outlet /> : <Navigate to="/" />;
};
