import { CellClassParams, ValueFormatterParams } from 'ag-grid-community';
import { convertNumberToUSDWithFractionDigits } from './CommonHelpers';

const isNull = (value: any) => {
  return value === null;
};

export const applyCellClassRules = {
  // editable can be undefined
  'read-only-cell': (params: CellClassParams) => (!params.colDef.editable ? true : false),
  'nan-cell': (params: CellClassParams) => isNaN(params.value)
};

export const readOnlyCellClass = {
  // editable can be undefined
  'read-only-cell': (params: CellClassParams) => (!params.colDef.editable ? true : false)
};

export const actualCurrencyFormatter = (params: ValueFormatterParams) => {
  if (isNull(params.value) || isNaN(params.value)) {
    return '-';
  } else {
    const enteredNumber = +params.value;
    /*
            This formatter is just to display in UI 
            with $ currency format with 2 fractional digits
        */
    return convertNumberToUSDWithFractionDigits(enteredNumber);
  }
};

export const forecastCurrencyFormatter = (params: ValueFormatterParams) => {
  if (params.value === null || params.value === undefined || params.value === '') {
    return '-';
  }

  if (isNaN(params.value)) {
    return params.value;
  } else {
    const enteredNumber = +params.value;
    return convertNumberToUSDWithFractionDigits(enteredNumber);
  }
};

export const cellValueParser = (value: any) => {
  // console.log(`Received ${value} - typeof - ${typeof value}`)
  return trimEmptyString(value);
};

export const isString = (value: any): value is string => typeof value === 'string';
export const trimEmptyString = <T>(value: T): T | null => (isString(value) && value.trim() === '' ? null : value);
