import * as Yup from 'yup';

interface ValidationField {
  key: string;
  isRequired: boolean;
  type: string;
}

export const REQUIRED_FIELD = 'Required field';

export const yupRequiredDropdownField = () => {
  return Yup.object().shape({
    label: Yup.string().required(REQUIRED_FIELD)
  });
};

export const getYupType = (type: string) => {
  switch (type) {
    case 'string':
      return Yup.string().required();
    case 'dropdown':
      return yupRequiredDropdownField();
    default:
      throw new Error('yup type not defined');
  }
};

export const createYupConditional = () => {};

/* fields
 * field key
 * field validation required / not required
 * field type
 * isBulkEdit (optional)
 * blocks requiring fields so only subset of fields can be bulk edited
 */
export const createYupSchema = (fields: any, isBulkEdit?: boolean) => {
  const schema = fields.reduce((schema: Yup.ObjectSchema<any>, field: ValidationField) => {
    if (!field.isRequired || isBulkEdit) {
      return schema;
    }
    return { ...schema, [field.key]: getYupType(field.type) };
  }, {});
  const result = Yup.object().shape(schema);
  return result;
};

export function mergeSchemas(...schemas: Yup.ObjectSchema<any>[]): Yup.ObjectSchema<any> {
  const [first, ...rest] = schemas;

  const merged = rest.reduce((mergedSchemas, schema) => mergedSchemas.concat(schema), first);

  return merged;
}
