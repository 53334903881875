import { ListFpnaDimensionsEntity, ListFpnaDimensionValues, User } from '../context/AppContextModel';
import { CampaignCorpDimensions, CorpDimensionsList, DropdownModel, ListProductLineDimensions, ProductLineCampaignsEntity } from './CampaignModels';

export const validateFpnaDimensionRequiredFields = (
  formValues: any,
  fpnaDimensions: ListFpnaDimensionsEntity[],
  selectedProductLineIsEcho: boolean
) => {
  let errors: any = {};
  const REQUIRED_FIELD = 'Required field';

  // For Product Line "Echo", Revenue Estimate field is required
  if (selectedProductLineIsEcho && (formValues.revenueEstimate?.value === null || formValues.revenueEstimate?.value === '')) {
    errors = { ...errors, revenueEstimate: { label: REQUIRED_FIELD } };
  }

  // For Product Line "Echo", Business Category field is required
  if (selectedProductLineIsEcho && (formValues.businessCategory?.value === null || formValues.businessCategory?.value === '')) {
    errors = { ...errors, businessCategory: { label: REQUIRED_FIELD } };
  }

  // Checking all required fields for FPNA dimensions
  fpnaDimensions.forEach((dimension) => {
    if (!formValues[dimension.dimensionKey]) {
      if (dimension.isRequired && formValues[dimension.dimensionKey]?.label?.length == 0) {
        errors[dimension.dimensionKey] = REQUIRED_FIELD;
      }
    }
  });

  return errors;
};

export const validateFpnaDimensionConditionalRelationships = (
  formValues: any,
  fpnaDimensions: ListFpnaDimensionsEntity[],
  listBusinessCategory: DropdownModel[],
  listRevenueEstimateCategory: DropdownModel[]
) => {
  let errors: any = {};
  const conditionalErrorMessage = 'Invalid combination';
  fpnaDimensions.forEach((dimension: ListFpnaDimensionsEntity) => {
    // check if dimension has been set in form
    let currentFormValue = formValues[dimension.dimensionKey]?.label;
    const allowedValues = dimension.dimensionValues?.map((dimensionValue) => dimensionValue.value);
    if (currentFormValue && allowedValues?.includes(currentFormValue)) {
      dimension.dimensionValues?.forEach((dimensionValue: ListFpnaDimensionValues) => {
        if (dimensionValue.value === currentFormValue) {
          // if they match then it's worth comparing
          let rules = dimensionValue.conditionalRelationship;
          rules?.forEach((rule) => {
            // check if foreign value has been set
            if (rule.dimensionId != null && rule.dimensionKey != null) {
              let foreignValue = formValues[rule.dimensionKey]?.label;
              if (foreignValue) {
                // it has a value so need to check
                if (rule?.validValues?.length && rule?.validValues?.length > 0) {
                  if (!rule.validValues.includes(foreignValue)) {
                    errors[dimension.dimensionKey] = `${conditionalErrorMessage} with ${rule.dimensionKey}. Valid values are: ${rule.validValues}`;
                  }
                } else if (rule?.invalidValues?.length && rule?.invalidValues?.length > 0) {
                  if (rule.invalidValues.length > 0) {
                    if (rule.invalidValues.includes(foreignValue)) {
                      errors[dimension.dimensionKey] =
                        `${conditionalErrorMessage} with ${rule.dimensionKey}. Invalid values are: ${rule.invalidValues}`;
                    }
                  }
                }
              }
            }
          });
        }
      });
    } else {
      errors[dimension.dimensionKey] = `Value of ${dimension.dimensionName} '${currentFormValue}' is Invalid.`;
    }
  });

  // Validating for Business Category
  const businessCategoryExists = listBusinessCategory.map((businessCategory) => businessCategory.value).includes(formValues.businessCategory.value);
  if (formValues.businessCategory.value && !businessCategoryExists) {
    errors = { ...errors, businessCategory: { label: `Value of Business Category '${formValues.businessCategory.value}' is Invalid.` } };
  }

  // Validating for Revenue Estimate Category
  const revenueEstimateCategoryExists = listRevenueEstimateCategory
    .map((revenueEstimate) => revenueEstimate.value)
    .includes(formValues.revenueEstimate.value);
  if (formValues.revenueEstimate.value && !revenueEstimateCategoryExists) {
    errors = { ...errors, revenueEstimate: { label: `Value of Revenue Estimate Category '${formValues.revenueEstimate.value}' is Invalid.` } };
  }

  return errors;
};

// validate the uniqueness of the Campaign details before submitting it.
export const validateCampaignDetailsUniqueness = (object: any, objects: any[], excludeFields: Array<keyof any>) => {
  return !objects.some((existingObject) => {
    const objectCopy = Object.assign({}, object);
    excludeFields.forEach((field) => {
      delete objectCopy[field];
    });
    const existingObjectCopy = Object.assign({}, existingObject);
    excludeFields.forEach((field) => {
      delete existingObjectCopy[field];
    });
    return JSON.stringify(existingObjectCopy) === JSON.stringify(objectCopy);
  });
};

export type CorpDimensionValidationStatus =
  | 'valid'
  | 'At least 1 Campaign Dimension is required'
  | 'At least 1 Active Campaign Dimension is required'
  | 'All fields are required in the Campaign Dimensions'
  | 'Duplicate Campaign Dimensions';
const CorpDimensionRequiredFields = [
  'isActive',
  'accountNumber',
  'channelCode',
  'companyCode',
  'costCenter',
  'locationCode',
  'productCode',
  'projectCode'
];
const CorpDimensionFieldsToExcludeFromDuplicateValidation = ['isActive', 'updatedBy', 'updatedTime', 'createdBy', 'createdTime'];
export const validateCorpDimensions = (campaignDimensions: CampaignCorpDimensions[]): CorpDimensionValidationStatus => {
  const validatedAtLeastOneRow = hasAtLeastOneCorpDimension(campaignDimensions);
  const validatedAllDimensionValues = hasAllDimensionValues(campaignDimensions);
  const validateUniqueness = checkIfArrayOfObjectsAreUnique(campaignDimensions, CorpDimensionFieldsToExcludeFromDuplicateValidation)
    ? 'valid'
    : 'Duplicate Campaign Dimensions';
  if (validatedAtLeastOneRow === 'valid') {
    if (validatedAllDimensionValues === 'valid') {
      if (validateUniqueness === 'valid') {
        return 'valid';
      } else {
        return validateUniqueness;
      }
    } else {
      return validatedAllDimensionValues;
    }
  } else {
    return validatedAtLeastOneRow;
  }
};

export const hasAtLeastOneCorpDimension = (campaignDimensions: CampaignCorpDimensions[]): CorpDimensionValidationStatus => {
  if (campaignDimensions.length === 0) {
    return 'At least 1 Campaign Dimension is required';
  } else if (campaignDimensions.filter((campaignDimension) => !campaignDimension.isActive).length === campaignDimensions.length) {
    return 'At least 1 Active Campaign Dimension is required';
  } else {
    return campaignDimensions.length > 0 ? 'valid' : 'At least 1 Campaign Dimension is required';
  }
};

export const hasAllDimensionValues = (campaignDimensions: CampaignCorpDimensions[]): CorpDimensionValidationStatus => {
  const isValid = campaignDimensions.every((object: any) => {
    return CorpDimensionRequiredFields.every((field: any) => {
      return object[field] !== '' && object[field] !== null;
    });
  });
  return isValid ? 'valid' : 'All fields are required in the Campaign Dimensions';
};

export const checkIfAllFieldsArePresent = (arrayOfObjects: any[], fieldsToExclude: string[]) => {
  return arrayOfObjects.every((object: any) => {
    return Object.keys(object).every((field: any) => {
      if (fieldsToExclude.includes(field)) {
        return true;
      }
      return object[field] !== undefined && object[field] !== null && object[field] !== '';
    });
  });
};

export const checkIfArrayOfObjectsAreUnique = (arrayOfObjects: any[], fieldsToExclude: string[]): boolean => {
  const set = new Set();
  for (const arrayOfObject of arrayOfObjects) {
    const filteredObj = Object.fromEntries(Object.entries(arrayOfObject).filter(([key]) => !fieldsToExclude.includes(key)));
    if (set.has(JSON.stringify(filteredObj))) {
      return false;
    }
    set.add(JSON.stringify(filteredObj));
  }
  return true;
};
