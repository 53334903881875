import React from 'react';
import { HelpPanel, ExpandableSection, Icon, Link } from '@amzn/awsui-components-react';
import { eSIMLinks } from 'src/utils/DmmConstants';

export const AccessAndAuthorizationToolBar = () => {
  return (
    <HelpPanel
      header={<h2>More Info</h2>}
      footer={
        <div>
          <h3>
            Useful Links <Icon name="external" />
          </h3>
          <ul>
            <li>
              <Link external href={eSIMLinks.ISSUE_REPORT}>
                Contact Tool Support
              </Link>
            </li>
          </ul>
        </div>
      }
    >
      <h3>Description</h3>
      <p>This controls the tool's access and permissions.</p>
      <p>
        Give users the <i>minimum</i> level of access required for their role.
      </p>
      <ExpandableSection header="Role types">
        <ul>
          <li>FP&A / HEI Admin</li>
          <ul>
            <li>full access</li>
          </ul>
          <li>Product Line Lead</li>
          <ul>
            <li>access to assigned product line(s)</li>
            <li>ability to add/edit campaigns (marketing programs)</li>
            <li>ability to assign budget owners to campaigns</li>
            <li>ability to associate purchase orders and campaigns for actuals process</li>
          </ul>
          <li>Business Owner</li>
          <ul>
            <li>ability to forecast to assigned product line campaigns</li>
          </ul>
        </ul>
      </ExpandableSection>

      <h3>How to use</h3>
      <div>
        <p>Add/Edit a user</p>
        <ol>
          <li>Enter the alias for the user you wish to add</li>
          <li>Fill out the form fields to provide the appropriate access level</li>
          <li>Save changes</li>
        </ol>
        <p>Remove a user</p>
        <p>Users may be deprecated to remove access.</p>
        <ol>
          <li>Toggle user's status to inactive</li>
          <li>Save changes</li>
        </ol>
      </div>
    </HelpPanel>
  );
};
