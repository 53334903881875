export const getMetadataQuery = () => {
  return `
  query GetMetadataQuery {
        listChannel {
            channelId
            channelName
        }
        listCountry {
            countryCode
            countryId
            countryName
            currencyCode
        }
        listProducts {
            productID
            productName
            isFpnaManagedPl
        }
        listUserAccess {
          isReadOnly
          userAccessDescription
        }
        listUserTypes {
          userTypeDescription
          userTypeId
        }
        listDataClassification {
          dataClassificationDesc
          dataClassificationId
        }
        listScenarios {
          createdBy
          createdTime
          scenarioId
          scenarioName
          updatedBy
          updatedTime
        }
    }`;
};

export const getCampaignCorpDimensions = (productLineId: number) => {
  return `query getCampaignCorpDimensions {
    listCorpDimensionsAccount(productLineId: ${productLineId}) {
      accountNumber {
        id
        name
      }
    }
    listCorpDimensionsChannel(productLineId: ${productLineId}) {
      channelCode {
        id
        name
      }
    }
    listCorpDimensionsCompany(productLineId: ${productLineId}) {
      companyCode {
        id
        name
      }
    }
    listCorpDimensionsCostCenter(productLineId: ${productLineId}) {
      costCenter {
        id
        name
      }
    }
    listCorpDimensionsLocation(productLineId: ${productLineId}) {
      locationCode {
        id
        name
      }
    }
    listCorpDimensionsProduct(productLineId: ${productLineId}) {
      productCode {
        id
        name
      }
    }
    listCorpDimensionsProject(productLineId: ${productLineId}) {
      projectCode {
        id
        name
      }
    }
  }`;
};

export const getFpnaDimensions = (dataClassificationId: number) => {
  return `query getFpnaDimensions {
    listFpnaDimensions(dataClassificationId: ${dataClassificationId}) {
      createdBy
      createdTime
      dataClassification {
        dataClassificationDesc
        dataClassificationId
      }
      dimensionId
      dimensionKey
      dimensionName
      dimensionValues {
        value
        valueDesc
        conditionalRelationship {
          dimensionId
          dimensionKey
          invalidValues
          validValues
        }
      }
      isActive
      isRequired
      scenario
      updatedBy
      updatedTime
    }
  }`;
};
