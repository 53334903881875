import { API } from 'aws-amplify';
import { CorpDimensionsList } from 'src/components/campaigns/CampaignModels';
import { logger } from 'src/utils/Logger';
import * as custom_queries from 'src/utils/custom-queries';

export const getCorpDimensionsList = async (productId: number) => {
  try {
    const response: any = await API.graphql({
      query: custom_queries.getCampaignCorpDimensions(productId)
    });
    const _CorpDimensionsList: CorpDimensionsList = {
      accountNumber: response.data.listCorpDimensionsAccount.accountNumber,
      channelCode: response.data.listCorpDimensionsChannel.channelCode,
      companyCode: response.data.listCorpDimensionsCompany.companyCode,
      costCenter: response.data.listCorpDimensionsCostCenter.costCenter,
      locationCode: response.data.listCorpDimensionsLocation.locationCode,
      productCode: response.data.listCorpDimensionsProduct.productCode,
      projectCode: response.data.listCorpDimensionsProject.projectCode
    };
    return _CorpDimensionsList;
  } catch (error: any) {
    logger.error(`Unable to load corp dimensions for product ${productId}`, error);
    return {} as CorpDimensionsList;
  }
};
