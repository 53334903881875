import { Alert, Button } from '@amzn/awsui-components-react';
import React, { useEffect } from 'react';

export const ErrorFallback = (error: any, resetErrorBoundary: any) => {
  return (
    <Alert type="error" dismissible={false} visible={true} header="Error" action={<Button onClick={resetErrorBoundary}>Try again</Button>}>
      {error.message}
    </Alert>
  );
};
