import { Box, Button, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';

export const TableNoMatchState = (props: any) => (
  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>No matches</b>
        <Box variant="p" color="inherit">
          We can't find a match.
        </Box>
      </div>
      <Button onClick={props.onClearFilter}>Clear filter</Button>
    </SpaceBetween>
  </Box>
);

interface TableEmptyStateProps {
  resourceName: string;
}

export const TableEmptyState = ({ resourceName }: TableEmptyStateProps) => (
  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>No {resourceName.toLowerCase()}s</b>
        <Box variant="p" color="inherit">
          No {resourceName.toLowerCase()}s associated with this resource.
        </Box>
      </div>
    </SpaceBetween>
  </Box>
);
