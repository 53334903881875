import React, { useState, useEffect, useContext, createContext } from 'react';
import { HelpPanel, ExpandableSection, Icon, Link } from '@amzn/awsui-components-react';
import { eSIMLinks } from 'src/utils/DmmConstants';

export const ProductLineToolBar = () => {
  return (
    <HelpPanel
      header={<h2>More Info</h2>}
      footer={
        <div>
          <h3>
            Useful Links <Icon name="external" />
          </h3>
          <ul>
            <li>
              <Link external href={eSIMLinks.ISSUE_REPORT}>
                Contact Tool Support
              </Link>
            </li>
          </ul>
        </div>
      }
    >
      <h3>Description</h3>
      <p>This page is used to manage Product Lines.</p>

      <h3>How to use</h3>
      <div>
        <p>{'Add a Product Line'}</p>
        <ol>
          <li>
            Click <b>Add</b> - Opens a side panel
          </li>
          <li>
            Select a value from <b>Product Code</b> dropdown
            <ul>
              This actually auto fills the <b>Product Line</b> fill. But you can still edit it.
            </ul>
          </li>
          <li>Submit changes by clicking Create</li>
        </ol>
      </div>

      <div>
        <p>{'Edit a Product Line'}</p>
        <ol>
          <li>Select a row from the table</li>
          <li>
            Click <b>Edit</b> - Opens a side panel
          </li>
          <li>
            <b>Product Code</b> is readonly
          </li>
          <li>Update the Product Line name</li>
          <li>Un toggle to disable the Product Line</li>
          <li>Submit changes by clicking Update</li>
        </ol>
      </div>
    </HelpPanel>
  );
};
