import { FlashbarProps } from '@amzn/awsui-components-react';
import { createContext, useContext } from 'react';
import {
  ProductLineCampaignsEntity,
  ProductLine,
  CorpDimensionsList,
  ListProductLineDimensions,
  CountryAndCompanyCodeMapping
} from './CampaignModels';

export type CampaignsContextType = {
  campaignItems: ProductLineCampaignsEntity[];
  setCampaignItems: (campaignItems: ProductLineCampaignsEntity[]) => void;
  campaignsLoading: boolean;
  setCampaignsLoading: (campaignsLoading: boolean) => void;
  filteredCampaignItems: ProductLineCampaignsEntity[];
  setFilteredCampaignItems: (campaignItems: ProductLineCampaignsEntity[]) => void;
  corpDimensionsList: CorpDimensionsList;
  setCorpDimensionsList: (corpDimension: CorpDimensionsList) => void;
  countryCompanyMapping: CountryAndCompanyCodeMapping[];
  setCountryAndCompanyMapping: (countryCompanyMapping: CountryAndCompanyCodeMapping[]) => void;
  selectedProductLine: ProductLine;
  setSelectedProductLine: (productLine: ProductLine) => void;
  listProductLineDimensions: ListProductLineDimensions[];
  setListProductLineDimensions: (listProductLineDimensions: ListProductLineDimensions[]) => void;
  toolsOpen: boolean;
  setToolsOpen: (toolOpen: boolean) => void;
  notificationHandler: (status: boolean, message: string, flashbarType: FlashbarProps.Type) => void;
  refreshCampaigns: () => void;
};

export const CampaignsContext = createContext<CampaignsContextType>({
  campaignItems: [],
  setCampaignItems: (campaigns) => {},
  campaignsLoading: false,
  setCampaignsLoading: (campaignsLoading: boolean) => {},
  filteredCampaignItems: [],
  setFilteredCampaignItems: (campaigns) => {},
  corpDimensionsList: {} as CorpDimensionsList,
  setCorpDimensionsList: (corpDimension: CorpDimensionsList) => {},
  countryCompanyMapping: [],
  setCountryAndCompanyMapping: (countryCompanyMapping: CountryAndCompanyCodeMapping[]) => {},
  selectedProductLine: {} as ProductLine,
  setSelectedProductLine: (productLine) => {},
  listProductLineDimensions: [],
  setListProductLineDimensions: (listProductLineDimensions) => {},
  toolsOpen: false,
  setToolsOpen: (toolOpen: boolean) => {},
  notificationHandler: (status: boolean, notificationMessage: string, notificationType: FlashbarProps.Type) => {},
  refreshCampaigns: () => {}
});

export const useCampaigns = () => useContext(CampaignsContext);
