import { REQUIRED_FIELD } from 'src/utils/FormValidationUtils';
import { BaseBreadcrumbs, eUserTypes } from 'src/utils/DmmConstants';
import * as Yup from 'yup';
import { AppContext, UserAuthContext, FpnaDimensionModel, ListFpnaDimensionValues, ListFpnaDimensionsEntity, User } from '../context/AppContextModel';
import {
  CampaignCorpDimensions,
  CampaignFpnaDimensionsEntity,
  CampaignsForm,
  DropdownModel,
  DropdownModelWithDescription,
  ProductLine,
  ProductLineCampaignsEntity
} from './CampaignModels';

export const getUserBreadcrumbs = (CampaignId: any) => {
  if (CampaignId) {
    return [
      ...BaseBreadcrumbs,
      {
        text: 'Campaigns',
        href: '/campaigns'
      },
      {
        text: 'Campaign ' + CampaignId,
        href: '#!'
      }
    ];
  } else {
    return [
      ...BaseBreadcrumbs,
      {
        text: 'Campaigns',
        href: '#!'
      }
    ];
  }
};

// Parsing listProductLineCampaigns API response to Campaigns Table
export const parseCampaignData = (listProductLineCampaigns: ProductLineCampaignsEntity[]) => {
  const result = listProductLineCampaigns.map((productLineCampaigns) => {
    // split out dimensions for table since property filter can't process nested data
    let fpnaDimensions: any = {};
    productLineCampaigns?.fpnaDimensions?.forEach((dim: CampaignFpnaDimensionsEntity) => {
      fpnaDimensions[dim.dimensionKey] = dim.dimensionValue;
    });

    const campaign = { ...productLineCampaigns, country: productLineCampaigns.country ?? '' };
    const status = { status: productLineCampaigns.isActive ? 'Active' : 'Inactive' }; // display status as string for property filter
    const result = { ...campaign, ...status, ...fpnaDimensions };
    return result;
  });
  return result;
};

// Parsing selected Campaign to Campaign Details FORM
// API to FORM
export const getSelectedCampaignFormValues = (campaign: ProductLineCampaignsEntity) => {
  let dimensions: any = {};
  campaign?.fpnaDimensions?.forEach((dim: any) => {
    dimensions[dim.dimensionKey] = { label: `${dim.dimensionValue}`, value: `${dim.dimensionValue}` };
  });
  let formValues = {
    campaignId: campaign.campaignId,
    campaignName: campaign.campaignName,
    isActive: campaign.isActive,
    efficiencyGoal: campaign.efficiencyGoal,
    country: {
      label: campaign.country ? campaign.country : '',
      value: campaign.country ? campaign.country : ''
    },
    revenueEstimate: {
      label: campaign.revenueEstimate ? campaign.revenueEstimate : '-',
      value: campaign.revenueEstimate ? campaign.revenueEstimate : ''
    },
    businessCategory: {
      label: campaign.businessCategory ? campaign.businessCategory : '-',
      value: campaign.businessCategory ? campaign.businessCategory : ''
    },
    businessOwner: {
      label: campaign.businessOwner ? campaign.businessOwner : '-',
      value: campaign.businessOwner ? campaign.businessOwner : ''
    },
    productLine: {
      productID: campaign.productLine.productID,
      productName: campaign.productLine.productName,
      isFpnaManagedPl: campaign.productLine.isFpnaManagedPl
    }
  };
  let result = { ...dimensions, ...formValues };
  return result;
};

/*
 * takes campaign form data values and list of campaigns
 * parses for modifying multiple campaigns
 */
// FORM to API
export const parseCampaignFormValues = (
  formValues: any,
  selectedProductLine: ProductLine,
  corpDimensions: CampaignCorpDimensions[] | [],
  user: UserAuthContext,
  appContext: AppContext
) => {
  // we don't know what dimensions or formValues there will be at runtime, so we need to populate from dimension list
  let campaignFPNADimensions = appContext.appMetadata.listFpnaDimensions.map((dimension) => {
    return {
      dimensionId: dimension.dimensionId,
      dimensionName: dimension.dimensionName,
      //@ts-ignore
      dimensionValue: formValues[dimension.dimensionKey].value,
      dimensionKey: dimension.dimensionKey
    };
  });

  let result = {
    campaignId: formValues.campaignId ? formValues.campaignId : null,
    campaignName: formValues.campaignName,
    country: formValues.country.value,
    businessOwner: formValues.businessOwner.value ? formValues.businessOwner.value : null,
    efficiencyGoal: formValues.efficiencyGoal,
    revenueEstimate: formValues.revenueEstimate.value ? formValues.revenueEstimate.value : null,
    businessCategory: formValues.businessCategory.value ? formValues.businessCategory.value : null,
    dataClassification: {
      dataClassificationId: appContext.userDetails.dataClassification.dataClassificationId
    },
    createdBy: user.Alias,
    updatedBy: user.Alias,
    isActive: formValues.isActive,
    productLine: {
      productID: selectedProductLine.productID
    },
    fpnaDimensions: campaignFPNADimensions,
    corpDimensions: corpDimensions.map(({ createdBy, createdTime, updatedTime, ...restAllFields }) => restAllFields)
  } as CampaignsForm;
  return [result];
};

// Fetch FPNA dimension Names for column names
export const getFPNADimensionsAsColumnNames = (fpnaDimensions: ListFpnaDimensionsEntity[]): string[] => {
  return fpnaDimensions.map((fpnaDimension) => fpnaDimension.dimensionKey);
};

// Parsing FPNA dimensions to suite according to Campaign Table
export const parseFpnaDimensionData = (dimensionData: ListFpnaDimensionsEntity[]) => {
  let result: { key: string; name: string }[] = dimensionData.map((row) => ({
    key: row.dimensionKey,
    name: row.dimensionName
  }));
  return result;
};

/*
 * gets the current user's authorized product lines
 */
export const getProductLineUsers = (allUsers: User[], productLineId: number): DropdownModelWithDescription[] => {
  const productLineUserTypeId = 3; // product line user type id
  let result: DropdownModelWithDescription[] = [];
  allUsers.forEach((user) => {
    user.productLine.forEach((product) => {
      // Removed logic of restricting to only Product Line Users.
      // if (product.productID == productLineId && user.isActive && user.userType.userTypeId == productLineUserTypeId) {
      if (product.productID == productLineId && user.isActive && user.userType.userTypeDescription !== eUserTypes.FPNA_USER) {
        result.push({
          label: `${user.userAlias}`,
          value: user.userAlias,
          description: user.userName
        });
      }
    });
  });
  return result.sort((a, b) => a.label.localeCompare(b.label));
};

export const FormInitialValues: any = {
  campaignId: null,
  campaignName: '',
  efficiencyGoal: '',
  country: { label: '', value: '' },
  revenueEstimate: { label: '', value: '' },
  businessCategory: { label: '', value: '' },
  isActive: true,
  businessOwner: { label: '', value: '' }
};

const yupRequiredDropdownField = () => {
  return Yup.object().shape({
    label: Yup.string().required(REQUIRED_FIELD)
  });
};
export const CampaignFormSchema = Yup.object().shape({
  campaignName: Yup.string().required(REQUIRED_FIELD),
  country: yupRequiredDropdownField()
});

// To setup Form Fields with corresponding dropdown values
export const parseDimensionData = (listFpnaDimensionsEntity: ListFpnaDimensionsEntity[]) => {
  let result: FpnaDimensionModel[] = listFpnaDimensionsEntity.map((listFpnaDimension: ListFpnaDimensionsEntity) => ({
    key: listFpnaDimension.dimensionKey,
    name: listFpnaDimension.dimensionName,
    dropdownValues: mapDimensionForDropdowns(listFpnaDimension.dimensionValues),
    isRequired: listFpnaDimension.isRequired
  }));
  return result; // ?.sort((a, b) => a.name.localeCompare(b.name));
};

/*
*	value: string;
  valueDesc: string | null;
  conditionalRelationship: FpnaDimensionConditionalRelationship[]
*/
export const mapDimensionForDropdowns = (listFpnaDimensionValues: ListFpnaDimensionValues[] | null) => {
  let result = [];
  result = listFpnaDimensionValues
    ? listFpnaDimensionValues.map((listFpnaDimension: ListFpnaDimensionValues) => {
        return {
          label: listFpnaDimension.value,
          value: `${listFpnaDimension.value}`
        };
      })
    : [];
  return result?.sort((a, b) => a.label.localeCompare(b.label));
};

export const updateFormDimensionValues = (dimensionData: FpnaDimensionModel[]) => {
  let result = FormInitialValues;
  dimensionData.forEach((dimension) => {
    result[dimension.key] = { label: '', value: '' }; // initialize dimension dropdown form values
  });
  return result;
};
