import React, { useState, useEffect, useContext, createContext } from 'react';
import { API } from 'aws-amplify';
import { logger } from 'src/utils/Logger';
import * as queries from 'src/graphql/queries';
import * as custom_queries from 'src/utils/custom-queries';
import {
  LoadingStatus,
  AppContext,
  AppMetadata as AppMetadata,
  User,
  UserDetails,
  UserAuthContext,
  ProductLine,
  ListFpnaDimensionsEntity
} from './AppContextModel';
import { useAuth } from './AuthContext';
import { configureAgGridLicense } from 'src/utils/AWSServices';
import { LoadingSpinner } from '../generic-components/Spinner';
import { eUserTypes, LDAP_AD3_TEAM } from 'src/utils/DmmConstants';

const AppContextInitialData: AppContext = {
  users: [],
  setUsers: (user) => {},
  userDetails: {} as UserDetails,
  setUserDetails: (userDetails: UserDetails) => {},
  appMetadata: {} as AppMetadata,
  setAppMetadata: (appMetadata) => {},
  contextLoadingError: null,
  contextLoadingStatus: LoadingStatus.NotInitiated
};
const AppContextDetails = createContext(AppContextInitialData);
export const useAppContext = () => {
  return useContext(AppContextDetails);
};

// AppContextProvider is used to create context that are useful across the application.
export const AppContextProvider = ({ children }: any) => {
  const userAuthDetails = useAuth();

  const [users, setUsers] = useState<User[]>([]);
  const [userDetails, setUserDetails] = useState<UserDetails>({} as UserDetails);
  const [appMetadata, setAppMetadata] = useState<AppMetadata>({} as AppMetadata);
  const [contextLoadingError, setContextLoadingError] = useState<string | null>(null);
  const [contextLoadingStatus, setContextLoadingStatus] = useState<LoadingStatus>(LoadingStatus.NotInitiated);

  useEffect(() => {
    setContextLoadingStatus(LoadingStatus.Loading);
    Promise.all([getAllUsers(), getUserDetails(userAuthDetails.Alias), getAppMetadata(), configureAgGridLicense()])
      .then(async (values) => {
        setUsers(values[0]);
        const userDetails = configureUserDetailsBasedOnUserGroup(userAuthDetails, values[0], values[1], values[2]);
        const appMetadata = await configureFPNADimensions(userDetails, values[2]);
        setUserDetails(userDetails);
        setAppMetadata(appMetadata);
        setContextLoadingError(null);
        setContextLoadingStatus(LoadingStatus.Completed);
      })
      .catch((err) => {
        const errorMessage = 'Error while loading context. Some of the features in Application might not work as expected.';
        setContextLoadingStatus(LoadingStatus.Failed);
        setContextLoadingError(errorMessage);
        logger.error(errorMessage);
      });
  }, []);

  return (
    <>
      {contextLoadingStatus === LoadingStatus.Completed && (
        <AppContextDetails.Provider
          value={{ users, setUsers, userDetails, setUserDetails, appMetadata, setAppMetadata, contextLoadingStatus, contextLoadingError }}
        >
          {children}
        </AppContextDetails.Provider>
      )}
      {contextLoadingStatus === LoadingStatus.Loading && <LoadingSpinner />}
    </>
  );
};

const isAd3TeamMember = (userAuthDetails: UserAuthContext) => userAuthDetails.UserLdaps.includes(LDAP_AD3_TEAM);

const configureUserDetailsBasedOnUserGroup = (
  userAuthDetails: UserAuthContext,
  users: User[],
  userDetails: UserDetails,
  appMetadata: AppMetadata
): UserDetails => {
  if (isAd3TeamMember(userAuthDetails)) {
    logger.info('Logged in user is an ad3-team member.');
    return {
      isActive: true,
      isReadOnly: true,
      userType: appMetadata.listUserTypes.find((userType) => userType.userTypeDescription === eUserTypes.FPNA_USER),
      dataClassification: appMetadata.listDataClassification[0],
      userProductLines: appMetadata.listProducts.sort((a: ProductLine, b: ProductLine) => (a.productName > b.productName ? 1 : -1))
    } as UserDetails;
  } else {
    logger.info(`Logged in user status is ${userDetails?.isActive ? 'active' : 'inactive'}.`);
    const userProductLines = users.find((user: User) => user.userAlias === userAuthDetails.Alias)?.productLine;
    return {
      ...userDetails,
      // In case of not an existing user, backend returns null
      isActive: userDetails?.isActive ? userDetails?.isActive : false,
      isReadOnly: userDetails?.isReadOnly ? userDetails?.isReadOnly : true,
      userProductLines: userProductLines ? userProductLines.sort((a: ProductLine, b: ProductLine) => (a.productName > b.productName ? 1 : -1)) : []
    };
  }
};

export const configureFPNADimensions = async (userDetails: UserDetails, appMetadata: AppMetadata) => {
  let updateAppMetadata: AppMetadata = {
    ...appMetadata,
    listCurrentPlanningCycles: [],
    listFpnaDimensions: []
  };

  try {
    // In case of not an existing user, backend returns null
    if (userDetails?.dataClassification?.dataClassificationId) {
      const planningCycles = await listCurrentPlanningCycles(userDetails.dataClassification.dataClassificationId);
      const fpnaDimensions: ListFpnaDimensionsEntity[] = await listFpnaDimensions(userDetails.dataClassification.dataClassificationId);
      return {
        ...updateAppMetadata,
        listCurrentPlanningCycles: planningCycles,
        listFpnaDimensions: fpnaDimensions
      } as AppMetadata;
    } else {
      return updateAppMetadata;
    }
  } catch (error: any) {
    logger.error('unable to configure FPNA Dimensions ', error);
    return updateAppMetadata;
  }
};

// call listCurrentPlanningCycles
export const listCurrentPlanningCycles = async (dataClassificationId: number) => {
  try {
    const response: any = await API.graphql({
      query: queries.listCurrentPlanningCycles,
      variables: { dataClassificationId: dataClassificationId }
    });
    return response.data.listCurrentPlanningCycles;
  } catch (error: any) {
    logger.error(`Unable to load Planning Cycles. `, error);
    return [];
  }
};

// call listFpnaDimensions
export const listFpnaDimensions = async (dataClassificationId: number) => {
  try {
    const response: any = await API.graphql({
      query: custom_queries.getFpnaDimensions(dataClassificationId)
    });
    return response.data.listFpnaDimensions;
  } catch (error: any) {
    logger.error(`Unable to load FPNA Dimensions. `, error);
    return [];
  }
};

// call getUserAuthorizationDetails
export const getUserDetails = async (userAlias: string) => {
  try {
    const getUserAuthorizationResponse: any = await API.graphql({
      query: queries.getUserAuthorization,
      variables: { userAlias: userAlias }
    });
    return getUserAuthorizationResponse.data.getUserAuthorization;
  } catch (error: any) {
    logger.error(`Unable to load User Authorization for ${userAlias}. `, error);
    throw new Error(error);
  }
};

// Calls custom-query GetMetadataQuery to collect dimensional data at once
export const getAppMetadata = async () => {
  try {
    const listAppMetadataResponse: any = await API.graphql({
      query: custom_queries.getMetadataQuery()
    });
    return listAppMetadataResponse.data;
  } catch (graphQlError: any) {
    logger.error('Unable to App Metadata ', { error: graphQlError });
    throw new Error(graphQlError.errors);
  }
};

// Calls listUsers query to get all the users.
// Separating this query to re-use this query multiple times
export const getAllUsers = async () => {
  try {
    const listUsersResponse: any = await API.graphql({ query: queries.listUsers });
    return listUsersResponse.data.listUsers;
  } catch (graphQlError: any) {
    logger.error('Unable to load users. ', { error: graphQlError });
    throw new Error(graphQlError.errors);
  }
};
