import { useMemo } from 'react';
import { eUserTablePreferenceKeys } from 'src/utils/DmmConstants';
import { useAsyncDynamoStorage } from './use-dynamo-storage';

export function useColumnWidths(storageKey: eUserTablePreferenceKeys, columnDefinitions: any[]) {
  const [widths, saveWidths, loading] = useAsyncDynamoStorage(storageKey);

  function handleWidthChange(event: any) {
    saveWidths(mapWithColumnDefinitionIds(columnDefinitions, 'width', event.detail.widths));
  }
  const memoDefinitions = useMemo(() => {
    return addToColumnDefinitions(columnDefinitions, 'width', widths);
  }, [widths, columnDefinitions]);

  return [memoDefinitions, handleWidthChange, loading];
}

export const addToColumnDefinitions = (columnDefinitions: any[], propertyName: string, columns: any) =>
  columnDefinitions.map((colDef) => {
    const column = (columns || []).find((col: any) => col.id === colDef.id);
    return {
      ...colDef,
      [propertyName]: (column && column[propertyName]) || colDef[propertyName]
    };
  });

export const mapWithColumnDefinitionIds = (columnDefinitions: any[], propertyName: string, items: any[]) =>
  columnDefinitions.map(({ id }, index) => ({
    id,
    [propertyName]: items[index]
  }));
