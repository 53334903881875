import React, { useEffect } from 'react';
import { AppContextProvider } from './context/AppContextProvider';

// All SCSS imports at one place
import '../styles/dmm-styles.scss';
import '../styles/header.scss';
import '../styles/ag-grid-override.scss';
import { useAuth } from './context/AuthContext';
import { logger } from 'src/utils/Logger';
import { LoadingStatus } from './context/AppContextModel';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from './generic-components/ErrorFallback';
import { AppRouter } from './navigation/AppRouter';

export const App = () => {
  const userAuth = useAuth();
  useEffect(() => {
    if (userAuth.userAuthDataLoadingStatus === LoadingStatus.Completed) {
      logger.info('User authenticated successfully!');
    } else if (userAuth.userAuthDataLoadingStatus === LoadingStatus.Failed) {
      logger.error('User authentication failed!');
    }
  }, [userAuth]);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        window.location.reload();
      }}
    >
      <AppContextProvider>
        {/* <AppSyncSubscriptionService /> */}
        <AppRouter />
      </AppContextProvider>
    </ErrorBoundary>
  );
};
