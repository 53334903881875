import { Alert, AppLayout, Box, Container, ContentLayout, Header, SpaceBetween, Tabs, TabsProps } from '@amzn/awsui-components-react';
import React, { useState, useEffect, useMemo } from 'react';
import { BaseBreadcrumbs } from 'src/utils/DmmConstants';
import { useAppContext } from '../../context/AppContextProvider';
import { AppBreadcrumb } from '../../navigation/AppBreadcrumb';
import { AppSideNavigation } from '../../navigation/AppSideNavigation';
import { API } from 'aws-amplify';
import * as queries from 'src/graphql/queries';
import { LoadingSpinner } from '../../generic-components/Spinner';
import { logger } from 'src/utils/Logger';
import { QuickSightDashboardReport } from './QuickSightDashboard';

export interface QuicksightReports {
  tabId: string;
  tabName: string;
  dashboardId: string;
}

const resourceBreadcrumbs = [
  ...BaseBreadcrumbs,
  {
    text: 'Reporting',
    href: '/reporting'
  },
  {
    text: 'Reports',
    href: '#!'
  }
];

export const ConsolidatedReport = () => {
  const appContext = useAppContext();
  const [visible, setVisible] = React.useState(true);
  const [activeTabId, setActiveTabId] = React.useState('');
  const [reports, setReports] = useState<QuicksightReports[]>([]);
  const [quicksightLoading, setQuicksightLoading] = useState<boolean>(true);

  useEffect(() => {
    loadQuickSightDashboardIds();
  }, []);

  const loadQuickSightDashboardIds = async () => {
    setQuicksightLoading(true);
    try {
      const response: any = await API.graphql({
        query: queries.listQuicksightReports
      });
      const listOfReports: QuicksightReports[] = response.data.listQuicksightReports;
      setReports(listOfReports);
      setQuicksightLoading(false);
      setActiveTabId(response.data.listQuicksightReports.length != 0 ? response.data.listQuicksightReports[0].TabId : '');
    } catch (error: any) {
      logger.error('Unable to load Quick Sight Dashboards ', error);
      setQuicksightLoading(false);
      setReports([]);
      setActiveTabId('');
    }
  };

  const getTabs = (): TabsProps.Tab[] => {
    return reports.map((report) => {
      return {
        id: report.tabId,
        label: report.tabName,
        content: (
          <>
            <QuickSightDashboardReport quickSightReportInfo={report}></QuickSightDashboardReport>
          </>
        )
      };
    }) as [];
  };

  return (
    <AppLayout
      headerSelector="#h"
      contentType="default"
      navigation={<AppSideNavigation />}
      breadcrumbs={<AppBreadcrumb items={resourceBreadcrumbs} />}
      toolsHide
      disableContentPaddings
      content={
        <ContentLayout>
          <Box margin={{ top: 'l', bottom: 'xl', left: 'xxxl', right: 'xxxl' }} padding={{ left: 'xxxl', right: 'xxxl' }}>
            {quicksightLoading && <LoadingSpinner />}
            {!quicksightLoading && (
              <>
                {reports.length > 0 && (
                  <Tabs
                    variant="container"
                    onChange={({ detail }) => setActiveTabId(detail.activeTabId)}
                    activeTabId={activeTabId}
                    tabs={getTabs()}
                  />
                )}
                {reports.length === 0 && (
                  <>
                    <Alert onDismiss={() => setVisible(false)} visible={visible} dismissAriaLabel="Close alert" header="Known issues/limitations">
                      No reports available at the moment
                    </Alert>
                  </>
                )}
              </>
            )}
          </Box>
        </ContentLayout>
      }
    />
  );
};
