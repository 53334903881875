import React from 'react';
import { HelpPanel, Link } from '@amzn/awsui-components-react';
import { eSIMLinks } from 'src/utils/DmmConstants';
import { DocumentationLink } from 'src/components/landing-page/landing-page-constants';

export const ForecastTemplateToolBar = () => {
  return (
    <HelpPanel
      header={<h2>More Info</h2>}
      footer={
        <div>
          <div>
            <h3>Contact Us</h3>
            <ul>
              <li>
                <Link external href={eSIMLinks.ISSUE_REPORT}>
                  Contact Tool Support
                </Link>
              </li>
            </ul>
          </div>
          <h3>Learn more</h3>
          <ul>
            <li>
              <Link external href={DocumentationLink}>
                Efficiency Goals
              </Link>
            </li>
          </ul>
        </div>
      }
    >
      <h3>Description</h3>
      <p>Product Lines forecast marketing spend amounts and efficiency goals for quarterly guidance and OP planning.</p>

      <h3>How to use</h3>
      <div>
        <h5>To view the forecast template and submit changes, please follow these steps:</h5>
        <ol>
          <li>Select the desired Product Line and Planning Cycle for the forecast.</li>
          <li>Once the Product Line and Planning Cycle are selected, the Campaign and Country dropdowns will be automatically populated.</li>
          <li>Choose the specific campaigns and countries you wish to include in the forecast template.</li>
          <li>Fill out the forecast amounts for each campaign in USD.</li>
          <li>Click "Submit changes" to update the forecast template.</li>
        </ol>
      </div>

      <div>
        <h5>Export/Import from Excel - Instructions:</h5>
        <ol>
          <li>Select the desired Product Line and Planning Cycle for the forecast.</li>
          <li>Once the Product Line and Planning Cycle are selected, the Campaign and Country dropdowns will be automatically populated.</li>
          <li>Choose the specific campaigns and countries you wish to include in the forecast template.</li>
          <li>After the forecast template is loaded, click "Export to Excel" to save the forecast template as an Excel file.</li>
        </ol>
        <h5>Guidelines for Modifying the Exported Excel File:</h5>
        <ol>
          <li>Do not remove or add any columns or rows.</li>
          <li>
            All columns, except for "Forecast Months," are provided for informational purposes only. Please refrain from modifying data in any other
            columns.
          </li>
          <li>
            If the values of <code>productLineCampaignCorpId</code> and <code>productLineCampaignId</code> are changed, you will not be able to upload
            the file.
          </li>
          <li>
            Changing values in any other columns, apart from "Forecast Months," <code>productLineCampaignCorpId</code>, and{' '}
            <code>productLineCampaignId</code>, will not affect any functionality.
          </li>
          <li>Click "Import from Excel" to import the forecast template from an Excel file.</li>
        </ol>
      </div>
    </HelpPanel>
  );
};
