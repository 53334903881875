/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const closePlanningCycle = /* GraphQL */ `
  mutation ClosePlanningCycle($dataClassificationId: Int!, $scenarioTemplateId: Int!, $userAlias: String!) {
    closePlanningCycle(dataClassificationId: $dataClassificationId, scenarioTemplateId: $scenarioTemplateId, userAlias: $userAlias) {
      generatedFields
      numberOfRecordsUpdated
    }
  }
`;
export const createForecastTemplateStatus = /* GraphQL */ `
  mutation CreateForecastTemplateStatus($input: ForecastTemplateStatusInput!) {
    createForecastTemplateStatus(input: $input) {
      generatedFields
      numberOfRecordsUpdated
    }
  }
`;
export const createPlanningCycle = /* GraphQL */ `
  mutation CreatePlanningCycle($input: [PlanningCycleInput]!) {
    createPlanningCycle(input: $input) {
      generatedFields
      numberOfRecordsUpdated
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct($input: CorpProductLineInput!) {
    createProduct(input: $input) {
      generatedFields
      numberOfRecordsUpdated
    }
  }
`;
export const createProductLineCampaigns = /* GraphQL */ `
  mutation CreateProductLineCampaigns($input: [ProductLineCampaignsInput]!) {
    createProductLineCampaigns(input: $input) {
      generatedFields
      numberOfRecordsUpdated
    }
  }
`;
export const createProductLineDimensions = /* GraphQL */ `
  mutation CreateProductLineDimensions($input: [ProductLineDimensionsInput]!) {
    createProductLineDimensions(input: $input) {
      generatedFields
      numberOfRecordsUpdated
    }
  }
`;
export const updateCampaignsDimMapping = /* GraphQL */ `
  mutation UpdateCampaignsDimMapping($input: [CampaignsDimMappingInput]!) {
    updateCampaignsDimMapping(input: $input) {
      generatedFields
      numberOfRecordsUpdated
    }
  }
`;
export const updateCampaignsPurchaseOrderMapping = /* GraphQL */ `
  mutation UpdateCampaignsPurchaseOrderMapping($input: [CampaignsPurchaseOrderMappingInput]!) {
    updateCampaignsPurchaseOrderMapping(input: $input) {
      generatedFields
      numberOfRecordsUpdated
    }
  }
`;
export const updateForecastEfficiencyGoals = /* GraphQL */ `
  mutation UpdateForecastEfficiencyGoals($input: [ForecastEfficiencyGoalsInput]!) {
    updateForecastEfficiencyGoals(input: $input) {
      generatedFields
      numberOfRecordsUpdated
    }
  }
`;
export const updateForecastTemplateStatus = /* GraphQL */ `
  mutation UpdateForecastTemplateStatus($input: ForecastTemplateStatusInput!) {
    updateForecastTemplateStatus(input: $input) {
      generatedFields
      numberOfRecordsUpdated
    }
  }
`;
export const updateForecastTemplates = /* GraphQL */ `
  mutation UpdateForecastTemplates($input: [ForecastTemplateInput]!) {
    updateForecastTemplates(input: $input) {
      generatedFields
      numberOfRecordsUpdated
    }
  }
`;
export const updatePlanningCycle = /* GraphQL */ `
  mutation UpdatePlanningCycle($input: [PlanningCycleInput]!) {
    updatePlanningCycle(input: $input) {
      generatedFields
      numberOfRecordsUpdated
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct($input: ProductLineDetailInput!) {
    updateProduct(input: $input) {
      generatedFields
      numberOfRecordsUpdated
    }
  }
`;
export const updateProductLineCampaigns = /* GraphQL */ `
  mutation UpdateProductLineCampaigns($input: [ProductLineCampaignsInput]!) {
    updateProductLineCampaigns(input: $input) {
      generatedFields
      numberOfRecordsUpdated
    }
  }
`;
export const updateProductLineDimensions = /* GraphQL */ `
  mutation UpdateProductLineDimensions($input: [ProductLineDimensionsInput]!) {
    updateProductLineDimensions(input: $input) {
      generatedFields
      numberOfRecordsUpdated
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UserProductInput!) {
    updateUser(input: $input) {
      generatedFields
      numberOfRecordsUpdated
    }
  }
`;
