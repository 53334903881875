import React from 'react';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import {
  Box,
  Button,
  FormField,
  Header,
  Multiselect,
  MultiselectProps,
  RadioGroup,
  RadioGroupProps,
  SpaceBetween
} from '@amzn/awsui-components-react';
import { useAppContext } from '../context/AppContextProvider';
import { eUserTypes } from 'src/utils/DmmConstants';
import { getMultiSelectPlaceHolderValue } from '../generic-components/Utils';

interface DebuggerComponentProps {
  showModal: boolean;
  closeModal: () => void;
}
export const DebuggerComponent = ({ showModal, closeModal }: DebuggerComponentProps) => {
  const appContext = useAppContext();

  const currentUserType = appContext.userDetails.userType?.userTypeId?.toString();
  const [userTypeSelection, setUserTypeSelection] = React.useState(currentUserType ? currentUserType : null);
  const [productLineSelection, setProductLineSelection] = React.useState<MultiselectProps.Options>([]);
  const [userActiveStatus, setUserActiveStatus] = React.useState('active');

  const selectedUserType = appContext.appMetadata.listUserTypes.find(
    (userType) => userType.userTypeId === (userTypeSelection ? +userTypeSelection : null)
  );
  const isProductLineUser = selectedUserType?.userTypeDescription === eUserTypes.PRODUCT_LINE_USER;
  const isProductLineLead = selectedUserType?.userTypeDescription === eUserTypes.PRODUCT_LINE_LEAD;

  const onCancel = () => {
    closeModal();
  };

  const onConfirm = () => {
    if (selectedUserType) {
      switch (selectedUserType.userTypeDescription) {
        case eUserTypes.FPNA_USER:
          appContext.setUserDetails({
            ...appContext.userDetails,
            userType: { userTypeId: selectedUserType?.userTypeId, userTypeDescription: selectedUserType?.userTypeDescription },
            userProductLines: appContext.appMetadata.listProducts,
            isActive: userActiveStatus === 'active' ? true : false
          });
          break;
        case eUserTypes.PRODUCT_LINE_LEAD:
          appContext.setUserDetails({
            ...appContext.userDetails,
            userType: { userTypeId: selectedUserType?.userTypeId, userTypeDescription: selectedUserType?.userTypeDescription },
            userProductLines: productLineSelection.map((productLine: any) => {
              return { productID: +productLine.value, productName: productLine.label.toString(), isFpnaManagedPl: false };
            }),
            isActive: userActiveStatus === 'active' ? true : false
          });
          break;
        case eUserTypes.PRODUCT_LINE_USER:
          appContext.setUserDetails({
            ...appContext.userDetails,
            userType: { userTypeId: selectedUserType?.userTypeId, userTypeDescription: selectedUserType?.userTypeDescription },
            userProductLines: productLineSelection.map((productLine: any) => {
              return { productID: +productLine.value, productName: productLine.label.toString(), isFpnaManagedPl: false };
            }),
            isActive: userActiveStatus === 'active' ? true : false
          });
          break;
      }
    }
    closeModal();
  };

  return (
    <Modal
      visible={showModal}
      onDismiss={onCancel}
      header={<Header description={'Only visible to DMM dev team'}>{'Update User Details'}</Header>}
      closeAriaLabel="Close dialog"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onConfirm}>
              Confirm
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size="m" direction="vertical">
        <FormField description="User Active Status" label="Status">
          <RadioGroup
            onChange={({ detail }) => setUserActiveStatus(detail.value)}
            value={userActiveStatus}
            items={[
              { value: 'active', label: 'Active' },
              { value: 'inactive', label: 'Inactive' }
            ]}
          />
        </FormField>

        <FormField description="Changes the user type and updates corresponding validations" label="User Type">
          <RadioGroup
            onChange={({ detail }) => setUserTypeSelection(detail.value)}
            value={userTypeSelection}
            items={appContext.appMetadata.listUserTypes.map((userType) => {
              return {
                value: userType.userTypeId.toString(),
                label: userType.userTypeDescription
              };
            })}
          />
        </FormField>

        {(isProductLineUser || isProductLineLead) && (
          <FormField description="For Product Line User, select Product Lines for access" label="Product Line">
            <Multiselect
              hideTokens
              placeholder={getMultiSelectPlaceHolderValue(productLineSelection, 'Product Line')}
              filteringType="auto"
              selectedOptions={productLineSelection}
              onChange={({ detail }) => setProductLineSelection(detail.selectedOptions)}
              options={appContext.appMetadata.listProducts.map((product) => {
                return {
                  value: product.productID.toString(),
                  label: product.productName
                };
              })}
              selectedAriaLabel="Selected"
            />
          </FormField>
        )}
      </SpaceBetween>
    </Modal>
  );
};
