import { Button, ButtonDropdown, Container, Header, SpaceBetween, StatusIndicator, StatusIndicatorProps } from '@amzn/awsui-components-react';
import { CellValueChangedEvent, GetRowIdParams, GridOptions, ProcessCellForExportParams } from 'ag-grid-community';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { API } from 'aws-amplify';
import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as mutations from 'src/graphql/mutations';
import * as queries from 'src/graphql/queries';
import { cellValueParser } from 'src/utils/AgGridUtils';
import { isDefinedAndNotEmptyObject } from 'src/utils/CommonHelpers';
import { isAfterCurrentTime } from 'src/utils/DateTimeUtils';
import { exportAsExcelFile } from 'src/utils/FileServices';
import { logger } from 'src/utils/Logger';
import { CorpDimensionsList } from '../campaigns/CampaignModels';
import { getCorpDimensionsList } from '../commons/Services/GenericServices';
import { PlanningCycleDataModel } from '../context/AppContextModel';
import { useAppContext } from '../context/AppContextProvider';
import { useAuth } from '../context/AuthContext';
import { LoadingSpinner } from '../generic-components/Spinner';
import { ForecastImportModal } from './ForecastImportModal';
import { FixedColumnInformation, ForecastTemplateMutation } from './ForecastModel';
import { useForecastTemplate } from './ForecastTemplateContext';
import * as ForecastUtils from './ForecastUtils';
import { ValidateData } from './ForecastValidations';

enum MessageText {
  DataUptoDate = 'Data is current and updated',
  NoChanges = 'No changes to submit',
  EnsureNumeric = 'Please make sure all the fields are numeric',
  DataGood = "Data looks good. Please click 'Submit Changes' to update the data.",
  ClickSubmit = "Please click 'Submit Changes' to confirm and update your data."
}

interface ForecastMessage {
  colorOverride?: StatusIndicatorProps.Color;
  typeOfMessage: StatusIndicatorProps.Type;
  message: MessageText;
}

const InitialTemplateState = {
  dataLoaded: false,
  templateDataChanged: false,
  isSubmitting: false
};

export interface ForecastTemplateTableMethods {
  refreshForecast: () => void;
  loadPartialData: (forecastData: any[]) => void;
}
const MAX_ALLOWED_FORECAST_CHUNK_50000 = 50000;
export const ForecastTemplateTable = forwardRef<ForecastTemplateTableMethods, any>(({}: any, ref) => {
  const userAuthDetails = useAuth();
  const appContext = useAppContext();

  const lisFpnaDimensions = appContext.appMetadata.listFpnaDimensions;
  const dataClassificationId = appContext.userDetails?.dataClassification?.dataClassificationId;

  const {
    selectedProductLine,
    selectedPlanningCycle,
    selectedCountryDropdown,
    selectedCampaignDropdown,
    selectedRevenueEstimateDropdown,

    displayFlashMessage,
    forecastTemplateStatus,
    setForecastTemplateStatus,
    fullForecastData,
    setFullForecastData
  } = useForecastTemplate();

  const [fixedColumnInfo, setFixedColumnInfo] = useState<FixedColumnInformation>({} as FixedColumnInformation);

  // Forecast table properties
  const [forecastTableColumnDefinition, setForecastTableColumnDefinition] = useState<any>([]);
  const [partialForecastData, setPartialForecastData] = useState<any[]>([]);

  // Ag grid properties
  const [templateState, setTemplateState] = useState(InitialTemplateState);
  const gridRef = useRef<AgGridReact>({} as AgGridReact);
  const gridStyle = useMemo(() => ({ height: '70vh', width: '100%' }), []);
  const [resettingAgGridData, setResettingAgGridData] = useState(false);

  const [showForecastImportModal, setShowForecastImportModal] = useState(false);
  const [isPastLockData, setIsPastLockData] = useState(false);
  const [fileState, setFileState] = useState<ForecastMessage>({
    typeOfMessage: 'pending',
    message: MessageText.DataUptoDate,
    colorOverride: 'grey'
  });

  useEffect(() => {
    const isPastDateChanged = async () => {
      if (selectedProductLine?.productID && selectedPlanningCycle?.scenarioTemplateId) {
        configureAgGridTable(fixedColumnInfo, !isPastLockData);
      }
    };
    isPastDateChanged();
  }, [isPastLockData]);

  useEffect(() => {
    if (selectedPlanningCycle?.lockToolDate) {
      const intervalId = setInterval(() => {
        setIsPastLockData(!isAfterCurrentTime(ForecastUtils.getFinalLockDate(selectedPlanningCycle, userAuthDetails.Alias)));
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [selectedPlanningCycle]);

  const refreshForecast = () => {
    setFileState({ typeOfMessage: 'pending', message: MessageText.DataUptoDate, colorOverride: 'grey' });
    setTemplateState({ ...templateState, templateDataChanged: false });
    const fixedColumnDetails = configureMetadataInfo();
    const isPastLockDateTime = !isAfterCurrentTime(ForecastUtils.getFinalLockDate(selectedPlanningCycle, userAuthDetails.Alias));
    setFixedColumnInfo(fixedColumnDetails);
    setIsPastLockData(isPastLockDateTime);
    configureAgGridTable(fixedColumnDetails, !isPastLockDateTime && !forecastTemplateStatus.isActive);
  };

  const loadPartialData = (forecastData: any[]) => {
    let forecastDataToLoad: any[] = forecastData.length !== 0 ? forecastData : fullForecastData;
    const filteredData = forecastDataToLoad.filter((item) => {
      // Check if the country or channel values exist in the countryList and channelList
      return (
        selectedCountryDropdown.some((country) => country.value === item.country) &&
        selectedCampaignDropdown.some((campaign) => campaign.value === item.campaignName) &&
        selectedRevenueEstimateDropdown.some((revenueEstimate) => revenueEstimate.value === item.revenueEstimate)
      );
    });
    setPartialForecastData(filteredData);
    gridRef?.current?.api?.refreshCells();
    setTimeout(() => {
      autoSizeAll(false);
    }, 1000);
  };

  React.useImperativeHandle(ref, () => ({
    refreshForecast,
    loadPartialData
  }));

  const configureMetadataInfo = () => {
    const campaignColumns = [
      'productLineCampaignCorpId',
      'productLineCampaignId',
      'campaignName',
      'country',
      'businessOwner',
      'efficiencyGoal',
      'revenueEstimate',
      'businessCategory'
    ];
    const fpnaDimensionKeys = lisFpnaDimensions?.map((fpnaDimension) => fpnaDimension.dimensionKey);
    const corpDimensions = ['accountNumber', 'channelCode', 'companyCode', 'costCenter', 'locationCode', 'productCode', 'projectCode'];
    const monthsInformation = ForecastUtils.getMonthsFromPlanningCycle(selectedPlanningCycle);
    return {
      campaignColumns: campaignColumns,
      fpnaDimensionKeys: fpnaDimensionKeys,
      corpDimensions: corpDimensions,
      actualMonths: monthsInformation.actualMonths,
      forecastMonths: monthsInformation.forecastMonths
    };
  };

  const configureAgGridTable = async (fixedColumnDetails: FixedColumnInformation, isPastLockDateTime: boolean) => {
    setResettingAgGridData(true);

    const tableInfo = await Promise.all([
      fetchActualAndForecastColumnDefinitionData(fixedColumnDetails, isPastLockDateTime),
      fetchActualAndForecastRowData(),
      selectedProductLine?.productID && getCorpDimensionsList(selectedProductLine?.productID)
    ]);

    let _forecastData = tableInfo[1];
    const accountDetails = (tableInfo[2] || {}) as CorpDimensionsList;
    const _forecastDataWithAccountName = _forecastData.map((forecastData) => {
      return {
        ...forecastData,
        accountName: accountDetails.accountNumber?.find((account) => account.id === forecastData.accountNumber)?.name,
        costCenterName: accountDetails.costCenter?.find((costCenter) => costCenter.id === forecastData.costCenter)?.name
      };
    });

    setForecastTableColumnDefinition(tableInfo[0]);
    setFullForecastData(_forecastDataWithAccountName);
    loadPartialData(_forecastDataWithAccountName);

    setResettingAgGridData(false);
    gridRef?.current?.api?.refreshCells();
    setTimeout(() => {
      autoSizeAll(false);
    }, 1000);
  };

  const fetchActualAndForecastRowData = async () => {
    try {
      const response: any = await API.graphql({
        query: queries.listActualsForecastDetails,
        variables: {
          dataClassificationId: appContext.userDetails.dataClassification.dataClassificationId,
          productLineId: selectedProductLine?.productID,
          scenarioTemplateId: selectedPlanningCycle?.scenarioTemplateId
        }
      });
      const listActualsForecastDetailsResponse = response.data.listActualsForecastDetails;
      const parsedRowData = ForecastUtils.parseRowData(listActualsForecastDetailsResponse);
      return parsedRowData;
    } catch (error: any) {
      logger.error('Unable to fetch Forecast data.', error);
      displayFlashMessage(`Unable to fetch Forecast data. Please try again or reach out to Admin.`, 'error', true);
      return [];
    }
  };

  const fetchActualAndForecastColumnDefinitionData = async (fixedColumnDetails: FixedColumnInformation, isPastLockData: boolean) => {
    try {
      if (Object.keys(fixedColumnDetails).length > 0) {
        const parsedOrderedColumnDefinition = ForecastUtils.parseColumnDefinition(fixedColumnDetails, isPastLockData);
        logger.info('Fetching Forecast table headers.', { info: parsedOrderedColumnDefinition });
        return parsedOrderedColumnDefinition;
      } else {
        return [];
      }
    } catch (error: any) {
      logger.error('Unable to fetch Forecast table headers.', error);
      displayFlashMessage(`Unable to fetch Forecast table headers. Please try again or reach out to Admin.`, 'error', true);
      return [];
    }
  };

  const submitChanges = async () => {
    
    let updatedConfig: any[] = [];
    gridRef?.current?.api.forEachNode((rowNode, index) => {
      if (rowNode.data.isEdited) updatedConfig.push(rowNode.data);
    });

    if (updatedConfig?.length === 0) {
      setTemplateState({ ...templateState, isSubmitting: false });
      setFileState({ typeOfMessage: 'warning', message: MessageText.NoChanges, colorOverride: 'red' });
    } else {
      const isValidData = ValidateData(fixedColumnInfo, updatedConfig);
      if (!isValidData) {
        setTemplateState({ ...templateState, isSubmitting: false });
        setFileState({ typeOfMessage: 'error', message: MessageText.EnsureNumeric, colorOverride: 'red' });
      } else {
        displayFlashMessage('Please wait while we process your request ', 'info', false);
        setTemplateState({ ...templateState, isSubmitting: true });
        setFileState({
          typeOfMessage: 'success',
          message: MessageText.DataGood,
          colorOverride: 'green'
        });
        updatedConfig = updatedConfig?.map(({ isEdited, ...restAllFields }) => restAllFields);
        const updatedForecastTemplate: any[] = ForecastUtils.parseAgGridRowDataToMutationObject(fixedColumnInfo, updatedConfig);

        logger.info(`Updated Forecast Template length ${JSON.stringify(updatedForecastTemplate).length}`);
        logger.info(
          `Updated Forecast Template exceeds the allowed length - 
          ${JSON.stringify(updatedForecastTemplate).length > MAX_ALLOWED_FORECAST_CHUNK_50000} 
          characters`
        );

        try {
          const chunks = ForecastUtils.splitArrayIntoChunks(MAX_ALLOWED_FORECAST_CHUNK_50000, updatedForecastTemplate);
          JSON.stringify(updatedForecastTemplate).length > MAX_ALLOWED_FORECAST_CHUNK_50000 ??
            logger.info(`Requests split into ${chunks.length} chunks`);
          const { allRequestsSuccessful, results } = await processChunks(chunks, userAuthDetails.Alias, dataClassificationId, selectedPlanningCycle);

          if (allRequestsSuccessful) {
            logger.info(`All of '${chunks.length}' API requests were successful!`);
            logger.info(`Successfully updated the Forecast data for ${selectedProductLine?.productName} by ${userAuthDetails.Alias}`);
            setTemplateState({ ...templateState, isSubmitting: false, templateDataChanged: false });
            setFileState({ typeOfMessage: 'pending', message: MessageText.DataUptoDate, colorOverride: 'grey' });
            displayFlashMessage(`Successfully updated the Forecast data for ${selectedProductLine?.productName}`, 'success', true);
            refreshForecast();
          } else {
            logger.error(`At least one API request from ${chunks.length} is failed. Forecast data partially updated.`);
            setTemplateState({ ...templateState, isSubmitting: false });
            displayFlashMessage('Forecast data partially updated. Please try again or reach out to Admin.', 'error', true);
          }
        } catch (error: any) {
          logger.error('Unable to submit the request for Forecast Template update.', error);
          setTemplateState({ ...templateState, isSubmitting: false });
          displayFlashMessage('Unable to submit the request for Forecast Template update.', 'error', true);
        }
      }
    }
  };

  const processChunks = async (chunks: any[], userAlias: string, dataClassificationId: number, selectedPlanningCycle: PlanningCycleDataModel) => {
    let allRequestsSuccessful = true;
    const results = [];

    for (const chunk of chunks) {
      const forecastMeasuresUsdString = JSON.stringify(chunk);
      const mutationObject: ForecastTemplateMutation = {
        dataClassificationId: dataClassificationId,
        scenarioTemplateId: selectedPlanningCycle.scenarioTemplateId,
        isActive: true,
        updatedBy: userAlias,
        forecastMeasuresUsd: forecastMeasuresUsdString
      };
      try {
        const updateResponse = await API.graphql({
          query: mutations.updateForecastTemplates,
          variables: { input: mutationObject }
        });

        // If the API request was successful, capture the response in the results array
        results.push(updateResponse);
      } catch (error) {
        console.error('API Error:', error);
        // If the API request failed, setting the flag to indicate that at least one request failed
        allRequestsSuccessful = false;
      }
    }

    return { allRequestsSuccessful, results };
  };

  const onExport = async () => {
    const agGridColumnsList = getAllAgGridColumns();
    let downloadData: any = ForecastUtils.parseForecastDataToExcelObject(partialForecastData, agGridColumnsList, fixedColumnInfo);
    exportAsExcelFile(
      downloadData,
      selectedProductLine?.productName + '_' + selectedPlanningCycle?.scenario?.scenarioName,
      selectedProductLine?.productName + '_' + selectedPlanningCycle?.scenario?.scenarioName
    );
  };

  // File Import
  const onCancel = () => {
    setShowForecastImportModal(false);
  };

  // File Import confirm
  const onConfirm = (uploadFileData: any[]) => {
    setShowForecastImportModal(false);
    let currentRowData: any[] = [];
    gridRef?.current?.api.forEachNode((rowNode, index) => {
      currentRowData.push(rowNode.data);
    });
    currentRowData.forEach((oldObj) => {
      const newObj = uploadFileData.find(
        (newObj: any) =>
          newObj.productLineCampaignCorpId === oldObj.productLineCampaignCorpId && newObj.productLineCampaignId === oldObj.productLineCampaignId
      );
      if (newObj) {
        const updatedData = { ...oldObj, ...newObj, isEdited: true };
        newObj ? gridRef?.current?.api?.applyTransaction({ update: [updatedData] }) : oldObj;
      }
    });
    gridRef?.current?.api?.refreshCells();
    setTemplateState({ ...templateState, templateDataChanged: true });
    setFileState({
      typeOfMessage: 'info',
      message: MessageText.ClickSubmit,
      colorOverride: 'blue'
    });
  };

  // AG grid properties/events
  // TODO - onGridReady
  // Yet to implement additional functionality.
  const onGridReady = useCallback((params: any) => {}, []);

  const getRowId = (params: GetRowIdParams) => params.data.productLineCampaignCorpId;

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        { statusPanel: 'agTotalRowCountComponent', align: 'center' },
        { statusPanel: 'agFilteredRowCountComponent' },
        { statusPanel: 'agSelectedRowCountComponent' },
        { statusPanel: 'agAggregationComponent' }
      ]
    };
  }, []);

  const defaultAgGridColumnDefinition = useMemo(() => {
    return {
      resizable: true,
      width: 100,
      sortable: true,
      tooltipComponent: accountNumberToolTip
    };
  }, []);

  const onCellValueChanged = useCallback((cellValueChangedEvent: CellValueChangedEvent) => {
    cellValueChangedEvent.node.data['isEdited'] = true;
    setTemplateState({ ...templateState, templateDataChanged: true });
    setFileState({
      typeOfMessage: 'info',
      message: MessageText.ClickSubmit,
      colorOverride: 'blue'
    });
  }, []);

  const processCellFromClipboard = useCallback((params: ProcessCellForExportParams) => {
    return cellValueParser(params.value);
  }, []);

  const processCellForClipboard = useCallback((params: ProcessCellForExportParams) => {
    return cellValueParser(params.value);
  }, []);

  const autoSizeAll = useCallback((skipHeader: boolean) => {
    const allColumnIds: string[] = [];
    gridRef.current.columnApi
      .getColumns()
      ?.filter((column) => column.getId() !== 'campaignName_1' && column.getId() !== 'campaignName')
      ?.forEach((column) => {
        allColumnIds.push(column.getId());
      });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }, []);

  const getAllAgGridColumns = () => {
    const allColumnIds: string[] = [];
    gridRef?.current?.columnApi?.getColumns()?.forEach((column) => {
      allColumnIds.push(column?.getId());
    });
    return allColumnIds;
  };

  const onColumnGroupOpened = () => {
    autoSizeAll(false);
  };

  const onFirstDataRendered = () => {};

  const getNoRowsToShowMessage = () => {
    if (!isDefinedAndNotEmptyObject(selectedPlanningCycle) || !isDefinedAndNotEmptyObject(selectedProductLine)) {
      return 'Please select Product Line and Planning Cycle.';
    } else if (selectedCountryDropdown.length === 0 || selectedCampaignDropdown.length === 0) {
      return 'Please select Country and Campaign.';
    } else {
      return 'No data available.';
    }
  };

  const gridOptions = {
    localeText: {
      noRowsToShow: getNoRowsToShowMessage()
    }
  } as GridOptions;

  const disableActions = () => {
    return !isDefinedAndNotEmptyObject(selectedProductLine) || !isDefinedAndNotEmptyObject(selectedPlanningCycle) || templateState.isSubmitting;
  };

  const forecastTemplateHasNoRows = () => {
    return partialForecastData?.length === 0;
  };

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel'
        }
      ],
      defaultToolPanel: 'filters'
    };
  }, []);

  const onExcelButtonDropdownClicked = (id: string) => {
    if (id === 'export_to_excel') {
      onExport();
    } else if (id === 'import_from_excel') {
      setShowForecastImportModal(true);
    }
  };

  return (
    <>
      <ForecastImportModal
        showModal={showForecastImportModal}
        onCancel={onCancel}
        onConfirm={onConfirm}
        fixedColumnInfo={fixedColumnInfo}
        forecastTableRowData={partialForecastData}
      />
      {/* <DisplayContentInCode
        details={{
          selectedProductLineDropdown: selectedProductLineDropdown,
          selectedPlanningCycleDropdown: selectedPlanningCycleDropdown,
          selectedCountryDropdown: selectedCountryDropdown,
          selectedCampaignDropdown: selectedCampaignDropdown,
          test: "--------------------------------",
          selectedProductLine: selectedProductLine,
          selectedPlanningCycle: selectedPlanningCycle,
        }}
      ></DisplayContentInCode> */}
      <Container
        disableContentPaddings
        header={
          <>
            <Header
              actions={
                <SpaceBetween size="m" direction="horizontal">
                  <Button iconName="refresh" disabled={disableActions()} onClick={refreshForecast}></Button>
                  <ButtonDropdown
                    onItemClick={({ detail }) => onExcelButtonDropdownClicked(detail.id)}
                    items={[
                      {
                        text: 'Export to Excel',
                        id: 'export_to_excel',
                        iconName: 'download',
                        disabled: disableActions() || forecastTemplateHasNoRows()
                      },
                      {
                        text: 'Import from Excel',
                        id: 'import_from_excel',
                        iconName: 'upload',
                        disabled: disableActions() || forecastTemplateHasNoRows() || isPastLockData || forecastTemplateStatus?.isActive
                      }
                    ]}
                  >
                    Excel
                  </ButtonDropdown>
                  {/* <Button disabled={disableActions() || forecastTemplateHasNoRows()} onClick={onExport}>
                    Export to Excel
                  </Button>
                  <Button
                    disabled={disableActions() || forecastTemplateHasNoRows() || isPastLockData || forecastTemplateStatus?.isActive}
                    onClick={() => setShowForecastImportModal(true)}
                  >
                    Import from Excel
                  </Button> */}
                  <Button
                    disabled={
                      disableActions() ||
                      forecastTemplateHasNoRows() ||
                      isPastLockData ||
                      forecastTemplateStatus?.isActive ||
                      !templateState.templateDataChanged
                    }
                    variant="primary"
                    onClick={() => submitChanges()}
                  >
                    Submit Changes
                  </Button>
                </SpaceBetween>
              }
            >
              <SpaceBetween size="s" direction="vertical">
                <span>{`Forecast Template (Input USD)`}</span>
                {fileState.typeOfMessage !== 'success' && fileState.typeOfMessage !== 'pending' ? (
                  <StatusIndicator colorOverride={fileState.colorOverride} type={fileState.typeOfMessage}>{`  ${fileState.message}`}</StatusIndicator>
                ) : (
                  <></>
                )}
              </SpaceBetween>
            </Header>
          </>
        }
      >
        <div style={gridStyle} className="ag-theme-alpine">
          {resettingAgGridData && <LoadingSpinner />}
          {!resettingAgGridData && (
            <AgGridReact
              ref={gridRef}
              getRowId={getRowId}
              gridOptions={gridOptions}
              defaultColDef={defaultAgGridColumnDefinition}
              stopEditingWhenCellsLoseFocus={true}
              columnDefs={forecastTableColumnDefinition}
              rowData={partialForecastData}
              onCellValueChanged={onCellValueChanged}
              enableRangeSelection={true}
              enableFillHandle={true}
              fillHandleDirection={'x'}
              undoRedoCellEditing={true}
              undoRedoCellEditingLimit={10}
              statusBar={statusBar}
              suppressDragLeaveHidesColumns={true}
              processDataFromClipboard={ForecastUtils.processDataFromClipboard}
              processCellForClipboard={processCellForClipboard}
              processCellFromClipboard={processCellFromClipboard}
              onColumnGroupOpened={onColumnGroupOpened}
              onFirstDataRendered={onFirstDataRendered}
              suppressExcelExport={true}
              suppressCsvExport={true}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              alwaysMultiSort={true}
              rowHeight={30}
            ></AgGridReact>
          )}
        </div>
      </Container>
    </>
  );
});

export const accountNumberToolTip = (props: any) => {
  const data = useMemo(() => props.api.getDisplayedRowAtIndex(props.rowIndex).data, []);
  return (
    <>
      <div className="custom-tooltip" style={{ backgroundColor: props.color || 'white' }}>
        <p>
          <span>{data.accountNumber}</span>
        </p>
        <p>{data.accountName}</p>
      </div>
    </>
  );
};

export const campaignNameToolTip = (props: any) => {
  const data = useMemo(() => props.api.getDisplayedRowAtIndex(props.rowIndex).data, []);
  return (
    <>
      <div className="custom-tooltip" style={{ backgroundColor: props.color || 'white' }}>
        <p>
          <span>{data.campaignName}</span>
        </p>
      </div>
    </>
  );
};

export const costCenterToolTip = (props: any) => {
  const data = useMemo(() => props.api.getDisplayedRowAtIndex(props.rowIndex).data, []);
  return (
    <>
      <div className="custom-tooltip" style={{ backgroundColor: props.color || 'white' }}>
        <p>
          <span>{data.costCenterName}</span>
        </p>
      </div>
    </>
  );
};
