import moment from 'moment-timezone';

// Returns current UTC time in below format
// example: Jan 22nd 2023, 02:15:35 PM UTC
export const getCurrentUTCTimeZone = () => moment.utc().format('MMM Do YYYY, hh:mm:ss A z');

// Returns current UTC time in ISO format
export const getCurrentUTCTimeZoneInISO = () => moment.utc().toISOString();

// Returns in the format as (User Based)
// 2022-07-29T07:28:11.091Z
export const getCurrentUserLocalTime = () => {
  const localTime = moment.tz(moment.tz.guess());
  return localTime.toISOString();
};

// Returns in the format as (User Based)
// YYYY-MM-DD-HH-mm-ss
export const getCurrentUserLocalTimeFormatted = () => {
  const localTime = moment.tz(moment.tz.guess());
  return localTime.format('YYYY-MM-DD-HH-mm-ss');
};

// Returns in the format as (User Based)
// July 29th 2022, 12:28:11 AM PDT
export const getCurrentUserLocalTimeReadableFormat = (inputValue?: string) => {
  const inputValueInMoment = moment(inputValue?.toString());
  const localTime = inputValue ? inputValueInMoment.tz(moment.tz.guess()) : moment.tz(moment.tz.guess());
  return localTime.format('MMM Do YYYY, hh:mm:ss A z');
};

// Returns pacific timestamp in readable format
// July 29th 2022, 12:28:11 AM PDT
export const getPacificTimeReadableFormat = (inputValue: string) => {
  const inputValueInMoment = moment(inputValue?.toString());
  return inputValueInMoment.tz('America/Los_Angeles').format('MMM Do YYYY, hh:mm:ss A z');
};

// Returns the date in below format
// Jan 1st 2022 | Dec 1st 2023
export const getReadableFormatOfDate = (inputValue?: string) => {
  const inputValueInMoment = moment(inputValue?.toString());
  return inputValueInMoment.format('MMM Do YYYY');
};

export const getReadableFormatOfDateMandatory = (inputValue: string) => {
  if (!inputValue) {
    return '';
  }

  const inputValueInMoment = moment(inputValue.toString());
  return inputValueInMoment.format('MMM Do YYYY');
};

export const dateTimeComparatorForTable = (dateTime1: string, dateTime2: string) => {
  const date1 = moment(dateTime1);
  const date2 = moment(dateTime2);
  return date1.isBefore(date2) ? -1 : 1;
};

export const dateTimeComparator = (dateTime1: string, dateTime2: string) => {
  const date1 = moment(dateTime1);
  const date2 = moment(dateTime2);
  return date1.isBefore(date2) ? 1 : -1;
};

// 2023-01-07T21:50:00.00Z
export const getThisTimestampInUserLocal = (inputValue?: string) => {
  // const inputValueInMoment = moment(inputValue?.toString());
  // const localTime = inputValue ? inputValueInMoment.tz(moment.tz.guess()) : moment.tz(moment.tz.guess());
  // return localTime.format("yyyy-MM-DDTHH:mm:ss.Z");
  const localTime = moment.utc(inputValue).local().format('YYYY-MM-DDTHH:mm:ssZ');
  return localTime;
};

// Returns the date in below format
// Jan 1st 2022 | Dec 1st 2023
export const getReadableFormatOfMonthAndYear = (inputValue?: string) => {
  const inputValueInMoment = moment(inputValue?.toString());
  return inputValueInMoment.format('MMM YYYY');
};

// Returns in the format as
// 2022-07-29T07:28:11.091Z
export const getCurrentPSTLocal = () => {
  const localTime = moment.tz('America/Los_Angeles');
  return localTime.toISOString();
};

// convert local time to UTC time
export const convertPacificTimeToUTC = (inputValue: string) => {
  const inputValueInMoment = moment(inputValue?.toString());
  const pacificTime = inputValueInMoment.tz('America/Los_Angeles');
  return moment.utc(pacificTime).toISOString();
};

// Returns in the format as
// July 29th 2022, 12:28:11 AM PDT
export const getCurrentPSTLocalTimeReadableFormat = () => {
  const localTime = moment.tz('America/Los_Angeles');
  return localTime.format('MMMM Do YYYY, hh:mm:ss A z');
};

// Polaris Date Format
// YYYY-MM-DD
export const getPolarisDateFormat = (value: string) => {
  return moment(value).format('YYYY-MM-DD');
};

// Checks if the param date time is after the current moment
export const isAfterCurrentTime = (inputDateTime: string): boolean => {
  const userTimeZone = moment.tz.guess();
  return moment(inputDateTime).tz(userTimeZone).isAfter(moment.tz(userTimeZone));
};

// Checks if the param date time is after the current moment
export const isBeforeCurrentTime = (inputDateTime: string): boolean => {
  return moment(inputDateTime).tz(moment.tz.guess()).isBefore(moment.tz(moment.tz.guess()));
};

export const getPacificTimeOffset = () => {
  return moment.tz(moment.utc(), 'America/Los_Angeles').utcOffset();
};

export const isDate1AfterTheDate2 = (date1: string, date2: string) => {
  return moment(date1).isAfter(moment(date2));
};

export const getRemainingTimeDetails = (futureDate: string) => {
  const currentDate = moment();
  const duration = moment.duration(moment(futureDate).diff(currentDate));
  let message = '';
  switch (true) {
    case duration.asDays() >= 1:
      message = `${Math.floor(duration.asDays())} day(s) left`;
      break;
    case duration.asHours() >= 1:
      message = `${Math.floor(duration.asHours())} hour(s) left`;
      break;
    case duration.asMinutes() >= 1:
      message = `${Math.floor(duration.asMinutes())} minute(s) left`;
      break;
    case duration.asSeconds() >= 0:
      message = `${Math.floor(duration.asSeconds())} second(s) left`;
      break;
  }
  return message;
};

export const addRelativeTimeToDateTime = (
  lockToolDateUTC: string,
  amount: number,
  unit: 'second' | 'minute' | 'hour' | 'day' | 'week' | 'month' | 'year'
) => {
  return moment(lockToolDateUTC).add(amount, unit).toISOString();
};

export const generateMonthList = (startMonth: string, endMonth: string): string[] => {
  const startDate = moment(startMonth);
  const endDate = moment(endMonth);
  const monthList: string[] = [];
  while (startDate.isSameOrBefore(endDate)) {
    monthList.push(startDate.format('MMMYYYY'));
    startDate.add(1, 'month');
  }
  return monthList;
};

export const getYearFromMonthYear = (input: string) => {
  return moment(input, 'MMMYYYY').year().toString();
};

// input like - 'MMMYYYY'
// output like Q1 Q2 Q3 Q4
export const getQuarterFromMonth = (dateString: string) => {
  // Extract year and month from the date string
  // const year: number = parseInt(dateString.slice(3));
  const month: number = moment(dateString, 'MMMYYYY').month() + 1;
  // Calculate the quarter using the month and year
  const quarter: number = Math.ceil(month / 3);
  return `Q${quarter}`;
};

export const getDateTimeObject = (input: string) => {
  return moment(input).toISOString();
};

export const isGivenDateTimeIsValid = (input: string) => {
  return moment(input).isValid();
};

export const userTimeZone = () => {
  return moment.tz.guess();
};

export const splitDateTime = (dateTimeString: string): { date: string; time: string } => {
  const dateTime = moment(dateTimeString);
  const dateTimeInPST = moment.tz(dateTime, 'America/Los_Angeles');

  const date = dateTimeInPST.format('YYYY-MM-DD');
  const time = dateTimeInPST.format('HH:MM');

  return { date, time };
};
