import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  AppLayout,
  Box,
  Button,
  Container,
  ContentLayout,
  Flashbar,
  FlashbarProps,
  FormField,
  Header,
  Link,
  Pagination,
  PropertyFilter,
  PropertyFilterProps,
  Select,
  SpaceBetween,
  SplitPanel,
  StatusIndicator,
  Table,
  TableProps
} from '@amzn/awsui-components-react';
import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import * as queries from 'src/graphql/queries';
import { eUserTablePreferenceKeys } from 'src/utils/DmmConstants';
import { getMatchesCountText, PAGINATION_LABELS } from 'src/utils/labels';
import { logger } from 'src/utils/Logger';
import { PROPERTY_FILTERING_I18N_CONSTANTS } from '../../utils/CommonHelpers';
import { useColumnWidths } from '../commons/CustomHooks/use-column-widths';
import { useAsyncDynamoStorage } from '../commons/CustomHooks/use-dynamo-storage';
import { usePersistedState } from '../commons/CustomHooks/use-local-storage';
import { DropdownModel } from '../context/AppContextModel';
import { useAppContext } from '../context/AppContextProvider';
import { useAuth } from '../context/AuthContext';
import { EmptyStateMessage } from '../generic-components/EmptyState';
import { SPLIT_PANEL_I18NSTRINGS } from '../generic-components/SplitPanelConstants';
import { AppBreadcrumb } from '../navigation/AppBreadcrumb';
import { AppSideNavigation } from '../navigation/AppSideNavigation';
import {
  PURCHASE_ORDER_COLUMN_DEFINITIONS,
  PURCHASE_ORDER_TABLE_DEFAULT_PREFERENCES,
  PURCHASE_ORDERS_FILTERING_PROPERTIES,
  PurchaseOrderTablePreferences
} from './PurchaseOrderTableConfig';
import { PurchaseOrderToolContent } from './PurchaseOrderToolContext';
import * as PurchaseOrderUtils from './PurchaseOrderUtils';

export const PurchaseOrders: React.FC = () => {
  const userDetails = useAuth();
  const appContext = useAppContext();

  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);
  const [toolsOpen, setToolsOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const EMPTY_DROPDOWN_OPTION = { label: 'Choose an option', value: '' };
  const [productLineOptions, setProductLineOptions] = useState<DropdownModel[]>([]);
  const [selectedProductLineOption, setSelectedProductLineOption] = usePersistedState(
    eUserTablePreferenceKeys.PURCHASE_ORDERS_PRODUCT_LINE_OPTION,
    EMPTY_DROPDOWN_OPTION
  );

  const [actualMonthOptions, setActualMonthOptions] = useState<any[]>([]);
  const [selectedActualsMonthOption, setSelectedActualsMonthOption] = usePersistedState(
    eUserTablePreferenceKeys.PURCHASE_ORDERS_ACTUAL_MONTHS_OPTION,
    EMPTY_DROPDOWN_OPTION
  );

  const [purchaseOrderItems, setPurchaseOrderItems] = useState<any[]>([]);
  const [selectedPurchaseOrderItems, setSelectedPurchaseOrderItems] = useState<any[]>([]);
  const [actualsStatus, setActualsStatus] = useState('');
  const [totalBalance, setTotalBalance] = useState('');
  const [totalUntaggedPO, setTotalUntaggedPO] = useState<{ hasCampaigns: boolean; emptyCampaignsCount: number; summaryMessage: string }>({
    hasCampaigns: false,
    emptyCampaignsCount: 0,
    summaryMessage: ''
  });

  const BLANK_SEARCH = { tokens: [], operation: 'and' } as PropertyFilterProps.Query;
  const [query, setQuery] = useAsyncDynamoStorage(eUserTablePreferenceKeys.PURCHASE_ORDER_TABLE_FILTERING_PROPERTIES, BLANK_SEARCH);
  const [columnDefinitions, saveWidths, columnDefinitionsLoading] = useColumnWidths(
    eUserTablePreferenceKeys.PURCHASE_ORDER_TABLE_COLUMN_WIDTHS,
    PURCHASE_ORDER_COLUMN_DEFINITIONS
  );
  const [preferences, setPreferences] = useAsyncDynamoStorage(
    eUserTablePreferenceKeys.PURCHASE_ORDER_TABLE_PREFERENCES,
    PURCHASE_ORDER_TABLE_DEFAULT_PREFERENCES
  );

  useEffect(() => {
    actions.setPropertyFiltering(query);
  }, [query]);

  const displayFlashMessage = (content: string, flashBarType: FlashbarProps.Type) => {
    setFlashbarItems([
      {
        type: flashBarType,
        content: content,
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => setFlashbarItems([])
      }
    ]);
  };

  const {
    splitPanelPrefs,
    splitPanelSize,
    splitPanelOpen,
    onSplitPanelPreferencesChange,
    onSplitPanelResize,
    onSplitPanelToggle,
    splitPanelOpenByEdit
  } = PurchaseOrderUtils.usePOSplitPanelProps();

  const updateTheTable = () => {
    splitPanelOpenByEdit(false);
    refreshPurchaseOrdersAndTotalBalances();
  };

  const panelContent = PurchaseOrderUtils.getPurchaseOrderPanelContent(
    +selectedProductLineOption?.value,
    selectedActualsMonthOption?.value,
    displayFlashMessage,
    updateTheTable,
    selectedPurchaseOrderItems
  );

  useEffect(() => {
    const loadInitialData = async () => {
      await Promise.all([getProductLinesList(), getGlActualMonthsList()]).then(() => {
        refreshPurchaseOrdersAndTotalBalances();
      });
    };
    loadInitialData();
  }, []);

  const getProductLinesList = async () => {
    const userProductLines: DropdownModel[] = appContext.userDetails.userProductLines?.map((productLine) => {
      return {
        label: productLine.productName,
        value: `${productLine.productID}`
      } as DropdownModel;
    });
    setProductLineOptions(userProductLines);
    if (JSON.stringify(selectedProductLineOption) !== JSON.stringify(EMPTY_DROPDOWN_OPTION)) {
      setSelectedProductLineOption(selectedProductLineOption);
    } else {
      setSelectedProductLineOption(userProductLines.length > 0 ? userProductLines[0] : EMPTY_DROPDOWN_OPTION);
    }
  };

  const getGlActualMonthsList = async () => {
    try {
      const response: any = await API.graphql({
        query: queries.listGlActualMonths
      });
      const glActualMonths = PurchaseOrderUtils.mapActualMonthsDropdown(response.data.listGlActualMonths.glActualMonths);
      setActualMonthOptions(glActualMonths);
      if (JSON.stringify(selectedActualsMonthOption) !== JSON.stringify(EMPTY_DROPDOWN_OPTION)) {
        setSelectedActualsMonthOption(selectedActualsMonthOption);
      } else {
        setSelectedActualsMonthOption(glActualMonths?.length != 0 ? glActualMonths[0] : EMPTY_DROPDOWN_OPTION);
      }
    } catch (error: any) {
      logger.error('Unable to load actuals month data.', error);
      displayFlashMessage('Unable to load actuals month data. If this continues please contact support.', 'error');
    }
  };

  useEffect(() => {
    refreshPurchaseOrdersAndTotalBalances();
  }, [selectedProductLineOption, selectedActualsMonthOption]);

  const refreshPurchaseOrdersAndTotalBalances = () => {
    getPurchaseOrders();
    getTotalBalances();
    setSelectedPurchaseOrderItems([]);
  };

  const getPurchaseOrders = async () => {
    if (selectedProductLineOption?.value && selectedActualsMonthOption.value) {
      setLoading(true);
      setPurchaseOrderItems([]);
      setActualsStatus('');
      setSelectedPurchaseOrderItems([]);
      try {
        const response: any = await API.graphql({
          query: queries.listCampaignsPurchaseOrderMapping,
          variables: {
            dataClassificationId: appContext.userDetails.dataClassification.dataClassificationId,
            productLineId: +selectedProductLineOption.value,
            monthId: selectedActualsMonthOption.value
          }
        });
        setLoading(false);
        setPurchaseOrderItems(PurchaseOrderUtils.mapCampaignPurchaseOrderDetails(response.data.listCampaignsPurchaseOrderMapping));
        setActualsStatus(PurchaseOrderUtils.getPurchaseOrderStatus(response.data.listCampaignsPurchaseOrderMapping));
        setTotalUntaggedPO(PurchaseOrderUtils.checkTotalNumberOfUnTaggedPOs(response.data.listCampaignsPurchaseOrderMapping));
      } catch (error: any) {
        logger.error('Unable to load purchase order data.', error);
        setLoading(false);
        setPurchaseOrderItems([]);
        setActualsStatus('');
        displayFlashMessage('Unable to load purchase order data. If this continues please contact support.', 'error');
      }
    }
  };

  const getTotalBalances = async () => {
    if (selectedProductLineOption?.value && selectedActualsMonthOption.value) {
      try {
        const response: any = await API.graphql({
          query: queries.listActualBalances,
          variables: {
            dataClassificationId: appContext.userDetails.dataClassification.dataClassificationId,
            productLineId: +selectedProductLineOption?.value,
            monthId: selectedActualsMonthOption.value
          }
        });
        setTotalBalance(PurchaseOrderUtils.parseTotalBalance(response.data.listActualBalances.glActuals));
      } catch (error: any) {
        logger.error('Unable to load purchase order data.', error);
        setTotalBalance('');
        displayFlashMessage('There was a problem. Please retry. If this continues please contact support.', 'error');
      }
    }
  };

  // When user selects a row using Radio button
  const tableSelectionChanged = (detail: any) => {
    setSelectedPurchaseOrderItems(detail.selectedItems);
  };

  const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(purchaseOrderItems, {
    propertyFiltering: {
      filteringProperties: PURCHASE_ORDERS_FILTERING_PROPERTIES,
      empty: <EmptyStateMessage title="No purchase orders" subtitle="No purchase orders to display." />,
      noMatch: (
        <EmptyStateMessage
          title="No matches"
          subtitle="No match found to display."
          action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
        />
      )
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {
      defaultState: {
        sortingColumn: { sortingField: 'updatedTime' },
        isDescending: true
      }
    },
    selection: {}
  });

  return (
    <>
      <AppLayout
        headerSelector="#h"
        contentType="default"
        navigation={<AppSideNavigation />}
        notifications={<Flashbar items={flashbarItems} />}
        breadcrumbs={<AppBreadcrumb items={PurchaseOrderUtils.getUserBreadcrumbs()} />}
        tools={<PurchaseOrderToolContent />}
        toolsOpen={toolsOpen}
        onToolsChange={({ detail }) => setToolsOpen(detail.open)}
        splitPanelOpen={splitPanelOpen}
        splitPanelPreferences={splitPanelPrefs}
        splitPanelSize={320}
        onSplitPanelPreferencesChange={onSplitPanelPreferencesChange}
        onSplitPanelResize={onSplitPanelResize}
        onSplitPanelToggle={onSplitPanelToggle}
        splitPanel={
          <SplitPanel className="dmm-split-panel" hidePreferencesButton header={'Tag Purchase orders'} i18nStrings={SPLIT_PANEL_I18NSTRINGS}>
            {panelContent}
          </SplitPanel>
        }
        disableContentPaddings
        content={
          <ContentLayout>
            <Box margin={{ top: 'l', bottom: 'xl', left: 'xxxl', right: 'xxxl' }} padding={{ left: 'xxxl', right: 'xxxl' }}>
              <SpaceBetween size="m">
                {/* Purchase Orders - Header Section - To select "Product Line" & "Actual Month" */}
                <Container>
                  <SpaceBetween direction="horizontal" size="m">
                    <FormField className="form-field-select-width-12rem" label="Product Line">
                      <Select
                        expandToViewport={true}
                        filteringType="auto"
                        selectedOption={selectedProductLineOption}
                        onChange={({ detail }) => {
                          setSelectedProductLineOption(detail.selectedOption);
                        }}
                        options={productLineOptions}
                        empty="No options"
                        placeholder="Choose an option"
                      ></Select>
                    </FormField>

                    {/* Actual month dropdown  */}
                    <FormField className="form-field-select-width-10rem" label="Actuals Month">
                      <Select
                        expandToViewport={true}
                        selectedOption={selectedActualsMonthOption}
                        onChange={({ detail }) => {
                          setSelectedActualsMonthOption(detail.selectedOption);
                        }}
                        options={actualMonthOptions}
                        empty="No options"
                        placeholder="Choose an option"
                      ></Select>
                    </FormField>

                    {/* Display Product Line Tagging status  */}
                    <div className="form-field-select-width-6rem">
                      <Box variant="awsui-key-label">Status</Box>
                      <div>
                        {!actualsStatus && '-'}
                        {actualsStatus && (
                          <StatusIndicator type={actualsStatus == 'incomplete' ? 'warning' : 'success'}>
                            {actualsStatus == 'incomplete' ? 'Incomplete' : 'Complete'}
                          </StatusIndicator>
                        )}
                      </div>
                    </div>

                    {/* Display Tagged/untagged summary */}
                    <div className="form-field-select-width-15rem">
                      <Box variant="awsui-key-label">Tagged/Untagged summary</Box>
                      <div>
                        {!totalUntaggedPO.hasCampaigns && '-'}
                        {totalUntaggedPO.hasCampaigns && totalUntaggedPO.emptyCampaignsCount === 0 && (
                          <StatusIndicator type="success">{'All POs are tagged'}</StatusIndicator>
                        )}
                        {totalUntaggedPO.hasCampaigns && totalUntaggedPO.emptyCampaignsCount > 0 && (
                          <StatusIndicator type="info">{`${totalUntaggedPO.summaryMessage} `}</StatusIndicator>
                        )}
                      </div>
                    </div>

                    {/* Display Total PO Amount   */}
                    <div className="form-field-select-width-12rem">
                      <Box variant="awsui-key-label">Total PO Amount (USD)</Box>
                      <div>
                        {/* {totalBalance ? <Badge>{totalBalance}</Badge> : "-"} */}
                        <Box fontSize="heading-s" fontWeight="normal" variant="samp">
                          {totalBalance ? (
                            <NumberFormat value={totalBalance} thousandSeparator displayType="text" decimalScale={2} prefix={'$'} />
                          ) : (
                            '-'
                          )}
                        </Box>
                      </div>
                    </div>
                  </SpaceBetween>
                </Container>

                {/* Based on PL & Actual Month selection, display Purchase Orders  */}
                <Table
                  variant="container"
                  stickyHeader
                  header={
                    <Header
                      counter={
                        purchaseOrderItems &&
                        (selectedPurchaseOrderItems!.length
                          ? `(${selectedPurchaseOrderItems!.length}/${purchaseOrderItems.length})`
                          : `(${purchaseOrderItems.length})`)
                      }
                      actions={
                        <SpaceBetween size="m" direction="horizontal">
                          <Button iconName="refresh" onClick={refreshPurchaseOrdersAndTotalBalances}></Button>
                          <Button
                            variant="primary"
                            disabled={selectedPurchaseOrderItems!.length === 0}
                            onClick={() => {
                              splitPanelOpenByEdit(true);
                            }}
                          >
                            Tag
                          </Button>
                        </SpaceBetween>
                      }
                      info={
                        <Link variant="info" onFollow={() => setToolsOpen(true)}>
                          Info
                        </Link>
                      }
                    >
                      {'Purchase Orders'}
                    </Header>
                  }
                  {...collectionProps}
                  selectionType={'multi'}
                  visibleColumns={preferences.visibleContent}
                  wrapLines={preferences.wrapLines}
                  stripedRows={preferences.stripedRows}
                  resizableColumns={preferences.custom}
                  loading={loading}
                  loadingText="Loading purchase order data"
                  columnDefinitions={columnDefinitions as TableProps.ColumnDefinition<any>[]}
                  items={items}
                  onColumnWidthsChange={saveWidths as any}
                  selectedItems={selectedPurchaseOrderItems}
                  onSelectionChange={({ detail }) => tableSelectionChanged(detail)}
                  filter={
                    <PropertyFilter
                      i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
                      countText={getMatchesCountText(filteredItemsCount!)}
                      expandToViewport={true}
                      {...propertyFilterProps}
                      query={query}
                      onChange={(event) => {
                        setQuery(event.detail.tokens?.length === 0 ? BLANK_SEARCH : event.detail);
                      }}
                    />
                  }
                  pagination={<Pagination {...paginationProps} ariaLabels={PAGINATION_LABELS} />}
                  preferences={
                    <PurchaseOrderTablePreferences
                      preferences={preferences ? preferences : PURCHASE_ORDER_TABLE_DEFAULT_PREFERENCES}
                      setPreferences={setPreferences}
                    />
                  }
                />
              </SpaceBetween>
            </Box>
          </ContentLayout>
        }
      />
    </>
  );
};
