import {
  Box,
  Button,
  ButtonDropdown,
  ButtonDropdownProps,
  ColumnLayout,
  Container,
  Header,
  Modal,
  SpaceBetween,
  StatusIndicator
} from '@amzn/awsui-components-react';
import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext, PlanningCycleDataModel, UserAuthContext } from 'src/components/context/AppContextModel';
import { useAuth } from 'src/components/context/AuthContext';
import { closePlanningCycle } from 'src/graphql/mutations';
import * as queries from 'src/graphql/queries';
import {
  dateTimeComparator,
  getCurrentUserLocalTimeReadableFormat,
  getPacificTimeReadableFormat,
  getReadableFormatOfDateMandatory,
  isAfterCurrentTime
} from 'src/utils/DateTimeUtils';
import { logger } from 'src/utils/Logger';
import { useAppContext } from '../context/AppContextProvider';
import { LoadingSpinner } from '../generic-components/Spinner';
import { FuncProps, PlanningCycleDataModelFlat } from './PlanningCycleModels';

/*
 * gets parameters for closing current planning cycle
 */
export const getParametersForClosingPlanningCycle = (scenarioTemplateId: number, user: UserAuthContext, appContext: AppContext) => {
  let result = {
    userAlias: user.Alias,
    scenarioTemplateId: scenarioTemplateId,
    dataClassificationId: appContext.userDetails.dataClassification.dataClassificationId
  };
  return result;
};

interface EmptyScenarioProps {
  title: string;
  subtitle: string;
  action?: () => void;
}
export const EmptyScenario = ({ title, subtitle, action }: EmptyScenarioProps) => {
  return (
    <Container>
      <Box textAlign="center" color="inherit">
        <b>{title}</b>
        <Box padding={{ bottom: 's' }} variant="p" color="inherit">
          {subtitle}
        </Box>
        <Button onClick={action}>{'New planning cycle'}</Button>
      </Box>
    </Container>
  );
};

interface ActiveScenarioProps {
  currentScenario: PlanningCycleDataModelFlat;
}
export const CurrentScenarioInfo = (props: ActiveScenarioProps) => {
  return (
    <ColumnLayout columns={4} variant="text-grid">
      {/* Row 1 */}
      <div>
        <Box variant="awsui-key-label">{'Current status'}</Box>
        <StatusIndicator
          type={
            (
              props.currentScenario.isExtended && props.currentScenario.extendedLockDateTime
                ? isAfterCurrentTime(props.currentScenario.extendedLockDateTime)
                : isAfterCurrentTime(props.currentScenario.lockToolDate)
            )
              ? 'success'
              : 'error'
          }
        >
          {(
            props.currentScenario.isExtended && props.currentScenario.extendedLockDateTime
              ? isAfterCurrentTime(props.currentScenario.extendedLockDateTime)
              : isAfterCurrentTime(props.currentScenario.lockToolDate)
          )
            ? 'open'
            : 'locked'}
        </StatusIndicator>
      </div>

      <div>
        <Box variant="awsui-key-label">{'Actuals'}</Box>
        <div>{`${getReadableFormatOfDateMandatory(props.currentScenario.actualsStartMonth) || ''} - ${
          getReadableFormatOfDateMandatory(props.currentScenario.actualsEndMonth) || ''
        }`}</div>
      </div>

      <div>
        <Box variant="awsui-key-label">{'Forecasts '}</Box>
        <div>{`${getReadableFormatOfDateMandatory(props.currentScenario.forecastStartMonth) || ''} - ${
          getReadableFormatOfDateMandatory(props.currentScenario.forecastEndMonth) || ''
        }`}</div>
      </div>

      <div>
        <Box variant="awsui-key-label">{'Open tool date'}</Box>
        <div>{getPacificTimeReadableFormat(props.currentScenario.openToolDate)}</div>
      </div>

      {/* Row 2 */}
      <div>
        <Box variant="awsui-key-label">{'Tool lock date'}</Box>
        <div>{getPacificTimeReadableFormat(props.currentScenario.lockToolDate)}</div>
      </div>

      <div>
        <Box variant="awsui-key-label">{'Extended lock date'}</Box>
        <div>
          {props.currentScenario.isExtended && props.currentScenario.extendedLockDateTime
            ? getPacificTimeReadableFormat(props.currentScenario.extendedLockDateTime)
            : '-'}
        </div>
      </div>

      <div>
        <Box variant="awsui-key-label">{'Last modified by'}</Box>
        <div>{props.currentScenario.updatedBy}</div>
      </div>

      <div>
        <Box variant="awsui-key-label">{'Last modified at'}</Box>
        <div>{getCurrentUserLocalTimeReadableFormat(props.currentScenario.updatedTime)}</div>
      </div>
    </ColumnLayout>
  );
};

export const parseCurrentPlanningCycle = (cycle: PlanningCycleDataModel) => {
  return {
    scenarioId: cycle.scenario.scenarioId,
    scenarioTemplateId: cycle.scenarioTemplateId,
    scenarioName: cycle.scenario.scenarioName,
    actualsStartMonth: cycle.actualsStartMonth,
    actualsEndMonth: cycle.actualsEndMonth,
    isActive: cycle.isActive,
    forecastStartMonth: cycle.forecastStartMonth,
    forecastEndMonth: cycle.forecastEndMonth,
    openToolDate: cycle.openToolDate,
    lockToolDate: cycle.lockToolDate,
    isExtended: cycle.isExtended,
    extendedLockDateTime: cycle.extendedLockDateTime,
    createdBy: cycle.createdBy,
    updatedBy: cycle.updatedBy,
    createdTime: cycle.createdTime,
    updatedTime: cycle.updatedTime
  } as PlanningCycleDataModelFlat;
};

export const parseCurrentPlanningCycles = (data: PlanningCycleDataModel[]) => {
  return data.map((cycle: PlanningCycleDataModel) => parseCurrentPlanningCycle(cycle));
};

const defaultActiveScenario = { scenarioId: 0, scenarioTemplateId: 0, scenarioName: '' };

export const CurrentPlanningCycleList: React.FC<FuncProps> = (props) => {
  const user = useAuth();
  const appContext = useAppContext();

  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const [activeScenario, setActiveScenario] = useState(defaultActiveScenario);
  const [currentScenarios, setCurrentScenarios] = useState<PlanningCycleDataModelFlat[]>([]);

  const [loading, setLoading] = useState(true);
  const [isCloseButtonLoading, setCloseButtonLoading] = useState(false);

  useEffect(() => {
    fetchCurrentPlanningCycleData();
  }, []);

  const fetchCurrentPlanningCycleData = async () => {
    try {
      const response: any = await API.graphql({
        query: queries.listCurrentPlanningCycles,
        variables: { dataClassificationId: appContext.userDetails.dataClassification.dataClassificationId }
      });
      const currentActivePlanningCycleList: PlanningCycleDataModelFlat[] = parseCurrentPlanningCycles(response.data.listCurrentPlanningCycles);
      setCurrentScenarios(
        currentActivePlanningCycleList.sort((a, b) => {
          return dateTimeComparator(a.openToolDate, b.openToolDate);
        })
      );
      appContext.setAppMetadata({ ...appContext.appMetadata, listCurrentPlanningCycles: response.data.listCurrentPlanningCycles });
      setLoading(false);
    } catch (err) {
      let errString = JSON.stringify(err);
      logger.info(`ERROR - ${errString}`);
      setLoading(false);
      props.messageHandler(false, 'There was a problem loading. Please retry. If this continues please contact support.', 'error');
    }
  };

  /* open modal for scenario closing */
  const handleButtonClick = (event: CustomEvent<ButtonDropdownProps.ItemClickDetails>, currentScenario: PlanningCycleDataModelFlat) => {
    event.preventDefault();
    if (event.detail.id === 'edit') {
      navigate({ pathname: `${event.detail.href}` });
    }
    if (event.detail.id === 'close') {
      setModalVisible(true);
      setActiveScenario({
        scenarioId: currentScenario.scenarioId,
        scenarioTemplateId: currentScenario.scenarioTemplateId,
        scenarioName: `${currentScenario.scenarioName}`
      });
    }
  };

  const handleScenarioClose = (scenarioTemplateId: number) => {
    setCloseButtonLoading(true);
    const closeCurrentPlanningCycleData = async () => {
      try {
        let data = getParametersForClosingPlanningCycle(scenarioTemplateId, user, appContext);
        const response: any = await API.graphql({
          query: closePlanningCycle,
          variables: {
            userAlias: user.Alias,
            scenarioTemplateId: scenarioTemplateId,
            dataClassificationId: appContext.userDetails.dataClassification.dataClassificationId
          }
        });
        setModalVisible(false);
        setCloseButtonLoading(false);
        props.messageHandler(false, 'Planning cycle closed successfully.', 'info');
        // refresh component to show no active scenario
        fetchCurrentPlanningCycleData();
        setActiveScenario(defaultActiveScenario);
      } catch (err) {
        let errString = JSON.stringify(err);
        logger.error(`ERROR - ${errString}`);
        setModalVisible(false);
        setCloseButtonLoading(false);
        props.messageHandler(false, 'There was a problem. Please retry. If this continues please contact support.', 'error');
      }
    };
    closeCurrentPlanningCycleData();
  };

  const handleCreateButton = () => {
    navigate({ pathname: '/planning-cycle/create' });
  };

  return (
    <div>
      {loading && <LoadingSpinner />}
      {!loading && currentScenarios.length == 0 && (
        <EmptyScenario title={'No scenarios'} subtitle={'No active scenario to display'} action={handleCreateButton} />
      )}
      {!loading && currentScenarios.length != 0 && (
        <SpaceBetween size="l" direction="vertical">
          {currentScenarios.map((currentScenario, index) => {
            return (
              <div key={index + currentScenario.scenarioName}>
                <Container
                  header={
                    <Header
                      variant="h3"
                      actions={
                        <SpaceBetween size="xs" direction="horizontal">
                          <ButtonDropdown
                            items={[
                              { text: 'Edit', id: 'edit', href: `/planning-cycle/edit/${currentScenario.scenarioTemplateId}` },
                              { text: 'Close', id: 'close' }
                            ]}
                            onItemClick={(event) => handleButtonClick(event, currentScenario)}
                          >
                            Actions
                          </ButtonDropdown>
                        </SpaceBetween>
                      }
                    >
                      {currentScenario.scenarioName}
                    </Header>
                  }
                >
                  <CurrentScenarioInfo currentScenario={currentScenario} />
                </Container>
              </div>
            );
          })}
        </SpaceBetween>
      )}
      <Modal
        onDismiss={() => setModalVisible(false)}
        visible={modalVisible}
        closeAriaLabel="Close modal"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={() => setModalVisible(false)}>
                Cancel
              </Button>
              <Button
                loading={isCloseButtonLoading}
                variant="primary"
                onClick={() => {
                  handleScenarioClose(activeScenario.scenarioTemplateId);
                }}
              >
                Yes
              </Button>
            </SpaceBetween>
          </Box>
        }
        header="Close scenario"
      >
        Would you like to close the current scenario - {activeScenario.scenarioName}?
      </Modal>
    </div>
  );
};
