import { Button, Flashbar } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';

export const ApplicationBanner = () => {
  const [items, setItems] = React.useState<any>([]);

  useEffect(() => {
    setItems([]);
  }, []);

  return <Flashbar items={items} />;
};
