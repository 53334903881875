import {
  AppLayout,
  Box,
  Button,
  Checkbox,
  ColumnLayout,
  Container,
  ContentLayout,
  DatePicker,
  Flashbar,
  FlashbarProps,
  Link,
  Multiselect,
  Select,
  SpaceBetween,
  TimeInput
} from '@amzn/awsui-components-react/polaris';
import DateRangePicker, { DateRangePickerProps } from '@amzn/awsui-components-react/polaris/date-range-picker';
import Form from '@amzn/awsui-components-react/polaris/form';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Header from '@amzn/awsui-components-react/polaris/header';
import { API } from 'aws-amplify';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from 'src/components/context/AuthContext';
import * as queries from 'src/graphql/queries';
import { convertPacificTimeToUTC, getPacificTimeOffset, isDate1AfterTheDate2, isGivenDateTimeIsValid } from 'src/utils/DateTimeUtils';
import { logger } from 'src/utils/Logger';
import { BaselineScenarioInput } from '../context/AppContextModel';
import { configureFPNADimensions, useAppContext } from '../context/AppContextProvider';
import { ErrorFallback } from '../generic-components/ErrorFallback';
import { getMultiSelectPlaceHolderValue } from '../generic-components/Utils';
import { AppBreadcrumb } from '../navigation/AppBreadcrumb';
import { AppSideNavigation } from '../navigation/AppSideNavigation';
import { PlanningCycleInfoPanel } from './PlanningCycleInfoPanel';
import { ScenarioResourceFormModel } from './PlanningCycleModels';
import * as ScenarioUtils from './PlanningCycleUtils';

export interface ScenarioFormProps {
  type: string;
}

export const PlanningCycleManageForm = (props: ScenarioFormProps) => {
  const auth = useAuth();
  const appContext = useAppContext();
  const pathParams = useParams();
  const navigate = useNavigate();
  const [notificationItems, setNotificationItems] = useState<FlashbarProps.MessageDefinition[]>([]);
  const [formValues, setFormValues] = useState<ScenarioResourceFormModel>(ScenarioUtils.InitialFormValues);
  const [scenarioOptions, setScenarioOptions] = useState<{ label: string; value: string }[]>([]);
  const [baseScenarioOptions, setBaseScenarioOptions] = useState<{ label: string; value: string }[]>([]);
  const formDetails = ScenarioUtils.getFormDetails(props.type);
  const isEditPlanningCycle = props.type === 'edit';
  const scenarioFormRef = useRef<FormikProps<ScenarioResourceFormModel>>(null);
  const [navigationOpen, setNavigationOpen] = useState(true);
  const [toolsOpen, setToolsOpen] = useState(false);

  useEffect(() => {
    fetchCurrentPlanningCycle(); // gets current planning cycle if it exists
    fetchScenariosList(); // gets scenario list for dropdowns
  }, []);

  const fetchCurrentPlanningCycle = async () => {
    // We need base scenario list for new / old planning cycle form.
    const baseScenarioResponse: any = await API.graphql({
      query: queries.listBaselineScenarios
    });
    let baseScenarioDropdowns = (baseScenarioResponse.data.listBaselineScenarios as BaselineScenarioInput[]).map((row: BaselineScenarioInput) => {
      return {
        label: row.baselineScenarioName || '',
        value: row.baselineScenarioTemplateId?.toString() || ''
      };
    });
    baseScenarioDropdowns = baseScenarioDropdowns.sort((a, b) => {
      const aScenarioTemplateId = +a.value;
      const bScenarioTemplateId = +b.value;
      return aScenarioTemplateId < bScenarioTemplateId ? 1 : -1;
    });
    setBaseScenarioOptions(baseScenarioDropdowns || []);

    // While create, scenarioTemplateId is undefined. And we don't need to call getCurrentPlanningCycle
    if (pathParams.scenarioTemplateId) {
      try {
        const response: any = await API.graphql({
          query: queries.getCurrentPlanningCycle,
          variables: {
            dataClassificationId: appContext.userDetails.dataClassification.dataClassificationId,
            scenarioTemplateId: ScenarioUtils.getScenarioTemplateId(pathParams.scenarioTemplateId)
          }
        });

        const form = ScenarioUtils.parseCurrentPlanningCycleToFormModel(response.data.getCurrentPlanningCycle);
        setFormValues(form);
      } catch (error: any) {
        logger.error(`Unable to fetch Current Planning Cycle details`, error);
        setNotificationItems([
          { type: 'error', loading: false, content: 'There was a problem loading resources. Please try again or notify support.' }
        ]);
      }
    }
  };

  const fetchScenariosList = () => {
    const scenarioDropdowns = appContext.appMetadata.listScenarios.map((row) => {
      return {
        label: row.scenarioName,
        value: row.scenarioId.toString()
      };
    });
    setScenarioOptions(scenarioDropdowns);
  };

  const handleNav = (pathName: string) => {
    navigate({ pathname: pathName });
  };

  const handleSubmit = async (
    pathParams: any,
    planningCycleFormValues: ScenarioResourceFormModel,
    formikHelpers: FormikHelpers<ScenarioResourceFormModel>,
    userName: string,
    dataClassificationId: number
  ) => {
    formikHelpers.setSubmitting(true);
    setNotificationItems([{ type: 'info', loading: true, content: 'submitting' }]);

    try {
      let parsedFormToMutationObject = ScenarioUtils.parsePlanningCycleFormValues(
        pathParams,
        planningCycleFormValues,
        userName,
        dataClassificationId
      );
      const response: any = await API.graphql({
        query: formDetails.formSubmitQuery,
        variables: { input: parsedFormToMutationObject }
      });

      if (response.data[formDetails.formQueryName].numberOfRecordsUpdated == 1) {
        setNotificationItems([{ type: 'success', loading: false, content: 'Succeeded' }]);
        appContext.setAppMetadata(await configureFPNADimensions(appContext.userDetails, appContext.appMetadata));
        formikHelpers.resetForm;
        formikHelpers.setSubmitting(false);
        navigate({ pathname: '/planning-cycle' });
      } else {
        formikHelpers.setSubmitting(false);
        setNotificationItems([{ type: 'error', loading: false, content: 'There was nothing to do.' }]);
      }
    } catch (error: any) {
      logger.error('ERROR there was a problem while submitting planning cycle details.', error);
      formikHelpers.setSubmitting(false);
      setNotificationItems([{ type: 'error', loading: false, content: 'There was a problem. Please try again or notify support.' }]);
    }
  };

  const formikCustomValidate = (formValues: ScenarioResourceFormModel) => {
    if (!formValues) {
      return;
    }

    let errors: any = {};

    if (formValues.isExtended) {
      if (!formValues.extendedLockDateTime) {
        errors = { ...errors, datePickerValue: 'Required field if Extend the timeline for specific users checked' };
      } else {
        if (formValues.toolWindowDateRange?.endDate) {
          if (
            isDate1AfterTheDate2(
              convertPacificTimeToUTC(formValues.toolWindowDateRange?.endDate),
              convertPacificTimeToUTC(formValues.extendedLockDateTime)
            )
          ) {
            errors = { ...errors, datePickerValue: 'Extended date must be after the end date of the planning cycle' };
          }
        }
      }

      if (formValues.extendedToUsers === null || formValues.extendedToUsers?.length === 0) {
        errors = { ...errors, extendedToUsers: { label: 'Required field if Extend the timeline for specific users checked' } };
      }
    }
    return errors;
  };

  const extendToolLockDateChanged = (isChecked: any) => {
    scenarioFormRef?.current?.setFieldValue('isExtended', isChecked);
    if (!isChecked) {
      scenarioFormRef?.current?.setFieldValue('datePickerValue', '');
      scenarioFormRef?.current?.setFieldValue('timePickerValue', '');
      scenarioFormRef?.current?.setFieldValue('extendedLockDateTime', '');
      scenarioFormRef?.current?.setFieldValue('extendedToUsers', []);
      setTimeout(() => {
        scenarioFormRef.current?.validateForm();
      }, 500);
    }
  };

  const handleDateTimeChange = (datePickerValue: string, timePickerValue: string) => {
    scenarioFormRef?.current?.setFieldValue('datePickerValue', datePickerValue);
    scenarioFormRef?.current?.setFieldValue('timePickerValue', timePickerValue);
    const dateTimeString = `${datePickerValue}T${timePickerValue}:00`;
    if (isGivenDateTimeIsValid(dateTimeString)) {
      scenarioFormRef?.current?.setFieldValue('extendedLockDateTime', dateTimeString);
    } else {
      scenarioFormRef?.current?.setFieldValue('extendedLockDateTime', '');
    }
  };

  return (
    <AppLayout
      headerSelector="#h"
      notifications={<Flashbar items={notificationItems} />}
      disableContentPaddings={false}
      contentType="cards"
      tools={<PlanningCycleInfoPanel />}
      toolsOpen={toolsOpen}
      onToolsChange={({ detail }) => setToolsOpen(detail.open)}
      navigation={<AppSideNavigation />}
      navigationOpen={navigationOpen}
      onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
      breadcrumbs={<AppBreadcrumb items={ScenarioUtils.getResourceScenarioBreadcrumbs(props.type)} />}
      content={
        <ContentLayout
          disableOverlap
          header={
            <Header
              variant="h2"
              info={
                <Link variant="info" onFollow={() => setToolsOpen(true)}>
                  Info
                </Link>
              }
            >
              {'Planning cycle'}
            </Header>
          }
        >
          <Box margin={{ top: 'l', bottom: 'xl' }} padding={{ left: 'xxxl', right: 'xxxl' }}>
            <Container
              header={
                <Header variant="h2" description={formDetails.description}>
                  {formDetails.header}
                </Header>
              }
            >
              <Formik<ScenarioResourceFormModel>
                innerRef={scenarioFormRef}
                initialValues={formValues}
                validationSchema={ScenarioUtils.createScenarioValidationSchema}
                validate={formikCustomValidate}
                onSubmit={(formValues, formikHelpers) =>
                  handleSubmit(pathParams, formValues, formikHelpers, auth.Alias, appContext.userDetails.dataClassification.dataClassificationId)
                }
                enableReinitialize={true}
              >
                {({ values, touched, errors, setFieldValue, handleSubmit, isSubmitting }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Form
                        actions={
                          <SpaceBetween direction="horizontal" size="xs">
                            <Button formAction="none" variant="link" onClick={() => handleNav('/planning-cycle')}>
                              Cancel
                            </Button>
                            <Button className={`${props.type}-scenario`} formAction="submit" variant="primary" disabled={isSubmitting}>
                              {formDetails.formButtonName}
                            </Button>
                          </SpaceBetween>
                        }
                      >
                        <SpaceBetween direction="vertical" size="l">
                          <ColumnLayout columns={2}>
                            <FormField
                              label="Scenario"
                              className="form-field-select-width-20rem"
                              errorText={touched?.scenarioName && errors?.scenarioName?.label}
                              data-testid="awsui-form-select-scenario"
                            >
                              <Select
                                data-test-id="awsui-select-scenario"
                                disabled={isEditPlanningCycle}
                                selectedOption={values.scenarioName}
                                onChange={(event) => setFieldValue('scenarioName', event.detail.selectedOption)}
                                options={scenarioOptions}
                                placeholder="Choose an option"
                                filteringType="auto"
                                selectedAriaLabel="Selected"
                              />
                            </FormField>

                            <FormField
                              label="Clone from previous Scenario"
                              className="form-field-select-width-20rem"
                              errorText={touched?.baselineScenario && errors?.baselineScenario?.label}
                              data-testid="awsui-form-select-scenario"
                            >
                              <Select
                                data-test-id="awsui-select-scenario"
                                disabled={values.forecastDataAvailable}
                                selectedOption={values.baselineScenario}
                                onChange={(event) => setFieldValue('baselineScenario', event.detail.selectedOption)}
                                options={baseScenarioOptions}
                                placeholder="Choose an option"
                                filteringType="auto"
                                selectedAriaLabel="Selected"
                              />
                            </FormField>
                          </ColumnLayout>

                          <ColumnLayout columns={2}>
                            {/* ACTUALS */}
                            <FormField
                              className="form-field-select-width-20rem"
                              label={
                                <span>
                                  Actuals date range<i> - optional</i>{' '}
                                </span>
                              }
                            >
                              <DateRangePicker
                                disabled={isEditPlanningCycle}
                                onChange={(event) => setFieldValue('actualsDateRange', event.detail.value)}
                                value={values.actualsDateRange as DateRangePickerProps.Value}
                                isValidRange={() => ({ valid: true })}
                                relativeOptions={[]}
                                i18nStrings={{
                                  todayAriaLabel: 'Today',
                                  nextMonthAriaLabel: 'Next month',
                                  previousMonthAriaLabel: 'Previous month',
                                  customRelativeRangeDurationLabel: 'Duration',
                                  customRelativeRangeDurationPlaceholder: 'Enter duration',
                                  customRelativeRangeOptionLabel: 'Custom range',
                                  customRelativeRangeOptionDescription: 'Set a custom range in the past',
                                  customRelativeRangeUnitLabel: 'Unit of time',
                                  dateTimeConstraintText: 'Range must be historical actuals.',
                                  formatRelativeRange: (e) => {
                                    const t = 1 === e.amount ? e.unit : `${e.unit}s`;
                                    return `Last ${e.amount} ${t}`;
                                  },
                                  formatUnit: (e, t) => (1 === t ? e : `${e}s`),
                                  relativeModeTitle: 'Relative range',
                                  absoluteModeTitle: 'Absolute range',
                                  relativeRangeSelectionHeading: 'Choose a range',
                                  startDateLabel: 'Start date',
                                  endDateLabel: 'End date',
                                  startTimeLabel: 'Start time',
                                  endTimeLabel: 'End time',
                                  clearButtonLabel: 'Clear',
                                  cancelButtonLabel: 'Cancel',
                                  applyButtonLabel: 'Apply'
                                }}
                                isDateEnabled={ScenarioUtils.validateActualsDateRange}
                                dateOnly={true}
                                placeholder="YYYY/MM - YYYY/MM"
                                rangeSelectorMode="absolute-only"
                              />
                            </FormField>

                            <FormField
                              className="form-field-select-width-20rem"
                              label="Forecast date range"
                              errorText={touched.forecastDateRange && errors.forecastDateRange}
                            >
                              <DateRangePicker
                                disabled={isEditPlanningCycle}
                                onChange={(event) => setFieldValue('forecastDateRange', event.detail.value)}
                                value={values.forecastDateRange as DateRangePickerProps.Value}
                                isValidRange={() => ({ valid: true })}
                                relativeOptions={[]}
                                i18nStrings={{
                                  todayAriaLabel: 'Today',
                                  nextMonthAriaLabel: 'Next month',
                                  previousMonthAriaLabel: 'Previous month',
                                  customRelativeRangeDurationLabel: 'Duration',
                                  customRelativeRangeDurationPlaceholder: 'Enter duration',
                                  customRelativeRangeOptionLabel: 'Custom range',
                                  customRelativeRangeOptionDescription: 'Set a custom range in the past',
                                  customRelativeRangeUnitLabel: 'Unit of time',
                                  dateTimeConstraintText: 'Range must be future forecast.',
                                  formatRelativeRange: (e) => {
                                    const t = 1 === e.amount ? e.unit : `${e.unit}s`;
                                    return `Last ${e.amount} ${t}`;
                                  },
                                  formatUnit: (e, t) => (1 === t ? e : `${e}s`),
                                  relativeModeTitle: 'Relative range',
                                  absoluteModeTitle: 'Absolute range',
                                  relativeRangeSelectionHeading: 'Choose a range',
                                  startDateLabel: 'Start date',
                                  endDateLabel: 'End date',
                                  startTimeLabel: 'Start time',
                                  endTimeLabel: 'End time',
                                  clearButtonLabel: 'Clear',
                                  cancelButtonLabel: 'Cancel',
                                  applyButtonLabel: 'Apply'
                                }}
                                isDateEnabled={ScenarioUtils.validateForecastDateRange}
                                dateOnly={true}
                                placeholder="YYYY/MM - YYYY/MM"
                                rangeSelectorMode="absolute-only"
                              />
                            </FormField>
                          </ColumnLayout>

                          <FormField
                            className="form-field-select-width-30rem"
                            label="Planning cycle window (timezone America/Los Angeles)"
                            errorText={touched.toolWindowDateRange && errors.toolWindowDateRange}
                          >
                            <DateRangePicker
                              onChange={(event) => setFieldValue('toolWindowDateRange', event.detail.value)}
                              value={values.toolWindowDateRange as DateRangePickerProps.Value}
                              isValidRange={() => ({ valid: true })}
                              relativeOptions={[]}
                              i18nStrings={{
                                todayAriaLabel: 'Today',
                                nextMonthAriaLabel: 'Next month',
                                previousMonthAriaLabel: 'Previous month',
                                customRelativeRangeDurationLabel: 'Duration',
                                customRelativeRangeDurationPlaceholder: 'Enter duration',
                                customRelativeRangeOptionLabel: 'Custom range',
                                customRelativeRangeOptionDescription: 'Set a custom range in the past',
                                customRelativeRangeUnitLabel: 'Unit of time',
                                dateTimeConstraintText: 'Range must be planning cycle window. Use 24 hour format in America/Los Angeles time.',
                                formatRelativeRange: (e) => {
                                  const t = 1 === e.amount ? e.unit : `${e.unit}s`;
                                  return `Last ${e.amount} ${t}`;
                                },
                                formatUnit: (e, t) => (1 === t ? e : `${e}s`),
                                relativeModeTitle: 'Relative range',
                                absoluteModeTitle: 'Absolute range',
                                relativeRangeSelectionHeading: 'Choose a range',
                                startDateLabel: 'Start date',
                                endDateLabel: 'End date',
                                startTimeLabel: 'Start time',
                                endTimeLabel: 'End time',
                                clearButtonLabel: 'Clear',
                                cancelButtonLabel: 'Cancel',
                                applyButtonLabel: 'Apply'
                              }}
                              isDateEnabled={(date: Date) => true}
                              placeholder="Select plan cycle window"
                              rangeSelectorMode="absolute-only"
                              timeOffset={getPacificTimeOffset()}
                              timeInputFormat="hh:mm"
                            />
                          </FormField>

                          <FormField>
                            <Checkbox
                              onChange={({ detail }) => {
                                extendToolLockDateChanged(detail.checked);
                              }}
                              checked={values.isExtended}
                            >
                              Extend the timeline for specific users
                            </Checkbox>
                          </FormField>

                          <FormField
                            label="Extended lock Date & Time"
                            constraintText="Use 24 hour format in America/Los Angeles time."
                            errorText={touched.datePickerValue && errors.datePickerValue}
                          >
                            <SpaceBetween size="s" direction="horizontal">
                              <DatePicker
                                disabled={!values.isExtended}
                                onChange={({ detail }) => handleDateTimeChange(detail.value, values.timePickerValue)}
                                value={values.datePickerValue}
                                nextMonthAriaLabel="Next month"
                                previousMonthAriaLabel="Previous month"
                                todayAriaLabel="Today"
                                placeholder="YYYY/MM/DD"
                              />
                              <TimeInput
                                disabled={!values.isExtended}
                                onChange={({ detail }) => handleDateTimeChange(values.datePickerValue, detail.value)}
                                value={values.timePickerValue}
                                format="hh:mm"
                                placeholder="hh:mm"
                                use24Hour={true}
                              />
                            </SpaceBetween>
                          </FormField>

                          <FormField
                            label="Users"
                            // @ts-ignore
                            errorText={touched.extendedToUsers && errors.extendedToUsers?.label}
                          >
                            <Multiselect
                              className="form-field-select-width-30rem"
                              disabled={!values.isExtended}
                              placeholder={getMultiSelectPlaceHolderValue(values.extendedToUsers, 'Users')}
                              selectedAriaLabel="Selected"
                              filteringType="auto"
                              hideTokens
                              selectedOptions={values.extendedToUsers}
                              onChange={({ detail }) => setFieldValue('extendedToUsers', detail.selectedOptions)}
                              deselectAriaLabel={(e) => `Remove ${e.label}`}
                              options={appContext?.users?.map((user) => {
                                return { label: user.userAlias, value: user.userAlias, description: user.userName };
                              })}
                            />
                          </FormField>
                        </SpaceBetween>
                      </Form>
                    </form>
                  );
                }}
              </Formik>
            </Container>
          </Box>
        </ContentLayout>
      }
    />
  );
};
